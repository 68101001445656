import produce from "immer";
import { selector } from "recoil";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate, isSyncable } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { subjectsState, subjectMetaState, subjectsSelectState } from "./subject.atoms";

export const subjectsSelector = selector({
  key: "subjectsSelector",
  get: ({ get }) => {
    const subjects = produce(get(subjectsState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.SUBJECT);
    return subjects.map((item, i) => {
      const { updatedAt, deletedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const subjectsSelectSelector = selector({
  key: "subjectsSelectSelector",
  get: ({ get }) => {
    const subjects = produce(get(subjectsSelectState), draft => draft);
    return subjects.map((item, i) => {
      const { title, code } = item;
      return { ...item, name: `[${code}]-${title}` };
    });
  }
});

export const subjectMetaSelector = selector({
  key: "subjectMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(subjectMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
