// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Course } from "./course.types";

export const coursesState = atom<Course[]>({
  key: "coursesState",
  default: []
});

export const courseMetaState = atom<Meta>({
  key: "courseMetaState",
  default: {}
});

export const courseState = atom<Course | {}>({
  key: "courseState",
  default: {}
});

export const courseLoadingState = atom<boolean>({
  key: "courseLoadingState",
  default: false
});
