import ICON_USER from "../assets/icons/user.svg";
import ICON_STACK from "../assets/icons/stack.svg";
import ICON_DELETE from "../assets/icons/delete.png";
import ICON_EDIT from "../assets/icons/edit.png";
import ICON_DUPLICATE from "../assets/icons/duplicate.png";
import ICON_VIDEO from "../assets/images/video-icon.jpg";

export const ImagePaths = {
  ICON_USER,
  ICON_STACK,
  ICON_DELETE,
  ICON_DUPLICATE,
  ICON_EDIT,
  ICON_VIDEO
};
