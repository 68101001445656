import moment from 'moment';
import _ from 'lodash';
import { STORAGE_PATH_ENV } from '../constants/environment.const';
import {
  FirestoreTimestampType,
  LooseObject,
  ToSort,
} from '../types/global.types';
import { OptionType } from '../components/form/select/select.type';
import {
  QUESTION_TYPE,
  ROLE_TYPE,
  SORT,
} from '../constants/default-value.const';
import { getTopicsSelectAsync } from '../atom/topic/topic.apis';
import produce from 'immer';
import { mapTopicsSelect } from '../atom/topic/topic.selectors';
import { FormInstance } from 'antd';
import { PrefixPermission } from '../constants/permission.const';
export const formatDateFirestore = (
  objDate: FirestoreTimestampType,
  format = 'DD/MM/YYYY HH:mm:ss'
) => {
  return moment(objDate.seconds * 1000).format(format);
};

export const getTitle = (data: Object) => {
  return _.get(data, 'translate.km.title', '');
};

export const getId = (data: Object) => {
  return _.get(data, 'id', '');
};

export const getStoragePath = (path: string) => {
  if (_.isNil(path)) return '';
  return STORAGE_PATH_ENV.replace('@@@', `images%2F${path}`);
};

export const sleep = (duration: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('done...');
    }, duration);
  });
};

export const formatDate = (date: any, format = 'DD/MM/YYYY') => {
  if (!date) return '';

  return moment(date).format(format);
};

export const isSyncable = (item: any) => {
  if (!item.syncAt || !item.updatedAt) return false;
  if (moment(item.syncAt).valueOf() < moment(item.updatedAt).valueOf())
    return true;

  return false;
};

export const sortString = (item1: string, item2: string) => {
  if (item1 < item2) return -1;
  if (item1 > item2) return 1;
  return 0;
};

export const removeUndefined = (data: LooseObject = {}) => {
  // for (let key in data) {
  //   if (data[key] === undefined) {
  //     delete data[key];
  //   }
  // }

  // return data;

  const newData: any = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== undefined) newData[key] = data[key];
  });
  return { ...newData };
};

export const toString = (item: any): string => {
  if (_.isArray(item)) {
    return item.toString();
  }
  return '';
};

export const convertObjectArrayToString = (info: any) => {
  let tmp: any = {};
  if (info.subjectId) tmp.subjectId = toString(info.subjectId);
  if (info.levelId) tmp.levelId = toString(info.levelId);
  if (info.topicId) tmp.topicId = toString(info.topicId);
  if (info.categoryId) tmp.categoryId = toString(info.categoryId);

  return { ...info, ...tmp };
};

export const exclude = (data: any, properties: string[] = []) => {
  const newData: any = {};
  Object.keys(data).forEach((key) => {
    if (!properties.includes(key)) newData[key] = data[key];
  });
  return { ...newData };
};

export const toNumberFilter = (value: string) => {
  if (value) return value.split(',').map((item: string) => Number(item));
  return [];
};

export const toStringFilter = (value: string) => {
  if (value) return value.split(',').map((item: string) => item);
  return [];
};

export const toSort = (data: ToSort) => {
  return {
    order: _.get(data, 'sortOrder', ''),
    columnKey: _.get(data, 'sortField', ''),
  };
};

export const toSortRank = () => {
  return toSort({ sortField: 'rank', sortOrder: SORT.ASC });
};

export const canSort = (
  option: any = {},
  defaultProperty: string[] = ['levelId', 'subjectId']
) => {
  const { sortField, sortOrder } = option;

  let invalid = false;
  Object.entries(option).forEach(([key, value]: [string, any]) => {
    if (
      ![
        'sortField',
        'sortOrder',
        'showAll',
        'page',
        'offset',
        'queryType',
        ...defaultProperty,
      ].includes(key)
    ) {
      if (value) invalid = true;
    }
  });

  if (invalid) return false;

  let check = defaultProperty.every((item: string) => {
    if (option[item]) {
      return toStringFilter(option[item]).length === 1;
    }

    return false;
  });

  return sortField === 'rank' && sortOrder === SORT.ASC && check;
};

export const canSortRow = (
  option: any = {},
  defaultProperty: string[] = ['levelId', 'subjectId']
) => {
  let invalid = false;
  Object.entries(option).forEach(([key, value]: [string, any]) => {
    if (
      ![
        'sortField',
        'sortOrder',
        'showAll',
        'page',
        'offset',
        'queryType',
        ...defaultProperty,
      ].includes(key)
    ) {
      if (value) invalid = true;
    }
  });

  if (invalid) return false;

  let check = defaultProperty.every((item: string) => {
    if (option[item]) {
      return toStringFilter(option[item]).length === 1;
    }

    return false;
  });

  return check;
};

export const canShowAll = (option: any = {}, filteredInfo = {}) => {
  if (option.searchValue) return true;
  if (
    Object.values(filteredInfo)
      .filter((item) => item)
      .flat().length
  )
    return true;

  return false;
};

export const removeParam = (routePath: string) => {
  return routePath.replace('/:id', '');
};

export const replaceParam = (routePath: string, id: string) => {
  return _.trim(routePath.replace(':id', id), '?');
};

export const toFilterSelect = (
  data: OptionType[],
  columnKey: string = 'name'
) => {
  return data.map((item: any) => {
    const { id } = item;
    return { value: id, text: item[columnKey] };
  });
};

export const toNewRecordActivities = (
  activities: any,
  check: boolean | undefined,
  defaultKey: string = 'activities'
) => {
  if (!check) {
    return activities;
  }
  return activities.map((item: any) => {
    return {
      ...item,
      isNewRecord: true,
      [defaultKey]: item[defaultKey].map((item2: any) => {
        return { ...item2, isNewRecord: true };
      }),
    };
  });
};

export const toNewRecordQuestions = (
  questions: any,
  check: boolean | undefined,
  isTest: boolean = false
) => {
  if (!check) {
    return questions;
  }

  if (!_.get(questions, `questions`))
    return questions
      .map((item: any) => {
        return {
          ...item,
          isNewRecord: true,
        };
      })
      .filter((item3: any) => {
        if (isTest) {
          return item3.questionType !== QUESTION_TYPE.GROUP;
        }
        return true;
      });

  return questions.map((item: any) => {
    return {
      ...item,
      isNewRecord: true,
      questions: item.questions
        .map((item2: any) => {
          return { ...item2, isNewRecord: true };
        })
        .filter((item3: any) => {
          if (isTest) {
            return item3.questionType !== QUESTION_TYPE.GROUP;
          }
          return true;
        }),
    };
  });
};

export const toNewRecords = (questions: any, check: boolean | undefined) => {
  if (!check) {
    return questions;
  }

  return questions.map((item: any) => {
    return {
      ...item,
      isNewRecord: true,
    };
  });
};

export const setSelectFilter = ({
  defaultFilter,
  dataSource,
  form,
  elementId,
}: {
  dataSource: any;
  defaultFilter: any;
  form: FormInstance;
  elementId: string;
}) => {
  let id = _.get(dataSource, 'id');
  // let levelId = _.get(dataSource, "level.id");
  // let subjectId = _.get(dataSource, "subject.id");
  // let topicId = _.get(dataSource, "topic.id");
  // if (_.size(defaultFilter)) {
  let levelId = _.get(defaultFilter, 'levelId');
  let subjectId = _.get(defaultFilter, 'subjectId');
  let topicId = _.get(defaultFilter, 'topicId');
  // }

  if (subjectId && levelId && !id) {
    form.setFieldsValue({
      subjectId,
      levelId,
    });
    if (topicId) {
      form.setFieldsValue({
        topicId,
      });
    }
    document.getElementById(elementId)?.click();
  } else if (_.get(dataSource, 'subject.id') && _.get(dataSource, 'level.id')) {
    document.getElementById(elementId)?.click();
  }
};

export const requestTopicDefault = ({
  defaultFilter,
  dataSource,
  // appFilter,
  setAppFilter,
  filterKey,
}: {
  defaultFilter: any;
  dataSource: any;
  appFilter?: any;
  setAppFilter: any;
  filterKey: string;
}) => {
  let id = _.get(dataSource, 'id');
  let levelId = _.get(dataSource, 'level.id');
  let subjectId = _.get(dataSource, 'subject.id');
  let topicId = _.get(dataSource, 'topic.id');
  if (_.size(defaultFilter) && !id) {
    levelId = _.get(defaultFilter, 'levelId');
    subjectId = _.get(defaultFilter, 'subjectId');
    topicId = _.get(defaultFilter, 'topicId');
  }

  // console.log({ topicId, subjectId, levelId, dataSource, defaultFilter, id });

  getTopicsSelectAsync({
    s_subjectId: subjectId,
    s_levelId: levelId,
  }).then((res) => {
    setAppFilter((appFilter: any) => {
      let app = produce<any, any>(appFilter, (draft: any) => {
        _.set(draft, `${filterKey}.topics`, mapTopicsSelect(res));
        _.set(draft, `${filterKey}.subjectId`, subjectId);
        _.set(draft, `${filterKey}.levelId`, levelId);
        _.set(draft, `${filterKey}.topicId`, topicId);
        return draft;
      });
      return { ...app };
    });
    // setAppFilter({ ...app });
  });
};

export const isAdmin = (profile: any) => {
  return _.get(profile, 'roleAlias') === ROLE_TYPE.ADMIN;
};

export const isCreator = (profile: any) => {
  return _.get(profile, 'roleAlias') === ROLE_TYPE.CONTENT_CREATOR;
};

export const checkAvailable = (
  profile: any,
  can: boolean,
  createdBy: string | undefined,
  permission: string = ''
) => {
  if (isAdmin(profile)) return true;

  if (createdBy) {
    if (isCreator(profile) && can) {
      // return true;

      // return can;
      if (permission === 'view') return can;

      return profile?.id === createdBy;
    }
  }

  return can;
};

export const checkPermission = (
  profile: any = {},
  prefixPermission: string | undefined
) => {
  if (isAdmin(profile) && !prefixPermission) {
    return {
      view: true,
      delete: true,
      update: true,
      create: true,
      list: true,
      listTrash: true,
      restore: true,
    };
  }

  // if (isAdmin(profile)) {
  // }

  let isCreate = false;
  if (prefixPermission === PrefixPermission.LIBRARY) {
    isCreate = _.get(profile, `permissions.library:textlibrary:create`)
      ? true
      : false;
  }

  return {
    view: _.get(profile, `permissions.${prefixPermission}:findOne`)
      ? true
      : false,
    delete: _.get(profile, `permissions.${prefixPermission}:remove`)
      ? true
      : false,
    update: _.get(profile, `permissions.${prefixPermission}:update`)
      ? true
      : false,
    create:
      _.get(profile, `permissions.${prefixPermission}:create`) || isCreate
        ? true
        : false,
    list: _.get(profile, `permissions.${prefixPermission}:findAll`)
      ? true
      : false,
    listTrash: _.get(profile, `permissions.${prefixPermission}:trash`)
      ? true
      : false,
    // status: true,
    // comingSoon: true,
    // premium: true,
    // sort: true,
    restore: _.get(profile, `permissions.${prefixPermission}:restore`)
      ? true
      : false,
  };
};

export const measureText = (str: string, fontSize: number = 10) => {
  if (!str) return 0;
  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875,
    0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125,
    0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625,
    0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625,
    0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875,
    0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875,
    0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875,
    0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5,
    0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625,
    0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5,
    0.3546875, 0.259375, 0.353125, 0.5890625,
  ];
  const avg = 0.5279276315789471;
  return (
    str
      .split('')
      .map((c) =>
        c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg
      )
      .reduce((cur, acc) => acc + cur) * fontSize
  );
};

// console.log("------------", measureText("Hello world")); // Output: 49.40625000000001
// console.log("---------", measureText("This is a long text")); // Output: 77.74999999999999
// console.log(measureText("i")); // Output: 2.21875

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
};

export const getTagCode = (text: string): { code: string; text: string } => {
  let matchString = Array.from(text.matchAll(/\[(.*?)\]-/g), (x) => x[1]);
  if (matchString.length) {
    const m = text.match(/\[(.*?)\]-/g);
    if (m) {
      return { code: matchString[0], text: text.replace(m[0], '') };
    }
  }

  return { code: matchString[0], text };
};

export const parseJson = (key: string, isObject = false) => {
  try {
    return JSON.parse(key);
  } catch (err) {
    return isObject ? {} : [];
  }
};

export const parseJsonLocalStorage = (key: string, isObject = false) => {
  return parseJson(localStorage.getItem(key) || '', isObject);
};

export const getVimeoVideoId = (vimeoId: string) => {
  const videoId = vimeoId.replace('https://vimeo.com/', '');
  return _.get(videoId.split('/'), '0');
};
