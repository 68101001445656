export enum RoutePaths {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  HOME = '/',

  COURSES = '/contents/courses',
  COURSES_FORM = '/contents/courses/form/:id?',

  REVIEWS = '/contents/reviews',
  REVIEWS_FORM = '/contents/reviews/form/:id?',

  EXAM_TESTS = '/contents/exam-tests',
  EXAM_TESTS_FORM = '/contents/exam-tests/form/:id?',

  LEARNS = '/contents/learns',
  LEARNS_FORM = '/contents/learns/form/:id?',

  COMPETITION_TESTS = '/contents/competition-tests',
  COMPETITION_TESTS_FORM = '/contents/competition-tests/form/:id?',

  COMPETENCY_TESTS = '/contents/competency-tests',
  COMPETENCY_TESTS_FORM = '/contents/competency-tests/form/:id?',

  PRACTICE_TESTS = '/contents/practice-tests',
  PRACTICE_TESTS_FORM = '/contents/practice-tests/form/:id?',

  RESOURCES = '/contents/resources',
  RESOURCES_FORM = '/contents/resources/form/:id?',

  COMPETENCIES = '/elements/competencies',
  COMPETENCIES_FORM = '/elements/competencies/form/:id?',

  TEXT_LIBRARIES = '/elements/text-libraries',
  TEXT_LIBRARIES_FORM = '/elements/text-libraries/form/:id?',

  QUESTIONS = '/elements/questions',
  QUESTIONS_INPUT_FORM = '/elements/questions-input/form/:id?',
  QUESTIONS_MCQ_FORM = '/elements/questions-mcq/form/:id?',
  QUESTIONS_GROUP_FORM = '/elements/questions-group/form/:id?',
  QUESTIONS_ORDER_FORM = '/elements/questions-order/form/:id?',
  QUESTIONS_FILL_GAP_FORM = '/elements/questions-fill_gap/form/:id?',
  QUESTIONS_DRAG_DROP_FORM = '/elements/questions-drag_drop/form/:id?',

  VIDEOS = '/elements/videos',
  VIDEOS_FORM = '/elements/videos/form/:id?',
  VIDEOS_OPTION = '/elements/videos/option',

  CONTENT_CATEGORIES = '/manages/content-categories',
  SUBJECTS = '/manages/content-categories/subjects',
  LEVELS = '/manages/content-categories/levels',
  TOPICS = '/manages/content-categories/topics',

  CATEGORY_TYPES = '/manages/category-types',
  TEST_CATEGORIES = '/manages/category-types/test-categories',
  RESOURCE_CATEGORIES = '/manages/category-types/resource-categories',

  COLOR_AND_PLACEHOLDERS = '/manages/color-placeholders',
  IMAGES = '/manages/color-placeholders/images',
  ICONS = '/manages/color-placeholders/icons',
  COLORS = '/manages/color-placeholders/colors',

  USER_ROLES = '/manages/user-roles',
  USERS = '/manages/user-roles/users',
  ROLES = '/manages/user-roles/roles',
  APP_FEATURES = '/manages/app-features',

  EDITOR = '/editor',

  // PREVIEWS = "/previews",
  // PREVIEW_SUBJECTS = "/previews/subjects",
  // PREVIEW_COURSES = "/previews/courses",
  // PREVIEW_COURSE_SECTIONS = "/previews/courses/sections",
  // PREVIEW_COURSE_SECTION_DETAIL = "/previews/courses/sections/details",

  DASHBOARD = '/reports/dashboard',

  REPORT = '/reports/reports-issues',
  REPORT_USER = '/reports/reports/user-activity',
  REPORT_USER_COURSE = '/reports/reports/user/course',
  REPORT_USER_COURSE_LEARN = '/reports/reports/user/course-learn',
  REPORT_USER_COURSE_DAILY = '/reports/reports/user/course-daily',

  USAGE = '/reports/reports/usage',
  USAGE_BY_OVERALL = '/reports/usage/by-overall',
  USAGE_BY_WEEK = '/reports/usage/by-week',
  USAGE_BY_DAILY = '/reports/usage/by-daily',
  USAGE_BY_SUBJECT = '/reports/usage/by-subject',
  USAGE_BY_USER = '/reports/usage/by-user',
  USAGE_BY_COURSE_LEARN = '/reports/usage/by-course-learn',

  MOBILE_PREVIEW = '/mobile-preview',
  MOBILE_PREVIEW_RESOURCE = '/mobile-preview/flow-resource/:id',
  MOBILE_PREVIEW_LEARN = '/mobile-preview/flow-learn/:id',
  MOBILE_PREVIEW_REVIEW = '/mobile-preview/flow-review/:id',
  MOBILE_PREVIEW_COMPETENCY_TEST = '/mobile-preview/flow-competency-test/:id',
  MOBILE_PREVIEW_COMPETITION_TEST = '/mobile-preview/flow-competition-test/:id',
  MOBILE_PREVIEW_PRACTICE_TEST = '/mobile-preview/flow-practice-test/:id',
  MOBILE_PREVIEW_COMPETENCY = '/mobile-preview/flow-competency/:id',
  MOBILE_PREVIEW_EXAM_TEST = '/mobile-preview/flow-exam-test/:id',
  MOBILE_PREVIEW_COURSE = '/mobile-preview/flow-course/:id',
}
