// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from 'recoil';
import { Meta } from '../../types/global.types';
import { User, UserSelect } from './user.types';

export const usersState = atom<User[]>({
  key: 'usersState',
  default: [],
});

export const usersSelectState = atom<UserSelect[]>({
  key: 'usersSelectState',
  default: [],
});

export const userMetaState = atom<Meta>({
  key: 'userMetaState',
  default: {},
});

export const userLoadingState = atom<boolean>({
  key: 'userLoadingState',
  default: false,
});
