import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { RestoreType, SortData } from "../../types/global.types";
import { Subject } from "./subject.type";

export const getSubjectsAsync = (option: {}) => {
  const url = generateQuery("/subjects", option, [
    "comingSoon",
    "status",
    "grade",
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getSubjectsSelectAsync = () => {
  const url = generateQuery("/subjects/for/select-option");
  return axios.get(url, config()).then(response => response.data.data);
};

export const getSubjectsTrashAsync = (option: {}) => {
  const url = generateQuery("/subjects/list/trash", option, [
    "comingSoon",
    "status",
    "grade",
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const createSubjectAsync = (data: Subject) => {
  return axios.post("/subjects", data, config()).then(response => response.data);
};

export const restoreSubjectsAsync = (data: RestoreType) => {
  return axios.patch("/subjects/restore", data, config()).then(response => response.data);
};

export const updateSubjectAsync = (id: string, data: Subject) => {
  return axios.put(`/subjects/${id}`, data, config()).then(response => response.data);
};

export const deleteSubjectAsync = (id: string) => {
  return axios.delete(`/subjects/${id}`, config()).then(response => response.data);
};

export const sortSubjectsAsync = (data: SortData) => {
  return axios.patch("/subjects/set-rank", data, config()).then(response => response.data);
};

export const subjectsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/subjects/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};
