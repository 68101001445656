import { useRecoilState } from "recoil";
import {
  topicLevelIdState,
  topicLoadingState,
  topicSubjectIdState,
  topicTopicIdState
} from "./topic.atoms";

export const useTopic = () => {
  const [topicSubjectId, setTopicSubjectId] = useRecoilState(topicSubjectIdState);
  const [topicLevelId, setTopicLevelId] = useRecoilState(topicLevelIdState);
  const [topicTopicId, setTopicTopicId] = useRecoilState(topicTopicIdState);
  const [loading, setLoading] = useRecoilState(topicLoadingState);

  return {
    setTopicSubjectId,
    setTopicLevelId,
    topicSubjectId,
    topicLevelId,
    topicTopicId,
    setTopicTopicId,
    loading,
    setLoading
  };
};
