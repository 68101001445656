import { Fragment, useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { v4 as uuid } from "uuid";
import { ButtonAddBrowse } from "../../../../components/button-add-browse/button-add-browse.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { SortableItems } from "../../../../components/sortable-items/sortable-items.component";
import { TitleItem } from "../../../../components/title-item/title-item.component";
import { FormActivityModal } from "../../../../components/form-activity-modal/form-activity-modal.component";
import { LibraryModal } from "../../library/library-modal.component";
import { FormInfoSection } from "../../../../components/form-info-section/form-info-section.component";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import _ from "lodash";
import useRoute from "../../../../hooks/use-route.hook";
import {
  createCompetencyAsync,
  deleteCompetencySourceAsync,
  deleteCompetencySourceLibraryAsync,
  deleteCompetencySourcesAsync,
  updateCompetencyAsync
} from "../../../../atom/competency/competency.apis";
import {
  messageError,
  messageSuccess,
  // messageWarn,
  showDeleteConfirm,
  showMessageErrors
} from "../../../../utils/message.utils";
import {
  FILTER_QUESTION_TYPES,
  FORM_TYPE,
  LIBRARY_TYPE
} from "../../../../constants/default-value.const";
import { useCompetency } from "./competency.hook";
import produce from "immer";
import { LibraryFormModal } from "../../library/library-form-modal.component";
import { formatActivities, formatQuestions } from "../../../../utils/request.utils";
import { Activity, FormActivityType, LooseObject, Question } from "../../../../types/global.types";
import { CompetencyFormType } from "../../../../atom/competency/competency.types";
import { FormModalProps } from "../../library/library.types";
import {
  exclude,
  requestTopicDefault,
  setSelectFilter,
  toNewRecordActivities,
  toNewRecordQuestions
} from "../../../../utils/helper.utils";
import { useApp } from "../../../../atom/app/app.hook";
import { useTranslation } from "react-i18next";
import { checkFormRedirect } from "../../../../utils/form.utils";
import { assignIndexKey } from "../../../../utils/response.utils";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

enum MODAL_TYPE {
  ACTIVITY_FROM = "ACTIVITY_FROM",
  LIBRARY_ACTIVITY = "LIBRARY_ACTIVITY",
  LIBRARY_ACTIVITY_FORM = "LIBRARY_ACTIVITY_FORM",
  LIBRARY_ACTIVITY_FORM_EDIT = "LIBRARY_ACTIVITY_FORM_EDIT",
  LIBRARY_QUESTION = "LIBRARY_QUESTION",
  LIBRARY_QUESTION_FORM = "LIBRARY_QUESTION_FORM",
  LIBRARY_QUESTION_FORM_EDIT = "LIBRARY_QUESTION_FORM_EDIT"
}

export const CompetencyForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false
}: FormModalProps) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [libraryType, setLibraryType] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [modalType, setModalType] = useState("");
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const [forceRender, setForceRender] = useState(false);
  useEnterEvent({ form, enter: !modalType });
  const {
    history,
    pathname,
    query: { formType },
    param: { id }
  } = useRoute();
  const { levels, subjects, topics } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true
  });
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  const { t } = useTranslation();
  const { loading, competency: competencyData, setCompetency: setCompetencyData } = useCompetency({
    id: formModal ? formId : id,
    forceRender
  });

  useEffect(() => {
    if (_.isNil(defaultFilter)) {
      return () => {
        setAppFilter({});
      };
    }
  }, [setAppFilter, defaultFilter]);

  useEffect(() => {
    if (!formId && formModal) {
      setCompetencyData({});
    }
  }, [setCompetencyData, formId, formModal]);

  useEffect(() => {
    form.resetFields();
    // if (_.size(competencyData)) {
    const levelId = _.get(competencyData, "level.id");
    const subjectId = _.get(competencyData, "subject.id");
    form.setFieldsValue({
      ...competencyData,
      topicId: _.get(competencyData, "topic.id"),
      subjectId,
      levelId
    });
    const check = formType === FORM_TYPE.DUPLICATE || formDuplicate;
    setActivities(toNewRecordActivities(_.get(competencyData, `activities`, []), check));
    setQuestions(toNewRecordQuestions(_.get(competencyData, `questions`, []), check));
    setSelectFilter({
      dataSource: competencyData,
      defaultFilter,
      form,
      elementId: "competencyRequest"
    });
    // }
  }, [competencyData, form, formType, defaultFilter, formDuplicate]);

  const onFinish = (value: CompetencyFormType) => {
    const sources = [
      ...formatActivities(activities, ["competencySourceLibraries", "activities"]),
      ...formatQuestions(questions, "competencySourceLibraries")
    ];
    // if (!sources.length) {
    //   messageWarn("Activities or questions is required");
    //   return false;
    // }
    const data = {
      ...value,
      competencySources: sources
    };

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateCompetencyAsync(formId, data)
          : createCompetencyAsync(data);
      setAppLoading(true);
      request
        .then(res => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch(err => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT ? updateCompetencyAsync(id, data) : createCompetencyAsync(data);

    setAppLoading(true);
    request
      .then(res => {
        messageSuccess();
        let check = checkFormRedirect({ history, pathname, id: res.data.id, formType, isBack });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch(err => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEnd = (items: Activity[]) => {
    setActivities(items);
  };

  const onSortEndQuestion = (items: Question[]) => {
    setQuestions(items);
  };

  const onSortEndChild = (items: Activity[]) => {
    setActivities(items);
  };

  const onEdit = (value: Activity) => {
    setDataSource(value);
    setModalType(MODAL_TYPE.ACTIVITY_FROM);
  };

  const onDelete = ({ id, isNewRecord }: Activity) => {
    showDeleteConfirm(() => {
      const filterActivities = produce<Activity[], Activity[]>(activities, draft => {
        return draft.filter(item => item.id !== id);
      });
      if (isNewRecord) setActivities(filterActivities);
      else
        deleteCompetencySourceAsync(id)
          .then(res => {
            setActivities(filterActivities);
          })
          .catch(err => {
            showMessageErrors(err);
          });
    });
  };

  const onDuplicate = (item: Activity) => {
    const newActivities = produce<Activity[], Activity[]>(activities, draft => {
      draft.push({ ...item, id: uuid(), isNewRecord: true });
      return draft;
    });
    setActivities(newActivities);
  };

  const onEditQuestion = (value: Question) => {
    setDataSource(exclude(value, ["isNewRecord"]));
    setLibraryType(value.type);
    setQuestionType(value.questionType);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT);
  };

  const onDeleteQuestion = ({ id, isNewRecord }: Question) => {
    showDeleteConfirm(() => {
      const filterActivities = produce<Question[], Question[]>(questions, draft => {
        return draft.filter(item => item.id !== id);
      });
      if (isNewRecord) setQuestions(filterActivities);
      else
        deleteCompetencySourceAsync(id)
          .then(res => {
            setQuestions(filterActivities);
          })
          .catch(err => {
            showMessageErrors(err);
          });
    });
  };

  const onDeleteQuestionAll = () => {
    showDeleteConfirm(() => {
      const ids = questions.map((q: Question) => q.id);
      deleteCompetencySourcesAsync(ids)
        .then(() => {
          setQuestions([]);
        })
        .catch(err => {
          showMessageErrors(err);
        });
    });
  };

  const onDuplicateQuestion = (item: Question) => {
    setQuestionType(item.questionType);
    setLibraryType(item.type);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onEditChild = (value: Question) => {
    const { type, questionType } = value;
    setDataSource(exclude(value, ["isNewRecord"]));
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT);
    setLibraryType(type);
    setQuestionType(questionType);
  };

  const onDeleteChild = ({ id, parentIndex, isNewRecord }: Question) => {
    showDeleteConfirm(() => {
      const filterActivities = produce<Activity[], Activity[]>(activities, draft => {
        const filterChildren = draft[parentIndex].activities.filter(item => item.id !== id);
        draft[parentIndex].activities = filterChildren;
        return draft;
      });
      if (isNewRecord) {
        setActivities(filterActivities);
      } else {
        deleteCompetencySourceLibraryAsync(id)
          .then(res => {
            messageSuccess();
            setActivities(filterActivities);
          })
          .catch(err => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onDuplicateChild = (item: Question) => {
    setQuestionType(item.questionType);
    setLibraryType(item.type);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM);
  };

  const onCreateActivity = () => {
    setDataSource({});
    setModalType(MODAL_TYPE.ACTIVITY_FROM);
  };

  const onCreateQuestion = (type: string) => {
    setQuestionType(type);
    setDataSource({});
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onBrowseQuestion = (type: string) => {
    setLibraryType(LIBRARY_TYPE.QUESTION);
    setQuestionType(type);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION);
  };

  const onOkFormActivity = (values: FormActivityType) => {
    const { title, id } = values;
    const newActivities = produce<Activity[], Activity[]>(activities, draft => {
      if (id) {
        let index = draft.findIndex(item => item.id === id);
        draft[index]["title"] = title;
      } else
        draft.push({
          id: uuid(),
          rank: draft.length + 1,
          title,
          isNewRecord: true,
          activities: []
        });
    });
    setActivities(newActivities);
    onCancel();
  };

  const onOkBrowseLibrary = (values: Question[]) => {
    const { parentIndex } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY) {
      const modifyActivities = produce<Activity[], Activity[]>(activities, draft => {
        if (!_.get(draft, `${parentIndex}.activities`))
          _.set(draft, `${parentIndex}.activities`, []);

        const activities = draft[parentIndex].activities;
        const filterValues = values.filter(
          item => !activities.map(a => a.libraryId).includes(item.id)
        );
        if (filterValues.length !== values.length) messageError("Some of library is duplicate");
        draft[parentIndex].activities = [...activities, ...filterValues];
        return draft;
      });
      setActivities(
        assignIndexKey({ dataSources: modifyActivities, childKey: ["activities", "children"] })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION) {
      const filterValues = values.filter(
        item => !questions.map(a => a.libraryId).includes(item.id)
      );

      if (filterValues.length !== values.length) messageError("Some of library is duplicate");
      setQuestions(assignIndexKey({ dataSources: [...questions, ...filterValues] }));
    }

    onCancel();
  };

  const onCancel = () => {
    setModalType("");
    setDataSource({});
    setLibraryType("");
    setQuestionType("");
  };

  const onBrowseLibrary = (item: Question) => {
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY);
    setLibraryType(item.type);
    setQuestionType(item.questionType);
    setDataSource(item);
  };

  const onCreateLibrary = (item: Activity) => {
    setDataSource(item);
    setLibraryType(item.type || "");
    setQuestionType(item.questionType || "");
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM);
  };

  const onOkFormLibrary = (value: LooseObject) => {
    const { id, libraryId, indexKey } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM) {
      const modifyActivities = produce<Activity[], Activity[]>(activities, draft => {
        if (!_.get(draft, `${indexKey[0]}.activities`))
          _.set(draft, `${indexKey[0]}.activities`, []);
        draft[indexKey[0]].activities.push({ ...value, isNewRecord: true, libraryId: value.id });
        return draft;
      });
      setActivities(
        assignIndexKey({ dataSources: modifyActivities, childKey: ["activities", "children"] })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT) {
      const modifyActivities = produce<Activity[], Activity[]>(activities, draft => {
        if (indexKey.length === 2)
          draft[indexKey[0]].activities[indexKey[1]] = {
            ...value,
            id,
            libraryId
          };
        if (indexKey.length === 3)
          draft[indexKey[0]].activities[indexKey[1]].children[indexKey[2]] = {
            ...value,
            id,
            libraryId
          };
        return draft;
      });

      setActivities(modifyActivities);
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM) {
      setQuestions(
        assignIndexKey({
          dataSources: [...questions, { ...value, isNewRecord: true, libraryId: value.id }]
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT) {
      const modifyQuestions = produce<Question[], Question[]>(questions, draft => {
        if (indexKey.length === 1) draft[indexKey[0]] = { ...value, id, libraryId } as Question;
        if (indexKey.length > 1) {
          draft[indexKey[0]].children[indexKey[1]] = {
            ...value,
            id,
            libraryId
          };
        }

        return draft;
      });

      setQuestions(assignIndexKey({ dataSources: modifyQuestions }));
    }
    onCancel();
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div
          onClick={() =>
            requestTopicDefault({
              dataSource: competencyData,
              defaultFilter,
              appFilter,
              setAppFilter,
              filterKey: "competency"
            })
          }
          id="competencyRequest"
        />
        <FormActivityModal
          visible={modalType === MODAL_TYPE.ACTIVITY_FROM}
          onOk={onOkFormActivity}
          onCancel={onCancel}
          title={t("Form Activity")}
          dataSource={dataSource}
        />
        <LibraryModal
          visible={
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY || modalType === MODAL_TYPE.LIBRARY_QUESTION
          }
          onOk={onOkBrowseLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          defaultFilter={_.get(appFilter, "competency", {})}
        />
        <LibraryFormModal
          visible={
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM ||
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT
          }
          key={`${modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM ||
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT}`}
          onOk={onOkFormLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, "competency")}
        />
        <FormWrapper form={form} name="competencyForm" onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={8} lg={8}>
              <FormInfoSection
                levels={levels}
                topics={topics}
                subjects={subjects}
                filterKey={"competency"}
              />
            </Col>
            <Col sm={24} md={16} lg={16}>
              <TitleItem title={t("Activities")} />

              <SortableItems
                dataSources={activities}
                onSortEnd={onSortEnd}
                onSortEndChild={onSortEndChild}
                onEdit={onEdit}
                onDuplicate={onDuplicate}
                onDelete={onDelete}
                onEditChild={onEditChild}
                onDuplicateChild={onDuplicateChild}
                onDeleteChild={onDeleteChild}
                onBrowseLibrary={onBrowseLibrary}
                onCreateLibrary={onCreateLibrary}
                childItemsKey={["activities", "children"]}
              />
              <ButtonAddBrowse onCreate={onCreateActivity} />
              <TitleItem title={t("Question")} onClear={onDeleteQuestionAll} />
              <SortableItems
                dataSources={questions}
                onSortEnd={onSortEndQuestion}
                onEdit={onEditQuestion}
                onEditChild={onEditQuestion}
                onDuplicate={onDuplicateQuestion}
                onDelete={onDeleteQuestion}
                childItemsKey={["children"]}
              />
              <ButtonAddBrowse
                titleCreate={t("Add Question")}
                titleBrowse={t("Link Question")}
                onCreates={onCreateQuestion}
                onBrowses={onBrowseQuestion}
                options={FILTER_QUESTION_TYPES}
              />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </Fragment>
  );
};
