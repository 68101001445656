import { ColorBox, WrapPreviewColor } from "./color-preview.styles";

export const ColorPreview = ({ color }: { color?: string }) => {
  return (
    <WrapPreviewColor>
      <ColorBox className="color-box" color={color} />
      {color}
    </WrapPreviewColor>
  );
};
