import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { ButtonAddBrowse } from '../../../../components/button-add-browse/button-add-browse.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { SortableItems } from '../../../../components/sortable-items/sortable-items.component';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import _ from 'lodash';
import { FORM_TYPE } from '../../../../constants/default-value.const';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import useRoute from '../../../../hooks/use-route.hook';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import {
  messageError,
  messageSuccess,
  messageWarn,
  showDeleteConfirm,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { LooseObject } from '../../../../types/global.types';
import produce from 'immer';
import { TextEditorForm } from '../../../../components/form';
import { useCompetencyTest } from './competency-test.hook';
import { CompetencyFormModal } from '../../../elements/competency/components/competency-form-modal.component';
import { CompetencyModal } from '../../../elements/competency/components/competency-modal.component';
import {
  createCompetencyTestAsync,
  deleteCompetencyTestCompetenciesAsync,
  deleteCompetencyTestCompetencyAsync,
  updateCompetencyTestAsync,
} from '../../../../atom/competency-test/competency-test.apis';
import { assignIndexKey, mapQuestions } from '../../../../utils/response.utils';
import {
  exclude,
  requestTopicDefault,
  setSelectFilter,
  toNewRecords,
} from '../../../../utils/helper.utils';
import { FormModalProps } from '../../../elements/library/library.types';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import { checkFormRedirect } from '../../../../utils/form.utils';
import { LibraryFormModal } from '../../../elements/library/library-form-modal.component';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';

enum MODAL_TYPE {
  COMPETENCY_LIST = 'COMPETENCY_LIST',
  COMPETENCY_CREATE = 'COMPETENCY_CREATE',
  COMPETENCY_EDIT = 'COMPETENCY_EDIT',
  LIBRARY_EDIT = 'LIBRARY_EDIT',
}

export interface Competency {
  id: string;
  parentIndex: number;
  rank: number;
  title: string;
  libraryId: string;
  questionType: string;
  type: string;
  data: LooseObject;
  children: LooseObject[];
  isNewRecord: boolean;
  competencyId: string;
}

export const CompetencyTestForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false,
}: FormModalProps) => {
  const [competencies, setCompetencies] = useState<Competency[]>([]);
  const [modalType, setModalType] = useState('');
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const [forceRender, setForceRender] = useState(false);
  const [libraryType, setLibraryType] = useState('');
  const [questionType, setQuestionType] = useState('');
  useEnterEvent({ form, enter: !modalType });
  const { t } = useTranslation();

  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, topics, colors } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
    hasColor: true,
  });
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  const { loading, competencyTest, setCompetencyTest } = useCompetencyTest({
    id: formModal ? formId : id,
    forceRender,
  });

  // useEffect(() => {
  //   if (_.isNil(defaultFilter)) {
  //     return () => {
  //       setAppFilter((state: any) => {
  //         const newState = produce(state, (draft: any) => {
  //           draft['competencyTest'] = null;
  //           return draft;
  //         });
  //         return { ...newState };
  //       });
  //     };
  //   }
  // }, [setAppFilter, defaultFilter]);

  useEffect(() => {
    setCompetencyTest({});
  }, [setCompetencyTest]);

  useEffect(() => {
    if (_.size(competencyTest)) {
      const competencies = _.get(competencyTest, 'competencies', []);
      const selectedOption = {};
      competencies.forEach((item: any) => {
        const { id, libraryId } = item;
        _.set(selectedOption, `options.${id}.selected`, libraryId);
      });
      form.resetFields();
      form.setFieldsValue({
        ...competencyTest,
        topicId: _.get(competencyTest, 'topic.id'),
        subjectId: _.get(competencyTest, 'subject.id'),
        levelId: _.get(competencyTest, 'level.id'),
        color:
          _.get(competencyTest, 'color') ||
          _.get(competencyTest, 'colorCode.code'),
        ...selectedOption,
      });

      setCompetencies(
        toNewRecords(
          competencies,
          formType === FORM_TYPE.DUPLICATE || formDuplicate
        )
      );
    }
    setSelectFilter({
      dataSource: competencyTest,
      defaultFilter,
      form,
      elementId: 'competencyTestRequest',
    });
  }, [competencyTest, form, defaultFilter, formType, formDuplicate]);

  const onCancel = () => {
    setModalType('');
    setDataSource({});
  };

  const onOkFormCompetency = (value: LooseObject) => {
    const { id, indexKey } = dataSource;

    const competency = produce(value, (draft: any) => {
      const questions = _.get(draft, `competencySources.questions`, []);
      let dataQuestions = mapQuestions({
        dataSources: questions,
        key: 'competencySourceLibraries',
      });
      const { id, code, title } = value;
      return {
        code,
        competencyId: id,
        id,
        libraryId: '',
        title,
        libraries: dataQuestions.map((item: any) => {
          return { ...item, selectedId: item.libraryId };
        }),
      };
    });

    if (modalType === MODAL_TYPE.COMPETENCY_CREATE) {
      setCompetencies([
        ...competencies,
        { ...competency, isNewRecord: true, id: value.id } as any,
      ]);
    } else if (modalType === MODAL_TYPE.COMPETENCY_EDIT) {
      const modifyCompetencies = produce<Competency[], Competency[]>(
        competencies,
        (draft) => {
          const foundIndex = draft.findIndex((item) => item.id === id);
          draft[foundIndex] = { ...competency, id } as any;
          return draft;
        }
      );
      setCompetencies(
        assignIndexKey({
          dataSources: modifyCompetencies,
          childKey: ['libraries', 'children'],
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_EDIT) {
      const modifyCompetencies = produce<any[], any[]>(
        competencies,
        (draft) => {
          if (indexKey.length === 2)
            draft[indexKey[0]].libraries[indexKey[1]] = {
              ...dataSource,
              ...value,
              id,
            };
          if (indexKey.length === 3)
            draft[indexKey[0]].libraries[indexKey[1]].children[indexKey[2]] = {
              ...dataSource,
              ...value,
              id,
            };
          return draft;
        }
      );

      setCompetencies(
        assignIndexKey({
          dataSources: modifyCompetencies,
          childKey: ['libraries', 'children'],
        })
      );
    }
    onCancel();
  };

  const onOkBrowseCompetency = (values: LooseObject) => {
    const filterValues = values
      .filter(
        (item: Competency) =>
          !competencies.map((a) => a.competencyId).includes(item.id)
      )
      .map((item: any) => {
        return {
          ...item,
          libraries: item.libraries.map((item: any) => {
            return { ...item, selectedId: item.id };
          }),
        };
      });

    if (filterValues.length !== values.length)
      messageError('Some of competencyTest is duplicate');
    setCompetencies(
      assignIndexKey({
        dataSources: [...competencies, ...filterValues],
        childKey: ['libraries', 'children'],
      })
    );

    onCancel();
  };

  const onFinish = (value: any) => {
    // if (!competencies.length) {
    //   messageWarn("Questions is required");
    //   return;
    // }

    const {
      code,
      color,
      comingSoon,
      description,
      levelId,
      premium,
      status,
      subjectId,
      title,
      topicId,
      options,
    } = value;

    const requestData = {
      code,
      color,
      comingSoon: comingSoon ? true : false,
      description,
      levelId,
      premium: premium ? true : false,
      status: status || formModal ? true : false,
      subjectId,
      title,
      topicId,
    };

    let check = false;
    const testCompetencyCompetencies = produce<Competency[], Competency[]>(
      competencies,
      (draft) => {
        return draft.map((item, i) => {
          const { id, competencyId, libraryId, isNewRecord } = item;

          const selectedLibraryId = _.get(options, `${id}.selected`, libraryId);

          if (!selectedLibraryId) {
            check = true;
          }
          if (isNewRecord)
            return {
              libraryId: selectedLibraryId,
              competencyId,
              rank: i,
            };

          return {
            id,
            libraryId: selectedLibraryId,
            competencyId,
            rank: i,
          };
        });
      }
    );

    if (check) {
      messageWarn('Competency option have to choose 1');
      return;
    }

    const data: any = {
      ...requestData,
      testCompetencyCompetencies,
    };

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateCompetencyTestAsync(formId, data)
          : createCompetencyTestAsync(data);
      setAppLoading(true);
      request
        .then((res) => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch((err) => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateCompetencyTestAsync(id, data)
        : createCompetencyTestAsync(data);

    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEndCompetency = (items: Competency[]) => {
    setCompetencies(items);
  };

  const onEditCompetency = (value: Competency) => {
    setDataSource(exclude(value, ['isNewRecord']));
    setModalType(MODAL_TYPE.COMPETENCY_EDIT);
  };

  const onEditLibrary = (value: any) => {
    setDataSource(exclude(value, ['isNewRecord']));
    setQuestionType(value.questionType);
    setLibraryType(value.type);
    setModalType(MODAL_TYPE.LIBRARY_EDIT);
  };

  const onDuplicateCompetency = (item: Competency) => {
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.COMPETENCY_CREATE);
  };

  const onDeleteCompetency = ({ id, isNewRecord }: Competency) => {
    showDeleteConfirm(() => {
      const filterQuestions = produce<Competency[], Competency[]>(
        competencies,
        (draft) => {
          return draft.filter((item) => item.id !== id);
        }
      );
      if (isNewRecord) setCompetencies(filterQuestions);
      else {
        deleteCompetencyTestCompetencyAsync(id)
          .then(() => {
            setCompetencies(filterQuestions);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onDeleteCompetencyAll = () => {
    showDeleteConfirm(() => {
      const ids = competencies.map((q: Competency) => q.id);
      deleteCompetencyTestCompetenciesAsync(ids)
        .then(() => {
          setCompetencies([]);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onCreateCompetency = () => {
    setDataSource({});
    setModalType(MODAL_TYPE.COMPETENCY_CREATE);
  };

  const onBrowseCompetency = () => {
    setModalType(MODAL_TYPE.COMPETENCY_LIST);
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div
          onClick={() =>
            requestTopicDefault({
              dataSource: competencyTest,
              defaultFilter,
              appFilter,
              setAppFilter,
              filterKey: 'competencyTest',
            })
          }
          id='competencyTestRequest'
        />
        <CompetencyFormModal
          visible={
            modalType === MODAL_TYPE.COMPETENCY_CREATE ||
            modalType === MODAL_TYPE.COMPETENCY_EDIT
          }
          key={`${
            modalType === MODAL_TYPE.COMPETENCY_CREATE ||
            modalType === MODAL_TYPE.COMPETENCY_EDIT
          }`}
          onOk={onOkFormCompetency}
          onCancel={onCancel}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'competencyTest', {})}
        />
        <CompetencyModal
          visible={modalType === MODAL_TYPE.COMPETENCY_LIST}
          onOk={onOkBrowseCompetency}
          onCancel={onCancel}
          defaultFilter={_.get(appFilter, 'competencyTest')}
        />
        <LibraryFormModal
          visible={modalType === MODAL_TYPE.LIBRARY_EDIT}
          key={`${modalType === MODAL_TYPE.LIBRARY_EDIT}`}
          onOk={onOkFormCompetency}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'resource')}
        />
        <FormWrapper form={form} name='competencyTestForm' onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={8} lg={8}>
              <FormInfoSection
                levels={levels}
                subjects={subjects}
                colors={colors}
                topics={topics}
                form={form}
                dataSource={competencyTest}
                hasStatusOption={true}
                filterKey='competencyTest'
              />
            </Col>
            <Col sm={24} md={16} lg={16}>
              <TitleItem title={t('Description')} />
              <TextEditorForm
                name='description'
                form={form}
                value={_.get(competencyTest, 'description', '')}
                rules={[{ required: false }]}
              />

              <TitleItem
                title={t('Competency')}
                onClear={onDeleteCompetencyAll}
              />
              <SortableItems
                dataSources={competencies}
                onSortEnd={onSortEndCompetency}
                onSortEndChild={onSortEndCompetency}
                onEdit={onEditCompetency}
                onEditChild={onEditLibrary}
                onDuplicate={onDuplicateCompetency}
                onDelete={onDeleteCompetency}
                childItemsKey={['libraries', 'children']}
                hasRadioGroup={true}
              />
              <ButtonAddBrowse
                titleCreate={t('Add Competency')}
                titleBrowse={t('Link Competency')}
                onCreate={onCreateCompetency}
                onBrowse={onBrowseCompetency}
              />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </Fragment>
  );
};
