import { Fragment } from "react"
import { Layout } from "../../../components/layout/layout.component";
import { Row, Col, Table, Input, Avatar } from "antd"
import { 
    Container, 
    FlexWrap, 
    ElementFlex, 
    FlexTitle
} from "./usage.style";

export const UsageByUserReportPage = () => {

  return (
    <Layout>
      <Row gutter={1}>
      <Col span={8}>
        <Container>
          <ElementFlex>
            <FlexTitle>Usage summary</FlexTitle>
          </ElementFlex>
        </Container>
        <Container style={{height: '250px'}}>
          <Row gutter={1}>
            {dataWidget.map((item,index)=> {
              return (
                <Col span={12} key={index}>
                  <ElementFlex style={{height: '125%px'}}>
                    <ElementFlex className='avatar'>
                      {562 + index}
                    </ElementFlex>
                  </ElementFlex>
                </Col>
              )
            })}
          </Row>
        </Container>
        </Col>
        <Col span={16}>
          <Container>
            <FlexWrap>
              <ElementFlex style={{width: '100%'}}>
                <ElementFlex style={{float: 'left'}}>
                  <FlexTitle>Usage session by subject</FlexTitle>
                </ElementFlex>
                <ElementFlex style={{float: 'right'}}>
                  <ElementFlex>
                    <Input placeholder="Search" type='text' size='small' />
                  </ElementFlex>
                </ElementFlex>
              </ElementFlex>
            </FlexWrap>
          </Container>
          <Container style={{ height: '250px'}}>
            <Row gutter={[8, 8]}>
              {data.map((item,index)=> {
                return(
                  <Fragment key={index}>
                    <Col span={2}>
                      <Avatar shape="square" style={{ backgroundColor: item.color, color: 'gray' }}>
                        {item.type}
                      </Avatar>
                    </Col>
                    <Col span={22}>
                      <Avatar shape="square" style={{ backgroundColor: item.color, width: '100%', color: 'gray' }}>
                        {item.value} Session
                      </Avatar>
                    </Col>
                </Fragment>)
              })}
            </Row>
          </Container>
        </Col>        
      </Row>
      <Row gutter={1} style={{marginTop: '1px'}}>
        <Col span={24}>
          <Container>
            <Table scroll={{ x: 100 }} columns={columns} dataSource={dataTable} />
          </Container>
        </Col>
      </Row>
    </Layout>
  )
}

const dataWidget = [{id:1},{id:2},{id:3},{id:4}]

const data = [
  {
    type: 'A',
    value: 27,
    color: '#a1dad073'
  },
  {
    type: 'B',
    value: 25,
    color: '#5b8ff982'
  },
  {
    type: 'C',
    value: 18,
    color: '#5d70928f'
  },
  {
    type: 'D',
    value: 15,
    color: '#f6bd1782'
  },
  {
    type: 'E',
    value: 10,
    color: '#6f5ef987'
  },
  {
    type: 'F',
    value: 5,
    color: '#6dc8ec'
  },
];

const dataTable = [
  {
    key: '1',
    name: 'Math',
    age: 32,
    address: '1000',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Physics',
    age: 42,
    address: '2000',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Khmer',
    age: 32,
    address: '100',
    tags: ['cool', 'teacher'],
  },
  {
    key: '4',
    name: 'English',
    age: 32,
    address: '300',
    tags: ['cool', 'teacher'],
  },
  {
    key: '5',
    name: 'Biology',
    age: 32,
    address: '100',
    tags: ['cool', 'teacher'],
  },
];
const columns = [
  {
    title: 'Subject',
    dataIndex: 'name',
    key: 'name',
    render: (text:any) => <a href="#/">{text}</a>,
  },
  {
    title: 'G12',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'G11',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'G10',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'G9',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'G8',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'G7',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'G6',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Total',
    dataIndex: 'address',
    key: 'address',
  },
];