import { useRecoilState } from 'recoil';
import {
  appAssignPrivacyState,
  appFilterKeyState,
  appFilterState,
  appLoadingState,
  appOwnContentState,
} from './app.atoms';

export const useApp = () => {
  const [appLoading, setAppLoading] = useRecoilState(appLoadingState);
  const [appFilter, setAppFilter] = useRecoilState(appFilterState);
  const [appFilterKey, setAppFilterKey] = useRecoilState(appFilterKeyState);
  const [appOwnContent, setAppOwnContent] = useRecoilState(appOwnContentState);
  const [appAssignPrivacy, setAppAssignPrivacy] = useRecoilState(
    appAssignPrivacyState
  );

  return {
    appLoading,
    setAppLoading,
    appFilter,
    setAppFilter,
    appFilterKey,
    setAppFilterKey,
    appOwnContent,
    setAppOwnContent,
    appAssignPrivacy,
    setAppAssignPrivacy,
  };
};
