import styled from "styled-components";

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 25px;
`;

export const SwitchLabel = styled.div`
  display: flex;
  align-self: flex-start;
  padding-top: 8px;
`;
