import { Layout } from '../../../components/layout/layout.component';
import { useTranslation } from 'react-i18next';
import { PrefixPermission } from '../../../constants/permission.const';
import { AppFeatureComponent } from './components/app-feature.component';

export const AppFeaturePage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Manage')} prefixPermission={PrefixPermission.USER}>
      <AppFeatureComponent />
    </Layout>
  );
};
