import { Col, Form, Row } from "antd";
import React, { Fragment, useEffect } from "react";
import { Color } from "../../../../atom/color/color.types";
import { InputForm } from "../../../../components/form";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { useTranslation } from "react-i18next";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

interface ColorFormProps {
  dataSource?: any;
  handleCancel?: () => void;
  handleOk: (value: Color) => void;
}
export const ColorForm: React.FC<ColorFormProps> = ({
  dataSource,
  handleCancel,
  handleOk,
}) => {
  const [form] = Form.useForm();
  useEnterEvent({ form });
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({ ...dataSource });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="colorForm" form={form} onFinish={onFinish}>
        <FormModalHeader title={t("Color")} handleCancel={handleCancel} />
        <InputForm name="id" placeholder="ID" className="d-none" />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputForm
              name="code"
              placeholder={t("Code")}
              rules={[
                {
                  required: true,
                  message: "Please input the code!",
                },
              ]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
