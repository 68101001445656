import styled from 'styled-components';
import { Button, Input } from 'antd';

export const StyleInput = styled(Input)``;

export const WrapFormItem = styled.div`
  .ant-form-item-control-input {
    min-height: 0px;
  }
  
`;

export const StyleEdit = styled(Button)`
  position: absolute;
  right: 0;
  
`;

export const StyleInputHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  p {
    margin: 0;
  }
  span {
    color: #1877f2;
    font-weight: bold;
    cursor: pointer;
  }
  
`;
