import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta, VisibleProps } from "../../../../types/global.types";
import { ColorFormModal } from "./color-form-modal.component";
import {
  checkPermission,
  exclude,
  isAdmin,
  removeUndefined,
  toNumberFilter,
  toSort,
} from "../../../../utils/helper.utils";
import {
  createColorAsync,
  deleteColorAsync,
  getColorsAsync,
  getColorsTrashAsync,
  restoreColorsAsync,
  updateColorAsync,
} from "../../../../atom/color/color.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
} from "../../../../utils/message.utils";
import { Color } from "../../../../atom/color/color.types";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  colorLoadingState,
  colorMetaState,
  colorsState,
} from "../../../../atom/color/color.atoms";
import {
  colorMetaSelector,
  colorsSelector,
} from "../../../../atom/color/color.selectors";
import useRoute from "../../../../hooks/use-route.hook";
import { QUERY_TYPE } from "../../../../constants/default-value.const";
import { TableActionButton } from "../../../../components/table/table-action-button.component";
import { generateRequest } from "../../../../utils/request.utils";
import { SourceDataTab } from "../../../../components/source-data-tab/source-data-tab.component";
import { ColorPreview } from "../../../../components/color-preview/color-preview.component";
import { useApp } from "../../../../atom/app/app.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../../constants/permission.const";
import { useAuth } from "../../../../hooks/use-auth";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";
import { Switch } from "antd";

const requestColors = (
  query: any,
  setColors: (items: Color[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getColorsTrashAsync
      : getColorsAsync;
  return request(option)
    .then(({ items, meta }) => {
      setColors(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setColors([]);
      setMeta({});
      setLoading(false);
    });
};

interface ColorProps extends VisibleProps {
  searchQuery: string;
}

export const ColorComponent: React.FC<ColorProps> = ({
  visible,
  setVisible,
  searchQuery,
}) => {
  const setColors = useSetRecoilState(colorsState);
  const setMeta = useSetRecoilState(colorMetaState);
  const colors = useRecoilValue(colorsSelector);
  const meta = useRecoilValue(colorMetaSelector);
  const { profile } = useAuth();
  const [loading, setLoading] = useRecoilState(colorLoadingState);
  const [dataSource, setDataSource] = useState<Color>();
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const { setAppLoading } = useApp();
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestColors(
      { ...option, query: searchQuery || query.query },
      setColors,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      status: toNumberFilter(query.status),
      grade: toNumberFilter(query.grade),
    });
  }, [searchQuery, query.query]);

  useEffect(() => {
    setColumns([
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        width: 200,
        // isSearchable: true,
        sorter: (a: Color, b: Color) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
        render: (text: string) => <ColorPreview color={text} />,
      },
      {
        title: t("Publish"),
        dataIndex: "status",
        sorter: (a: Color, b: Color) => Number(a.status) - Number(b.status),
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Color) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Color, b: Color) => a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.updatedAt,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Deleted At"),
        dataIndex: "deletedAt",
        sorter: (a: Color, b: Color) => a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.updatedAt,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: Color) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            record={record}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo]);

  const handleSwitch = (id: string, value: boolean, dataIndex: string) => {
    updateColorAsync(id, { [dataIndex]: value } as any)
      .then(() => {
        messageSuccess();
        // requestResources(query, setResources, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handlePublish = (id: string, value: boolean) => {
    handleSwitch(id, value, "status");
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      status,
      grade,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      status: status ? status.toString() : "",
      grade: grade ? grade.toString() : "",
    };

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ status, grade });
    requestColors(option, setColors, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestColors(option, setColors, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const handleEdit = (record: Color) => {
    setDataSource(record);
    setVisible(true);
  };

  const handleOk = async (value: Color) => {
    const { id } = value;

    setAppLoading(true);
    const request = id ? updateColorAsync(id, value) : createColorAsync(value);
    request
      .then(({ message }) => {
        messageSuccess(message);
        requestColors(query, setColors, setMeta, setLoading);
        handleCancel();
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSaveEditable = (value: Color) => {
    const { id, code } = value;

    updateColorAsync(id, { code } as Color)
      .then(({ message }) => {
        messageSuccess(message);
        requestColors(query, setColors, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setDataSource({} as Color);
  };

  const handleDuplicate = (record: Color) => {
    setDataSource(exclude(record, ["id"]));
    setVisible(true);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteColorAsync(id)
        .then(() => {
          messageSuccess();
          requestColors(query, setColors, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  const onChangeTab = (key: string) => {
    requestColors(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setColors,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Color[]) => {
    showRestoreConfirm(() => {
      restoreColorsAsync({ ids: selectedRows.map((item: Color) => item.id) })
        .then(() => {
          messageSuccess();
          requestColors(query, setColors, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestColors(option, setColors, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    requestColors({}, setColors, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.COLOR_CODE}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={true}
        query={query}
      />
      <ColorFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={dataSource}
      />
      <TableComponent
        key={`table-${loading}-${colors.length}`}
        dataSource={colors}
        // onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.COLOR_CODE).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
      />
    </Fragment>
  );
};
