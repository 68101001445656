// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Permission } from "./permission.types";

export const permissionsState = atom<Permission | {}>({
  key: "permissionsState",
  default: {}
});

export const permissionLoadingState = atom<boolean>({
  key: "permissionLoadingState",
  default: false
});
