import produce from 'immer';
import _ from 'lodash';
import { selector } from 'recoil';
import { PrefixPermission } from '../../constants/permission.const';
import {
  checkPermission,
  formatDate,
  isSyncable,
} from '../../utils/helper.utils';
import { toPaginationProperty } from '../../utils/request.utils';
import { mapQuestions } from '../../utils/response.utils';
import { authUserSelector } from '../auth/auth.selectors';
import {
  practiceTestsState,
  practiceTestMetaState,
  practiceTestState,
} from './practice-test.atoms';

export const practiceTestsSelector = selector({
  key: 'practiceTestsSelector',
  get: ({ get }) => {
    const practiceTests = produce(get(practiceTestsState), (draft) => draft);
    const permissions = checkPermission(
      get(authUserSelector),
      PrefixPermission.PRACTICE_TEST
    );
    return practiceTests.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, 'DD/MM/YYYY HH:mm:ss'),
        deletedAt: formatDate(deletedAt, 'DD/MM/YYYY HH:mm:ss'),
      };
    });
  },
});

export const practiceTestSelector = selector({
  key: 'practiceTestSelector',
  get: ({ get }) => {
    const practiceTest = produce(get(practiceTestState), (draft) => draft);
    const questions = _.get(practiceTest, 'testPracticeLibraries', []);
    return {
      ...practiceTest,
      questions: mapQuestions({
        dataSources: questions,
        childKey: ['children'],
      }),
    };
  },
});

export const practiceTestMetaSelector = selector({
  key: 'practiceTestMetaSelector',
  get: ({ get }) => {
    const meta = produce(get(practiceTestMetaState), (draft) => draft);
    return toPaginationProperty(meta);
  },
});
