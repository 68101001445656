import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Switch } from "antd";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta, VisibleProps } from "../../../../types/global.types";
import { UserFormModal } from "./user-form-modal.component";
import {
  canShowAll,
  checkPermission,
  exclude,
  isAdmin,
  removeUndefined,
  toFilterSelect,
  toNumberFilter,
  toSort,
  toString,
  toStringFilter,
} from "../../../../utils/helper.utils";
import {
  createUserAsync,
  deleteUserAsync,
  getUsersAsync,
  getUsersTrashAsync,
  restoreUsersAsync,
  sortUsersAsync,
  updateUserAsync,
} from "../../../../atom/user/user.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
} from "../../../../utils/message.utils";
import { User } from "../../../../atom/user/user.types";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  userLoadingState,
  userMetaState,
  usersState,
} from "../../../../atom/user/user.atoms";
import {
  userMetaSelector,
  usersSelector,
} from "../../../../atom/user/user.selectors";
import useRoute from "../../../../hooks/use-route.hook";
import {
  QUERY_TYPE,
  FILTER_STATUS,
  STORAGE_PATH,
} from "../../../../constants/default-value.const";
import { TableActionButton } from "../../../../components/table/table-action-button.component";
import { arrangeData, generateRequest } from "../../../../utils/request.utils";
import { SourceDataTab } from "../../../../components/source-data-tab/source-data-tab.component";
import { storagePath, uploadPhoto } from "../../../../utils/firebase.utils";
import _ from "lodash";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { useApp } from "../../../../atom/app/app.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../../constants/permission.const";
import { useAuth } from "../../../../hooks/use-auth";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestUsers = (
  query: any,
  setUsers: (items: User[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH ? getUsersTrashAsync : getUsersAsync;
  return request(option)
    .then(({ items, meta }) => {
      setUsers(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setUsers([]);
      setMeta({});
      setLoading(false);
    });
};

interface UserProps extends VisibleProps {
  searchQuery: string;
}
export const UserComponent: React.FC<UserProps> = ({
  visible,
  setVisible,
  searchQuery,
}) => {
  const { profile } = useAuth();
  const setUsers = useSetRecoilState(usersState);
  const setMeta = useSetRecoilState(userMetaState);
  const users = useRecoilValue(usersSelector);
  const meta = useRecoilValue(userMetaSelector);
  const [loading, setLoading] = useRecoilState(userLoadingState);
  const [dataSource, setDataSource] = useState<User>({} as User);
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const { roles } = useSelectOption({ hasRole: true });
  const { setAppLoading } = useApp();
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestUsers(
      { ...option, query: searchQuery || query.query },
      setUsers,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      status: toNumberFilter(query.status),
      grade: toNumberFilter(query.grade),
      roleId: toStringFilter(query.roleId),
    });
  }, [searchQuery, query.query]);

  useEffect(() => {
    setColumns([
      {
        title: t("Sort"),
        dataIndex: "sort",
        width: 30,
        isDraggable: true,
      },
      {
        title: t("Profile"),
        dataIndex: "profile",
        render: (text: string) => <Avatar src={storagePath(text)} />,
      },
      {
        title: t("First Name"),
        dataIndex: "firstName",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: User, b: User) => a.firstName.length - b.firstName.length,
        sortOrder: sortedInfo.columnKey === "firstName" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        editable: true,
      },
      {
        title: t("Last Name"),
        dataIndex: "lastName",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: User, b: User) => a.lastName.length - b.lastName.length,
        sortOrder: sortedInfo.columnKey === "lastName" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        editable: true,
      },
      {
        title: t("Email"),
        dataIndex: "email",
        isSearchable: true,
        sorter: (a: User, b: User) => a.email.length - b.email.length,
        sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        editable: true,
      },

      {
        title: t("Role"),
        dataIndex: "roleId",
        filteredValue: filteredInfo.roleId || null,
        filters: toFilterSelect(roles),
        render: (text: string, { role }: User) => role.name,
        onFilter: () => true,
      },
      {
        title: t("Publish"),
        dataIndex: "status",
        sorter: (a: User, b: User) => Number(a.status) - Number(b.status),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: User) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      // {
      //   title: t('Updated At'),
      //   dataIndex: 'updatedAt',
      //   sorter: (a: User, b: User) => a.updatedAt.length - b.updatedAt.length,
      //   sortOrder: sortedInfo.columnKey === 'updatedAt' && sortedInfo.updatedAt,
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: t('Deleted At'),
      //   dataIndex: 'deletedAt',
      //   sorter: (a: User, b: User) => a.deletedAt.length - b.deletedAt.length,
      //   sortOrder: sortedInfo.columnKey === 'updatedAt' && sortedInfo.updatedAt,
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: User) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            record={record}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo, roles]);

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<User>(data);
    sortUsersAsync(sortData)
      .then(() => {
        messageSuccess();
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      status,
      roleId,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      status: toString(status),
      roleId: toString(roleId),
    };

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ status, roleId });
    requestUsers(option, setUsers, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestUsers(option, setUsers, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const handleEdit = (record: User) => {
    setDataSource(record);
    setVisible(true);
  };

  const handleOk = async (value: User) => {
    const { id } = value;
    setAppLoading(true);

    let profile = id ? dataSource?.profile || "" : "";
    if (_.get(value, "profile.file")) {
      const photo = await uploadPhoto(
        _.get(value, "profile.file"),
        STORAGE_PATH.ADMIN_USER
      );
      profile = photo.path;
    }

    const request = id
      ? updateUserAsync(id, { ...value, profile })
      : createUserAsync({ ...value, profile });
    request
      .then(({ message }) => {
        messageSuccess(message);
        requestUsers(query, setUsers, setMeta, setLoading);
        handleCancel();
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSaveEditable = (value: User) => {
    const { id, firstName, lastName } = value;

    updateUserAsync(id, { firstName, lastName } as User)
      .then(({ message }) => {
        messageSuccess(message);
        requestUsers(query, setUsers, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setDataSource({} as User);
  };

  const handleDuplicate = (record: User) => {
    setDataSource(exclude(record, ["id"]));
    setVisible(true);
    setDataSource({} as User);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteUserAsync(id)
        .then(() => {
          messageSuccess();
          requestUsers(query, setUsers, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  const handlePublish = (id: string, value: boolean) => {
    updateUserAsync(id, { status: value } as User)
      .then(() => messageSuccess())
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const onChangeTab = (key: string) => {
    requestUsers(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setUsers,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: User[]) => {
    showRestoreConfirm(() => {
      restoreUsersAsync({ ids: selectedRows.map((item: User) => item.id) })
        .then(() => {
          messageSuccess();
          requestUsers(query, setUsers, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestUsers(option, setUsers, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    requestUsers({}, setUsers, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.USER}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={canShowAll(query, filteredInfo)}
        query={query}
      />
      <UserFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={dataSource}
        roles={roles}
      />
      <TableComponent
        key={`table-${loading}-${users.length}`}
        dataSource={users}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.USER).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
      />
    </Fragment>
  );
};
