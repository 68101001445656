import { Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import { useApp } from "../../../../atom/app/app.hook";
import { getCompetencyTestAsync } from "../../../../atom/competency-test/competency-test.apis";
import { mapCompetencies } from "../../../../atom/competency-test/competency-test.selectors";
import { getLearnAsync } from "../../../../atom/learn/learn.apis";
import { mapLearnTest } from "../../../../atom/learn/learn.selectors";
import { getPracticeTestAsync } from "../../../../atom/practice-test/practice-test.apis";
import { getResourceAsync } from "../../../../atom/resource/resource.apis";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { SECTION_TYPE } from "../../../../constants/default-value.const";
import { LooseObject } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";
import { mapActivities, mapQuestions } from "../../../../utils/response.utils";
import { CompetencyTestForm } from "../../competency-test/components/competency-test-form.component";
import { LearnForm } from "../../learn/components/learn-form.component";
import { PracticeTestForm } from "../../practice-test/components/practice-test-form.component";
import { ResourceForm } from "../../resource/components/resource-form.component";

interface SectionFormModalProps {
  visible: boolean;
  onOk: (value: LooseObject[]) => void;
  onCancel: () => void;
  type: string;
  dataSource: LooseObject;
  defaultFilter?: any;
}

export const SectionFormModal = ({
  visible,
  onOk,
  onCancel,
  type,
  dataSource,
  defaultFilter
}: SectionFormModalProps) => {
  const [form] = useForm();
  const { appLoading } = useApp();

  const _onOk = (value: any) => {
    let request = getResourceAsync;
    if (type === SECTION_TYPE.LEARN) {
      request = getLearnAsync;
    } else if (type === SECTION_TYPE.COMPETENCY_TEST) {
      request = getCompetencyTestAsync;
    } else if (type === SECTION_TYPE.PRACTICE_TEST) {
      request = getPracticeTestAsync;
    }

    request(value.id)
      .then(res => {
        if (type === SECTION_TYPE.RESOURCE) {
          onOk({
            ...res,
            sourceAbleId: res.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: mapActivities({
              dataSources: _.get(res, `resourceActivities`, []),
              key: "resourceActivityLibraries",
              childKey: ["libraries", "libraries"],
              filterKey: type
            })
          });
        } else if (type === SECTION_TYPE.LEARN) {
          onOk({
            ...res,
            sourceAbleId: res.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: [
              ...mapActivities({
                dataSources: _.get(res, `learnActivities`, []),
                key: "learnActivityLibraries",
                childKey: ["libraries", "libraries"],
                filterKey: type
              }),
              ...mapLearnTest(res, ["libraries", "libraries"], ["libraries", "children"])
            ]
          });
        } else if (type === SECTION_TYPE.COMPETENCY_TEST) {
          onOk({
            ...res,
            sourceAbleId: res.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: mapCompetencies({
              dataSources: _.get(res, "testCompetencyCompetencies", []),
              childKey: ["libraries", "libraries"],
              filterKey: type
            })
          });
        } else if (type === SECTION_TYPE.PRACTICE_TEST) {
          onOk({
            ...res,
            sourceAbleId: res.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: mapQuestions({
              dataSources: _.get(res, "testPracticeLibraries", []),
              childKey: ["libraries", "libraries"],
              filterKey: type
            })
          });
        }
      })
      .catch(err => {
        showMessageErrors(err);
      });
  };

  const renderForm = () => {
    const { sourceAbleId: formId, isNewRecord } = dataSource;

    const dataProps = {
      form,
      formId: formId,
      formDuplicate: isNewRecord,
      formModal: true,
      onOk: _onOk,
      defaultFilter: defaultFilter
      // defaultFilter: !formId ? defaultFilter : null
      // defaultFilter: formId ? null : defaultFilter
    };

    if (type === SECTION_TYPE.RESOURCE) {
      return <ResourceForm {...dataProps} />;
    } else if (type === SECTION_TYPE.LEARN) {
      return <LearnForm {...dataProps} />;
    } else if (type === SECTION_TYPE.PRACTICE_TEST) {
      return <PracticeTestForm {...dataProps} />;
    } else if (type === SECTION_TYPE.COMPETENCY_TEST) {
      return <CompetencyTestForm {...dataProps} />;
    }

    return null;
  };

  const renderTitle = () => {
    if (type === SECTION_TYPE.RESOURCE) {
      return "Resource";
    } else if (type === SECTION_TYPE.LEARN) {
      return "Learn";
    } else if (type === SECTION_TYPE.PRACTICE_TEST) {
      return "Practice Test";
    } else if (type === SECTION_TYPE.COMPETENCY_TEST) {
      return "Competency Test";
    }

    return "";
  };

  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      maskClosable={false}
      width="80%"
      footer={null}
    >
      <Spin spinning={appLoading}>
        <FormModalHeader
          title={renderTitle()}
          handleCancel={onCancel}
          handleOk={() => form.submit()}
        />
        {renderForm()}
      </Spin>
    </Modal>
  );
};
