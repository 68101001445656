import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import { competitionTestsSyncToFirestoreAsync } from "../../../atom/competition-test/competition-test.apis";
import useRoute from "../../../hooks/use-route.hook";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { toSwiper } from "../../../utils/response.utils";
import { useCompetitionTest } from "../../contents/competition-test/components/competition-test.hook";
import { SwiperComponent } from "../components/swiper/swiper.component";

export const FlowCompetitionTestPage = () => {
  const {
    param: { id },
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { competitionTest } = useCompetitionTest({ id, forceRender });

  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      competitionTestsSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  return (
    <SwiperComponent
      title={competitionTest.title}
      data={competitionTest}
      //onSync={() => handleSyncTofirestore(id)}
      dataSources={toSwiper(competitionTest.questions || [], [
        "questions",
        "children",
      ])}
    />
  );
};
