import styled from "styled-components";
import { Layout } from "antd";

export const MainContent = styled(Layout.Content)`
  padding: 20px;
  /* margin-bottom: 10px; */
  /* background: white; */
  /* min-height: 100vh; */
  overflow: auto;
  background-color: #fefefe;
`;