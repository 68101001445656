import styled from 'styled-components';
export const WrapUpload = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 36px;
    height: 36px;
  }

  .ant-upload-list-picture-card-container {
    width: 36px;
    height: 36px;
  }

  .ant-upload-list-item-name {
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px;
  }
`;
