import { useEffect, useState } from "react";
import { Layout } from "../../../components/layout/layout.component";
import { useTranslation } from "react-i18next";
import {
  axiosInstance,
  getVideoVimeoTotalPage,
  insertVideoVimeo
} from "../../../atom/library/video.apis";
import { Button, Col, Row, Table } from "antd";
import { messageSuccess, showMessageErrors } from "../../../utils/message.utils";
import { useApp } from "../../../atom/app/app.hook";
import _ from "lodash";

export interface IRequest {
  page: number;
  retry: boolean;
  request: boolean;
  path: string;
}

export const VideoOptionPage = () => {
  const { t } = useTranslation();
  const title = t("Videos");
  const [requests, setRequests] = useState<IRequest[]>([]);
  const { setAppLoading } = useApp();
  useEffect(() => {
    getVideoVimeoTotalPage().then(total => {
      let data: IRequest[] = [];
      for (let i = 1; i <= total; i++) {
        data = [
          ...data,
          {
            page: i,
            retry: false,
            request: false,
            path: `/?per_page=100&page=${i}`
          }
        ];
      }
      setRequests(data);
    });
  }, []);

  const handleRequest = (request: IRequest) => {
    setAppLoading(true);
    axiosInstance
      .get(request.path)
      .then(res => {
        setAppLoading(false);
        requests[request.page - 1].request = true;
        requests[request.page - 1].retry = false;
        setRequests([...requests]);

        const response = res.data.data.map((item: any) => {
          return {
            title: item.name,
            url: item.link,
            tag: item.tags
              .map((tag: any) => {
                return tag.name;
              })
              .toString()
          };
        });

        insertVideoVimeo({ data: response })
          .then(() => {
            messageSuccess();
          })
          .catch(err => {
            showMessageErrors(err);
            requests[request.page - 1].retry = true;
            setRequests([...requests]);
          });
      })
      .catch(err => {
        showMessageErrors(err);
        setAppLoading(false);
        requests[request.page - 1].retry = true;
        setRequests([...requests]);
      });
  };

  const handleRequestAll = async () => {
    setAppLoading(true);
    const arrPromise = [];
    for (let i = 1; i <= requests.length; i++) {
      const result = axiosInstance
        .get(`/?per_page=100&page=${i}`)
        .then(res => res.data.data)
        .catch(err => {
          console.log(`/?per_page=100&page=${i}` + err.message);
          // errPromise.push(axiosInstance.get(`/?per_page=100&page=${i}`).then(res => res.data.data));
        });

      arrPromise.push(result);
    }

    let results = await Promise.all(arrPromise);
    results.forEach((item: any, i: number) => {
      requests[i].request = true;
      requests[i].retry = false;
      if (!item) {
        requests[i].retry = true;
      }
    });

    setRequests([...requests]);
    setAppLoading(false);

    const data: any = _.flatten<IRequest>(results.filter((item: any) => item)).map((item: any) => {
      return {
        title: item.name,
        url: item.link,
        tag: item.tags
          .map((tag: any) => {
            return tag.name;
          })
          .toString()
      };
    });

    insertVideoVimeo({ data })
      .then(() => {
        messageSuccess();
      })
      .catch(err => {
        showMessageErrors(err);
      });
  };

  const columns = [
    {
      title: "Page",
      dataIndex: "page",
      key: "page"
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: string, record: IRequest) => (
        <Button
          size="small"
          type="primary"
          // style={{ color: record.retry ? "red" : record.request ? "yellow" : "green" }}
          onClick={() => handleRequest(record)}
        >
          {record.retry ? "Retry" : record.request ? "Requested" : "Request"}
        </Button>
      )
    }
  ];

  return (
    <Layout title={title}>
      <Row justify="end">
        <Col>
          <Button
            // size="small"
            type="primary"
            style={{ marginBottom: "15px" }}
            onClick={handleRequestAll}
          >
            Request All
          </Button>
        </Col>
      </Row>
      <Table size="small" pagination={false} dataSource={requests} columns={columns} />
    </Layout>
  );
};
