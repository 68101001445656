import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import { competenciesSyncToFirestoreAsync } from "../../../atom/competency/competency.apis";
import useRoute from "../../../hooks/use-route.hook";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { toSwiper } from "../../../utils/response.utils";
import { useCompetency } from "../../elements/competency/components/competency.hook";
import { SwiperComponent } from "../components/swiper/swiper.component";

export const FlowCompetencyPage = () => {
  const {
    param: { id },
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { competency } = useCompetency({ id, forceRender });
  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      competenciesSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };
  return (
    <SwiperComponent
      title={competency.title}
      data={competency}
      //onSync={() => handleSyncTofirestore(id)}
      // key={`${forceRender}`}
      dataSources={[
        ...(toSwiper(competency.activities, ["activities", "children"]) || []),
        ...(toSwiper(competency.questions, ["children", "children"]) || []),
      ]}
    />
  );
};
