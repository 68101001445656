import {
  ClusterOutlined,
  CopyOutlined,
  DeleteOutlined,
  DisconnectOutlined,
  EditOutlined,
  EyeOutlined,
  KeyOutlined,
  MoreOutlined,
  SyncOutlined,
  CopyTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  ApiTwoTone,
  CloudSyncOutlined,
  EyeTwoTone,
  ControlTwoTone,
  CloudTwoTone,
  FontColorsOutlined,
  DingtalkOutlined,
  DotChartOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Checkbox,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import _ from "lodash";
import React from "react";
import { STATUS_FEATURES } from "../../constants/default-value.const";
import { useAuth } from "../../hooks/use-auth";
import { checkAvailable, isAdmin } from "../../utils/helper.utils";
import { WrapperAction } from "./table.styles";
interface RecordProp {
  id: string;
  comingSoon?: boolean;
  status?: boolean;
  premium?: boolean;
  statusFeature?: any;
  createdBy?: string;
  syncAt?: string;
  updatedAt?: string;
  isSyncable?: boolean;
  public?: boolean;
}

interface TableActionButtonProps {
  handleEdit?: (e: any) => void;
  onRender?: any;
  handlePreview?: (e: any) => void;
  handleDelete?: (e: string) => void;
  handleFirestoreDelete?: (e: string) => void;
  handleSyncTofirestore?: (e: string) => void;
  handleDuplicate?: (e: any) => void;
  handlePublish?: (e: string, value: boolean) => void;
  handleComingSoon?: (e: string, value: boolean) => void;
  handlePremium?: (e: string, value: boolean) => void;
  handleStatusFeature?: (e: string, value: any) => void;
  handleChangePrivacy?: (e: any) => void;
  handlePreviewReferenceUsing?: (e: any) => void;
  record: RecordProp;
  hasMore?: true;
}

export const TableActionButton: React.FC<TableActionButtonProps> = ({
  handleEdit,
  handleDelete,
  handleFirestoreDelete,
  handleSyncTofirestore,
  handleDuplicate,
  handlePublish,
  handleComingSoon,
  handlePremium,
  handlePreview,
  onRender,
  record,
  hasMore,
  handleStatusFeature,
  handleChangePrivacy,
  handlePreviewReferenceUsing,
}) => {
  const {
    id,
    comingSoon,
    premium,
    status,
    statusFeature,
    createdBy,
    syncAt,
    // updatedAt,
    isSyncable,
    public: privacy,
  } = record;
  const { profile } = useAuth();

  const onCheckboxChange = (values: any) => {
    let data: any = {};
    STATUS_FEATURES.forEach((item: { text: string; value: string }) => {
      data[item.value] = values.includes(item.value) ? true : false;
    });

    if (handleStatusFeature) handleStatusFeature(id, data);
  };

  const renderFeatureStatus = () => {
    let df = [];
    if (statusFeature) {
      df = Object.entries(statusFeature)
        .filter((item: any) => {
          if (item[1] === true) {
            return true;
          }
          return false;
        })
        .map((item: any) => item[0]);
    }

    return (
      <div>
        <Checkbox.Group
          style={{ width: "100%" }}
          defaultValue={df}
          onChange={onCheckboxChange}
        >
          {STATUS_FEATURES.map((item: { text: string; value: string }) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={item.value}>{item.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
      </div>
    );
  };

  const menu = () => {
    return (
      <Menu>
        {handlePublish && (
          <Menu.Item>
            <Switch
              size="small"
              checked={status}
              onClick={(value) => handlePublish(id, value)}
            />{" "}
            Publish
          </Menu.Item>
        )}
        {handleComingSoon && (
          <Menu.Item>
            <Switch
              size="small"
              checked={comingSoon}
              onClick={(value) => handleComingSoon(id, value)}
            />{" "}
            ComingSoon
          </Menu.Item>
        )}
        {handlePremium && (
          <Menu.Item>
            <Switch
              size="small"
              checked={premium}
              onClick={(value) => handlePremium(id, value)}
            />{" "}
            Premium
          </Menu.Item>
        )}
        {handleStatusFeature && <Menu.Item>{renderFeatureStatus()}</Menu.Item>}
      </Menu>
    );
  };

  let color = "";
  if (!isSyncable && syncAt) color = "green";
  else if (isSyncable) color = "orange";

  return (
    <WrapperAction>
      <Space>
        {handleEdit &&
        (checkAvailable(profile, _.get(record, "update", false), createdBy) ||
          privacy) ? (
          <Tooltip title="Edit">
            <EditTwoTone
              style={{ fontSize: "18px" }}
              twoToneColor={"#00a98c"}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
        ) : null}
        {handleDuplicate &&
        checkAvailable(profile, _.get(record, "create", false), "") ? (
          <Tooltip title="Duplicate">
            <CopyTwoTone
              style={{ fontSize: "18px" }}
              twoToneColor={"#00a98c"}
              onClick={() => handleDuplicate(record)}
            />
          </Tooltip>
        ) : null}
        {handleDelete &&
        checkAvailable(profile, _.get(record, "delete", false), createdBy) ? (
          <Tooltip title="Delete">
            <DeleteTwoTone
              style={{ fontSize: "18px" }}
              twoToneColor={"#00a98c"}
              onClick={() => handleDelete(id)}
            />
          </Tooltip>
        ) : null}
        {handleFirestoreDelete && checkAvailable(profile, false, "") ? (
          <Tooltip title="Unsync From App">
            <ApiTwoTone
              style={{ fontSize: "18px" }}
              twoToneColor={"#00a98c"}
              onClick={() => handleFirestoreDelete(id)}
            />
          </Tooltip>
        ) : null}
        {handleSyncTofirestore &&
        checkAvailable(profile, _.get(record, "sync", false), "") ? (
          <Tooltip title="Sync to App">
            <Badge status="default" dot color={color}>
              <CloudTwoTone
                style={{ fontSize: "18px" }}
                twoToneColor={"#00a98c"}
                onClick={() => handleSyncTofirestore(id)}
              />
            </Badge>
          </Tooltip>
        ) : null}
        {handleChangePrivacy &&
        checkAvailable(profile, _.get(record, "update", false), createdBy) ? (
          <Tooltip title="Privacy">
            <ControlTwoTone
              style={{ fontSize: "18px" }}
              twoToneColor={"#00a98c"}
              onClick={() => handleChangePrivacy(record)}
            />
          </Tooltip>
        ) : null}
        {handlePreviewReferenceUsing &&
        checkAvailable(profile, _.get(record, "update", false), createdBy) ? (
          <Tooltip title="Reference Using">
            <ClusterOutlined
              style={{ fontSize: "18px" }}
              onClick={() => handlePreviewReferenceUsing(record)}
            />
          </Tooltip>
        ) : null}
        {handlePreview &&
        checkAvailable(
          profile,
          _.get(record, "view", false),
          createdBy,
          "view"
        ) ? (
          <Tooltip title="Preview">
            <EyeTwoTone
              style={{ fontSize: "18px" }}
              twoToneColor={"#00a98c"}
              onClick={() => handlePreview(record)}
            />
          </Tooltip>
        ) : null}
        {/* {(handleDuplicate && _.get(record, "create")) ||
        (handleDuplicate && isAdmin(profile)) ||
        (handleDuplicate && _.isNil(_.get(record, "create"))) ? (
          <CopyOutlined onClick={() => handleDuplicate(record)} />
        ) : null}

        {(handleDelete && _.get(record, "delete")) ||
        (handleDelete && isAdmin(profile)) ||
        (handleDelete && _.isNil(_.get(record, "delete"))) ? (
          <DeleteOutlined onClick={() => handleDelete(id)} />
        ) : null} */}
        {onRender}
        {(hasMore && _.get(record, "update")) ||
        (hasMore && isAdmin(profile)) ||
        (hasMore && _.isNil(_.get(record, "update"))) ? (
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <MoreOutlined />
          </Dropdown>
        ) : null}
      </Space>
    </WrapperAction>
  );
};
