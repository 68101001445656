import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { Level } from "../../../../atom/level/level.types";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { LevelForm } from "./level-form.component";

interface LevelFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: Level) => void;
  dataSource?: Level;
}
export const LevelFormModal: React.FC<LevelFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource
}) => {
  const { appLoading } = useApp();
  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <LevelForm dataSource={dataSource} handleCancel={handleCancel} handleOk={handleOk} />
      </Spin>
    </ModalWrapper>
  );
};
