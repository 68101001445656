// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Category, CategorySelect } from "./category.types";

export const categoriesState = atom<Category[]>({
  key: "categoriesState",
  default: []
});

export const categoriesSelectState = atom<CategorySelect[]>({
  key: "categoriesSelectState",
  default: []
});

export const categoryMetaState = atom<Meta>({
  key: "categoryMetaState",
  default: {}
});

export const categoryLoadingState = atom<boolean>({
  key: "categoryLoadingState",
  default: false
});
