import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../components/table/table.component";
import { Meta } from "../../../types/global.types";
import {
  canShowAll,
  checkPermission,
  exclude,
  isAdmin,
  removeUndefined,
  toFilterSelect,
  toNumberFilter,
  toSort,
  toString,
  toStringFilter,
} from "../../../utils/helper.utils";
import {
  deleteLibraryAsync,
  getLibrariesAsync,
  getLibrariesTrashAsync,
  getLibraryReferenceUsingAsync,
  // librariesSyncToFirestoreAsync,
  restoreLibrariesAsync,
  sortLibrariesAsync,
  updateLibraryAsync,
} from "../../../atom/library/library.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
  // showSyncConfirm,
} from "../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  libraryLoadingState,
  libraryMetaState,
  librariesState,
} from "../../../atom/library/library.atoms";
import {
  libraryMetaSelector,
  librariesSelector,
} from "../../../atom/library/library.selectors";
import useRoute from "../../../hooks/use-route.hook";
import {
  QUERY_TYPE,
  FILTER_QUESTION_TYPES,
  FORM_TYPE,
  LIBRARY_TYPE,
  FILTER_STATUS,
} from "../../../constants/default-value.const";
import { TableActionButton } from "../../../components/table/table-action-button.component";
import { arrangeData, generateRequest } from "../../../utils/request.utils";
import { SourceDataTab } from "../../../components/source-data-tab/source-data-tab.component";
import { Library } from "../../../atom/library/library.types";
import { useSelectOption } from "../../../hooks/use-select-option.hook";
import _ from "lodash";
import { Switch, Tag } from "antd";
import { useTopic } from "../../../atom/topic/topic.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../constants/permission.const";
import { useAuth } from "../../../hooks/use-auth";
import { switchQuestionForm } from "../../../utils/form.utils";
import { PreviewReferenceUsing } from "../../../components/preview-reference-using/preview-reference-using.component";
import { ListFeatureOption } from "../../../components/list-feature-option/list-feature-option.component";
import { columnTableEllipsis } from "../../../utils/table.utils";
import { useApp } from "../../../atom/app/app.hook";
import { PrivacyModal } from "../../../components/privacy-modal/privacy-modal.component";

const switchLibraryType = (pathname: string) => {
  if (pathname.includes("/questions")) return LIBRARY_TYPE.QUESTION;
  if (pathname.includes("/videos")) return LIBRARY_TYPE.VIDEO;
  return LIBRARY_TYPE.TEXT;
};

const requestLibraries = (
  query: any,
  setLibraries: (items: Library[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getLibrariesTrashAsync
      : getLibrariesAsync;
  return request(option)
    .then(({ items, meta }) => {
      setLibraries(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setLibraries([]);
      setMeta({});
      setLoading(false);
    });
};

interface LibraryProps {
  searchQuery: string;
}
export const LibraryComponent: React.FC<LibraryProps> = ({ searchQuery }) => {
  const setLibraries = useSetRecoilState(librariesState);
  const setMeta = useSetRecoilState(libraryMetaState);
  const libraries = useRecoilValue(librariesSelector);
  const meta = useRecoilValue(libraryMetaSelector);
  const { profile } = useAuth();
  const [loading, setLoading] = useRecoilState(libraryLoadingState);
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const type = switchLibraryType(pathname);
  query.type = type;
  const { topics, levels, subjects } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
  });
  const { t } = useTranslation();
  const [visibleReference, setVisibleReference] = useState(false);
  const [dataSourceReferences, setDataSourceReferences] = useState([]);
  const { appOwnContent, setAppAssignPrivacy } = useApp();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestLibraries(
      { ...option, query: searchQuery || query.query },
      setLibraries,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      subjectId: toStringFilter(query.subjectId),
      levelId: toStringFilter(query.levelId),
      topicId: toStringFilter(query.topicId),
      questionType: toStringFilter(query.questionType),
      status: toNumberFilter(query.status),
    });
    setTopicLevelId(query.levelId);
    setTopicSubjectId(query.subjectId);
  }, [
    searchQuery,
    setTopicLevelId,
    setTopicSubjectId,
    appOwnContent,
    query.query,
  ]);

  useEffect(() => {
    let data = [
      {
        title: t("Code"),
        //fixed: 'left',
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Library, b: Library) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
        dataIndex: "code",
        // render: (title: String, record: Library) => (
        //   <Tag color="geekblue" key="code">
        //     {record.code}
        //   </Tag>
        // ),
      },
      {
        title: t("Title"),
        //fixed: 'left',
        width: 300,
        dataIndex: "title",
        // ellipsis: true,
        // className: "limitTitle",
        isSearchable: true,
        sorter: (a: Library, b: Library) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        // render: (text: string, record: Library) => (
        //   <div style={{ maxHeight: "100px", overflow: "hidden" }}>
        //     xxx---{record.title}
        //   </div>
        // ),
        editable: true,
      },
      {
        title: t("Vimeo ID"),
        dataIndex: "url",
        width: 200,
        //editable: true,
        ...columnTableEllipsis("data", "url"),
      },
      {
        title: t("Bunny ID"),
        dataIndex: "url",
        width: 200,
        //editable: true,
        ...columnTableEllipsis("data", "bunnyId"),
      },
      {
        title: t("Question Type"),
        dataIndex: "questionType",
        filteredValue: filteredInfo.questionType || null,
        filters: FILTER_QUESTION_TYPES,
        onFilter: () => true,
        render: (text: string, record: Library) => record.data?.type,
      },
      {
        title: t("Subject"),
        dataIndex: "subjectId",
        width: 200,
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis("subject"),
      },
      {
        title: t("Level"),
        dataIndex: "levelId",
        width: 200,
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis("level"),
      },
      {
        title: t("Topic"),
        dataIndex: "topicId",
        width: 200,
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis("topic"),
      },
      {
        title: t("Publish"),
        dataIndex: "status",
        sorter: (a: Library, b: Library) => Number(a.status) - Number(b.status),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Library) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Library, b: Library) =>
          a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Deleted At"),
        dataIndex: "deletedAt",
        sorter: (a: Library, b: Library) =>
          a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === "deletedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: Library) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            //handleChangePrivacy={handleChangePrivacy}
            //handleSyncTofirestore={handleSyncTofirestore}
            record={record}
          />
        ),
      },
    ];

    data = data.filter((item) => {
      if (type === LIBRARY_TYPE.VIDEO && item.dataIndex === "questionType") {
        return false;
      }
      if (type === LIBRARY_TYPE.QUESTION && item.dataIndex === "url") {
        return false;
      }

      if (
        type === LIBRARY_TYPE.TEXT &&
        (item.dataIndex === "questionType" || item.dataIndex === "url")
      ) {
        return false;
      }

      return true;
    });

    setColumns(data);
  }, [filteredInfo, sortedInfo, subjects, levels, topics]);

  const handleChangePrivacy = (value: Library) => {
    setVisible(true);
    setAppAssignPrivacy(value);
  };

  const onOkPrivacy = (value: any) => {
    setVisible(false);
    updateLibraryAsync(value.id, {
      ...value,
    } as Library)
      .then(({ message }) => {
        messageSuccess(message);
        requestLibraries(query, setLibraries, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handlePublish = (id: string, value: boolean) => {
    updateLibraryAsync(id, { status: value } as any)
      .then(() => {
        messageSuccess();
        requestLibraries(query, setLibraries, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<Library>(data);
    sortLibrariesAsync(sortData)
      .then(() => {
        messageSuccess();
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
      status,
      questionType,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
      status: toString(status),
      questionType: toString(questionType),
    };

    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));
    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ subjectId, levelId, topicId, status, questionType });
    requestLibraries(option, setLibraries, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestLibraries(option, setLibraries, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onSaveEditable = (value: Library) => {
    const { id, title, code } = value;

    updateLibraryAsync(id, { title, code } as Library)
      .then(({ message }) => {
        messageSuccess(message);
        requestLibraries(query, setLibraries, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleEdit = (record: Library) => {
    history.push(
      `${switchQuestionForm(
        _.get(record, "questionType") || _.get(record, "type"),
        record.id
      )}?formType=${FORM_TYPE.EDIT}`
    );
  };

  const handleDuplicate = (record: Library) => {
    history.push(
      `${switchQuestionForm(
        _.get(record, "questionType") || _.get(record, "type"),
        record.id
      )}?formType=${FORM_TYPE.DUPLICATE}`
    );
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      getLibraryReferenceUsingAsync(id)
        .then((res) => {
          if (res.length) {
            setVisibleReference(true);
            setDataSourceReferences(res);
          } else
            deleteLibraryAsync(id)
              .then(() => {
                messageSuccess();
                requestLibraries(query, setLibraries, setMeta, setLoading);
              })
              .catch((err) => showMessageErrors(err));
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  // const handleSyncTofirestore = (id: string) => {
  //   showSyncConfirm(() => {
  //     librariesSyncToFirestoreAsync([id])
  //       .then(() => {
  //         messageSuccess();
  //       })
  //       .catch((err) => showMessageErrors(err.message));
  //   });
  // };

  const onChangeTab = (key: string) => {
    requestLibraries(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setLibraries,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Library[]) => {
    showRestoreConfirm(() => {
      restoreLibrariesAsync({
        ids: selectedRows.map((item: Library) => item.id),
      })
        .then(() => {
          messageSuccess();
          requestLibraries(query, setLibraries, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestLibraries(option, setLibraries, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    requestLibraries({ type: query.type }, setLibraries, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <PreviewReferenceUsing
        visible={visibleReference}
        dataSources={dataSourceReferences}
        onCancel={() => setVisibleReference(false)}
        onOk={() => setVisibleReference(false)}
      />
      <PrivacyModal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOkPrivacy}
      />
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.LIBRARY}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={canShowAll(query, filteredInfo)}
        query={query}
      />
      <TableComponent
        key={`table-${loading}-${libraries.length}`}
        dataSource={libraries}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.LIBRARY).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
      />
    </Fragment>
  );
};
