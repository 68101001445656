import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { Library } from "./library.types";
import { RestoreType, SortData } from "../../types/global.types";

export const getLibrariesAsync = (option: {}) => {
  const url = generateQuery("/libraries", option, [
    "subjectId",
    "levelId",
    "topicId",
    "status",
    "questionType"
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getLibraryAsync = (id: string) => {
  return axios.get(`/libraries/${id}`, config()).then(response => response.data.data);
};

export const getLibrariesSelectAsync = () => {
  const url = generateQuery("/libraries/for/select-option");
  return axios.get(url, config()).then(response => response.data.data);
};

export const getLibrarySelectLibrariesAsync = (data: RestoreType) => {
  const url = generateQuery("/libraries/selected/libraries");
  return axios.post(url, data, config()).then(response => response.data.data);
};

export const getLibrariesTrashAsync = (option: {}) => {
  const url = generateQuery("/libraries/list/trash", option, [
    "subjectId",
    "levelId",
    "topicId",
    "status",
    "questionType"
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const restoreLibrariesAsync = (data: RestoreType) => {
  return axios.patch("/libraries/restore", data, config()).then(response => response.data);
};

export const updateLibraryAsync = (id: string, data: Library) => {
  return axios.put(`/libraries/${id}`, data, config()).then(response => response.data);
};

export const deleteLibraryAsync = (id: string) => {
  return axios.delete(`/libraries/${id}`, config()).then(response => response.data);
};

export const sortLibrariesAsync = (data: SortData) => {
  if (Math.random() > 0.5) return Promise.resolve(1);
  return Promise.reject("something went wrong");
  //   return axios.put(`/libraries/${id}/status`, data, config()).then(response => response.data);
};

export const getLibraryReferenceUsingAsync = (id: string) => {
  return axios
    .get(`/libraries/reference-using/${id}`, config())
    .then(response => response.data.data);
};

export const librariesSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/libraries/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};
