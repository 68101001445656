import { Header } from "./header/header.component";
import { Sidebar } from "./sidebar/sidebar.component";
import { Content } from "./content/content.component";
import { MainLayout, LayoutContainer } from "./layout.styles";
import { Spin } from "antd";
import { useApp } from "../../atom/app/app.hook";
import ProfileSetting from "../profile-setting/profile.setting";

export interface LayoutProps {
  loading?: boolean;
  onCreate?: () => void;
  onSubmit?: (isBack?: boolean) => void;
  onSearch?: (e: string) => void;
  onSync?: () => void;
  title?: string;
  render?: any;
  prefixPermission?: string;
  onPreview?: () => void;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  onSearch,
  onCreate,
  loading = false,
  render,
  prefixPermission,
  onSubmit,
  onPreview,
  onSync,
  ...props
}) => {
  const { appLoading } = useApp();
  return (
    <Spin spinning={appLoading}>
      <MainLayout>
        <Sidebar />

        <LayoutContainer>
          <Header
            title={title}
            onCreate={onCreate}
            render={render}
            onSearch={onSearch}
            onSubmit={onSubmit}
            onPreview={onPreview}
            prefixPermission={prefixPermission}
            onSync={onSync}
            {...props}
          />

          <Content>{children}</Content>
        </LayoutContainer>

        {/* <ProfileSetting /> */}
      </MainLayout>
    </Spin>
  );
};
