/*eslint-disable react/jsx-no-target-blank */

import { EyeOutlined } from "@ant-design/icons";
import { Modal, Space, Table, Tag } from "antd";
import React from "react";
import { EntityName } from "../../constants/default-value.const";
import { redirectRoute } from "../../utils/form.utils";
import { columnEllipsis } from "../../utils/table.utils";

interface ReferenceUsing {
  id: string;
  code: string;
  title: string;
  entityName: EntityName;
}

interface PropType {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  dataSources: ReferenceUsing[];
}

export const PreviewReferenceUsing: React.FC<PropType> = ({
  visible,
  onOk,
  onCancel,
  dataSources
}) => {
  const columns = [
    {
      title: "Entity Name",
      dataIndex: "entityName",
      key: "entityName",
      render: (text: string) => <Tag color="yellow">{text}</Tag>
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text: string) => <Tag color="cyan">{text}</Tag>
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...columnEllipsis
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Space size="middle">
          <a href={record.redirectRoute} target="_blank">
            <EyeOutlined />
          </a>
        </Space>
      )
    }
  ];

  return (
    <Modal
      title="Reference Using"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={false}
      width="60%"
    >
      <Table
        columns={columns}
        dataSource={dataSources.map((item: any) => {
          return { ...item, redirectRoute: redirectRoute(item) };
        })}
        size="small"
        pagination={{
          // total: number;
          // current?: number;
          // pageSize?: number;,
          size: "small",
          showTotal: total => `Total: ${total} records`
        }}
        scroll={{ y: 500 }}
      />
    </Modal>
  );
};
