import { Layout } from "../../../components/layout/layout.component";
import React, {  useEffect, useState } from "react";
import { TableComponent } from "../../../components/table/table.component";
import { Row, Col, Avatar, Progress, Checkbox } from "antd"
import { Container , FlexTitle, ElementFlex, FlexWrap, FlexTitleNoBottom } from "./report.style";
import { CaretRightOutlined } from "@ant-design/icons"
export interface Report {
  key?: string;
  id: string;
  rank: string;
  title: string;
  subject: string;
  type: boolean;
  level: string;
  learn: number;
  competency: number;
}

export const ReportUserCourseLearnPage = () => {
  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    setColumns([
      {
        title: "Rank",
        dataIndex: "rank",
        editable: false,
        width: 250
      },
      {
        title: "Section Tile",
        dataIndex: "title",
        width: 200
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (text: boolean, { id }: Report) => (
          <Checkbox checked={text}/>
        ),
        width: 50
      },
      {
        title: "Learn",
        dataIndex: "learn",
        render: (text: number, { id }: Report) => (
          <Progress strokeColor='#2ea8d5' percent={text} />
        ),
        width: 200
      },
      {
        title: "Competency",
        dataIndex: "competency",
        render: (text: number, { id }: Report) => (
          <Progress strokeColor='#e2008b' percent={text} />
        ),
        width: 200
      },
      
      {
        title: "Manage",
        dataIndex: "manage",
        render: (text: string, record: Report) => (
          <CaretRightOutlined />
        ),
        width: 50
      }
    ]);
  }, []);

  return (
    <Layout>
      <Row gutter={1}>
        <Col span={8}>
          <Container style={{height: '160px'}}>
            <ElementFlex>
              <FlexTitle>
                User Infos
              </FlexTitle>
            </ElementFlex>
            <FlexWrap>
              <ElementFlex style={{width: '30%'}}>
              <Avatar size={74} style={{ backgroundColor: '#1b97af' }} />
              </ElementFlex>
              <ElementFlex style={{width: '70%'}}>
                <FlexTitle>Name: Prak Moni Udom</FlexTitle>
                <FlexTitleNoBottom>Grade: 12</FlexTitleNoBottom>
                <FlexTitleNoBottom>School Name:</FlexTitleNoBottom>
                <FlexTitleNoBottom>UserId: xxx-111-xxx</FlexTitleNoBottom>
              </ElementFlex>
            </FlexWrap>
          </Container>
        </Col>
        <Col span={16}>
        <Container style={{height: '160px'}}>
            <ElementFlex>
              <FlexTitle>Learning Report</FlexTitle>
            </ElementFlex>
            <FlexWrap style={{justifyContent: 'center', position: 'relative'}}>
              {dataWidget.map((item,index)=> {
                return (
                <ElementFlex style={{width: '20%'}} key={index}>
                  <ElementFlex className='avatar'>
                    {562 + index}
                  </ElementFlex>
                  <ElementFlex>
                    <FlexTitle style={{textAlign: 'center'}}>Day Online</FlexTitle>
                  </ElementFlex>
                </ElementFlex>
                )
              })}
              
            </FlexWrap>
          </Container>
        </Col>
      </Row>
      <Row gutter={1} style={{marginTop: '1px'}}>
        <Col span={24}>
          <Container>
            <FlexTitle>
              Section Report 
            </FlexTitle>
            <TableComponent
              dataSource={dataTest}
              columns={columns}
              onChange={()=>console.log("[onTableChange]")}
              onColumnSearch={()=>console.log("[onColumnSearch]")}
              pagination={{}}
            />
          </Container>
        </Col>
      </Row>
    </Layout>
  );
};

const dataWidget = [{id:1},{id:2},{id:3},{id:4},{id:5}];
const dataTest = [
  {
    id: "R-001",
    key: "0",
    index: 0,
    rank: "R-001",
    title: "Nullam blandit viverra",
    level: '12',
    type: true,
    subject: "Nullam blandit viverra",
    learn: 80,
    competency: 30,
  },
  {
    id: "R-002",
    key: "1",
    index: 1,
    rank: "R-002",
    title: "Nullam blandit viverra",
    level: '12',
    type: false,
    subject: "Nullam blandit viverra",
    learn: 80,
    competency: 30,
  },
  {
    id: "R-003",
    key: "3",
    index: 3,
    rank: "R-003",
    title: "Nullam blandit viverra",
    level: '11',
    type: true,
    subject: "Nullam blandit viverra",
    learn: 80,
    competency: 30,
  },
];
