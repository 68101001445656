import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface PropsType {
  title?: string;
  handleClick: (e: any) => void;
}

export const CancelButton: React.FC<PropsType> = ({ handleClick, title = "Cancel" }) => {
  const { t } = useTranslation();
  return <Button onClick={handleClick}>{t(title)}</Button>;
};
