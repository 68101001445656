import { useState } from "react";
import { Layout } from "../../../components/layout/layout.component";
import useRoute from "../../../hooks/use-route.hook";
import { removeParam } from "../../../utils/helper.utils";
import { LibraryComponent } from "../library/library.component";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../constants/permission.const";

export const VideoPage = () => {
  const { t } = useTranslation();
  const title = t("Videos");
  const { history, pathname } = useRoute();
  const [searchQuery, setSearchQuery] = useState("");

  const onCreate = () => {
    history.push(removeParam(`${pathname}/form`));
  };

  return (
    <Layout
      onSearch={value => setSearchQuery(value)}
      title={title}
      onCreate={onCreate}
      prefixPermission={PrefixPermission.LIBRARY}
    >
      <LibraryComponent searchQuery={searchQuery} />
    </Layout>
  );
};
