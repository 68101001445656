import React, { Fragment, useEffect, useState } from "react";
import { Switch } from "antd";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta, VisibleProps } from "../../../../types/global.types";
import { LevelFormModal } from "./level-form-modal.component";
import {
  exclude,
  removeUndefined,
  toNumberFilter,
  toSort,
  canSort,
  toString,
  checkPermission,
  isAdmin,
  canShowAll,
  canSortRow,
  toSortRank,
} from "../../../../utils/helper.utils";
import {
  createLevelAsync,
  deleteLevelAsync,
  getLevelsAsync,
  getLevelsTrashAsync,
  levelsSyncToFirestoreAsync,
  restoreLevelsAsync,
  sortLevelsAsync,
  updateLevelAsync,
} from "../../../../atom/level/level.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
  showSyncConfirm,
} from "../../../../utils/message.utils";
import { Level } from "../../../../atom/level/level.types";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  levelLoadingState,
  levelMetaState,
  levelsState,
} from "../../../../atom/level/level.atoms";
import {
  levelMetaSelector,
  levelsSelector,
} from "../../../../atom/level/level.selectors";
import useRoute from "../../../../hooks/use-route.hook";
import {
  QUERY_TYPE,
  FILTER_LEVELS,
  FILTER_STATUS,
  SORT,
} from "../../../../constants/default-value.const";
import { TableActionButton } from "../../../../components/table/table-action-button.component";
import { arrangeData, generateRequest } from "../../../../utils/request.utils";
import { SourceDataTab } from "../../../../components/source-data-tab/source-data-tab.component";
import { StatusFeature } from "../../../../atom/subject/subject.type";
import { useApp } from "../../../../atom/app/app.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../../constants/permission.const";
import { useAuth } from "../../../../hooks/use-auth";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestLevels = (
  query: any,
  setLevels: (items: Level[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getLevelsTrashAsync
      : getLevelsAsync;
  return request(option)
    .then(({ items, meta }) => {
      setLevels(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setLevels([]);
      setMeta({});
      setLoading(false);
    });
};

interface LevelProps extends VisibleProps {
  searchQuery: string;
}
export const LevelComponent: React.FC<LevelProps> = ({
  visible,
  setVisible,
  searchQuery,
}) => {
  const setLevels = useSetRecoilState(levelsState);
  const setMeta = useSetRecoilState(levelMetaState);
  const levels = useRecoilValue(levelsSelector);
  const meta = useRecoilValue(levelMetaSelector);
  const { profile } = useAuth();
  const [loading, setLoading] = useRecoilState(levelLoadingState);
  const [dataSource, setDataSource] = useState<Level>();
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [enableSort, setEnableSort] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const { setAppLoading } = useApp();
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestLevels(
      { ...option, query: searchQuery || query.query },
      setLevels,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      status: toNumberFilter(query.status),
      grade: toNumberFilter(query.grade),
      comingSoon: toNumberFilter(query.comingSoon),
    });
    setShowAll(canSort(query, []));
  }, [searchQuery, query.query]);

  useEffect(() => {
    if (!showAll) setEnableSort(showAll);
  }, [showAll]);

  useEffect(() => {
    setColumns([
      {
        title: t("Sort"),
        dataIndex: "sort",
        width: 30,
        isDraggable: true,
      },
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Level, b: Level) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: Level, b: Level) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Rank"),
        dataIndex: "rank",
        sorter: (a: Level, b: Level) => a.rank - b.rank,
        sortOrder: sortedInfo.columnKey === "rank" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: t("Grade"),
        dataIndex: "grade",
        sorter: (a: Level, b: Level) => a.grade - b.grade,
        filteredValue: filteredInfo.grade || null,
        sortOrder: sortedInfo.columnKey === "grade" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        filters: FILTER_LEVELS,
        onFilter: () => true,
      },
      {
        title: t("Publish"),
        dataIndex: "status",
        sorter: (a: Level, b: Level) => Number(a.status) - Number(b.status),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Level) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      /* {
        title: t('ComingSoon'),
        dataIndex: 'comingSoon',
        sorter: (a: Level, b: Level) =>
          Number(a.comingSoon) - Number(b.comingSoon),
        sortDirections: ['descend', 'ascend'],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.comingSoon || null,
        sortOrder: sortedInfo.columnKey === 'comingSoon' && sortedInfo.order,
        render: (text: boolean, { id }: Level) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handleComingSoon(id, value)}
          />
        ),
      }, */
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Level, b: Level) => a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Deleted At"),
        dataIndex: "deletedAt",
        sorter: (a: Level, b: Level) => a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === "deletedAt" && sortedInfo.deletedAt,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: Level) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            //handleSyncTofirestore={handleSyncTofirestore}
            record={record}
            //hasMore={true}
            //handleStatusFeature={handleStatusFeature}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo]);

  const handleStatusFeature = (id: string, value: StatusFeature) => {
    updateLevelAsync(id, { statusFeature: value } as Level)
      .then(() => messageSuccess())
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<Level>(data);
    sortLevelsAsync(sortData)
      .then(() => {
        messageSuccess();
        requestLevels(query, setLevels, setMeta, setLoading);
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      status,
      grade,
      comingSoon,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      status: toString(status),
      grade: toString(grade),
      comingSoon: toString(comingSoon),
    };
    setShowAll(canSort(option, []));

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ status, grade, comingSoon });
    requestLevels(option, setLevels, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    setShowAll(canSort(option, []));
    requestLevels(option, setLevels, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const handleEdit = (record: Level) => {
    setDataSource(record);
    setVisible(true);
  };

  const handleOk = async (value: Level) => {
    const { id } = value;

    const request = id ? updateLevelAsync(id, value) : createLevelAsync(value);
    setAppLoading(true);
    request
      .then(({ message }) => {
        messageSuccess(message);
        requestLevels(query, setLevels, setMeta, setLoading);
        handleCancel();
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSaveEditable = (value: Level) => {
    const { id, title, code } = value;

    updateLevelAsync(id, { title, code } as Level)
      .then(({ message }) => {
        messageSuccess(message);
        requestLevels(query, setLevels, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setDataSource({} as Level);
  };

  const handleDuplicate = (record: Level) => {
    setDataSource(exclude(record, ["id"]));
    setVisible(true);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteLevelAsync(id)
        .then(() => {
          messageSuccess();
          requestLevels(query, setLevels, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      levelsSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  const handleSwitch = (id: string, value: boolean, dataIndex: string) => {
    updateLevelAsync(id, { [dataIndex]: value } as any)
      .then(() => {
        messageSuccess();
        requestLevels(query, setLevels, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handlePublish = (id: string, value: boolean) => {
    handleSwitch(id, value, "status");
  };

  // const handleComingSoon = (id: string, value: boolean) => {
  //   handleSwitch(id, value, 'comingSoon');
  // };

  const onChangeTab = (key: string) => {
    requestLevels(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setLevels,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Level[]) => {
    showRestoreConfirm(() => {
      restoreLevelsAsync({ ids: selectedRows.map((item: Level) => item.id) })
        .then(() => {
          messageSuccess();
          requestLevels(query, setLevels, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onEnableSort = () => {
    setEnableSort(true);
    const option = {
      ...query,
      showAll: "yes",
      sortField: "rank",
      sortOrder: SORT.ASC,
    };
    setSortedInfo(toSortRank());
    requestLevels(option, setLevels, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestLevels(option, setLevels, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setEnableSort(false);
    requestLevels({}, setLevels, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.LEVEL}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        onEnableSort={onEnableSort}
        showAll={canShowAll(query, filteredInfo)}
        enableSort={canSortRow(query, [])}
        query={query}
      />
      <LevelFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={dataSource}
      />
      <TableComponent
        key={`table-${loading}-${levels.length}`}
        dataSource={levels}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.LEVEL).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
        enableSort={enableSort && canSortRow(query, [])}
      />
    </Fragment>
  );
};
