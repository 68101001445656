import styled from "styled-components";

export const WrapSwaper: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #e5e5f7;
  //opacity: 0.8;
  // background-image: radial-gradient(#1e81b0 0.5px, transparent 0.5px),
  //   radial-gradient(#1e81b0 0.5px, #e5e5f7 0.5px);
  // background-size: 20px 20px;
  // background-position: 0 0, 10px 10px;
  padding: 50px 0px;
  background: linear-gradient(to top, #93a5cf, #e4efe9);
  .swiper {
    width: 460px;
    height: 100%;
    border-radius: 20px;
    border: 2px solid #ddd;
  }

  .swiper-slide {
    &::-webkit-scrollbar {
      width: 0px;
    }

    /* &::-webkit-scrollbar-track {
      background: #ddd;
    }

    &::-webkit-scrollbar-thumb {
      background: #666;
    } */

    /* font-size: 18px; */
    background: #ecf7fd;
    height: calc(100vh - 100px);
    padding: 60px 20px;
    overflow-y: auto;
    overflow-x: hidden;

    /* Center slide text vertically */
    /* display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex; */
    /* display: flex; */
    /* -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center; */
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    bottom: revert;
    top: 0px;

    bottom: revert;
    background: #ecf7fd;
    padding: 15px;
    display: flex;
    justify-content: space-between;

    .swiper-pagination-bullet {
      margin: 1px !important;
      border-radius: 0px;
      width: calc(
        ${({ bulletWidth }: { bulletWidth: number }) =>
          bulletWidth ? bulletWidth : 100}%
      );

      &:first-child {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        margin-left: 0px;
      }

      &:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        margin-right: 0px;
      }
    }
  }

  /* .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    font-size: 10px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  } */

  /* width:100px​​ !important; */

  /* @media (min-width: 640px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 1024px) {
    width: 100px;
  } */
  /* @media (min-width: 1280px) {
  }
  @media (min-width: 1536px) {
  } */

  .pl-1 {
  }

  .mb-1 {
    margin-bottom: 1rem;
  }
`;
export const Title = styled.h4`
  display: flex;
  color: purple;
  font-size: 1.3em;
  justify-content: space-between;
`;
export const Answer: any = styled.div`
  display: flex;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  //box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1px 0px;
  margin-bottom: 1rem;
  background: ${({ isCorrect }: any) => (isCorrect ? "#f6ffed" : "#fff2f0")};
  outline: ${({ isCorrect }: any) =>
    isCorrect ? "1px solid #b7eb8f" : "1px solid #ffccc7"};

  .icon-info {
    margin-right: 10px;
  }
  .rank {
    font-size: 1em;
    margin: auto 0;
    possition: relative;
    margin-right: 10px;
  }
`;
export const Container = styled.div`
  display: flex;
`;

export const TitlePreview = styled.h3`
  font-family: "Hanuman", "PT Serif", serif, Helvetica, Arial, sans-serif,
    "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Apple Color Emoji",
    /* Emojis*/ "Segoe UI Emoji", /* Emojis*/ "Segoe UI Symbol"; /* Emojis*/
  position: absolute;
  z-index: 1000;
  top: 10px;
  font-size: 1.3em;
  color: darkblue;
`;

export const Solution = styled.div`
  background: #e6f4ff;
  border: 1px solid #91caff;
  padding: 15px;
  //box-shadow: rgb(0 0 0 / 10%) 0px 5px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  .title {
    color: blue;
    font-size: 1em;
  }
`;

export const WrapQuestion = styled.div`
  margin-bottom: 20px;
`;
