import produce from "immer";
import _ from "lodash";
import { selector } from "recoil";
import { SECTION_TYPE } from "../../constants/default-value.const";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { assignIndexKey, mapActivities, mapQuestions } from "../../utils/response.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { mapCompetencies } from "../competency-test/competency-test.selectors";
import { learnsState, learnMetaState, learnState } from "./learn.atoms";

export const learnsSelector = selector({
  key: "learnsSelector",
  get: ({ get }) => {
    const learns = produce(get(learnsState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.LEARN);
    return learns.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const mapLearnTest = (
  learn: any,
  keys = ["libraries", "children"],
  testKeys: string[] = []
) => {
  let tests: any[] = [];
  const testAble = _.get(learn, "testAble");
  if (testAble) {
    const { id, code, title } = testAble;

    const data = {
      id,
      code,
      title,
      sourceAbleType: _.get(learn, "testAbleType"),
      sourceAbleId: _.get(learn, "testAbleId"),
      filterKey: _.get(learn, "testAbleType")
    };

    if (_.get(learn, "testAbleType") === SECTION_TYPE.PRACTICE_TEST) {
      tests = [
        {
          ...data,
          [keys[0]]: mapQuestions({
            dataSources: _.get(testAble, "testPracticeLibraries", []),
            childKey: keys,
            filterKey: SECTION_TYPE.PRACTICE_TEST,
            initIndexKey: [0]
          })
        }
      ];
    }
    if (_.get(learn, "testAbleType") === SECTION_TYPE.COMPETENCY_TEST) {
      tests = [
        {
          ...data,
          [keys[0]]: mapCompetencies({
            dataSources: _.get(testAble, "testCompetencyCompetencies"),
            childKey: testKeys.length ? testKeys : keys,
            // childKey: ["libraries", "children"],
            filterKey: SECTION_TYPE.COMPETENCY_TEST,
            initIndexKey: [0]
          })
        }
      ];
    }
  }

  return _.cloneDeep(tests);
};

export const learnSelector = selector({
  key: "learnSelector",
  get: ({ get }) => {
    const learn = produce(get(learnState), draft => draft);
    const activities = _.get(learn, `learnActivities`, []);

    return {
      ...learn,
      activities: mapActivities({ dataSources: activities, key: "learnActivityLibraries" }),
      tests: assignIndexKey({
        dataSources: mapLearnTest(learn),
        childKey: ["libraries", "libraries", "children"]
      })
    };
  }
});

export const learnMetaSelector = selector({
  key: "learnMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(learnMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
