import styled from "styled-components";

export const FlexContainer = styled.div`
  padding: 8px;
  background: #fdb9ba47;
  display: flex;
`;
export const FlexWrap = styled.div`
  display: flex;
`;

export const Container = styled.div`
  padding: 8px;
  background: #fafafa;
`;

export const ElementFlex = styled.div``;

export const FlexTitle = styled.p`
  color: #536471;
`;
