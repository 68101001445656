import produce from "immer";
import { selector } from "recoil";
import { STORAGE_PATH } from "../../constants/default-value.const";
import { PrefixPermission } from "../../constants/permission.const";
import { storagePath } from "../../utils/firebase.utils";
import { checkPermission } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { mediasState, mediaMetaState, mediasSelectState } from "./media.atoms";

export const mediasSelector = selector({
  key: "mediasSelector",
  get: ({ get }) => {
    const medias = produce(get(mediasState), draft => draft);
    return medias.map((item, i) => {
      return { ...item, key: i.toString(), index: i };
    });
  }
});

export const mediasSelectSelector = selector({
  key: "mediasSelectSelector",
  get: ({ get }) => {
    const medias = produce(get(mediasSelectState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.MEDIA);
    return medias.map((item, i) => {
      const { title, name, extension } = item;
      return {
        ...item,
        name: title,
        path: storagePath(`${STORAGE_PATH.MEDIA_ICON}/${name}.${extension}`),
        ...permissions
      };
    });
  }
});

export const mediaMetaSelector = selector({
  key: "mediaMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(mediaMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
