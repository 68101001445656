import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getCompetitionTestAsync } from "../../../../atom/competition-test/competition-test.apis";
import {
  competitionTestLoadingState,
  competitionTestState
} from "../../../../atom/competition-test/competition-test.atoms";
import { competitionTestSelector } from "../../../../atom/competition-test/competition-test.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const useCompetitionTest = ({ id, forceRender }: UseHookProps) => {
  const setCompetitionTest = useSetRecoilState(competitionTestState);
  const competitionTest = useRecoilValue(competitionTestSelector);
  const [loading, setLoading] = useRecoilState(competitionTestLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCompetitionTestAsync(id)
        .then(res => {
          setCompetitionTest(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }

    return () => {
      setCompetitionTest({});
    };
  }, [id, setCompetitionTest, setLoading, forceRender]);

  return {
    competitionTest,
    loading,
    setLoading
  };
};
