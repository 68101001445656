import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { searchErrorState, searchesState, searchLoadingState } from "./search.atoms";
import { searchesSelector } from "./search.selectors";

export const useSearch = () => {
  const [searchLoading, setSearchLoading] = useRecoilState(searchLoadingState);
  const [searchError, setSearchError] = useRecoilState(searchErrorState);
  const setSearches = useSetRecoilState(searchesState);
  const searches = useRecoilValue(searchesSelector);

  return {
    searchLoading,
    setSearchLoading,
    searchError,
    setSearchError,
    searches,
    setSearches
  };
};
