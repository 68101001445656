import { useHistory, useLocation, useParams } from "react-router-dom";
import { parse } from "query-string";

const useRoute: any = () => {
  const { search, pathname } = useLocation();
  const param = useParams<any>();
  const searchQuery = parse(search);
  const history = useHistory();

  return {
    // ...searchQuery,
    history,
    pathname,
    query: searchQuery,
    param: param
  };
};

export default useRoute;
