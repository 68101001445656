import produce from 'immer';
import _ from 'lodash';
import { selector } from 'recoil';
import { PrefixPermission } from '../../constants/permission.const';
import {
  checkPermission,
  formatDate,
  isSyncable,
} from '../../utils/helper.utils';
import { toPaginationProperty } from '../../utils/request.utils';
import { mapActivities } from '../../utils/response.utils';
import { authUserSelector } from '../auth/auth.selectors';
import {
  resourcesState,
  resourceMetaState,
  resourceState,
} from './resource.atoms';

export const resourcesSelector = selector({
  key: 'resourcesSelector',
  get: ({ get }) => {
    const resources = produce(get(resourcesState), (draft) => draft);
    const permissions = checkPermission(
      get(authUserSelector),
      PrefixPermission.RESOURCE
    );
    return resources.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, 'DD/MM/YYYY HH:mm:ss'),
        deletedAt: formatDate(deletedAt, 'DD/MM/YYYY HH:mm:ss'),
      };
    });
  },
});

export const resourceSelector = selector({
  key: 'resourceSelector',
  get: ({ get }) => {
    const resource = produce(get(resourceState), (draft) => draft);
    const activities = _.get(resource, `resourceActivities`, []);

    return {
      ...resource,
      activities: mapActivities({
        dataSources: activities,
        key: 'resourceActivityLibraries',
      }),
    };
  },
});

export const resourceMetaSelector = selector({
  key: 'resourceMetaSelector',
  get: ({ get }) => {
    const meta = produce(get(resourceMetaState), (draft) => draft);
    return toPaginationProperty(meta);
  },
});
