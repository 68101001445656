/*eslint-disable react/jsx-no-target-blank*/

import { EditOutlined } from '@ant-design/icons';
import { Col, Collapse, Row, Space, Tag } from 'antd';
// import useRoute from "../../hooks/use-route.hook";
import { MathJaxPreview } from '../mathjax-preview/mathjax-preview.component';

import { truncate } from '../../utils/helper.utils';

const { Panel } = Collapse;

export const SearchResult = ({
  search,
  dataSources,
}: {
  search?: string;
  dataSources: any[];
}) => {
  // const { history } = useRoute();
  const callback = (key: string[] | string) => {
    console.log(key);
  };

  const genExtra = (data: any) => (
    <Space>
      {data.type ? (
        <Tag color="blue">{data.questionType || data.type}</Tag>
      ) : null}
      <Tag color="purple" style={{ textTransform: 'uppercase' }}>
        {data.entityName}
      </Tag>

      <a href={data.redirectRoute} target="_blank">
        <EditOutlined
          onClick={(event) => {
            event.stopPropagation();
            // history.push(data.redirectRoute);
          }}
        />
      </a>
    </Space>
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <Collapse
            //   defaultActiveKey={["1"]}
            onChange={callback}
            expandIconPosition={'right'}
            style={{ width: '100%' }}
            //bordered={false}
          >
            {dataSources.map((item, i) => {
              return (
                <Panel
                  key={i}
                  header={
                    <Space>
                      <Tag color="cyan" style={{width:"180px" , textAlign:"center"}}>{item.code}</Tag>{' '}
                      {truncate(item.title, 50)}
                    </Space>
                  }
                  extra={genExtra(item)}
                  //collapsible="disabled"
                >
                  <div>
                    <MathJaxPreview math={item.title} />
                  </div>
                  <div>
                    <MathJaxPreview math={item.description} />
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};
