import produce from "immer";
import { selector } from "recoil";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { categoriesSelectState, categoriesState, categoryMetaState } from "./category.atoms";

export const categoriesSelector = selector({
  key: "categoriesSelector",
  get: ({ get }) => {
    const categories = produce(get(categoriesState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.CATEGORY);
    return categories.map((item, i) => {
      const { updatedAt, deletedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const categoriesSelectSelector = selector({
  key: "categoriesSelectSelector",
  get: ({ get }) => {
    const categories = produce(get(categoriesSelectState), draft => draft);
    return categories.map((item, i) => {
      const { title } = item;
      return { ...item, name: `${title}` };
    });
  }
});

export const categoryMetaSelector = selector({
  key: "categoryMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(categoryMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
