import { Form, Tooltip } from 'antd';
import reactCSS from 'reactcss';
import { TwitterPicker } from 'react-color';
import { PropsType } from './color-picker.type';
import { StyleInput, WrapFormItem } from './color-picker.styles';
import { useEffect, useState } from 'react';

export const ColorPickerForm: React.FC<PropsType> = ({
  label,
  required,
  tooltip,
  placeholder,
  disabled,
  name,
  rules,
  fieldKey,
  value,
  hidden,
  form,
  dataSource,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('');

  useEffect(() => {
    if (form) {
      setColor(form.getFieldValue(name));
    }
  }, [form, name]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: { hex: string }) => {
    setColor(color.hex);
    if (form) form.setFieldsValue({ [name]: color.hex });
  };

  let df = '';
  if (form) {
    df = form.getFieldValue(name);
  }

  const styles = reactCSS({
    default: {
      color: {
        width: '30px',
        height: '30px',
        borderRadius: '4px',
        background: color || df,
      },
      swatch: {
        padding: '0px',
        background: '#fff',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        top: '50px',
        left: '0',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });
  return (
    <>
      <Tooltip placement="topLeft" title={placeholder}>
        <WrapFormItem>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // marginBottom: '20px',
              paddingTop: '4px',
            }}
          >
            <div style={styles.swatch} onClick={handleClick}>
              <div style={styles.color} />
            </div>
            <div style={{ marginLeft: '10px' }}>{color || df}</div>
          </div>
          {displayColorPicker ? (
            <div style={styles.popover as any}>
              <div style={styles.cover as any} onClick={handleClose} />
              <TwitterPicker
                colors={dataSource}
                color={color || df}
                onChange={handleChange}
                triangle="top-left"
              />
            </div>
          ) : null}
          <Form.Item
            hidden={hidden}
            name={name}
            label={label}
            rules={rules}
            required={required}
            tooltip={tooltip}
            fieldKey={fieldKey}
            // className={'d-none'}
          >
            <StyleInput
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              hidden={true}
            />
          </Form.Item>
        </WrapFormItem>
      </Tooltip>
    </>
  );
};
