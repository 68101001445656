import { Col, Form, Row } from "antd";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { UserProfile } from "../../../atom/user/user.types";
import { UploadForm, InputForm } from "../../../components/form";
import { FormModalHeader } from "../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../components/form/form.component";
import { storagePath } from "../../../utils/firebase.utils";
import { useTranslation } from "react-i18next";

interface UserProfileFormProps {
  dataSource?: any;
  handleCancel?: () => void;
  handleOk: (value: UserProfile) => void;
}
export const UserProfileForm: React.FC<UserProfileFormProps> = ({
  dataSource,
  handleCancel,
  handleOk,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({ ...dataSource, roleId: _.get(dataSource, "role.id") });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="userProfileForm" form={form} onFinish={onFinish}>
        <FormModalHeader title="Profile" handleCancel={handleCancel} />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputForm
              name="firstName"
              placeholder={t("First Name")}
              rules={[
                {
                  required: true,
                  message: "Please input the first name!"
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <InputForm
              name="lastName"
              placeholder={t("Last Name")}
              rules={[
                {
                  required: true,
                  message: "Please input the last name!"
                }
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <UploadForm
              form={form}
              name="profile"
              imageUrl={storagePath(dataSource.profile)}
              required={true}
              // rules={[{ required: true, message: "Profile is required" }]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
