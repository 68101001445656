import _ from "lodash";
import { v4 as uuid } from "uuid";
import { getCompetencyTestSelectCompetencyTestsAsync } from "../../../../atom/competency-test/competency-test.apis";
import { mapCompetencies } from "../../../../atom/competency-test/competency-test.selectors";
import { getLearnsSelectLearnsAsync } from "../../../../atom/learn/learn.apis";
import { mapLearnTest } from "../../../../atom/learn/learn.selectors";
import { getPraceticeTestSelectPraceticeTestsAsync } from "../../../../atom/practice-test/practice-test.apis";
import { getResourceSelectResourcesAsync } from "../../../../atom/resource/resource.apis";
import { SECTION_TYPE } from "../../../../constants/default-value.const";
import { LooseObject } from "../../../../types/global.types";
import { mapActivities, mapQuestions } from "../../../../utils/response.utils";
import { CompetencyTestModal } from "../../competency-test/components/competency-test-modal.compenent";
import { LearnModal } from "../../learn/components/learn-modal.component";
import { PracticeTestModal } from "../../practice-test/components/practice-test-modal.component";
import { ResourceModal } from "../../resource/components/resource-modal.component";

interface SectionFormModalProps {
  visible: boolean;
  onOk: (value: LooseObject[]) => void;
  onCancel: () => void;
  type: string;
  selectionType?: string;
  defaultFilter?: any;
}

export const SectionModal = ({
  visible,
  onOk,
  onCancel,
  type,
  selectionType,
  defaultFilter
}: SectionFormModalProps) => {
  const _onOk = (values: any) => {
    let request = getResourceSelectResourcesAsync;
    if (type === SECTION_TYPE.LEARN) {
      request = getLearnsSelectLearnsAsync;
    } else if (type === SECTION_TYPE.COMPETENCY_TEST) {
      request = getCompetencyTestSelectCompetencyTestsAsync;
    } else if (type === SECTION_TYPE.PRACTICE_TEST) {
      request = getPraceticeTestSelectPraceticeTestsAsync;
    }

    request({ ids: values.map((item: any) => item.id) }).then(res => {
      if (type === SECTION_TYPE.RESOURCE) {
        const results = res.map((item: any) => {
          return {
            ...item,
            id: uuid(),
            isNewRecord: true,
            sourceAbleId: item.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: mapActivities({
              dataSources: _.get(item, `resourceActivities`, []),
              key: "resourceActivityLibraries",
              childKey: ["libraries", "libraries"],
              filterKey: type
            })
          };
        });

        onOk(results);
      } else if (type === SECTION_TYPE.LEARN) {
        const results = res.map((item: any) => {
          return {
            ...item,
            id: uuid(),
            isNewRecord: true,
            sourceAbleId: item.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: [
              ...mapActivities({
                dataSources: _.get(item, `learnActivities`, []),
                key: "learnActivityLibraries",
                childKey: ["libraries", "libraries"],
                filterKey: type
              }),
              ...mapLearnTest(item, ["libraries", "libraries"], ["libraries", "children"])
            ]
          };
        });
        onOk(results);
      } else if (type === SECTION_TYPE.COMPETENCY_TEST) {
        const results = res.map((item: any) => {
          return {
            ...item,
            id: uuid(),
            isNewRecord: true,
            sourceAbleId: item.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: mapCompetencies({
              dataSources: _.get(item, "testCompetencyCompetencies", []),
              childKey: ["libraries", "libraries"],
              filterKey: type
            })
          };
        });
        onOk(results);
      } else if (type === SECTION_TYPE.PRACTICE_TEST) {
        const results = res.map((item: any) => {
          return {
            ...item,
            id: uuid(),
            isNewRecord: true,
            sourceAbleId: item.id,
            sourceAbleType: type,
            filterKey: type,
            dataSources: mapQuestions({
              dataSources: _.get(item, "testPracticeLibraries", []),
              childKey: ["libraries"]
            })
          };
        });
        onOk(results);
      }
    });
  };

  const renderModal = () => {
    const renderProps = {
      visible: visible,
      onOk: _onOk,
      onCancel: onCancel,
      selectionType: selectionType,
      defaultFilter
    };

    if (visible)
      if (type === SECTION_TYPE.RESOURCE) {
        return <ResourceModal {...renderProps} />;
      } else if (type === SECTION_TYPE.LEARN) {
        return <LearnModal {...renderProps} />;
      } else if (type === SECTION_TYPE.PRACTICE_TEST) {
        return <PracticeTestModal {...renderProps} />;
      } else if (type === SECTION_TYPE.COMPETENCY_TEST) {
        return <CompetencyTestModal {...renderProps} />;
      }

    return null;
  };

  return renderModal();
};
