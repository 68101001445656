import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta } from "../../../../types/global.types";
import {
  canShowAll,
  convertObjectArrayToString,
  exclude,
  removeUndefined,
  toFilterSelect,
  toSort,
  toString,
  toStringFilter,
} from "../../../../utils/helper.utils";
import { getResourcesAsync } from "../../../../atom/resource/resource.apis";
import { showMessageErrors } from "../../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  resourceLoadingState,
  resourceMetaState,
  resourcesState,
} from "../../../../atom/resource/resource.atoms";
import {
  resourceMetaSelector,
  resourcesSelector,
} from "../../../../atom/resource/resource.selectors";
import { Resource } from "../../../../atom/resource/resource.types";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { useTopic } from "../../../../atom/topic/topic.hook";
import { Modal } from "antd";
import { SearchAndTitle } from "../../../../components/search-and-title/search-and-title.component";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { columnTableEllipsis } from "../../../../utils/table.utils";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestResources = (
  query: any,
  setResources: (items: Resource[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = exclude(removeUndefined(query), ["topics"]);
  setLoading(true);
  return getResourcesAsync({ ...option, status: true })
    .then(({ items, meta }) => {
      setResources(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setResources([]);
      setMeta({});
      setLoading(false);
    });
};

interface ResourceModalProps {
  visible: boolean;
  onOk: (value: any) => void;
  onCancel: () => void;
  selectionType?: any;
  defaultFilter?: any;
}

export const ResourceModal: React.FC<ResourceModalProps> = ({
  visible,
  onOk,
  onCancel,
  selectionType,
  defaultFilter,
}) => {
  const setResources = useSetRecoilState(resourcesState);
  const setMeta = useSetRecoilState(resourceMetaState);
  const resources = useRecoilValue(resourcesSelector);
  const meta = useRecoilValue(resourceMetaSelector);
  const [loading, setLoading] = useRecoilState(resourceLoadingState);
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [searchInfo, setSearchInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const query: any = defaultFilter;
  const { t } = useTranslation();

  const { topics, levels, subjects, categories, setTopics } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
    hasResourceCategory: true,
  });

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestResources(
      { ...option, query: searchQuery },
      setResources,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      subjectId: toStringFilter(query.subjectId),
      levelId: toStringFilter(query.levelId),
      topicId: toStringFilter(query.topicId),
      categoryId: toStringFilter(query.categoryId),
    });
    setTopicLevelId(toString(query.levelId));
    setTopicSubjectId(toString(query.subjectId));
    setTopics(_.get(query, "topics", []));
    return () => {
      setSearchInfo({});
    };
  }, [searchQuery, setTopicLevelId, setTopicSubjectId]);

  useEffect(() => {
    setColumns([
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Resource, b: Resource) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: false,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: Resource, b: Resource) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: false,
      },
      {
        title: t("Rank"),
        dataIndex: "rank",
        sorter: (a: Resource, b: Resource) => a.rank - b.rank,
        sortOrder: sortedInfo.columnKey === "rank" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Category"),
        dataIndex: "categoryId",
        filteredValue: filteredInfo.categoryId || null,
        filters: toFilterSelect(categories),
        onFilter: () => true,
        ...columnTableEllipsis("category"),
      },
      {
        title: t("Subject"),
        dataIndex: "subjectId",
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis("subject"),
      },
      {
        title: t("Level"),
        dataIndex: "levelId",
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis("level"),
      },
      {
        title: t("Topic"),
        dataIndex: "topicId",
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis("topic"),
      },
    ]);
  }, [filteredInfo, sortedInfo, subjects, levels, topics, categories, t]);

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
      categoryId,
    } = data;

    const option = {
      ...query,
      ...searchInfo,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      query: searchQuery,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
      categoryId: toString(categoryId),
    };
    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));
    setFilteredInfo({ subjectId, levelId, topicId, categoryId });
    requestResources(option, setResources, setMeta, setLoading);
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
      query: searchQuery,
    });
    setSearchInfo({ searchField: column, searchValue: value });
    requestResources(option, setResources, setMeta, setLoading);
  };

  const _onOk = () => {
    if (selectedRows.length) {
      onOk(selectedRows);
    } else {
      onCancel();
    }
  };

  const onSelectedRows = (selectedRows: Resource[]) => {
    setSelectedRows(selectedRows);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  console.log(filteredInfo);

  const onShowAll = () => {
    const option = {
      ...convertObjectArrayToString(filteredInfo),
      ...searchInfo,
      query: searchQuery,
      showAll: "yes",
    };
    requestResources(option, setResources, setMeta, setLoading);
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchInfo({});
    requestResources({}, setResources, setMeta, setLoading);
  };

  return (
    <Fragment>
      <Modal
        visible={visible}
        title={null}
        closable={false}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        onCancel={onCancel}
        maskClosable={false}
        width="80%"
        onOk={_onOk}
      >
        <SearchAndTitle title={t("Resources")} handleSearch={handleSearch} />
        <ListFeatureOption
          onShowAll={onShowAll}
          onClearFilter={onClearFilter}
          showAll={canShowAll(searchInfo, filteredInfo)}
          query={query}
        />
        <TableComponent
          key={`table-${loading}-${resources.length}`}
          dataSource={resources}
          columns={columns}
          onChange={onTableChange}
          onColumnSearch={onColumnSearch}
          pagination={meta}
          loading={loading}
          query={query}
          hasSelectedRows={true}
          onSelectedRows={onSelectedRows}
          selectionType={selectionType}
        />
      </Modal>
    </Fragment>
  );
};
