import { useForm } from "antd/lib/form/Form";
import { Layout } from "../../../components/layout/layout.component";
import { CompetencyTestForm } from "./components/competency-test-form.component";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { replaceParam } from "../../../utils/helper.utils";
import { RoutePaths } from "../../../constants/route.const";
import useRoute from "../../../hooks/use-route.hook";
import { messageSuccess, showMessageErrors, showSyncConfirm } from "../../../utils/message.utils";
import { competencyTestsSyncToFirestoreAsync } from "../../../atom/competency-test/competency-test.apis";

export const CompetencyTestFormPage = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isBack, setIsBack] = useState(false);
  const { param } = useRoute();

  const onSync = () => {
    showSyncConfirm(() => {
      competencyTestsSyncToFirestoreAsync([param.id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  return (
    <Layout
      title={t("Competency Test")}
      onSubmit={isBack => {
        setIsBack(isBack || false);
        form.submit();
      }}
      onPreview={() => {
        window.open(replaceParam(RoutePaths.MOBILE_PREVIEW_COMPETENCY_TEST, param.id), "_blank");
      }}
      onSync={onSync}
    >
      <CompetencyTestForm form={form} isBack={isBack} />
    </Layout>
  );
};
