// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { ExamTest } from "./exam-test.types";

export const examTestsState = atom<ExamTest[]>({
  key: "examTestsState",
  default: []
});

export const examTestMetaState = atom<Meta>({
  key: "examTestMetaState",
  default: {}
});

export const examTestState = atom<ExamTest | {}>({
  key: "examTestState",
  default: {}
});

export const examTestLoadingState = atom<boolean>({
  key: "examTestLoadingState",
  default: false
});
