import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { Resource } from './resource.types';

export const getResourcesAsync = (option: {}) => {
  const url = generateQuery('/resources', option, [
    'levelId',
    'subjectId',
    'topicId',
    'categoryId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getResourceAsync = (id: string) => {
  return axios
    .get(`/resources/${id}`, config())
    .then((response) => response.data.data);
};

export const getResourcesSelectAsync = (option: {}) => {
  const url = generateQuery('/resources/for/select-option', option);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getResourcesTrashAsync = (option: {}) => {
  const url = generateQuery('/resources/list/trash', option, [
    'levelId',
    'subjectId',
    'topicId',
    'categoryId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getResourceSelectResourcesAsync = (data: RestoreType) => {
  return axios
    .post('/resources/list/selected-resource', data, config())
    .then((response) => response.data.data);
};

export const restoreResourcesAsync = (data: RestoreType) => {
  return axios
    .patch('/resources/restore', data, config())
    .then((response) => response.data);
};

export const createResourceAsync = (data: Resource) => {
  return axios
    .post('/resources', data, config())
    .then((response) => response.data);
};

export const updateResourceAsync = (id: string, data: Resource) => {
  return axios
    .put(`/resources/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteResourceAsync = (id: string) => {
  return axios
    .delete(`/resources/${id}`, config())
    .then((response) => response.data);
};

export const deleteResourceSourceAsync = (id: string) => {
  return axios
    .delete(`/resource-activities/${id}`, config())
    .then((response) => response.data);
};

export const deleteResourceSourceLibraryAsync = (id: string) => {
  return axios
    .delete(`/resource-activity-libraries/${id}`, config())
    .then((response) => response.data);
};

export const sortResourcesAsync = (data: SortData) => {
  return axios
    .patch('/resources/set-rank', data, config())
    .then((response) => response.data);
};

export const getResourceReferenceUsingAsync = (id: string) => {
  return axios
    .get(`/resources/reference-using/${id}`, config())
    .then((response) => response.data.data);
};

export const resourcesSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/resources/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};

export const resourceUnsyncFromFirestore = async (id: string) => {
  return axios
    .delete(`/resources/unsync-from-firestore/${id}`, config())
    .then((response) => response.data);
};
