import React, { Fragment, useEffect, useState } from "react";
import { Switch } from "antd";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta, VisibleProps } from "../../../../types/global.types";
import { SubjectFormModal } from "./subject-form-modal.component";
import {
  exclude,
  removeUndefined,
  toNumberFilter,
  toSort,
  toString,
  checkPermission,
  isAdmin,
  toSortRank,
  canShowAll,
  canSortRow,
} from "../../../../utils/helper.utils";
import {
  createSubjectAsync,
  deleteSubjectAsync,
  getSubjectsAsync,
  getSubjectsTrashAsync,
  restoreSubjectsAsync,
  sortSubjectsAsync,
  subjectsSyncToFirestoreAsync,
  updateSubjectAsync,
} from "../../../../atom/subject/subject.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
  showSyncConfirm,
} from "../../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  subjectLoadingState,
  subjectMetaState,
  subjectsState,
} from "../../../../atom/subject/subject.atoms";
import {
  subjectMetaSelector,
  subjectsSelector,
} from "../../../../atom/subject/subject.selectors";
import useRoute from "../../../../hooks/use-route.hook";
import {
  QUERY_TYPE,
  FILTER_STATUS,
  SORT,
} from "../../../../constants/default-value.const";
import { TableActionButton } from "../../../../components/table/table-action-button.component";
import { arrangeData, generateRequest } from "../../../../utils/request.utils";
import { SourceDataTab } from "../../../../components/source-data-tab/source-data-tab.component";
import { StatusFeature, Subject } from "../../../../atom/subject/subject.type";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../../atom/app/app.hook";
import { PrefixPermission } from "../../../../constants/permission.const";
import { useAuth } from "../../../../hooks/use-auth";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestSubjects = (
  query: any,
  setSubjects: (items: Subject[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getSubjectsTrashAsync
      : getSubjectsAsync;
  return request(option)
    .then(({ items, meta }) => {
      setSubjects(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setSubjects([]);
      setMeta({});
      setLoading(false);
    });
};

interface SubjectProps extends VisibleProps {
  searchQuery: string;
}
export const SubjectComponent: React.FC<SubjectProps> = ({
  visible,
  setVisible,
  searchQuery,
}) => {
  const setSubjects = useSetRecoilState(subjectsState);
  const setMeta = useSetRecoilState(subjectMetaState);
  const subjects = useRecoilValue(subjectsSelector);
  const meta = useRecoilValue(subjectMetaSelector);
  const { profile } = useAuth();
  const [loading, setLoading] = useRecoilState(subjectLoadingState);
  const [dataSource, setDataSource] = useState<Subject>();
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [enableSort, setEnableSort] = useState(false);
  const { t } = useTranslation();
  const { setAppLoading } = useApp();

  const { colors, medias } = useSelectOption({
    hasColor: true,
    hasMedia: true,
  });

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestSubjects(
      { ...option, query: searchQuery || query.query },
      setSubjects,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      status: toNumberFilter(query.status),
      grade: toNumberFilter(query.grade),
      comingSoon: toNumberFilter(query.comingSoon),
    });
  }, [searchQuery, query.query]);

  useEffect(() => {
    setColumns([
      {
        title: t("Sort"),
        dataIndex: "sort",
        width: 30,
        isDraggable: true,
      },
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Subject, b: Subject) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: Subject, b: Subject) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Rank"),
        dataIndex: "rank",
        sorter: (a: Subject, b: Subject) => a.rank - b.rank,
        sortOrder: sortedInfo.columnKey === "rank" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },

      {
        title: t("Publish"),
        dataIndex: "status",
        sorter: (a: Subject, b: Subject) => Number(a.status) - Number(b.status),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Subject) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      /* {
        title: t('ComingSoon'),
        dataIndex: 'comingSoon',
        sorter: (a: Subject, b: Subject) =>
          Number(a.comingSoon) - Number(b.comingSoon),
        sortDirections: ['descend', 'ascend'],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.comingSoon || null,
        sortOrder: sortedInfo.columnKey === 'comingSoon' && sortedInfo.order,
        render: (text: boolean, { id }: Subject) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handleComingSoon(id, value)}
          />
        ),
      }, */
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Subject, b: Subject) =>
          a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Deleted At"),
        dataIndex: "deletedAt",
        sorter: (a: Subject, b: Subject) =>
          a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === "deletedAt" && sortedInfo.deletedAt,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: Subject) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            //handleSyncTofirestore={handleSyncTofirestore}
            record={record}
            //hasMore={true}
            //handleStatusFeature={handleStatusFeature}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo, t]);

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<Subject>(data);
    sortSubjectsAsync(sortData)
      .then(() => {
        messageSuccess();
        requestSubjects(query, setSubjects, setMeta, setLoading);
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      status,
      grade,
      comingSoon,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      status: toString(status),
      grade: toString(grade),
      comingSoon: toString(comingSoon),
    };

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ status, grade });
    requestSubjects(option, setSubjects, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestSubjects(option, setSubjects, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const handleEdit = (record: Subject) => {
    setDataSource(record);
    setVisible(true);
  };

  const handleOk = async (value: Subject) => {
    const { id } = value;
    setAppLoading(true);
    const request = id
      ? updateSubjectAsync(id, value)
      : createSubjectAsync(value);
    request
      .then(({ message }) => {
        messageSuccess(message);
        requestSubjects(query, setSubjects, setMeta, setLoading);
        handleCancel();
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSaveEditable = (value: Subject) => {
    const { id, title, code, rank } = value;

    updateSubjectAsync(id, { title, code, rank: Number(rank) } as Subject)
      .then(({ message }) => {
        messageSuccess(message);
        requestSubjects(query, setSubjects, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setDataSource({} as Subject);
  };

  const handleDuplicate = (record: Subject) => {
    setDataSource(exclude(record, ["id"]));
    setVisible(true);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteSubjectAsync(id)
        .then(() => {
          messageSuccess();
          requestSubjects(query, setSubjects, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      subjectsSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  const handlePublish = (id: string, value: boolean) => {
    updateSubjectAsync(id, { status: value } as Subject)
      .then(() => messageSuccess())
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleStatusFeature = (id: string, value: StatusFeature) => {
    updateSubjectAsync(id, { statusFeature: value } as Subject)
      .then(() => messageSuccess())
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  // const handleComingSoon = (id: string, value: boolean) => {
  //   updateSubjectAsync(id, { status: value } as Subject)
  //     .then(() => messageSuccess())
  //     .catch((err) => {
  //       showMessageErrors(err);
  //     });
  // };

  const onChangeTab = (key: string) => {
    requestSubjects(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setSubjects,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Subject[]) => {
    showRestoreConfirm(() => {
      restoreSubjectsAsync({
        ids: selectedRows.map((item: Subject) => item.id),
      })
        .then(() => {
          messageSuccess();
          requestSubjects(query, setSubjects, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onEnableSort = () => {
    setEnableSort(true);
    const option = {
      ...query,
      showAll: "yes",
      sortField: "rank",
      sortOrder: SORT.ASC,
    };
    setSortedInfo(toSortRank());
    requestSubjects(option, setSubjects, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestSubjects(option, setSubjects, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setEnableSort(false);
    requestSubjects({}, setSubjects, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.SUBJECT}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        onEnableSort={onEnableSort}
        showAll={canShowAll(query, filteredInfo)}
        enableSort={canSortRow(query, [])}
        query={query}
      />
      <SubjectFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={dataSource}
        colors={colors}
        medias={medias}
      />
      <TableComponent
        key={`table-${loading}-${subjects.length}`}
        dataSource={subjects}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.SUBJECT).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
        enableSort={enableSort && canSortRow(query, [])}
      />
    </Fragment>
  );
};
