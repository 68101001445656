import styled from "styled-components";

export const TablePermission = styled.table`
  width: 100%;

  tr {
    border-bottom: 1px solid #ddd;

    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;
