// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Role, RoleSelect } from "./role.type";

export const rolesState = atom<Role[]>({
  key: "rolesState",
  default: []
});

export const rolesSelectState = atom<RoleSelect[]>({
  key: "rolesSelectState",
  default: []
});

export const roleMetaState = atom<Meta>({
  key: "roleMetaState",
  default: {}
});

export const roleLoadingState = atom<boolean>({
  key: "roleLoadingState",
  default: false
});
