import Axios from "axios";
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
  // timeout: 5000
});

export const config = (option = {}) => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    },
    ...option
  };
};
