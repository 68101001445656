import styled from 'styled-components';
import { Button, Form } from 'antd';

export const FormItem = styled(Form.Item)`
  .ql-container {
    height: 200px;
    background: #eeeeee;
  }
`;

export const StyleEdit = styled(Button)`
  position: absolute;
  right: 0;
`;

export const StyleInputHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  p {
    margin: 0;
  }
  span {
    color: #1877f2;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const WrapIcon = styled.div`
  display: flex;
  span.anticon.anticon-cloud-upload {
    position: absolute;
    right: 30px;
    top: 12px;
  }
  span.anticon.anticon-zoom-in {
    position: absolute;
    right: 15px;
    top: 12px;
  }

  span.anticon.anticon-audio {
    position: absolute;
    right: 55px;
    top: 12px;
  }
`;
export const OptionIcon = styled.div`
  flex-grow: 1;
`;
export const MeasureText = styled.div``;


export const EditorContainer = styled.div`
  a#cke_15,
  a#cke_16,
  a#cke_17,
  a#cke_18,
  a#cke_24,
  a#cke_25,
  a#cke_26,
  a#cke_53,
  a#cke_56 {
    display: none;
  }
  
  .cke_chrome {
    border: 1px solid #ced6e0;
    font-family: "Hanuman", serif;
  }

  .cke_top,
  .cke_bottom {
    background: #f1f2f6;
    border: none;
  }

  .cke_editable
  .cke_editable_themed
  .cke_contents_ltr
  .cke_show_borders{
    font-family: "Hanuman", serif;
  }
  
`;

export const TextEditorPreview = styled.div`
  * {
    padding: revert;
  }
`;
