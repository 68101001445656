import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getCompetencyAsync } from "../../../../atom/competency/competency.apis";
import {
  competencyLoadingState,
  competencyState
} from "../../../../atom/competency/competency.atoms";
import { competencySelector } from "../../../../atom/competency/competency.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const useCompetency = ({ id, forceRender }: UseHookProps) => {
  const setCompetency = useSetRecoilState(competencyState);
  const [loading, setLoading] = useRecoilState(competencyLoadingState);
  const competency = useRecoilValue(competencySelector);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCompetencyAsync(id)
        .then(res => {
          setCompetency(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }

    return () => {
      setCompetency({});
    };
  }, [id, setCompetency, setLoading, forceRender]);

  return {
    competency,
    loading,
    setLoading,
    setCompetency
  };
};
