import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RoutePaths } from '../constants/route.const';
import _ from 'lodash';
const { confirm } = Modal;

export const showDeleteConfirm = (
  callback: () => void,
  message = 'Do you want to delete these item?'
) => {
  confirm({
    title: message,
    icon: <ExclamationCircleOutlined />,
    content: 'You can not undo it.',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      callback();
    },
    onCancel() {},
  });
};

export const showSyncConfirm = (callback: () => void) => {
  showDeleteConfirm(callback, 'Do you want to sync these item to firestore?');
};

export const showUnSyncConfirm = (callback: () => void) => {
  showDeleteConfirm(callback, 'Do you want to remove sync from firestore?');
};

export const showRestoreConfirm = (callback: () => void) => {
  showDeleteConfirm(callback, 'Do you want to restore these item?');
};

export const messageSuccess = (msg: string = 'Success') => {
  message.success(msg);
};

export const messageError = (msg: string = 'Error') => {
  message.error(msg);
};

export const messageWarn = (msg: string = 'Warning') => {
  message.warning(msg);
};

export const showMessageErrors = (err: any) => {
  if (!_.get(err, `response.status`)) {
    messageError(err.message);
  } else if (err.response.status === 422) {
    err.response.data.message.forEach((item: any) => {
      // const { property, constraints } = item;
      const msg = `${item}`;
      messageError(msg);
    });
  } else if (err.response.status === 401) {
    messageError(err.response.data.message);
    clearAppLocalStorage();
    setTimeout(() => {
      window.location.replace(RoutePaths.SIGN_IN);
    }, 2000);
  } else {
    messageError(err.response.data.message);
  }
};

export const clearAppLocalStorage = () => {
  localStorage.removeItem('accessToken');
};
