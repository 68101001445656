import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { Course } from './course.types';

export const getCoursesAsync = (option: {}) => {
  const url = generateQuery('/courses', option, [
    'levelId',
    'subjectId',
    'topicId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getCourseAsync = (id: string) => {
  return axios
    .get(`/courses/${id}`, config())
    .then((response) => response.data.data);
};

export const getCoursesTrashAsync = (option: {}) => {
  const url = generateQuery('/courses/list/trash', option);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const restoreCoursesAsync = (data: RestoreType) => {
  return axios
    .patch('/courses/restore', data, config())
    .then((response) => response.data);
};

export const createCourseAsync = (data: Course) => {
  return axios
    .post('/courses', data, config())
    .then((response) => response.data);
};

export const updateCourseAsync = (id: string, data: Course) => {
  return axios
    .put(`/courses/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteCourseAsync = (id: string) => {
  return axios
    .delete(`/courses/${id}`, config())
    .then((response) => response.data);
};

// export const deleteCourseLibraryAsync = (id: string) => {
//   return axios.delete(`/exam-test-libraries/${id}`, config()).then(response => response.data);
// };

export const deleteCourseSectionAsync = (id: string) => {
  return axios
    .delete(`/course-section/${id}`, config())
    .then((response) => response.data);
};

export const sortCoursesAsync = (data: SortData) => {
  return axios
    .patch(`courses/set-rank`, data, config())
    .then((response) => response.data);
};

export const coursesSyncToFirestoreAsync = (id: string) => {
  return axios
    .patch(`/courses/sync/to-firestore/${id}`, {}, config())
    .then((response) => response.data);
};

export const courseUnsyncFromFirestore = async (id: string) => {
  return axios
    .delete(`/courses/unsync-from-firestore/${id}`, config())
    .then((response) => response.data);
};
