import React, { Fragment } from "react";
import { VisibleProps } from "../../../types/global.types";
import { ChangePasswordFormModal } from "./change-password-form-modal.component";
import { updatePasswordAsync } from "../../../atom/auth/auth.apis";
import {
  clearAppLocalStorage,
  messageSuccess,
  showMessageErrors
} from "../../../utils/message.utils";
import { UserChangePassword } from "../../../atom/auth/auth.types";
import { useApp } from "../../../atom/app/app.hook";
import useRoute from "../../../hooks/use-route.hook";
import { RoutePaths } from "../../../constants/route.const";

export const ChangePasswordComponent: React.FC<VisibleProps> = ({ visible, setVisible }) => {
  const { setAppLoading } = useApp();
  let { history } = useRoute();

  const logout = () => {
    clearAppLocalStorage();
    history.push(RoutePaths.SIGN_IN);
  };

  const handleOk = async (value: UserChangePassword) => {
    setAppLoading(true);
    const { oldPassword, password } = value;

    const request = updatePasswordAsync({ oldPassword, password });
    request
      .then(({ message }) => {
        messageSuccess(message);
        handleCancel();
        setAppLoading(false);
        logout();
      })
      .catch(err => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Fragment>
      <ChangePasswordFormModal visible={visible} handleCancel={handleCancel} handleOk={handleOk} />
    </Fragment>
  );
};
