import produce from "immer";
import { selector } from "recoil";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { rolesState, roleMetaState, rolesSelectState } from "./role.atoms";

export const rolesSelector = selector({
  key: "rolesSelector",
  get: ({ get }) => {
    const roles = produce(get(rolesState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.ROLE);
    return roles.map((item, i) => {
      const { updatedAt, deletedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const rolesSelectSelector = selector({
  key: "rolesSelectSelector",
  get: ({ get }) => {
    const roles = produce(get(rolesSelectState), draft => draft);
    return roles.map((item, i) => {
      const { name } = item;
      return { ...item, name: `${name}` };
    });
  }
});

export const roleMetaSelector = selector({
  key: "roleMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(roleMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
