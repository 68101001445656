import styled from "styled-components";

export const WrapPreviewColor = styled.div`
  display: flex;
`;

export const ColorBox = styled.div`
  width: 22px;
  height: 22px;
  border: 0px solid #dadada;
  margin-right: 10px;
  border-radius: 4px;
  background: ${({ color }) => color};
`;
