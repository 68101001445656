import produce from "immer";
import { selector } from "recoil";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { colorsState, colorMetaState, colorsSelectState } from "./color.atoms";

export const colorsSelector = selector({
  key: "colorsSelector",
  get: ({ get }) => {
    const colors = produce(get(colorsState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.COLOR_CODE);
    return colors.map((item, i) => {
      const { updatedAt, deletedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const colorsSelectSelector = selector({
  key: "colorsSelectSelector",
  get: ({ get }) => {
    const colors = produce(get(colorsSelectState), draft => draft);
    return colors.map((item, i) => {
      const { id, code } = item;
      return {
        id,
        name: code,
        code
      };
    });
  }
});

export const colorMetaSelector = selector({
  key: "colorMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(colorMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
