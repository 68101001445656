import { useState } from "react";
import { Layout } from "../../../components/layout/layout.component";
import { PrefixPermission } from "../../../constants/permission.const";
import { useVisible } from "../../../hooks/use-visible.hook";
import { CategoryComponent } from "./components/category.component";

export const CategoryPage = () => {
  const { visible, setVisible } = useVisible();
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Layout
      onCreate={() => setVisible(true)}
      onSearch={value => setSearchQuery(value)}
      prefixPermission={PrefixPermission.CATEGORY}
    >
      <CategoryComponent visible={visible} setVisible={setVisible} searchQuery={searchQuery} />
    </Layout>
  );
};
