import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { v4 as uuid } from 'uuid';
import { ButtonAddBrowse } from '../../../../components/button-add-browse/button-add-browse.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { SortableItems } from '../../../../components/sortable-items/sortable-items.component';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import { FormActivityModal } from '../../../../components/form-activity-modal/form-activity-modal.component';
import { LibraryModal } from '../../../elements/library/library-modal.component';
import useRoute from '../../../../hooks/use-route.hook';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import { useReview } from './review.hook';
import { FORM_TYPE } from '../../../../constants/default-value.const';
import {
  createReviewAsync,
  updateReviewAsync,
  deleteReviewSourceAsync,
  deleteReviewSourceLibraryAsync,
} from '../../../../atom/review/review.apis';

import { FormInstance } from 'antd/lib/form/Form';
import _ from 'lodash';
import {
  messageError,
  messageSuccess,
  messageWarn,
  showDeleteConfirm,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import {
  Activity,
  FormActivityType,
  LooseObject,
  Question,
} from '../../../../types/global.types';
import { LibraryFormModal } from '../../../elements/library/library-form-modal.component';
import { formatActivities } from '../../../../utils/request.utils';
import produce from 'immer';
import { TextEditorForm } from '../../../../components/form';
import { ReviewFormValue } from '../../../../atom/review/review.types';
import {
  exclude,
  requestTopicDefault,
  setSelectFilter,
  toNewRecordActivities,
} from '../../../../utils/helper.utils';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import { checkFormRedirect } from '../../../../utils/form.utils';
import { assignIndexKey } from '../../../../utils/response.utils';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';

enum MODAL_TYPE {
  ACTIVITY_FROM = 'ACTIVITY_FROM',
  LIBRARY_ACTIVITY = 'LIBRARY_ACTIVITY',
  LIBRARY_ACTIVITY_FORM = 'LIBRARY_ACTIVITY_FORM',
  LIBRARY_ACTIVITY_FORM_EDIT = 'LIBRARY_ACTIVITY_FORM_EDIT',
}
export interface FormValue extends ReviewFormValue {
  activities: LooseObject;
}

export const ReviewForm = ({
  form,
  isBack,
}: {
  form: FormInstance;
  isBack: boolean;
}) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [libraryType, setLibraryType] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [modalType, setModalType] = useState('');
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const [forceRender, setForceRender] = useState(false);
  useEnterEvent({ form, enter: !modalType });
  const { t } = useTranslation();
  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, topics, colors } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
    hasColor: true,
  });

  const { setAppLoading, appFilter, setAppFilter } = useApp();
  const { loading, review } = useReview({ id, forceRender });

  useEffect(() => {
    return () => {
      setAppFilter({});
    };
  }, [setAppFilter]);

  useEffect(() => {
    const levelId = _.get(review, 'level.id');
    const subjectId = _.get(review, 'subject.id');
    form.setFieldsValue({
      ...review,
      topicId: _.get(review, 'topic.id'),
      categoryId: _.get(review, 'category.id'),
      color: _.get(review, 'color') || _.get(review, 'colorCode.code'),
      subjectId,
      levelId,
    });
    setActivities(
      toNewRecordActivities(
        _.get(review, `activities`, []),
        formType === FORM_TYPE.DUPLICATE
      )
    );
    setSelectFilter({
      dataSource: review,
      defaultFilter: {},
      form,
      elementId: 'reviewRequest',
    });
  }, [review, form, formType]);

  const onFinish = (value: any) => {
    const {
      code,
      title,
      topicId,
      subjectId,
      levelId,
      color,
      status,
      premium,
      comingSoon,
      description,
    } = value;
    const sources = [
      ...formatActivities(activities, [
        'reviewActivityLibraries',
        'activities',
      ]),
    ];
    // if (!sources.length) {
    //   messageWarn("Activities or questions is required");
    //   return false;
    // }

    if (sources.length !== activities.length) {
      messageWarn('Has some blank activities');
      return;
    }

    const data: any = {
      code: code,
      title: title,
      topicId: topicId,
      subjectId: subjectId,
      levelId: levelId,
      color: color,
      status: status ? true : false,
      premium: premium ? true : false,
      comingSoon: comingSoon ? true : false,
      description: description,
      reviewActivities: sources,
    };

    let request =
      formType === FORM_TYPE.EDIT
        ? updateReviewAsync(id, data)
        : createReviewAsync(data);

    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEnd = (items: Activity[]) => {
    setActivities(items);
  };

  const onSortEndChild = (items: Activity[]) => {
    setActivities(items);
  };

  const onEdit = (value: Activity) => {
    setDataSource(value);
    setModalType(MODAL_TYPE.ACTIVITY_FROM);
  };

  const onDelete = ({ id, isNewRecord }: Activity) => {
    showDeleteConfirm(() => {
      const filterActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          return draft.filter((item) => item.id !== id);
        }
      );
      if (isNewRecord) setActivities(filterActivities);
      else
        deleteReviewSourceAsync(id)
          .then((res) => {
            setActivities(filterActivities);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
    });
  };

  const onDuplicate = (item: Activity) => {
    const newActivities = produce<Activity[], Activity[]>(
      activities,
      (draft) => {
        const actData = item.activities.map((act) => {
          return { ...act, isNewRecord: true };
        });

        draft.push({
          ...item,
          id: uuid(),
          isNewRecord: true,
          activities: actData,
        });
        return draft;
      }
    );
    setActivities(newActivities);
  };

  const onEditChild = (value: Question) => {
    const { type, questionType } = value;
    setDataSource(exclude(value, ['isNewRecord']));
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT);
    setLibraryType(type);
    setQuestionType(questionType);
  };

  const onDeleteChild = ({ id, parentIndex, isNewRecord }: Question) => {
    showDeleteConfirm(() => {
      const filterActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          const filterChildren = draft[parentIndex].activities.filter(
            (item) => item.id !== id
          );
          draft[parentIndex].activities = filterChildren;
          return draft;
        }
      );
      if (isNewRecord) {
        setActivities(filterActivities);
      } else {
        deleteReviewSourceLibraryAsync(id)
          .then((res) => {
            messageSuccess();
            setActivities(filterActivities);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onDuplicateChild = (item: Question) => {
    setQuestionType(item.questionType);
    setLibraryType(item.type);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM);
  };

  const onCreateActivity = () => {
    setDataSource({});
    setModalType(MODAL_TYPE.ACTIVITY_FROM);
  };

  const onOkFormActivity = (values: FormActivityType) => {
    const { title, id } = values;
    const newActivities = produce<Activity[], Activity[]>(
      activities,
      (draft) => {
        if (id) {
          let index = draft.findIndex((item) => item.id === id);
          draft[index]['title'] = title;
        } else
          draft.push({
            id: uuid(),
            rank: draft.length + 1,
            title,
            isNewRecord: true,
            activities: [],
          });
      }
    );
    setActivities(newActivities);
    onCancel();
  };

  const onOkBrowseLibrary = (values: Question[]) => {
    const { parentIndex } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY) {
      const modifyActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          if (!_.get(draft, `${parentIndex}.activities`))
            _.set(draft, `${parentIndex}.activities`, []);

          const activities = draft[parentIndex].activities;
          const filterValues = values.filter(
            (item) => !activities.map((a) => a.libraryId).includes(item.id)
          );
          if (filterValues.length !== values.length)
            messageError('Some of library is duplicate');
          draft[parentIndex].activities = [...activities, ...filterValues];
          return draft;
        }
      );
      setActivities(
        assignIndexKey({
          dataSources: modifyActivities,
          childKey: ['activities', 'children'],
        })
      );
    }

    onCancel();
  };

  const onCancel = () => {
    setModalType('');
    setDataSource({});
    setLibraryType('');
    setQuestionType('');
  };

  const onBrowseLibrary = (item: Question) => {
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY);
    setLibraryType(item.type);
    setQuestionType(item.questionType);
    setDataSource(item);
  };

  const onCreateLibrary = (item: Activity) => {
    setDataSource(item);
    setLibraryType(item.type || '');
    setQuestionType(item.questionType || '');
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM);
  };

  const onOkFormLibrary = (value: LooseObject) => {
    const { id, libraryId, parentIndex, indexKey } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM) {
      const modifyActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          if (!_.get(draft, `${parentIndex}.activities`))
            _.set(draft, `${parentIndex}.activities`, []);
          draft[parentIndex].activities.push({
            ...value,
            isNewRecord: true,
            libraryId: value.id,
          });
          return draft;
        }
      );
      setActivities(
        assignIndexKey({
          dataSources: modifyActivities,
          childKey: ['activities', 'children'],
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT) {
      const modifyActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          if (indexKey.length === 2)
            draft[indexKey[0]].activities[indexKey[1]] = {
              ...value,
              id,
              libraryId,
            };
          if (indexKey.length === 3)
            draft[indexKey[0]].activities[indexKey[1]].children[indexKey[2]] = {
              ...value,
              id,
              libraryId,
            };
          return draft;
        }
      );
      setActivities(
        assignIndexKey({
          dataSources: modifyActivities,
          childKey: ['activities', 'children'],
        })
      );
    }
    onCancel();
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div
          onClick={() =>
            requestTopicDefault({
              dataSource: review,
              defaultFilter: {},
              appFilter,
              setAppFilter,
              filterKey: 'review',
            })
          }
          id="reviewRequest"
        />
        <FormActivityModal
          visible={modalType === MODAL_TYPE.ACTIVITY_FROM}
          onOk={onOkFormActivity}
          onCancel={onCancel}
          title={t('Form Activity')}
          dataSource={dataSource}
        />
        <LibraryModal
          visible={modalType === MODAL_TYPE.LIBRARY_ACTIVITY}
          onOk={onOkBrowseLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          defaultFilter={_.get(appFilter, 'review', {})}
        />
        <LibraryFormModal
          visible={
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM ||
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT
          }
          key={`${
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM ||
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT
          }`}
          onOk={onOkFormLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'review')}
        />
        <FormWrapper form={form} name="reviewForm" onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={8} lg={8}>
              <FormInfoSection
                levels={levels}
                topics={topics}
                subjects={subjects}
                colors={colors}
                form={form}
                dataSource={review}
                hasStatusOption={true}
                filterKey="review"
              />
            </Col>
            <Col sm={24} md={16} lg={16}>
              <TitleItem title={t('Description')} />
              <TextEditorForm
                name="description"
                form={form}
                value={_.get(review, 'description', '')}
                rules={[{ required: false }]}
              />
              <TitleItem title={t('Activities')} />
              <SortableItems
                dataSources={activities}
                onSortEnd={onSortEnd}
                onSortEndChild={onSortEndChild}
                onEdit={onEdit}
                onDuplicate={onDuplicate}
                onDelete={onDelete}
                onEditChild={onEditChild}
                onDuplicateChild={onDuplicateChild}
                onDeleteChild={onDeleteChild}
                onBrowseLibrary={onBrowseLibrary}
                onCreateLibrary={onCreateLibrary}
                childItemsKey={['activities', 'children']}
              />
              <ButtonAddBrowse onCreate={onCreateActivity} />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </Fragment>
  );
};
