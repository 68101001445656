// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Resource } from "./resource.types";

export const resourcesState = atom<Resource[]>({
  key: "resourcesState",
  default: []
});

export const resourceMetaState = atom<Meta>({
  key: "resourceMetaState",
  default: {}
});

export const resourceState = atom<Resource | {}>({
  key: "resourceState",
  default: {}
});

export const resourceLoadingState = atom<boolean>({
  key: "resourceLoadingState",
  default: false
});
