import { Col, Row, Spin } from "antd";
import produce from "immer";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { updateLibraryAsync } from "../../../../atom/library/library.apis";
import { Library } from "../../../../atom/library/library.types";
import {
  createQuestionGroupAsync,
  updateQuestionGroupAsync
} from "../../../../atom/library/question.apis";
import { ButtonAddBrowse } from "../../../../components/button-add-browse/button-add-browse.component";
import { FormInfoSection } from "../../../../components/form-info-section/form-info-section.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { SortableItems } from "../../../../components/sortable-items/sortable-items.component";
import { TitleItem } from "../../../../components/title-item/title-item.component";
import {
  FILTER_QUESTION_TYPES,
  FORM_TYPE,
  LIBRARY_TYPE,
  QUESTION_TYPE
} from "../../../../constants/default-value.const";
import useRoute from "../../../../hooks/use-route.hook";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { LooseObject, Question } from "../../../../types/global.types";
import {
  exclude,
  requestTopicDefault,
  setSelectFilter,
  toNewRecords
} from "../../../../utils/helper.utils";
import {
  messageError,
  messageSuccess,
  messageWarn,
  showDeleteConfirm,
  showMessageErrors
} from "../../../../utils/message.utils";
import { LibraryFormModal } from "../../library/library-form-modal.component";
import { LibraryModal } from "../../library/library-modal.component";
import { useLibrary } from "../../library/library.hook";
import { FormModalProps, LibraryFormValue } from "../../library/library.types";
import { QuestionSection } from "./question-section.component";
import { useTranslation } from "react-i18next";
import { checkFormRedirect } from "../../../../utils/form.utils";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

export interface FormValue extends LibraryFormValue {
  answer: string[];
}

enum MODAL_TYPE {
  LIBRARY_QUESTION = "LIBRARY_QUESTION",
  LIBRARY_QUESTION_FORM = "LIBRARY_QUESTION_FORM",
  LIBRARY_QUESTION_FORM_EDIT = "LIBRARY_QUESTION_FORM_EDIT"
}

export const QuestionGroupForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isEditTest,
  isBack = false
}: FormModalProps) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [libraryType, setLibraryType] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [modalType, setModalType] = useState("");
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const {
    history,
    pathname,
    query: { formType },
    param: { id }
  } = useRoute();
  const { levels, subjects, topics } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true
  });
  const [forceRender, setForceRender] = useState(false);
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  const { loading, questionGroup: library, setQuestionGroup } = useLibrary({
    questionGroupId: formModal ? formId : id,
    forceRender,
    id: ""
  });
  const { t } = useTranslation();
  useEnterEvent({ form, enter: !modalType });

  // useEffect(() => {
  //   if (_.isNil(defaultFilter)) {
  //     return () => {
  //       setAppFilter({});
  //     };
  //   }
  // }, [setAppFilter, defaultFilter]);

  useEffect(() => {
    setQuestionGroup({});
  }, [setQuestionGroup]);

  useEffect(() => {
    if (_.size(library)) {
      form.resetFields();
      setQuestions([]);
      form.setFieldsValue({
        ...library,
        topicId: _.get(library, "topic.id"),
        subjectId: _.get(library, "subject.id"),
        levelId: _.get(library, "level.id"),
        questionText: _.get(library, "data.questionText"),
        solution: _.get(library, "data.solution")
      });
      const check = formType === FORM_TYPE.DUPLICATE || formDuplicate;
      setQuestions(
        toNewRecords(
          _.get(library, "children", []).map((item: LooseObject) => {
            return { ...item, libraryId: item.id };
          }),
          check
        )
      );
      if (check) {
        setQuestions([]);
      }
    }
    setSelectFilter({
      dataSource: library,
      defaultFilter,
      // defaultFilter: !_.size(library) ? {} : {},
      form,
      elementId: "questionGroupRequest"
    });
  }, [library, form, formDuplicate, formType, defaultFilter]);

  const onCancel = () => {
    setModalType("");
    setDataSource({});
    setLibraryType("");
    setQuestionType("");
  };

  const onOkBrowseLibrary = (values: Question[]) => {
    const filterValues = values.filter(item => !questions.map(a => a.id).includes(item.id));
    if (filterValues.length !== values.length) messageError("Some of library is duplicate");
    setQuestions([...questions, ...filterValues]);

    onCancel();
  };

  const onOkFormLibrary = (value: LooseObject) => {
    const { id } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM) {
      setQuestions([
        ...questions,
        { ...value, isNewRecord: true, libraryId: value.id } as Question
      ]);
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT) {
      const modifyQuestions = produce<Question[], Question[]>(questions, draft => {
        const foundIndex = draft.findIndex(item => item.id === id);
        draft[foundIndex] = { ...value } as Question;
        return draft;
      });
      setQuestions(modifyQuestions);
    }
    onCancel();
  };

  const onFinish = (value: FormValue) => {
    const { questionText, solution } = value;

    if (!questions.length) {
      messageWarn("Question child is required");
      return;
    }

    const childQuestions = questions.map((item: Question) => {
      return { id: item.id };
    });
    const data: any = {
      ...value,
      children: childQuestions,
      data: { questionText, answer: [], solution, type: QUESTION_TYPE.GROUP },
      questionType: QUESTION_TYPE.GROUP
    };

    if (isEditTest) {
      data.isEditTest = true;
    }

    setAppLoading(true);
    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateQuestionGroupAsync(formId, data)
          : createQuestionGroupAsync(data);
      request
        .then(res => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch(err => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateQuestionGroupAsync(id, data)
        : createQuestionGroupAsync(data);

    request
      .then(res => {
        messageSuccess();
        let check = checkFormRedirect({ history, pathname, id: res.data.id, formType, isBack });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch(err => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEndQuestion = (items: Question[]) => {
    setQuestions(items);
  };

  const onEditQuestion = (value: Question) => {
    setDataSource(exclude(value, ["isNewRecord"]));
    setLibraryType(value.type);
    setQuestionType(value.questionType);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT);
  };

  const onDuplicateQuestion = (item: Question) => {
    setQuestionType(item.questionType);
    setLibraryType(item.type);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onDeleteQuestion = ({ id, isNewRecord }: Question) => {
    showDeleteConfirm(() => {
      const filterQuestions = produce<Question[], Question[]>(questions, draft => {
        return draft.filter(item => item.id !== id);
      });
      if (isNewRecord) setQuestions(filterQuestions);
      else {
        updateLibraryAsync(id, { parentId: null } as Library)
          .then(res => {
            setQuestions(filterQuestions);
          })
          .catch(err => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onCreateQuestion = (type: string) => {
    setQuestionType(type);
    setDataSource({});
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onBrowseQuestion = (type: string) => {
    setLibraryType(LIBRARY_TYPE.QUESTION);
    setQuestionType(type);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION);
  };

  return (
    <Spin spinning={loading}>
      <div
        onClick={() =>
          requestTopicDefault({
            dataSource: library,
            defaultFilter,
            appFilter,
            setAppFilter,
            filterKey: "questionGroup"
          })
        }
        id="questionGroupRequest"
      />
      <LibraryModal
        visible={modalType === MODAL_TYPE.LIBRARY_QUESTION}
        onOk={onOkBrowseLibrary}
        onCancel={onCancel}
        type={libraryType}
        questionType={questionType}
        onlyChildQuestion={true}
        defaultFilter={_.get(appFilter, "questionGroup", {})}
      />
      <LibraryFormModal
        visible={
          modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
          modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT
        }
        key={`${modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
          modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT}`}
        onOk={onOkFormLibrary}
        onCancel={onCancel}
        type={libraryType}
        questionType={questionType}
        dataSource={dataSource}
        defaultFilter={_.get(appFilter, "questionGroup")}
      />
      <FormWrapper form={form} name="questionGroupForm" onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8} lg={8}>
            <FormInfoSection
              levels={levels}
              topics={topics}
              subjects={subjects}
              filterKey="questionGroup"
            />
          </Col>
          <Col sm={24} md={16} lg={16}>
            <QuestionSection form={form} dataSource={library}>
              <TitleItem title={t("Question")} />
              <SortableItems
                dataSources={questions}
                onSortEnd={onSortEndQuestion}
                onEdit={onEditQuestion}
                onDuplicate={onDuplicateQuestion}
                onDelete={onDeleteQuestion}
                childItemsKey={["children"]}
              />
              <ButtonAddBrowse
                titleCreate={t("Add Question")}
                titleBrowse={t("Link Question")}
                onCreates={onCreateQuestion}
                onBrowses={onBrowseQuestion}
                options={FILTER_QUESTION_TYPES.filter(
                  item => ![QUESTION_TYPE.GROUP].includes(item.value)
                )}
              />
            </QuestionSection>
          </Col>
        </Row>
      </FormWrapper>
    </Spin>
  );
};
