import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { Role } from "../../../../atom/role/role.type";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { RoleForm } from "./role-form.component";

interface RoleFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: Role) => void;
  dataSource?: Role;
}
export const RoleFormModal: React.FC<RoleFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <RoleForm dataSource={dataSource} handleCancel={handleCancel} handleOk={handleOk} />
      </Spin>
    </ModalWrapper>
  );
};
