import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../atom/app/app.hook";
import { UserChangePassword } from "../../../atom/auth/auth.types";
import { ModalWrapper } from "../../../components/modal/modal.component";
import { ChangePasswordForm } from "./change-password-form.component";

interface ChangePasswordFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: UserChangePassword) => void;
}
export const ChangePasswordFormModal: React.FC<ChangePasswordFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <ChangePasswordForm
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
      </Spin>
    </ModalWrapper>
  );
};
