import styled from "styled-components";
import { Layout } from "antd";

export const MainLayout = styled(Layout)`
  min-height: 100vh;
  /* overflow: hidden; */
`;

export const LayoutContainer = styled(Layout)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* overflow-y: scroll; */
  /* background: white; */
`;