import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { Subject } from "../../../../atom/subject/subject.type";
import { OptionType } from "../../../../components/form/select/select.type";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { SubjectForm } from "./subject-form.component";

interface SubjectFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: Subject) => void;
  dataSource?: Subject;
  colors: OptionType[];
  medias: OptionType[];
}
export const SubjectFormModal: React.FC<SubjectFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource,
  colors,
  medias
}) => {
  const { appLoading } = useApp();
  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <SubjectForm
          dataSource={dataSource}
          handleCancel={handleCancel}
          handleOk={handleOk}
          colors={colors}
          medias={medias}
        />
      </Spin>
    </ModalWrapper>
  );
};
