import { RoutePaths } from '../constants/route.const';
import { CompetencyTestFormPage } from '../pages/contents/competency-test/competency-test-form.component';
import { CompetencyTestPage } from '../pages/contents/competency-test/competency-test.component';
import { CompetitionTestFormPage } from '../pages/contents/competition-test/competition-test-form.component';
import { CompetitionTestPage } from '../pages/contents/competition-test/competition-test.component';
import { CourseFormPage } from '../pages/contents/course/course-form.component';
import { CoursePage } from '../pages/contents/course/course.component';
import { ExamTestFormPage } from '../pages/contents/exam-test/exam-test-form.component';
import { ExamTestPage } from '../pages/contents/exam-test/exam-test.component';
import { PracticeTestFormPage } from '../pages/contents/practice-test/practice-test-form.component';
import { PracticeTestListPage } from '../pages/contents/practice-test/practice-test.component';
import { ResourceFormPage } from '../pages/contents/resource/resource-form.component';
import { ResourceListPage } from '../pages/contents/resource/resource.component';
import { ReviewFormPage } from '../pages/contents/review/review-form.component';
import { ReviewListPage } from '../pages/contents/review/review.component';
import { EditorPage } from '../pages/editor/editor.component';
import { CompetencyFormPage } from '../pages/elements/competency/competency-form.component';
import { CompetencyListPage } from '../pages/elements/competency/competency.component';
import { QuestionInputFormPage } from '../pages/elements/question/question-input-form.component';
import { QuestionPage } from '../pages/elements/question/question.component';
import { TextLibraryFormPage } from '../pages/elements/text-library/text-library-form.component';
import { TextLibraryPage } from '../pages/elements/text-library/text-library.component';
import { VideoFormPage } from '../pages/elements/video/video-form.component';
import { VideoPage } from '../pages/elements/video/video.component';
import { ColorPage } from '../pages/manages/color/color.component';
import { IconPage } from '../pages/manages/media/media.component';
import { ImagePage } from '../pages/manages/image/image.component';
import { LevelPage } from '../pages/manages/level/level.component';
import { CategoryPage } from '../pages/manages/category/category.component';
import { SubjectPage } from '../pages/manages/subject/subject.component';
import { TopicPage } from '../pages/manages/topic/topic.component';
import { UserPage } from '../pages/manages/user/user.component';
import { QuestionMCQFormPage } from '../pages/elements/question/question-mcq-form.component';
import { QuestionDragDropFormPage } from '../pages/elements/question/question-drag-drop-form.component';
import { QuestionOrderFormPage } from '../pages/elements/question/question-order-form.component';
import { QuestionFillGapFormPage } from '../pages/elements/question/question-fill-gap-form.component';
import { QuestionGroupFormPage } from '../pages/elements/question/question-group-form.component';
import { LearnListPage } from '../pages/contents/learn/learn.component';
import { LearnFormPage } from '../pages/contents/learn/learn-form.component';
import { RolePage } from '../pages/manages/role/role.component';
import { DashboardPage } from '../pages/report/dashboard/dashboard.component';
import { UsagePage } from '../pages/report/usage/usage.component';
import { ReportPage } from '../pages/report/report/report.component';
import { ReportUserPage } from '../pages/report/report-user/report-user.component';
import { ReportUserCoursePage } from '../pages/report/report-user/report-user-course.component';
import { ReportUserCourseLearnPage } from '../pages/report/report-user/report-user-course-learn.component';
import { ReportUserCourseDailyPage } from '../pages/report/report-user/report-user-course-diaily.component';
import { UsageByWeekReportPage } from '../pages/report/usage/usage-by-week.component';
import { UsageByDailyReportPage } from '../pages/report/usage/usage-by-daily.component';
import { UsageBySubjectReportPage } from '../pages/report/usage/usage-by-subject-component';
import { UsageByUserReportPage } from '../pages/report/usage/usage-by-user.component';
import { UsageByOverallReportPage } from '../pages/report/usage/usage-by-overall.component';
import { UsageByCourseLearnReportPage } from '../pages/report/usage/usage-by-course-learn.component';
import { EditorTestPage } from '../pages/editor-test/editor-test.component';
import { VideoOptionPage } from '../pages/elements/video-option/video-option.component';
import { FlowResourcePage } from '../pages/mobile-preview/flow-resource/flow-resource.page';
import { FlowExamTestPage } from '../pages/mobile-preview/flow-exam-test/flow-exam-test.page';
import { FlowCoursePage } from '../pages/mobile-preview/flow-course/flow-course.page';
import { FlowReviewPage } from '../pages/mobile-preview/flow-review/flow-review.page';
import { FlowCompetencyPage } from '../pages/mobile-preview/flow-competency/flow-competency.page';
import { FlowCompetencyTestPage } from '../pages/mobile-preview/flow-competency-test/flow-competency-test.page';
import { FlowCompetitionTestPage } from '../pages/mobile-preview/flow-competition-test/flow-competition-test.page';
import { FlowPracticeTestPage } from '../pages/mobile-preview/flow-practice-test/flow-practice-test.page';
import { FlowLearnPage } from '../pages/mobile-preview/flow-learn/flow-learn.page';
import { AppFeaturePage } from '../pages/manages/app-feature/app-reature.page';

export const routes = [
  {
    path: RoutePaths.COURSES,
    component: CoursePage,
  },
  {
    path: RoutePaths.COURSES_FORM,
    component: CourseFormPage,
  },
  {
    path: RoutePaths.REVIEWS,
    component: ReviewListPage,
  },
  {
    path: RoutePaths.REVIEWS_FORM,
    component: ReviewFormPage,
  },
  {
    path: RoutePaths.LEARNS,
    component: LearnListPage,
  },
  {
    path: RoutePaths.LEARNS_FORM,
    component: LearnFormPage,
  },
  {
    path: RoutePaths.COMPETITION_TESTS,
    component: CompetitionTestPage,
  },
  {
    path: RoutePaths.COMPETITION_TESTS_FORM,
    component: CompetitionTestFormPage,
  },
  {
    path: RoutePaths.EXAM_TESTS,
    component: ExamTestPage,
  },
  {
    path: RoutePaths.EXAM_TESTS_FORM,
    component: ExamTestFormPage,
  },
  {
    path: RoutePaths.COMPETENCY_TESTS,
    component: CompetencyTestPage,
  },
  {
    path: RoutePaths.COMPETENCY_TESTS_FORM,
    component: CompetencyTestFormPage,
  },
  {
    path: RoutePaths.PRACTICE_TESTS,
    component: PracticeTestListPage,
  },
  {
    path: RoutePaths.PRACTICE_TESTS_FORM,
    component: PracticeTestFormPage,
  },
  {
    path: RoutePaths.RESOURCES,
    component: ResourceListPage,
  },
  {
    path: RoutePaths.RESOURCES_FORM,
    component: ResourceFormPage,
  },
  {
    path: RoutePaths.COMPETENCIES,
    component: CompetencyListPage,
  },
  {
    path: RoutePaths.COMPETENCIES_FORM,
    component: CompetencyFormPage,
  },
  {
    path: RoutePaths.TEXT_LIBRARIES,
    component: TextLibraryPage,
  },
  {
    path: RoutePaths.TEXT_LIBRARIES_FORM,
    component: TextLibraryFormPage,
  },
  {
    path: RoutePaths.QUESTIONS,
    component: QuestionPage,
  },
  {
    path: RoutePaths.QUESTIONS_INPUT_FORM,
    component: QuestionInputFormPage,
  },
  {
    path: RoutePaths.QUESTIONS_MCQ_FORM,
    component: QuestionMCQFormPage,
  },
  {
    path: RoutePaths.QUESTIONS_DRAG_DROP_FORM,
    component: QuestionDragDropFormPage,
  },
  {
    path: RoutePaths.QUESTIONS_ORDER_FORM,
    component: QuestionOrderFormPage,
  },
  {
    path: RoutePaths.QUESTIONS_FILL_GAP_FORM,
    component: QuestionFillGapFormPage,
  },
  {
    path: RoutePaths.QUESTIONS_GROUP_FORM,
    component: QuestionGroupFormPage,
  },
  {
    path: RoutePaths.VIDEOS,
    component: VideoPage,
  },
  {
    path: RoutePaths.VIDEOS_FORM,
    component: VideoFormPage,
  },
  {
    path: RoutePaths.VIDEOS_OPTION,
    component: VideoOptionPage,
  },
  {
    path: RoutePaths.SUBJECTS,
    component: SubjectPage,
  },
  {
    path: RoutePaths.LEVELS,
    component: LevelPage,
  },
  {
    path: RoutePaths.TOPICS,
    component: TopicPage,
  },
  {
    path: RoutePaths.TEST_CATEGORIES,
    component: CategoryPage,
  },
  {
    path: RoutePaths.RESOURCE_CATEGORIES,
    component: CategoryPage,
  },
  {
    path: RoutePaths.COLORS,
    component: ColorPage,
  },
  {
    path: RoutePaths.ICONS,
    component: IconPage,
  },
  {
    path: RoutePaths.IMAGES,
    component: ImagePage,
  },
  {
    path: RoutePaths.ROLES,
    component: RolePage,
  },
  {
    path: RoutePaths.USERS,
    component: UserPage,
  },
  {
    path: RoutePaths.APP_FEATURES,
    component: AppFeaturePage,
  },
  {
    path: RoutePaths.EDITOR,
    component: EditorPage,
  },
  {
    path: '/test',
    component: EditorTestPage,
  },
  {
    path: RoutePaths.DASHBOARD,
    component: DashboardPage,
  },
  {
    path: RoutePaths.REPORT,
    component: ReportPage,
  },
  {
    path: RoutePaths.REPORT_USER,
    component: ReportUserPage,
  },
  {
    path: RoutePaths.REPORT_USER_COURSE,
    component: ReportUserCoursePage,
  },
  {
    path: RoutePaths.REPORT_USER_COURSE_LEARN,
    component: ReportUserCourseLearnPage,
  },
  {
    path: RoutePaths.REPORT_USER_COURSE_DAILY,
    component: ReportUserCourseDailyPage,
  },
  {
    path: RoutePaths.USAGE,
    component: UsagePage,
  },
  {
    path: RoutePaths.USAGE_BY_WEEK,
    component: UsageByWeekReportPage,
  },
  {
    path: RoutePaths.USAGE_BY_DAILY,
    component: UsageByDailyReportPage,
  },
  {
    path: RoutePaths.USAGE_BY_SUBJECT,
    component: UsageBySubjectReportPage,
  },
  {
    path: RoutePaths.USAGE_BY_OVERALL,
    component: UsageBySubjectReportPage,
  },
  {
    path: RoutePaths.USAGE_BY_USER,
    component: UsageByUserReportPage,
  },
  {
    path: RoutePaths.USAGE_BY_COURSE_LEARN,
    component: UsageByCourseLearnReportPage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_RESOURCE,
    component: FlowResourcePage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_EXAM_TEST,
    component: FlowExamTestPage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_COURSE,
    component: FlowCoursePage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_REVIEW,
    component: FlowReviewPage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_COMPETENCY,
    component: FlowCompetencyPage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_COMPETENCY_TEST,
    component: FlowCompetencyTestPage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_COMPETITION_TEST,
    component: FlowCompetitionTestPage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_PRACTICE_TEST,
    component: FlowPracticeTestPage,
  },
  {
    path: RoutePaths.MOBILE_PREVIEW_LEARN,
    component: FlowLearnPage,
  },
];
