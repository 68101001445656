import produce from "immer";
import { selector } from "recoil";
import { permissionsState } from "./permission.atoms";

export const permissionsSelector = selector({
  key: "permissionsSelector",
  get: ({ get }) => {
    const permissions = produce(get(permissionsState), draft => draft);

    return permissions;
  }
});
