import { Button, Space } from 'antd';
import { QUERY_TYPE } from '../../constants/default-value.const';
import { useTranslation } from 'react-i18next';

interface IListFeatureOption {
  onShowAll?: () => void;
  onClearFilter?: () => void;
  onEnableSort?: () => void;
  showAll?: boolean;
  enableSort?: boolean;
  query?: any;
}

export const ListFeatureOption: React.FC<IListFeatureOption> = ({
  onShowAll,
  onClearFilter,
  onEnableSort,
  showAll,
  enableSort,
  query,
}) => {
  const { t } = useTranslation();
  if (query?.queryType === QUERY_TYPE.TRASH) return null;
  return (
    <Space style={{ marginBottom: '20px' }}>
      {onShowAll && (
        <Button disabled={!showAll} onClick={onShowAll}>
          {t('Show all')}
        </Button>
      )}
      {onClearFilter && (
        <Button onClick={onClearFilter}>{t('Clear filters')}</Button>
      )}
      {onEnableSort && (
        <Button disabled={!enableSort} onClick={onEnableSort}>
          {t('Enable sort')}
        </Button>
      )}
    </Space>
  );
};
