import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import { resourcesSyncToFirestoreAsync } from "../../../atom/resource/resource.apis";
import useRoute from "../../../hooks/use-route.hook";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { toSwiper } from "../../../utils/response.utils";
import { useResource } from "../../contents/resource/components/resource.hook";
import { SwiperComponent } from "../components/swiper/swiper.component";

export const FlowResourcePage = () => {
  const {
    param: { id },
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { resource } = useResource({ id, forceRender });
  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      resourcesSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };
  return (
    <SwiperComponent
      title={resource.title}
      data={resource}
      //onSync={() => handleSyncTofirestore(id)}
      dataSources={
        toSwiper(resource.activities, ["activities", "children"]) || []
      }
    />
  );
};
