import produce from "immer";
import _ from "lodash";
import { selector } from "recoil";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate, isSyncable } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { authUserSelector } from "../auth/auth.selectors";
import {
  competencyTestsState,
  competencyTestMetaState,
  competencyTestState
} from "./competency-test.atoms";

export const competencyTestsSelector = selector({
  key: "competencyTestsSelector",
  get: ({ get }) => {
    const competencyTests = produce(get(competencyTestsState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.COMPETENCY_TEST);
    return competencyTests.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const mapCompetencies = ({
  dataSources,
  childKey = ["libraries", "children"],
  filterKey = "",
  initIndexKey = []
}: {
  dataSources: any[];
  childKey?: string[];
  filterKey?: string;
  initIndexKey?: number[];
}) => {
  let results = dataSources.filter((item: any) => item.competency);
  results = _.sortBy(results, ["rank"]);

  return results.map((competencyItem: any, i: number) => {
    const { id, rank, libraryId, competencyId, competency, library } = competencyItem;
    let mapLibraries = [];
    if (_.get(competency, "libraries")) {
      mapLibraries = competency.libraries.map((library: any, j: number) => {
        const { id, code, title, type, questionType, data, children } = library;
        return {
          indexKey: [...initIndexKey, i, j],
          libraryId: id,
          selectedId: id,
          code,
          title,
          type,
          questionType,
          data,
          [childKey[1]]: children.map((child: any, k: number) => {
            return { ...child, libraryId: child.id, filterKey, indexKey: [initIndexKey, i, j, k] };
          }),
          id,
          filterKey
        };
      });
    } else if (library) {
      const { id, code, title, type, questionType, data, children } = library;
      mapLibraries = [
        {
          indexKey: [...initIndexKey, i, 0],
          libraryId: id,
          selectedId: id,
          code,
          title,
          type,
          questionType,
          data,
          [childKey[1]]: children.map((child: any, k: number) => {
            return {
              ...child,
              libraryId: child.id,
              filterKey,
              indexKey: [...initIndexKey, i, 0, k]
            };
          }),
          id,
          filterKey
        }
      ];
    }

    return {
      indexKey: [...initIndexKey, i],
      id,
      rank,
      code: _.get(competency, "code"),
      title: _.get(competency, "title"),
      libraryId,
      competencyId,
      [childKey[0]]: mapLibraries,
      filterKey
    };
  });

  // return _.sortBy(results, ["rank"]);
};

export const competencyTestSelector = selector({
  key: "competencyTestSelector",
  get: ({ get }) => {
    const competencyTest = produce(get(competencyTestState), draft => draft);
    const competencies = _.get(competencyTest, "testCompetencyCompetencies", []);
    return {
      ...competencyTest,
      competencies: mapCompetencies({ dataSources: competencies })
    };
  }
});

export const competencyTestMetaSelector = selector({
  key: "competencyTestMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(competencyTestMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
