import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { PracticeTest } from './practice-test.types';

export const getPracticeTestsAsync = (option: {}) => {
  const url = generateQuery('/test-practices', option, [
    'levelId',
    'subjectId',
    'topicId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getPracticeTestAsync = (id: string) => {
  return axios
    .get(`/test-practices/${id}`, config())
    .then((response) => response.data.data);
};

export const getPracticeTestsTrashAsync = (option: {}) => {
  const url = generateQuery('/test-practices/list/trash', option);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getPraceticeTestSelectPraceticeTestsAsync = (
  data: RestoreType
) => {
  return axios
    .post('/test-practices/list/selected-test-practice', data, config())
    .then((response) => response.data.data);
};

export const restorePracticeTestsAsync = (data: RestoreType) => {
  return axios
    .patch('/test-practices/restore', data, config())
    .then((response) => response.data);
};

export const createPracticeTestAsync = (data: PracticeTest) => {
  return axios
    .post('/test-practices', data, config())
    .then((response) => response.data);
};

export const updatePracticeTestAsync = (id: string, data: PracticeTest) => {
  return axios
    .put(`/test-practices/${id}`, data, config())
    .then((response) => response.data);
};

export const deletePracticeTestAsync = (id: string) => {
  return axios
    .delete(`/test-practices/${id}`, config())
    .then((response) => response.data);
};

export const deletePracticeTestLibraryAsync = (id: string) => {
  return axios
    .delete(`/test-practice-libraries/${id}`, config())
    .then((response) => response.data);
};

export const deletePracticeTestLibrariesAsync = (ids: string[]) => {
  return axios
    .post(`/test-practice-libraries/deletes`, { ids }, config())
    .then((response) => response.data);
};

export const sortPracticeTestsAsync = (data: SortData) => {
  return axios
    .patch(`/test-practices/set-rank`, data, config())
    .then((response) => response.data);
};

export const getPracticeTestReferenceUsingAsync = (id: string) => {
  return axios
    .get(`/test-practices/reference-using/${id}`, config())
    .then((response) => response.data.data);
};

export const practiceTestsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/test-practices/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};

export const practiceTestUnsyncFromFirestore = async (id: string) => {
  return axios
    .delete(`/test-practices/unsync-from-firestore/${id}`, config())
    .then((response) => response.data);
};
