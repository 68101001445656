import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { RestoreType } from "../../types/global.types";
import { Role } from "./role.type";

export const getRolesAsync = (option: {}) => {
  const url = generateQuery("/roles", option);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getRolesSelectAsync = () => {
  return axios.get("/roles/for/select-option", config()).then(response => response.data.data);
};

export const getRolesTrashAsync = (option: {}) => {
  const url = generateQuery("/roles/list/trash", option);
  return axios.get(url, config()).then(response => response.data.data);
};

export const createRoleAsync = (data: Role) => {
  return axios.post("/roles", data, config()).then(response => response.data);
};

export const restoreRolesAsync = (data: RestoreType) => {
  return axios.patch("/roles/restore", data, config()).then(response => response.data);
};

export const updateRoleAsync = (id: string, data: Role) => {
  return axios.put(`/roles/${id}`, data, config()).then(response => response.data);
};

export const deleteRoleAsync = (id: string) => {
  return axios.delete(`/roles/${id}`, config()).then(response => response.data);
};
