import {
  BookTwoTone,
  CloudFilled,
  RocketFilled,
  SyncOutlined,
  ThunderboltFilled,
} from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tooltip,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { coursesSyncToFirestoreAsync } from "../../../atom/course/course.apis";
import { MathJaxPreview } from "../../../components/mathjax-preview/mathjax-preview.component";
import { SECTION_TYPE } from "../../../constants/default-value.const";
import useRoute from "../../../hooks/use-route.hook";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { generateRequest } from "../../../utils/request.utils";
import { toSwiper } from "../../../utils/response.utils";
import { SwiperComponent } from "../components/swiper/swiper.component";
import { TitlePreview } from "../components/swiper/swiper.styles";
import { Section, WrapCourse } from "./flow-course.styles";
interface IFlowCourse {
  dataSources: any[];
  data: any;
}

export const FlowCourse: React.FC<IFlowCourse> = ({ dataSources, data }) => {
  const { pathname, query, history } = useRoute();
  const [dataItem, setDataItem] = useState<any>({});
  const { flowType, sectionId, childSectionId } = query;

  const [dataSections, setDataSections] = useState([]);

  useEffect(() => {
    if (sectionId && dataSources.length) {
      const found = dataSources.find((item: any) => {
        return item.id === sectionId;
      });

      if (!found) return;

      setDataItem(found);

      if (flowType === SECTION_TYPE.LEARN) {
        const learns = found.dataSources.filter((item: any) => {
          return !item.sourceAbleType;
        });

        setDataSections(learns);
      } else if (flowType === SECTION_TYPE.TEST) {
        const learns = found.dataSources.filter((item: any) => {
          return item.sourceAbleType;
        });
        setDataItem(learns[0]);
        setDataSections(_.get(learns, "0.libraries", []));
      } else {
        setDataSections(found.dataSources);
      }
    }
  }, [flowType, sectionId, childSectionId, dataSources]);

  const handleSection = (data: any) => {
    history.push(generateRequest(pathname, data));
  };

  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      coursesSyncToFirestoreAsync(id)
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  if (query.flowType) {
    const keys =
      query.flowType === SECTION_TYPE.TEST
        ? ["libraries", "children"]
        : ["libraries", "libraries"];
    return (
      <SwiperComponent
        title={dataItem.title}
        // onSync={() => handleSyncTofirestore(data.id)}
        // data={{ title: dataItem.title, description: dataItem.description }}
        dataSources={toSwiper(dataSections, keys)}
      />
    );
  }

  const customClass = (type: string) => {
    return type === SECTION_TYPE.COMPETENCY_TEST
      ? "competencyTest"
      : type === SECTION_TYPE.RESOURCE
      ? "resource"
      : "";
  };

  return (
    <WrapCourse>
      <TitlePreview>
        {data.title}
        {/* <Space>
          <Tooltip title="Sync To App" placement="right">
            <Avatar
              shape="square"
              size="small"
              style={{
                marginLeft: 10,
              }}
            >
              <ThunderboltFilled
                style={{ color: "#4c4c4c" }}
                onClick={() => handleSyncTofirestore(data.id)}
              />
            </Avatar>
          </Tooltip>
        </Space> */}
      </TitlePreview>
      <div className="swiper">
        {/* <Row style={{height:'25%' , backgroundColor:'#123' ,opacity:'50%' , marginBottom:'20px' , borderRadius:'20px'}}>
            <Col style={{textAlign:'center'}}>
              <span>TEXT</span>
            </Col>
        </Row> */}
        <Row gutter={[16, 16]}>
          {dataSources.map((section: any) => {
            const { id, sourceAbleType, title } = section;
            return (
              <Col
                span={sourceAbleType === SECTION_TYPE.COMPETENCY_TEST ? 24 : 12}
                key={id}
              >
                {sourceAbleType === SECTION_TYPE.LEARN && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          style={{ textAlign: "center", margin: "0px" }}
                        >
                          <p
                            onClick={() =>
                              handleSection({
                                sectionId: id,
                                flowType: SECTION_TYPE.LEARN,
                              })
                            }
                          >
                            Learn
                          </p>
                        </Menu.Item>
                        <Menu.Item
                          style={{ textAlign: "center", margin: "0px" }}
                        >
                          <p
                            onClick={() =>
                              handleSection({
                                sectionId: id,
                                flowType: SECTION_TYPE.TEST,
                              })
                            }
                          >
                            Test
                          </p>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Section className={customClass(sourceAbleType)}>
                      {section.title}{" "}
                      {/* <DownOutlined style={{ marginLeft: '10px' }} /> */}
                    </Section>
                  </Dropdown>
                )}
                {sourceAbleType !== SECTION_TYPE.LEARN && (
                  <Section
                    className={customClass(sourceAbleType)}
                    onClick={() =>
                      handleSection({
                        flowType: sourceAbleType,
                        sectionId: id,
                      })
                    }
                  >
                    <MathJaxPreview math={title} />
                  </Section>
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    </WrapCourse>
  );
};
