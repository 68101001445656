import { Table } from "antd";
import styled from "styled-components";

export const WrapperAction = styled.div`
  display: flex;
  img {
    cursor: pointer;
  }
`;

export const WrapTable = styled(Table)`
  th,
  td {
    /* white-space: nowrap; */
    //width: 140px;
  }

  // .rank {
  //   width: 30px;
  // }
  // .title {
  //   width: 250px;
  // }

  // .date {
  //   width: 150px;
  // }

  tr:hover {
    background-color: #e9f3f1;
  }

  tr:hover > td:last-child {
    background-color: #e9f3f1;
  }

  table {
    overflow: ellipsis;
  }
`;
