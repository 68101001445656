import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getCourseAsync } from "../../../../atom/course/course.apis";
import { courseLoadingState, courseState } from "../../../../atom/course/course.atoms";
import { courseSelector } from "../../../../atom/course/course.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const useCourse = ({ id, forceRender }: UseHookProps) => {
  const course = useRecoilValue(courseSelector);
  const setCourse = useSetRecoilState(courseState);
  const [loading, setLoading] = useRecoilState(courseLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCourseAsync(id)
        .then(res => {
          setCourse(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }
    return () => {
      setCourse({});
    };
  }, [id, setCourse, setLoading, forceRender]);

  return {
    course,
    loading,
    setLoading
  };
};
