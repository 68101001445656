// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Learn } from "./learn.types";

export const learnsState = atom<Learn[]>({
  key: "learnsState",
  default: []
});

export const learnMetaState = atom<Meta>({
  key: "learnMetaState",
  default: {}
});

export const learnState = atom<Learn | {}>({
  key: "learnState",
  default: {}
});

export const learnLoadingState = atom<boolean>({
  key: "learnLoadingState",
  default: false
});
