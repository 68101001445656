import {
  HomeFilled,
  FileTextFilled,
  SettingFilled,
  DatabaseFilled,
  EditFilled,
  LineChartOutlined,
} from "@ant-design/icons";
import { PrefixPermission } from "./permission.const";
import { RoutePaths } from "./route.const";

type MenuType = {
  key: string;
  title: string;
  slug: string;
  icon?: React.ReactNode;
  prefixPermission?: string;
  subMenus?: Array<MenuType>;
};

export const menus: Array<MenuType> = [
  {
    key: RoutePaths.HOME,
    title: "Home",
    slug: RoutePaths.HOME,
    icon: <HomeFilled />,
  },
  // {
  //   key: '/reports',
  //   title: 'Report Data',
  //   slug: '/reports',
  //   // icon: <PartitionOutlined />,
  //   icon: <LineChartOutlined />,
  //   subMenus: [
  //     {
  //       key: RoutePaths.DASHBOARD,
  //       title: 'Dashboard',
  //       slug: RoutePaths.DASHBOARD,
  //       // prefixPermission: PrefixPermission.COURSE
  //     },
  //     {
  //       key: RoutePaths.USAGE,
  //       title: 'Usage Data',
  //       slug: RoutePaths.USAGE,
  //       // prefixPermission: PrefixPermission.COURSE
  //     },
  //     {
  //       key: RoutePaths.REPORT,
  //       title: 'Report Issues',
  //       slug: RoutePaths.REPORT,
  //       // prefixPermission: PrefixPermission.LEARN
  //     },
  //   ],
  // },

  {
    key: "/contents",
    title: "Contents",
    slug: "/contents",
    // icon: <PartitionOutlined />,
    icon: <DatabaseFilled />,
    subMenus: [
      {
        key: RoutePaths.COURSES,
        title: "Courses",
        slug: RoutePaths.COURSES,
        prefixPermission: PrefixPermission.COURSE,
      },
      {
        key: RoutePaths.LEARNS,
        title: "Learns",
        slug: RoutePaths.LEARNS,
        prefixPermission: PrefixPermission.LEARN,
      },
      {
        key: RoutePaths.REVIEWS,
        title: "Reviews",
        slug: RoutePaths.REVIEWS,
        prefixPermission: PrefixPermission.REVIEW,
      },
      {
        key: RoutePaths.EXAM_TESTS,
        title: "Exam Tests",
        slug: RoutePaths.EXAM_TESTS,
        prefixPermission: PrefixPermission.EXAM_TEST,
      },
      {
        key: RoutePaths.COMPETENCY_TESTS,
        title: "Competency Tests",
        slug: RoutePaths.COMPETENCY_TESTS,
        prefixPermission: PrefixPermission.COMPETENCY_TEST,
      },
      {
        key: RoutePaths.COMPETITION_TESTS,
        title: "Competition Tests",
        slug: RoutePaths.COMPETITION_TESTS,
        prefixPermission: PrefixPermission.COMPETITION_TEST,
      },
      {
        key: RoutePaths.PRACTICE_TESTS,
        title: "Practice Tests",
        slug: RoutePaths.PRACTICE_TESTS,
        prefixPermission: PrefixPermission.PRACTICE_TEST,
      },
      {
        key: RoutePaths.RESOURCES,
        title: "Resources",
        slug: RoutePaths.RESOURCES,
        prefixPermission: PrefixPermission.RESOURCE,
      },
    ],
  },
  {
    key: "/elements",
    title: "Elements",
    slug: "/elements",
    icon: <FileTextFilled />,
    subMenus: [
      {
        key: RoutePaths.COMPETENCIES,
        title: "Competencies",
        slug: RoutePaths.COMPETENCIES,
        prefixPermission: PrefixPermission.COMPETENCY,
      },
      {
        key: RoutePaths.TEXT_LIBRARIES,
        title: "Text Libraries",
        slug: RoutePaths.TEXT_LIBRARIES,
        prefixPermission: PrefixPermission.LIBRARY,
      },
      {
        key: RoutePaths.QUESTIONS,
        title: "Questions",
        slug: RoutePaths.QUESTIONS,
        prefixPermission: PrefixPermission.LIBRARY,
      },
      {
        key: RoutePaths.VIDEOS,
        title: "Videos",
        slug: RoutePaths.VIDEOS,
        prefixPermission: PrefixPermission.LIBRARY,
      },
    ],
  },
  {
    key: "/manages",
    title: "Manages",
    slug: "/manages",
    icon: <SettingFilled />,
    subMenus: [
      {
        key: RoutePaths.CONTENT_CATEGORIES,
        title: "Content Categories",
        slug: RoutePaths.SUBJECTS,
        prefixPermission: PrefixPermission.SUBJECT,
      },
      {
        key: RoutePaths.CATEGORY_TYPES,
        title: "Category Types",
        slug: RoutePaths.TEST_CATEGORIES,
        prefixPermission: PrefixPermission.CATEGORY,
      },
      {
        key: RoutePaths.COLOR_AND_PLACEHOLDERS,
        title: "Colors and Placeholders",
        slug: RoutePaths.COLORS,
        prefixPermission: PrefixPermission.COLOR_CODE,
      },
      {
        key: RoutePaths.USER_ROLES,
        title: "Users And Roles",
        slug: RoutePaths.USERS,
        prefixPermission: PrefixPermission.USER,
      },
      {
        key: RoutePaths.APP_FEATURES,
        title: "Manage App Feature",
        slug: RoutePaths.APP_FEATURES,
        prefixPermission: PrefixPermission.USER,
      },
    ],
  },
  {
    key: RoutePaths.EDITOR,
    title: "Editor",
    slug: RoutePaths.EDITOR,
    icon: <EditFilled />,
  },
];
