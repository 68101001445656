import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Pagination, Keyboard } from "swiper";
import { Alert, Avatar, Divider, Tabs, Tag, Tooltip } from "antd";
import {
  Answer,
  Container,
  Solution,
  Title,
  TitlePreview,
  WrapSwaper,
  WrapQuestion,
} from "./swiper.styles";
import "swiper/swiper.scss"; // core Swiper
// import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import {
  KHMER_ALPHABET,
  LIBRARY_TYPE,
  QUESTION_TYPE,
} from "../../../../constants/default-value.const";
import { MathJaxPreview } from "../../../../components/mathjax-preview/mathjax-preview.component";
import _ from "lodash";
import { VideoPreview } from "../../../../components/video-preview/video-preview.component";
// import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { LooseObject } from "../../../../types/global.types";
import {
  EditFilled,
  EditOutlined,
  EditTwoTone,
  SyncOutlined,
  ThunderboltFilled,
} from "@ant-design/icons";
import { LibraryFormModal } from "../../../elements/library/library-form-modal.component";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { appForceRenderState } from "../../../../atom/app/app.atoms";
import { librariesSyncToFirestoreAsync } from "../../../../atom/library/library.apis";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../../utils/message.utils";
import { Space } from "antd";

SwiperCore.use([Pagination]);

export interface IRenderLibrary {
  dataSource: LooseObject;
  onEdit?: (dataSource: any) => void;
  onSync?: (dataSource: any) => void;
}

const ShowSolution = ({ solution }: { solution: string }) => {
  if (!solution) return null;
  return (
    <Solution>
      <div style={{ marginBottom: "10px" }}>
        <b className="title">ដំណោះស្រាយ :</b>
      </div>
      <MathJaxPreview math={solution} />
    </Solution>
  );
};

const RenderLibrary: React.FC<IRenderLibrary> = ({
  dataSource,
  onEdit,
  onSync,
}) => {
  const {
    type,
    questionType,
    title,
    children,
    data: {
      questionText,
      solution,
      answer,
      description,
      url,
      bunnyId,
      youtubeId,
    },
  } = dataSource;
  const { TabPane } = Tabs;
  const sectionTitle = (
    <Title>
      <MathJaxPreview math={title} />
      <Space>
        {onEdit && (
          <Tooltip title="Quick Edit">
            <Avatar size="small" shape="square">
              <EditFilled
                style={{ color: "#4C4C4C" }}
                onClick={() => onEdit(dataSource)}
              />
            </Avatar>
          </Tooltip>
        )}
        {/* {onSync && (
          <SyncOutlined
            style={{ color: 'red' }}
            onClick={() => onSync(dataSource)}
          />
        )} */}
      </Space>
    </Title>
  );

  if (type === LIBRARY_TYPE.TEXT)
    return (
      <WrapQuestion>
        {sectionTitle}
        <MathJaxPreview math={description} />
      </WrapQuestion>
    );
  else if (type === LIBRARY_TYPE.VIDEO)
    return (
      <WrapQuestion>
        <Title>
          <MathJaxPreview math={title} />
          <Space>
            {onEdit && (
              <Tooltip title="Quick Edit">
                <Avatar size="small" shape="square">
                  <EditFilled
                    style={{ color: "#4C4C4C" }}
                    onClick={() => onEdit(dataSource)}
                  />
                </Avatar>
              </Tooltip>
            )}
            {/* {onSync && (
              <SyncOutlined
                style={{ color: 'red' }}
                onClick={() => onSync(dataSource)}
              />
            )} */}
          </Space>
        </Title>
        <Tabs
          centered
          tabPosition="bottom"
          defaultActiveKey={(() => {
            if (url) return "1";
            if (bunnyId) return "2";
            if (youtubeId) return "3";
          })()}
        >
          <TabPane tab="Vimeo" key="1">
            <VideoPreview vimeoId={url} />
          </TabPane>
          <TabPane tab="Bunny" key="2">
            <VideoPreview bunnyId={bunnyId} />
          </TabPane>
          <TabPane tab="Youtube" key="3">
            <VideoPreview youtubeId={youtubeId} />
          </TabPane>
        </Tabs>
        {/* <VideoPreview vimeoId={url} />
        <VideoPreview bunnyId={bunnyId} /> */}
        <MathJaxPreview math={description} />
      </WrapQuestion>
    );
  else if (questionType === QUESTION_TYPE.DRAG_DROP)
    return (
      <WrapQuestion>
        {sectionTitle}
        <div style={{ marginBottom: "10px" }}>
          <b className="title"> ចម្លើយ :</b>
        </div>
        <MathJaxPreview math={questionText} />
        {answer.map((answer: any) => {
          return (
            <Container>
              <Answer>{_.get(answer, "value1")}</Answer>
              <div>&nbsp;&nbsp;</div>
              <Answer>{_.get(answer, "value2")}</Answer>
            </Container>
          );
        })}
        <ShowSolution solution={solution} />
      </WrapQuestion>
    );
  else if (questionType === QUESTION_TYPE.FILL_GAP)
    return (
      <WrapQuestion>
        {sectionTitle}
        <div style={{ marginBottom: "10px" }}>
          <b className="title"> ចម្លើយ :</b>
        </div>
        <MathJaxPreview math={questionText} />
        <ShowSolution solution={solution} />
      </WrapQuestion>
    );
  else if (questionType === QUESTION_TYPE.GROUP) {
    return (
      <WrapQuestion>
        {sectionTitle}
        <MathJaxPreview math={questionText} />
        {_.get(dataSource, children ? "children" : "libraries", []).map(
          (item: any, i: number) => {
            return (
              <RenderLibrary
                key={item.id + i}
                dataSource={item}
                onEdit={onEdit}
                onSync={onSync}
              />
            );
          }
        )}
        <ShowSolution solution={solution} />
      </WrapQuestion>
    );
  } else if (questionType === QUESTION_TYPE.INPUT)
    return (
      <WrapQuestion>
        {sectionTitle}
        <MathJaxPreview math={questionText} />
        <div style={{ marginBottom: "10px" }}>
          <b className="title"> ចម្លើយ :</b>
        </div>

        <Answer>{_.get(answer, "0")}</Answer>
        <ShowSolution solution={solution} />
      </WrapQuestion>
    );
  else if (questionType === QUESTION_TYPE.MCQ)
    return (
      <WrapQuestion>
        {sectionTitle}
        <MathJaxPreview math={questionText} />
        <div style={{ marginBottom: "10px" }}>
          <b className="title"> ចម្លើយ :</b>
        </div>
        {answer.map((answer: any, i: number) => {
          return (
            <Answer isCorrect={answer.isRight}>
              {/* <div className="icon-info">
                {answer.isRight ? (
                  <CheckCircleOutlined style={{ color: "green" }} />
                ) : (
                  <CloseCircleOutlined style={{ color: "red" }} />
                )}
              </div> */}
              <div className="rank">{KHMER_ALPHABET[i]}.</div>
              <MathJaxPreview math={_.get(answer, "text")} />
            </Answer>
          );
        })}
        <ShowSolution solution={solution} />
      </WrapQuestion>
    );
  else if (questionType === QUESTION_TYPE.ORDER)
    return (
      <WrapQuestion>
        {sectionTitle}
        <MathJaxPreview math={questionText} />
        {answer.map((answer: any) => {
          return (
            <Container>
              <Answer>
                {answer.order + 1} {_.get(answer, "value")}
              </Answer>
            </Container>
          );
        })}
        <ShowSolution solution={solution} />
      </WrapQuestion>
    );
  return null;
};

interface IData {
  title?: string;
  description?: string;
}
export interface ISwiperComponent {
  dataSources: any[];
  title?: string;
  data?: IData;
  onSync?: () => void;
}

export const SwiperComponent: React.FC<ISwiperComponent> = ({
  dataSources,
  title = "N/A",
  data,
  onSync,
}) => {
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState<any>({});
  const setForceRender = useSetRecoilState(appForceRenderState);

  const onEdit = (value: any) => {
    setVisible(true);
    setDataSource(value);
  };

  const handleSyncTofirestore = ({ id }: { id: string }) => {
    showSyncConfirm(() => {
      librariesSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onOk = (value: any) => {
    setVisible(false);
    console.log(value);
    // let time = setTimeout(() => {
    setForceRender((s) => s + 1);
    // }, 2000);
    // clearTimeout(time);
  };

  const calculateBulletWidth = (dataSources: any) => {
    return Math.floor(100 / dataSources.length);
  };

  return (
    // <WrapSwaper bulletWidth={Math.floor(92 / dataSources.length)}>
    <WrapSwaper bulletWidth={calculateBulletWidth(dataSources)}>
      <LibraryFormModal
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        type={dataSource.type}
        questionType={dataSource.questionType}
        dataSource={dataSource}
      />
      <TitlePreview>
        {title}
        {onSync && (
          <Tooltip title="Sync To App" placement="right">
            <Avatar shape="square" size="small" style={{ marginLeft: 10 }}>
              <ThunderboltFilled
                style={{ color: "#4c4c4c" }}
                onClick={onSync}
              />
            </Avatar>
          </Tooltip>
        )}
      </TitlePreview>
      <Swiper
        className="mySwiper"
        spaceBetween={0}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
          /* renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          }, */
        }}
        /* loop={true} */
        modules={[Keyboard, Pagination]}
      >
        {dataSources.map((item: any) => {
          return (
            <SwiperSlide
              // onClick={() => {
              //   console.log(1111);
              // }}
              key={item.id}
            >
              {data && !item.title && (
                <>
                  <Title>{_.get(data, "title")}</Title>
                  <MathJaxPreview math={_.get(data, "description")} />
                </>
              )}
              {/* {item.title && (
                <>
                  <Title>{_.get(item, "title")}</Title>
                  <MathJaxPreview math={_.get(item, "description")} />
                </>
              )} */}
              {item.type === LIBRARY_TYPE.QUESTION ? (
                <RenderLibrary
                  key={item.id}
                  dataSource={item}
                  onEdit={onEdit}
                  onSync={handleSyncTofirestore}
                />
              ) : (
                item.libraries.map((item2: any) => {
                  return (
                    <RenderLibrary
                      key={item2.id}
                      dataSource={item2}
                      onEdit={onEdit}
                      onSync={handleSyncTofirestore}
                    />
                  );
                })
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </WrapSwaper>
  );
};
