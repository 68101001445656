import produce from 'immer';
import _ from 'lodash';
import { selector } from 'recoil';
import { PrefixPermission } from '../../constants/permission.const';
import {
  checkPermission,
  formatDate,
  isSyncable,
} from '../../utils/helper.utils';
import { toPaginationProperty } from '../../utils/request.utils';
import { mapQuestions } from '../../utils/response.utils';
import { authUserSelector } from '../auth/auth.selectors';
import {
  examTestsState,
  examTestMetaState,
  examTestState,
} from './exam-test.atoms';

export const examTestsSelector = selector({
  key: 'examTestsSelector',
  get: ({ get }) => {
    const examTests = produce(get(examTestsState), (draft) => draft);
    const permissions = checkPermission(
      get(authUserSelector),
      PrefixPermission.EXAM_TEST
    );
    return examTests.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, 'DD/MM/YYYY HH:mm:ss'),
        deletedAt: formatDate(deletedAt, 'DD/MM/YYYY HH:mm:ss'),
      };
    });
  },
});

export const examTestSelector = selector({
  key: 'examTestSelector',
  get: ({ get }) => {
    const examTest = produce(get(examTestState), (draft) => draft);
    const questions = _.get(examTest, 'examTestLibraries', []);
    return {
      ...examTest,
      questions: mapQuestions({ dataSources: questions }),
    };
  },
});

export const examTestMetaSelector = selector({
  key: 'examTestMetaSelector',
  get: ({ get }) => {
    const meta = produce(get(examTestMetaState), (draft) => draft);
    return toPaginationProperty(meta);
  },
});
