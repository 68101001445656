import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { ButtonAddBrowse } from '../../../../components/button-add-browse/button-add-browse.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { SortableItems } from '../../../../components/sortable-items/sortable-items.component';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import _ from 'lodash';
import { usePracticeTest } from './practice-test.hook';
import {
  FILTER_QUESTION_TYPES,
  FORM_TYPE,
  LIBRARY_TYPE,
} from '../../../../constants/default-value.const';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import useRoute from '../../../../hooks/use-route.hook';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import {
  createPracticeTestAsync,
  deletePracticeTestLibrariesAsync,
  deletePracticeTestLibraryAsync,
  updatePracticeTestAsync,
} from '../../../../atom/practice-test/practice-test.apis';
import {
  messageError,
  messageSuccess,
  // messageWarn,
  showDeleteConfirm,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { LooseObject, Question } from '../../../../types/global.types';
import produce from 'immer';
import { LibraryFormModal } from '../../../elements/library/library-form-modal.component';
import { LibraryModal } from '../../../elements/library/library-modal.component';
import { TextEditorForm } from '../../../../components/form';
import {
  exclude,
  requestTopicDefault,
  setSelectFilter,
  toNewRecordQuestions,
} from '../../../../utils/helper.utils';
import { FormModalProps } from '../../../elements/library/library.types';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import { checkFormRedirect } from '../../../../utils/form.utils';
import { assignIndexKey } from '../../../../utils/response.utils';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';

enum MODAL_TYPE {
  LIBRARY_QUESTION = 'LIBRARY_QUESTION',
  LIBRARY_QUESTION_FORM = 'LIBRARY_QUESTION_FORM',
  LIBRARY_QUESTION_FORM_EDIT = 'LIBRARY_QUESTION_FORM_EDIT',
}

export const PracticeTestForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false,
}: FormModalProps) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [libraryType, setLibraryType] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [modalType, setModalType] = useState('');
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const [forceRender, setForceRender] = useState(false);
  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, topics, colors } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
    hasColor: true,
  });
  const { setAppLoading, appFilter, setAppFilter } = useApp();
  const { loading, practiceTest, setPracticeTest } = usePracticeTest({
    id: formModal ? formId : id,
    forceRender,
  });
  useEnterEvent({ form, enter: !modalType });
  const { t } = useTranslation();

  useEffect(() => {
    if (_.isNil(defaultFilter)) {
      return () => {
        setAppFilter({});
      };
    }
  }, [setAppFilter, defaultFilter]);

  useEffect(() => {
    setPracticeTest({});
  }, [setPracticeTest]);

  useEffect(() => {
    // if (_.size(practiceTest)) {
    form.resetFields();
    form.setFieldsValue({
      ...practiceTest,
      topicId: _.get(practiceTest, 'topic.id'),
      subjectId: _.get(practiceTest, 'subject.id'),
      levelId: _.get(practiceTest, 'level.id'),
      color:
        _.get(practiceTest, 'color') || _.get(practiceTest, 'colorCode.code'),
    });

    setQuestions(
      toNewRecordQuestions(
        _.get(practiceTest, 'questions', []),
        formType === FORM_TYPE.DUPLICATE
      )
    );
    setSelectFilter({
      dataSource: practiceTest,
      defaultFilter,
      form,
      elementId: 'practiceTestRequest',
    });
    // }
  }, [practiceTest, form, formType, defaultFilter]);

  const onCancel = () => {
    setModalType('');
    setDataSource({});
    setLibraryType('');
    setQuestionType('');
  };

  const onOkBrowseLibrary = (values: Question[]) => {
    const filterValues = values.filter(
      (item) => !questions.map((a) => a.id).includes(item.id)
    );
    if (filterValues.length !== values.length)
      messageError('Some of practiceTest is duplicate');
    setQuestions(
      assignIndexKey({ dataSources: [...questions, ...filterValues] })
    );
    onCancel();
  };

  const onOkFormLibrary = (value: LooseObject) => {
    const { id, libraryId, indexKey } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM) {
      setQuestions(
        assignIndexKey({
          dataSources: [
            ...questions,
            {
              ...value,
              isNewRecord: true,
              id: value.id,
              libraryId: value.id,
            } as Question,
          ],
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT) {
      const modifyQuestions = produce<Question[], Question[]>(
        questions,
        (draft) => {
          if (indexKey.length === 1)
            draft[indexKey[0]] = { ...value, id, libraryId } as Question;
          if (indexKey.length > 1) {
            draft[indexKey[0]].children[indexKey[1]] = {
              ...value,
              id,
              libraryId,
            };
          }

          return draft;
        }
      );
      setQuestions(assignIndexKey({ dataSources: modifyQuestions }));
    }
    onCancel();
  };

  const onFinish = (value: any) => {
    // if (!questions.length) {
    //   messageWarn("Questions is required");
    //   return;
    // }

    const testPracticeLibraries = questions.map((item, i) => {
      const { id, libraryId, isNewRecord } = item;
      if (isNewRecord) return { libraryId, rank: i };
      return { id, libraryId, rank: i };
    });

    const data: any = {
      code: value.code,
      color: value.color,
      comingSoon: value.comingSoon ? true : false,
      description: value.description,
      levelId: value.levelId,
      premium: value.premium ? true : false,
      status: value.status || formModal ? true : false,
      subjectId: value.subjectId,
      title: value.title,
      subtitle: value.subtitle,
      topicId: value.topicId,
      testPracticeLibraries,
    };

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updatePracticeTestAsync(formId, data)
          : createPracticeTestAsync(data);
      setAppLoading(true);
      request
        .then((res) => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch((err) => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updatePracticeTestAsync(id, data)
        : createPracticeTestAsync(data);

    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEndQuestion = (items: Question[]) => {
    setQuestions(items);
  };

  const onEditQuestion = (value: Question) => {
    setDataSource(exclude(value, ['isNewRecord']));
    setLibraryType(value.type);
    setQuestionType(value.questionType);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT);
  };

  const onDuplicateQuestion = (item: Question) => {
    setQuestionType(item.questionType);
    setLibraryType(item.type);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onDeleteQuestion = ({ id, isNewRecord }: Question) => {
    showDeleteConfirm(() => {
      const filterQuestions = produce<Question[], Question[]>(
        questions,
        (draft) => {
          return draft.filter((item) => item.id !== id);
        }
      );
      if (isNewRecord) setQuestions(filterQuestions);
      else {
        deletePracticeTestLibraryAsync(id)
          .then((res) => {
            setQuestions(filterQuestions);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onDeleteQuestionAll = () => {
    showDeleteConfirm(() => {
      const ids = questions.map((q: Question) => q.id);
      deletePracticeTestLibrariesAsync(ids)
        .then(() => {
          setQuestions([]);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onCreateQuestion = (type: string) => {
    setQuestionType(type);
    setDataSource({});
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onBrowseQuestion = (type: string) => {
    setLibraryType(LIBRARY_TYPE.QUESTION);
    setQuestionType(type);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION);
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div
          onClick={() =>
            requestTopicDefault({
              dataSource: practiceTest,
              defaultFilter,
              appFilter,
              setAppFilter,
              filterKey: 'practiceTest',
            })
          }
          id="practiceTestRequest"
        />
        <LibraryModal
          visible={modalType === MODAL_TYPE.LIBRARY_QUESTION}
          onOk={onOkBrowseLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          defaultFilter={_.get(appFilter, 'practiceTest', {})}
        />
        <LibraryFormModal
          visible={
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT
          }
          key={`${
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT
          }`}
          onOk={onOkFormLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'practiceTest')}
        />
        <FormWrapper form={form} name="competencyForm" onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={8} lg={8}>
              <FormInfoSection
                levels={levels}
                topics={topics}
                subjects={subjects}
                colors={colors}
                form={form}
                dataSource={practiceTest}
                hasStatusOption={true}
                hasSubtitle={true}
                filterKey="practiceTest"
              />
            </Col>
            <Col sm={24} md={16} lg={16}>
              <TitleItem title={t('Description')} />
              <TextEditorForm
                name="description"
                form={form}
                value={_.get(practiceTest, 'description', '')}
                rules={[{ required: false }]}
              />

              <TitleItem title={t('Question')} onClear={onDeleteQuestionAll} />
              <SortableItems
                dataSources={questions}
                onSortEnd={onSortEndQuestion}
                onEdit={onEditQuestion}
                onEditChild={onEditQuestion}
                onDuplicate={onDuplicateQuestion}
                onDelete={onDeleteQuestion}
                childItemsKey={['children']}
              />
              <ButtonAddBrowse
                titleCreate={t('Add Question')}
                titleBrowse={t('Link Question')}
                onCreates={onCreateQuestion}
                onBrowses={onBrowseQuestion}
                options={FILTER_QUESTION_TYPES}
              />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </Fragment>
  );
};
