import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta } from "../../../../types/global.types";
import {
  canShowAll,
  checkPermission,
  exclude,
  isAdmin,
  removeUndefined,
  replaceParam,
  toFilterSelect,
  toNumberFilter,
  toSort,
  toString,
  toStringFilter,
} from "../../../../utils/helper.utils";
import {
  // competenciesSyncToFirestoreAsync,
  deleteCompetencyAsync,
  getCompetenciesAsync,
  getCompetenciesTrashAsync,
  restoreCompetenciesAsync,
  sortCompetenciesAsync,
  updateCompetencyAsync,
} from "../../../../atom/competency/competency.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
  // showSyncConfirm,
} from "../../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  competencyLoadingState,
  competencyMetaState,
  competenciesState,
} from "../../../../atom/competency/competency.atoms";
import {
  competencyMetaSelector,
  competenciesSelector,
} from "../../../../atom/competency/competency.selectors";
import useRoute from "../../../../hooks/use-route.hook";
import {
  FILTER_STATUS,
  FORM_TYPE,
  QUERY_TYPE,
} from "../../../../constants/default-value.const";
import { TableActionButton } from "../../../../components/table/table-action-button.component";
import { arrangeData, generateRequest } from "../../../../utils/request.utils";
import { SourceDataTab } from "../../../../components/source-data-tab/source-data-tab.component";
import {
  Competency,
  CompetencyFormType,
} from "../../../../atom/competency/competency.types";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { Switch } from "antd";
import { useTopic } from "../../../../atom/topic/topic.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../../constants/permission.const";
import { useAuth } from "../../../../hooks/use-auth";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";
import { columnTableEllipsis } from "../../../../utils/table.utils";
import { RoutePaths } from "../../../../constants/route.const";
import { useApp } from "../../../../atom/app/app.hook";
import { PrivacyModal } from "../../../../components/privacy-modal/privacy-modal.component";

const requestCompetencies = (
  query: any,
  setCompetencies: (items: Competency[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getCompetenciesTrashAsync
      : getCompetenciesAsync;
  return request(option)
    .then(({ items, meta }) => {
      setCompetencies(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setCompetencies([]);
      setMeta({});
      setLoading(false);
    });
};

interface CompetencyProps {
  searchQuery: string;
}
export const CompetencyComponent: React.FC<CompetencyProps> = ({
  searchQuery,
}) => {
  const setCompetencies = useSetRecoilState(competenciesState);
  const setMeta = useSetRecoilState(competencyMetaState);
  const competencies = useRecoilValue(competenciesSelector);
  const { profile } = useAuth();
  const meta = useRecoilValue(competencyMetaSelector);
  const [loading, setLoading] = useRecoilState(competencyLoadingState);
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const { t } = useTranslation();
  const { appOwnContent, setAppAssignPrivacy } = useApp();
  const { topics, levels, subjects } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestCompetencies(
      { ...option, query: searchQuery || query.query },
      setCompetencies,
      setMeta,
      setLoading
    );
    // history.push(
    //   generateRequest(pathname, {
    //     ...query,
    //     query: searchQuery || query.query,
    //   })
    // );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      subjectId: toStringFilter(query.subjectId),
      levelId: toStringFilter(query.levelId),
      topicId: toStringFilter(query.topicId),
      status: toNumberFilter(query.status),
    });
    setTopicLevelId(query.levelId);
    setTopicSubjectId(query.subjectId);
  }, [
    searchQuery,
    setTopicLevelId,
    setTopicSubjectId,
    appOwnContent,
    query.query,
  ]);

  useEffect(() => {
    setColumns([
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Competency, b: Competency) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: Competency, b: Competency) =>
          a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Subject"),
        dataIndex: "subjectId",
        width: 200,
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis("subject"),
      },
      {
        title: t("Level"),
        dataIndex: "levelId",
        width: 200,
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis("level"),
      },
      {
        title: t("Topic"),
        dataIndex: "topicId",
        width: 200,
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis("topic"),
      },
      {
        title: t("Publish"),
        dataIndex: "status",
        sorter: (a: Competency, b: Competency) =>
          Number(a.status) - Number(b.status),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Competency) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Competency, b: Competency) =>
          a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Deleted At"),
        dataIndex: "deletedAt",
        sorter: (a: Competency, b: Competency) =>
          a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === "deletedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Action"),
        dataIndex: "action",
        class: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: Competency) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            //handleChangePrivacy={handleChangePrivacy}
            handlePreview={handlePreview}
            //handleSyncTofirestore={handleSyncTofirestore}
            record={record}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo, subjects, levels, topics]);

  const handleChangePrivacy = (value: Competency) => {
    setVisible(true);
    setAppAssignPrivacy(value);
  };

  const onOkPrivacy = (value: any) => {
    setVisible(false);
    updateCompetencyAsync(value.id, {
      ...value,
    } as CompetencyFormType)
      .then(({ message }) => {
        messageSuccess(message);
        requestCompetencies(query, setCompetencies, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handlePublish = (id: string, value: boolean) => {
    updateCompetencyAsync(id, { status: value } as any)
      .then(() => {
        messageSuccess();
        requestCompetencies(query, setCompetencies, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<Competency>(data);
    sortCompetenciesAsync(sortData)
      .then(() => {
        messageSuccess();
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
      status,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
      status: toString(status),
    };

    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));
    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ subjectId, levelId, topicId, status });
    requestCompetencies(option, setCompetencies, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestCompetencies(option, setCompetencies, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onSaveEditable = (value: CompetencyFormType) => {
    const { id, title, code } = value;

    updateCompetencyAsync(id, { title, code } as CompetencyFormType)
      .then(({ message }) => {
        messageSuccess(message);
        requestCompetencies(query, setCompetencies, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleEdit = (record: Competency) => {
    history.push(`${pathname}/form/${record.id}?formType=${FORM_TYPE.EDIT}`);
  };

  const handleDuplicate = (record: Competency) => {
    history.push(
      `${pathname}/form/${record.id}?formType=${FORM_TYPE.DUPLICATE}`
    );
  };

  const handlePreview = (record: Competency) => {
    window.open(
      replaceParam(RoutePaths.MOBILE_PREVIEW_COMPETENCY, record.id),
      "_blank"
    );
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteCompetencyAsync(id)
        .then(() => {
          messageSuccess();
          requestCompetencies(query, setCompetencies, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  // const handleSyncTofirestore = (id: string) => {
  //   showSyncConfirm(() => {
  //     competenciesSyncToFirestoreAsync([id])
  //       .then(() => {
  //         messageSuccess();
  //       })
  //       .catch((err) => showMessageErrors(err.message));
  //   });
  // };

  const onChangeTab = (key: string) => {
    requestCompetencies(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setCompetencies,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Competency[]) => {
    showRestoreConfirm(() => {
      restoreCompetenciesAsync({
        ids: selectedRows.map((item: Competency) => item.id),
      })
        .then(() => {
          messageSuccess();
          requestCompetencies(query, setCompetencies, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestCompetencies(option, setCompetencies, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    requestCompetencies({}, setCompetencies, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.COMPETENCY}
      />
      <PrivacyModal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOkPrivacy}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={canShowAll(query, filteredInfo)}
        query={query}
      />
      <TableComponent
        key={`table-${loading}-${competencies.length}`}
        dataSource={competencies}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.COMPETENCY).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
      />
    </Fragment>
  );
};
