import React, { Fragment, useEffect, useState } from 'react';
import { TableComponent } from '../../../../components/table/table.component';
import { Meta, VisibleProps } from '../../../../types/global.types';
import { RoleFormModal } from './role-form-modal.component';
import {
  canShowAll,
  checkPermission,
  exclude,
  isAdmin,
  removeUndefined,
  toNumberFilter,
  toSort,
} from '../../../../utils/helper.utils';
import {
  createRoleAsync,
  deleteRoleAsync,
  getRolesAsync,
  getRolesTrashAsync,
  restoreRolesAsync,
  updateRoleAsync,
} from '../../../../atom/role/role.apis';
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
} from '../../../../utils/message.utils';
import { Role } from '../../../../atom/role/role.type';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  roleLoadingState,
  roleMetaState,
  rolesState,
} from '../../../../atom/role/role.atoms';
import {
  roleMetaSelector,
  rolesSelector,
} from '../../../../atom/role/role.selectors';
import useRoute from '../../../../hooks/use-route.hook';
import { QUERY_TYPE } from '../../../../constants/default-value.const';
import { TableActionButton } from '../../../../components/table/table-action-button.component';
import { generateRequest } from '../../../../utils/request.utils';
import { SourceDataTab } from '../../../../components/source-data-tab/source-data-tab.component';
import { PermissionModal } from './permission-modal.component';
import { KeyOutlined } from '@ant-design/icons';
import {
  getPermissionsAsync,
  updatePermissionAsync,
} from '../../../../atom/permission/permission.apis';
import { permissionsSelector } from '../../../../atom/permission/permission.selectors';
import { permissionsState } from '../../../../atom/permission/permission.atoms';
import _ from 'lodash';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import { PrefixPermission } from '../../../../constants/permission.const';
import { useAuth } from '../../../../hooks/use-auth';
import { ListFeatureOption } from '../../../../components/list-feature-option/list-feature-option.component';

const requestRoles = (
  query: any,
  setRoles: (items: Role[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH ? getRolesTrashAsync : getRolesAsync;
  return request(option)
    .then(({ items, meta }) => {
      setRoles(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setRoles([]);
      setMeta({});
      setLoading(false);
    });
};

interface RoleProps extends VisibleProps {
  searchQuery: string;
}
export const RoleComponent: React.FC<RoleProps> = ({
  visible,
  setVisible,
  searchQuery,
}) => {
  const setRoles = useSetRecoilState(rolesState);
  const setMeta = useSetRecoilState(roleMetaState);
  const roles = useRecoilValue(rolesSelector);
  const meta = useRecoilValue(roleMetaSelector);
  const { profile } = useAuth();
  const [loading, setLoading] = useRecoilState(roleLoadingState);
  const permissions = useRecoilValue(permissionsSelector);
  const setPermissions = useSetRecoilState(permissionsState);
  const [dataSource, setDataSource] = useState<Role>();
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [visiblePermission, setVisiblePermission] = useState(false);
  const { setAppLoading } = useApp();
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestRoles(
      { ...option, query: searchQuery || query.query },
      setRoles,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      status: toNumberFilter(query.status),
      grade: toNumberFilter(query.grade),
    });
  }, [searchQuery, query.query]);

  useEffect(() => {
    setColumns([
      {
        title: t('Sort'),
        dataIndex: 'sort',
        width: 30,
        isDraggable: true,
      },
      {
        title: t('Name'),
        dataIndex: 'name',
        className: 'drag-visible',
        isSearchable: true,
        sorter: (a: Role, b: Role) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        title: t('Alias'),
        dataIndex: 'alias',
        isSearchable: true,
        sorter: (a: Role, b: Role) => a.alias.length - b.alias.length,
        sortOrder: sortedInfo.columnKey === 'alias' && sortedInfo.order,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        title: t('Updated At'),
        dataIndex: 'updatedAt',
        sorter: (a: Role, b: Role) => a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === 'updatedAt' && sortedInfo.updatedAt,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('Deleted At'),
        dataIndex: 'deletedAt',
        sorter: (a: Role, b: Role) => a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === 'updatedAt' && sortedInfo.updatedAt,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('Action'),
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: Role) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            onRender={<KeyOutlined onClick={() => handlePermission(record)} />}
            record={record}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo]);

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      status,
      grade,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      status: status ? status.toString() : '',
      grade: grade ? grade.toString() : '',
    };

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ status, grade });
    requestRoles(option, setRoles, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestRoles(option, setRoles, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const handleEdit = (record: Role) => {
    setDataSource(record);
    setVisible(true);
  };

  const handlePermission = (record: Role) => {
    // if (record.alias === USER_ROLE.ADMIN) {
    //   messageWarn("Admin has full access option no need to set it...");
    //   return;
    // }

    setDataSource(record);
    getPermissionsAsync(record.id)
      .then((res) => {
        setPermissions(res);
        setVisiblePermission(true);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleOk = async (value: Role) => {
    const { id } = value;
    setAppLoading(true);
    const request = id ? updateRoleAsync(id, value) : createRoleAsync(value);
    request
      .then(({ message }) => {
        messageSuccess(message);
        requestRoles(query, setRoles, setMeta, setLoading);
        handleCancel();
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSaveEditable = (value: Role) => {
    const { id, name, alias } = value;

    updateRoleAsync(id, { name, alias } as Role)
      .then(({ message }) => {
        messageSuccess(message);
        requestRoles(query, setRoles, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setDataSource({} as Role);
  };

  const handleDuplicate = (record: Role) => {
    setDataSource(exclude(record, ['id']));
    setVisible(true);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteRoleAsync(id)
        .then(() => {
          messageSuccess();
          requestRoles(query, setRoles, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  const onChangeTab = (key: string) => {
    requestRoles(
      { ...exclude(query, ['sortField', 'sortOrder']), queryType: key },
      setRoles,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ['sortField', 'sortOrder']),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Role[]) => {
    showRestoreConfirm(() => {
      restoreRolesAsync({ ids: selectedRows.map((item: Role) => item.id) })
        .then(() => {
          messageSuccess();
          requestRoles(query, setRoles, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onOkPermission = (value: any) => {
    let names: string[] = _.flatten(
      Object.values(value).filter((item) => item)
    ) as string[];
    updatePermissionAsync({
      roleId: dataSource?.id || '',
      names,
    })
      .then(() => {
        messageSuccess();
        setVisiblePermission(false);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const onShowAll = () => {
    const option = { ...query, showAll: 'yes' };
    requestRoles(option, setRoles, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    requestRoles({}, setRoles, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.ROLE}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={canShowAll(query, filteredInfo)}
        query={query}
      />
      <RoleFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={dataSource}
      />
      <PermissionModal
        visible={visiblePermission}
        onCancel={() => setVisiblePermission(false)}
        onOk={onOkPermission}
        dataSource={permissions}
      />
      <TableComponent
        key={`table-${loading}-${roles.length}`}
        dataSource={roles}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.ROLE).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
      />
    </Fragment>
  );
};
