import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Input, Space, Switch, Tooltip } from "antd";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import { SearchOutlined } from "@ant-design/icons";
import Form, { FormInstance } from "antd/lib/form";
import _ from "lodash";
import { RowSelectionType } from "antd/lib/table/interface";
import { withTranslation } from "react-i18next";
import { WrapTable } from "./table.styles";
import { columnEllipsis } from "../../utils/table.utils";
import { MathJaxPreview } from "../mathjax-preview/mathjax-preview.component";

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        {/* <tr {...props} /> */}
        <SortableItem index={index} {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => (
  <tbody {...props} />
));

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onDoubleClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}

export interface TableProps {
  [key: string]: any;
}

interface Pagination {
  total?: number;
  current?: number;
  pageSize?: number;
}

interface TableComponentProps<T extends any> {
  dataSource: T[];
  onSortEnd?: (data: T) => void;
  onColumnSearch: (column: string, value: string) => void;
  columns: any;
  onChange: (props: any) => void;
  pagination: Pagination;
  loading?: boolean;
  onSaveEditable?: (data: any) => void;
  query?: any;
  onSelectedRows?: (e: any) => void;
  hasSelectedRows?: boolean;
  onRestore?: (e: any) => void;
  selectionType?: RowSelectionType;
  enableSort?: boolean;
  onEnableSort?: (value: boolean) => void;
  showAll?: boolean;
  scroll?: number;
}

interface TableComponentState<T> {
  dataSource: T[];
  searchText: string;
  searchedColumn: string;
}

class TableComponents extends React.Component<
  TableComponentProps<TableProps>,
  TableComponentState<TableProps>
> {
  state: any = {
    dataSource: this.props.dataSource,
    searchText: _.get(this.props.query, `searchValue`, ""),
    searchedColumn: _.get(this.props.query, `searchField`, ""),
    selectedRowKeys: [],
    selectedRows: [],
  };

  searchInput: any = React.createRef();
  onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const { dataSource } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      this.setState({ dataSource: newData });
      if (this.props.onSortEnd) this.props.onSortEnd(newData);
    }
  };

  DraggableContainer = (props: any) => (
    <SortableContainerItem
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
      {...props}
    />
  );

  DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x: any) => x.index === restProps["data-row-key"]
    );
    return (
      <>
        <EditableRow index={index} {...restProps} />
      </>
    );
  };

  getColumnSearchProps = (dataIndex: any) => {
    const defaultValue =
      this.state.searchedColumn === dataIndex ? this.state.searchText : "";
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node: any) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0] || defaultValue}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
              type="link"
            >
              Reset
            </Button>
            {/* <Button
              type="link"
              size="small"
              style={{ width: 90 }}
              onClick={() => {
                // confirm({ closeDropdown: false });
                // this.setState({
                //   searchText: selectedKeys[0],
                //   searchedColumn: dataIndex
                // });
                this.handleSearch(selectedKeys, confirm, dataIndex);
              }}
            >
              Filter
            </Button> */}
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              // icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: any) => {
        return (
          <SearchOutlined
            style={{ color: defaultValue ? "#1890ff" : undefined }}
          />
        );
      },
      onFilter: (value: any, record: any) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible: any) => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      // ellipsis: {
      //   showTitle: false
      // },
      ellipsis: true,
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          <MathJaxPreview math={text} />
        </Tooltip>
      ),
      // render: (text: any) => text
      // render: (text: any) => (
      //   <Tooltip placement="topLeft" title={text}>
      //     <div>
      //       <MathJaxPreview math={text} />
      //     </div>
      //   </Tooltip>
      // )
    };
  };

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    // confirm();
    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex
    // });
    // this.props.onChange()

    this.props.onColumnSearch(dataIndex, selectedKeys[0]);
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.onColumnSearch("", "");
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    this.props.onChange({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  handleSave = (row: DataType) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
    if (this.props.onSaveEditable) this.props.onSaveEditable(row);
  };

  onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    if (this.props.onSelectedRows) this.props.onSelectedRows(selectedRows);
    this.setState<any>({ selectedRowKeys, selectedRows });
  };

  render() {
    const { t }: any = this.props;
    const { dataSource, selectedRowKeys, selectedRows } = this.state;
    const {
      pagination,
      loading,
      hasSelectedRows,
      onRestore,
      selectionType,
      enableSort,
      onEnableSort,
      showAll,
      scroll,
    } = this.props;

    let rowSelection = undefined;
    if (hasSelectedRows) {
      rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        type: selectionType || "checkbox",
      };
    }

    const columns = this.props.columns
      .map((col: any) => {
        let option = {};
        if (col.isSearchable) {
          option = this.getColumnSearchProps(col.dataIndex);
        }

        if (col.isDraggable && !hasSelectedRows) {
          option = {
            ...option,
            title: "",
            className: "drag-visible",
            fixed: "left",
            render: () => <DragHandle />,
          };
        }

        if (["deletedAt", "updatedAt"].includes(col.dataIndex)) {
          option = {
            ...option,
            ...columnEllipsis,
          };
        }

        return {
          ...col,
          ...option,
          onCell: (record: DataType) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      })
      .filter((item: any) => {
        if (hasSelectedRows && item.isDraggable) return false;
        else if (hasSelectedRows && item.dataIndex === "action") return false;
        else if (hasSelectedRows && item.dataIndex === "updatedAt")
          return false;
        else if (!hasSelectedRows && item.dataIndex === "deletedAt")
          return false;
        else if (!enableSort && item.dataIndex === "sort") return false;

        return true;
      });

    return (
      <>
        {selectedRows.length > 0 && onRestore && (
          <div style={{ textAlign: "right", marginBottom: "20px" }}>
            <Button
              onClick={() => {
                if (onRestore) onRestore(selectedRows);
              }}
            >
              {t("Restore")}
              {selectedRows.length}
            </Button>
          </div>
        )}
        {showAll && onEnableSort && !hasSelectedRows && (
          <>
            <div style={{ textAlign: "right", marginBottom: "20px" }}>
              <Switch defaultChecked={enableSort} onChange={onEnableSort} />
              &nbsp; Enable Sort
            </div>
          </>
        )}
        <WrapTable
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          loading={loading}
          rowSelection={rowSelection}
          components={{
            body: {
              wrapper: this.DraggableContainer,
              row: this.DraggableBodyRow,
              cell: EditableCell,
            },
          }}
          bordered
          onChange={this.handleTableChange}
          rowClassName={() => "editable-row"}
          size="small"
          pagination={{
            ...pagination,
            size: "small",
            showTotal: (total) => `Total: ${total} records`,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          scroll={{ x: "max-content" }}
          //scroll={{ x: scroll || columns.length * 150, y: 660 }}
        />
      </>
    );
  }
}

export const TableComponent = withTranslation()(TableComponents);
