import produce from "immer";
import _ from "lodash";
import { selector } from "recoil";
import { authUserState } from "./auth.atoms";

export const getCurrentUser = selector({
  key: "currentUser",
  get: async ({ get }) => {
    return {};
  }
});

export const authUserSelector = selector({
  key: "authUserSelector",
  get: async ({ get }) => {
    const user = produce(get(authUserState), draft => draft);

    if (!_.size(user)) return null;

    const { id, firstName, lastName, profile, role }: any = user;
    const permissions = _.get(role, "permissions", []).reduce(
      (acc: { [key: string]: boolean }, cur: { id: string; name: string }) => {
        acc[cur.name] = true;
        return acc;
      },
      {}
    );

    return {
      id,
      firstName,
      lastName,
      profile,
      fullName: `${lastName} ${firstName}`,
      roleName: role.name,
      roleAlias: role.alias,
      permissions
    };
  }
});
