import { Col, Form, Row } from "antd";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { Level } from "../../../../atom/level/level.types";
import { InputForm, InputNumberForm, TextEditorForm } from "../../../../components/form";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { useTranslation } from "react-i18next";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

interface LevelFormProps {
  dataSource?: any;
  handleCancel?: () => void;
  handleOk: (value: Level) => void;
}
export const LevelForm: React.FC<LevelFormProps> = ({ dataSource, handleCancel, handleOk }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  useEnterEvent({ form });

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({ ...dataSource });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="levelForm" form={form} onFinish={onFinish}>
        <FormModalHeader title={t("Level")} handleCancel={handleCancel} />
        <InputForm name="id" placeholder="ID" className="d-none" />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputForm
              name="title"
              placeholder={t("Title")}
              rules={[
                {
                  required: true,
                  message: "Please input the title!"
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <InputForm
              name="code"
              placeholder={t("Code")}
              rules={[
                {
                  required: true,
                  message: "Please input the code!"
                }
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputNumberForm
              name="rank"
              placeholder={t("Rank")}
              rules={[
                {
                  required: true,
                  message: "Please input the rank!"
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <InputNumberForm
              name="grade"
              placeholder={t("Grade")}
              rules={[
                {
                  required: true,
                  message: "Please input the grade!"
                }
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextEditorForm
              value={_.get(dataSource, `description`)}
              name="description"
              form={form}
              rules={[
                {
                  required: false,
                  message: "Please input the description!"
                }
              ]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
