import produce from "immer";
import { selector } from "recoil";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { topicsState, topicMetaState, topicsSelectState } from "./topic.atoms";

export const topicsSelector = selector({
  key: "topicsSelector",
  get: ({ get }) => {
    const topics = produce(get(topicsState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.TOPIC);
    return topics.map((item, i) => {
      const { updatedAt, deletedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const mapTopicsSelect = (topics: any[]) => {
  return topics.map((item, i) => {
    const { title, code } = item;
    return { ...item, name: `[${code}]-${title}` };
  });
};

export const topicsSelectSelector = selector({
  key: "topicsSelectSelector",
  get: ({ get }) => {
    const topics = produce(get(topicsSelectState), draft => draft);
    return mapTopicsSelect(topics);
  }
});

export const topicMetaSelector = selector({
  key: "topicMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(topicMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
