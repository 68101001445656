import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { Topic } from './topic.types';
import { RestoreType } from '../../types/global.types';

export const getTopicsAsync = (option: {}) => {
  const url = generateQuery('/topics', option, [
    'status',
    'grade',
    'comingSoon',
    'subjectId',
    'levelId',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getTopicsSelectAsync = (option: {}) => {
  const url = generateQuery('/topics/for/select-option', {
    ...option,
    noDefaultParam: true,
    noDefaultOwnContent: true,
  });
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getTopicsTrashAsync = (option: {}) => {
  const url = generateQuery('/topics/list/trash', option, [
    'rank',
    'status',
    'grade',
    'comingSoon',
    'subjectId',
    'levelId',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const createTopicAsync = (data: Topic) => {
  return axios
    .post('/topics', data, config())
    .then((response) => response.data);
};

export const restoreTopicsAsync = (data: RestoreType) => {
  return axios
    .patch('/topics/restore', data, config())
    .then((response) => response.data);
};

export const updateTopicAsync = (id: string, data: Topic) => {
  return axios
    .put(`/topics/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteTopicAsync = (id: string) => {
  return axios
    .delete(`/topics/${id}`, config())
    .then((response) => response.data);
};

export const topicsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/topics/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};
