import { PropsType } from "./switch.type";
import { StyleSwitch, FormItem } from "./switch.styles";
import _ from "lodash";

export const SwitchForm: React.FC<PropsType> = ({
  label,
  required,
  tooltip,
  disabled,
  name,
  rules,
  defaultChecked = false,
  onChange,
  size,
  form,
  labelCol,
  wrapperCol
}) => {
  const _onChange = (value: boolean) => {
    if (_.isArray(name)) {
      const filed: any = {};
      _.set(filed, name.join("."), value);
      form?.setFieldsValue({ ...filed });
    } else {
      form?.setFieldsValue({ [name]: value });
    }
    if (onChange) onChange(value);
  };
  return (
    <>
      <FormItem
        name={name}
        label={label}
        rules={rules}
        tooltip={tooltip}
        required={required}
        valuePropName={name}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
      >
        <StyleSwitch
          size={size}
          onChange={_onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
        />{""}
      </FormItem>
    </>
  );
};
