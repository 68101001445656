import { Col, Row, Spin } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useApp } from "../../../../atom/app/app.hook";
import {
  createQuestionFillGapAsync,
  updateQuestionFillGapAsync
} from "../../../../atom/library/question.apis";
import { FormInfoSection } from "../../../../components/form-info-section/form-info-section.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { FORM_TYPE, QUESTION_TYPE } from "../../../../constants/default-value.const";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";
import useRoute from "../../../../hooks/use-route.hook";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { LooseObject } from "../../../../types/global.types";
import { checkFormRedirect } from "../../../../utils/form.utils";
import { requestTopicDefault, setSelectFilter } from "../../../../utils/helper.utils";
import { messageSuccess, showMessageErrors } from "../../../../utils/message.utils";
import { useLibrary } from "../../library/library.hook";
import { FormModalProps, LibraryFormValue } from "../../library/library.types";
import { QuestionSection } from "./question-section.component";

export interface FormValue extends LibraryFormValue {
  answer: LooseObject[];
}

export const QuestionFillGapForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false
}: FormModalProps) => {
  const {
    history,
    pathname,
    query: { formType },
    param: { id }
  } = useRoute();
  const { levels, subjects, topics } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true
  });
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  const [forceRender, setForceRender] = useState(false);
  const { loading, library } = useLibrary({ id: formModal ? formId : id, forceRender });
  useEnterEvent({ form });

  // useEffect(() => {
  //   if (_.isNil(defaultFilter)) {
  //     return () => {
  //       setAppFilter({});
  //     };
  //   }
  // }, [setAppFilter, defaultFilter]);

  useEffect(() => {
    if (_.size(library)) {
      form.resetFields();
      form.setFieldsValue({
        ...library,
        topicId: _.get(library, "topic.id"),
        subjectId: _.get(library, "subject.id"),
        levelId: _.get(library, "level.id"),
        questionText: _.get(library, "data.questionText"),
        solution: _.get(library, "data.solution")
      });
    }
    setSelectFilter({ dataSource: library, defaultFilter, form, elementId: "fillGapRequest" });
  }, [library, form, defaultFilter]);

  const onFinish = (value: FormValue) => {
    const { questionText, solution } = value;
    const data = {
      ...value,
      data: {
        questionText,
        answer: [],
        solution,
        type: QUESTION_TYPE.FILL_GAP
      }
    };

    setAppLoading(true);

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateQuestionFillGapAsync(formId, data)
          : createQuestionFillGapAsync(data);

      request
        .then(res => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch(err => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateQuestionFillGapAsync(id, data)
        : createQuestionFillGapAsync(data);

    request
      .then(res => {
        messageSuccess();
        setAppLoading(false);
        let check = checkFormRedirect({ history, pathname, id: res.data.id, formType, isBack });
        if (check) setForceRender(!forceRender);
      })
      .catch(err => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div
        onClick={() =>
          requestTopicDefault({
            dataSource: library,
            defaultFilter,
            appFilter,
            setAppFilter,
            filterKey: "question"
          })
        }
        id="orderRequest"
      />
      <FormWrapper form={form} name="competencyForm" onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8} lg={8}>
            <FormInfoSection
              levels={levels}
              topics={topics}
              subjects={subjects}
              filterKey="question"
            />
          </Col>
          <Col sm={24} md={16} lg={16}>
            <QuestionSection form={form} dataSource={library} />
          </Col>
        </Row>
      </FormWrapper>
    </Spin>
  );
};
