import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { ExamTest } from './exam-test.types';

export const getExamTestsAsync = (option: {}) => {
  const url = generateQuery('/exam-tests', option, [
    'levelId',
    'subjectId',
    'categoryId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getExamTestAsync = (id: string) => {
  return axios
    .get(`/exam-tests/${id}`, config())
    .then((response) => response.data.data);
};

export const getExamTestsTrashAsync = (option: {}) => {
  const url = generateQuery('/exam-tests/list/trash', option);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const restoreExamTestsAsync = (data: RestoreType) => {
  return axios
    .patch('/exam-tests/restore', data, config())
    .then((response) => response.data);
};

export const createExamTestAsync = (data: ExamTest) => {
  return axios
    .post('/exam-tests', data, config())
    .then((response) => response.data);
};

export const updateExamTestAsync = (id: string, data: ExamTest) => {
  return axios
    .put(`/exam-tests/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteExamTestAsync = (id: string) => {
  return axios
    .delete(`/exam-tests/${id}`, config())
    .then((response) => response.data);
};

export const deleteExamTestLibraryAsync = (id: string) => {
  return axios
    .delete(`/exam-test-libraries/${id}`, config())
    .then((response) => response.data);
};

export const sortExamTestsAsync = (data: SortData) => {
  return axios
    .patch(`exam-tests/set-rank`, data, config())
    .then((response) => response.data);
};

export const examTestsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/exam-tests/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};

export const testTexamUnsyncFromFirestore = async (id: string) => {
  return axios
    .delete(`/exam-tests/unsync-from-firestore/${id}`, config())
    .then((response) => response.data);
};
