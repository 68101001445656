import styled from "styled-components";
import { Switch, Form } from "antd";

export const FormItem = styled(Form.Item)`
  flex-direction: column;
  justify-content: flex-start;
  .ant-form-item-label { text-align: left; }
`

export const StyleSwitch = styled(Switch)``;
