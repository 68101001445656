import { Layout } from "../../../components/layout/layout.component";
import { ColorComponent } from "./components/color.component";
import { useVisible } from "../../../hooks/use-visible.hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../constants/permission.const";

export const ColorPage = () => {
  const { visible, setVisible } = useVisible();
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  return (
    <Layout
      title={t("Manage")}
      onCreate={() => setVisible(true)}
      onSearch={(value) => setSearchQuery(value)}
      prefixPermission={PrefixPermission.COLOR_CODE}
    >
      <ColorComponent
        visible={visible}
        setVisible={setVisible}
        searchQuery={searchQuery}
      />
    </Layout>
  );
};
