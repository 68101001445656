import { Spin } from "antd";
import { WrapLoading } from "./loading.styles";
import { LoadingOutlined } from "@ant-design/icons";

export interface LoadingProps {}

export const Loading: React.FC<LoadingProps> = () => {
  return (
    <WrapLoading>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
    </WrapLoading>
  );
};
