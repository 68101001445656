import produce from "immer";
import _ from "lodash";
import { selector } from "recoil";
import { redirectRoute } from "../../utils/form.utils";
import { searchesState } from "./search.atoms";

export const searchesSelector = selector({
  key: "searchesSelector",
  get: ({ get }) => {
    const searches = produce(get(searchesState), draft => draft);
    return _.flatten(Object.values(searches)).map((item: any) => {
      return { ...item, redirectRoute: redirectRoute(item) };
    });
  }
});
