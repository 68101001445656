import Pie from "@ant-design/charts/es/plots/pie";

export const PieChart = () => (
    <Pie {...config} />
)


const data = [
    {
      type: 'A',
      value: 27,
      color: '#a1dad073'
    },
    {
      type: 'B',
      value: 25,
      color: '#5b8ff982'
    },
    {
      type: 'C',
      value: 18,
      color: '#5d70928f'
    },
    {
      type: 'D',
      value: 15,
      color: '#f6bd1782'
    },
    {
      type: 'E',
      value: 10,
      color: '#6f5ef987'
    },
    {
      type: 'F',
      value: 5,
      color: '#6dc8ec'
    },
  ];
  
  
  
  const config = {
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '2000\nSession',
      },
    },
  };