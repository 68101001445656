import { Col, Form, Row } from "antd";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { Topic } from "../../../../atom/topic/topic.types";
import {
  InputForm,
  SelectForm,
  TextEditorForm,
} from "../../../../components/form";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { OptionType } from "../../../../components/form/select/select.type";
import { useTranslation } from "react-i18next";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

interface TopicFormProps {
  dataSource?: any;
  handleCancel?: () => void;
  handleOk: (value: Topic) => void;
  levels: OptionType[];
  subjects: OptionType[];
  medias: OptionType[];
}
export const TopicForm: React.FC<TopicFormProps> = ({
  dataSource,
  handleCancel,
  handleOk,
  levels,
  subjects,
  medias,
}) => {
  const [form] = Form.useForm();
  useEnterEvent({ form });
  const { t } = useTranslation();
  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({ ...dataSource });
    form.setFieldsValue({
      mediaId: _.get(dataSource, "icon.id"),
      subjectId: _.get(dataSource, "subject.id"),
      levelId: _.get(dataSource, "level.id"),
    });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="topicForm" form={form} onFinish={onFinish}>
        <FormModalHeader title={t("Topic")} handleCancel={handleCancel} />
        <InputForm name="id" placeholder="ID" className="d-none" />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputForm
              name="title"
              placeholder={t("Title")}
              rules={[
                {
                  required: true,
                  message: "Please input the title!",
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <InputForm
              name="code"
              placeholder={t("Code")}
              rules={[
                {
                  required: true,
                  message: "Please input the code!",
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SelectForm
              name="subjectId"
              placeholder={t("Subject")}
              rules={[
                {
                  required: true,
                  message: "Please select the subject!",
                },
              ]}
              dataSource={subjects}
            />
          </Col>
          <Col span={12}>
            <SelectForm
              name="levelId"
              placeholder={t("Level")}
              rules={[
                {
                  required: true,
                  message: "Please select the level!",
                },
              ]}
              dataSource={levels}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <SelectForm
              name="mediaId"
              placeholder={t("Icon")}
              rules={[
                {
                  required: true,
                  message: "Please select the icon!",
                },
              ]}
              dataSource={medias}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextEditorForm
              value={_.get(dataSource, `description`)}
              name="description"
              form={form}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input the description!"
              //   }
              // ]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
