import { Col, Row, Spin } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useApp } from '../../../../atom/app/app.hook';
import {
  createTextLibraryAsync,
  updateTextLibraryAsync,
} from '../../../../atom/library/text-library.apis';
import { TextEditorForm } from '../../../../components/form';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import { FORM_TYPE } from '../../../../constants/default-value.const';
import useRoute from '../../../../hooks/use-route.hook';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import {
  messageSuccess,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { useLibrary } from '../../library/library.hook';
import { FormModalProps } from '../../library/library.types';
import { useTranslation } from 'react-i18next';
import {
  requestTopicDefault,
  setSelectFilter,
} from '../../../../utils/helper.utils';
import { checkFormRedirect } from '../../../../utils/form.utils';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';
// import produce from 'immer';

export const TextLibraryForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false,
}: FormModalProps) => {
  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, topics } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
  });
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  const { t } = useTranslation();
  const [forceRender, setForceRender] = useState(false);
  const { loading, library, setLibrary } = useLibrary({
    id: formModal ? formId : id,
    forceRender,
  });
  useEnterEvent({ form });

  // useEffect(() => {
  //   if (_.isNil(defaultFilter)) {
  //     return () => {
  //       setAppFilter((state: any) => {
  //         const newState = produce(state, (draft: any) => {
  //           draft['textLibrary'] = null;
  //           return draft;
  //         });
  //         return { ...newState };
  //       });
  //     };
  //   }
  // }, [setAppFilter, defaultFilter]);

  useEffect(() => {
    setLibrary({});
  }, [setLibrary]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...library,
      description: _.get(library, 'data.description'),
      levelId: _.get(library, 'level.id'),
      subjectId: _.get(library, 'subject.id'),
      topicId: _.get(library, 'topic.id'),
    });

    setSelectFilter({
      dataSource: library,
      defaultFilter,
      form,
      elementId: 'textLibraryRequest',
    });
  }, [library, form, defaultFilter]);

  const onFinish = (value: any) => {
    const data: any = {
      code: value.code,
      levelId: value.levelId,
      subjectId: value.subjectId,
      title: value.title,
      topicId: value.topicId,
      data: { description: value.description || ' ' },
    };

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateTextLibraryAsync(formId, data)
          : createTextLibraryAsync(data);
      setAppLoading(true);
      request
        .then((res) => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch((err) => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateTextLibraryAsync(id, data)
        : createTextLibraryAsync(data);

    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div
        onClick={() =>
          requestTopicDefault({
            dataSource: library,
            defaultFilter,
            appFilter,
            setAppFilter,
            filterKey: 'textLibrary',
          })
        }
        id='textLibraryRequest'
      />
      <FormWrapper form={form} name='textLibraryForm' onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8} lg={8}>
            <FormInfoSection
              levels={levels}
              topics={topics}
              subjects={subjects}
              filterKey='textLibrary'
            />
          </Col>
          <Col sm={24} md={16} lg={16}>
            <TitleItem title={t('Description')} />
            <TextEditorForm
              name='description'
              form={form}
              value={_.get(library, 'data.description', '')}
              rules={[{ required: false }]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Spin>
  );
};
