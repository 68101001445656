import {
  ApiTwoTone,
  DeleteOutlined,
  EditOutlined,
  EditTwoTone,
  FormOutlined,
  LinkOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { Col, FormInstance, Row, Space, Tooltip } from "antd";
import _ from "lodash";
import { LooseObject } from "../../types/global.types";
import { InputNumberForm } from "../form/input-number/input-number.component";
import { InputForm } from "../form/input/input.component";
import { ColorPickerForm } from "../form/color-picker/color-picker.component";
import { SelectForm } from "../form/select/select.component";
import { OptionType } from "../form/select/select.type";
import { SwitchForm } from "../form/switch/switch.component";
import { TitleItem } from "../title-item/title-item.component";
import { SwitchLabel, SwitchWrapper } from "./form-info.styles";
import { useTranslation } from "react-i18next";
import { useApp } from "../../atom/app/app.hook";
import produce from "immer";
import { getTopicsSelectAsync } from "../../atom/topic/topic.apis";
import { mapTopicsSelect } from "../../atom/topic/topic.selectors";
import { ExamLibraryType } from "../../constants/default-value.const";

export interface FormInfoSectionProps {
  levels?: OptionType[];
  categories?: OptionType[];
  colors?: OptionType[];
  // medias?: OptionType[];
  subjects?: OptionType[];
  topics?: OptionType[];
  onChangeSubject?: (value: string) => void;
  onChangeLevel?: (value: string) => void;
  onChangeTopic?: (value: string) => void;
  form?: FormInstance;
  dataSource?: LooseObject;
  hasStatusOption?: boolean;
  hasTestOption?: boolean;
  hasSubtitle?: boolean;
  onCreateLibrary?: (value: string) => void;
  onBrowseLibrary?: (value: string) => void;
  onEditLibrary?: (value: string) => void;
  onDeleteLibrary?: (value: string) => void;
  // onBrowseSolutionLibrary?: () => void;
  onChangeInput?: (value: number) => void;
  filterKey?: string;
}
export const FormInfoSection: React.FC<FormInfoSectionProps> = ({
  levels,
  categories,
  colors,
  //   medias,
  subjects,
  topics,
  children,
  onChangeSubject,
  onChangeLevel,
  form,
  dataSource,
  hasStatusOption,
  hasTestOption,
  hasSubtitle,
  onBrowseLibrary,
  onCreateLibrary,
  onEditLibrary,
  onDeleteLibrary,
  onChangeInput,
  onChangeTopic,
  filterKey,
}) => {
  const { t } = useTranslation();
  const { appFilter, setAppFilter } = useApp();

  const _onChangeLevel = async (id: string) => {
    form?.setFieldsValue({ topicId: "" });
    if (onChangeLevel) {
      onChangeLevel(id);
    }
    if (filterKey) {
      if (id && _.get(appFilter, `${filterKey}.subjectId`)) {
        let topics = await getTopicsSelectAsync({
          s_subjectId: _.get(appFilter, `${filterKey}.subjectId`),
          s_levelId: id,
        });
        let app = produce<any, any>(appFilter, (draft: any) => {
          _.set(draft, `${filterKey}.topics`, mapTopicsSelect(topics));
          _.set(draft, `${filterKey}.levelId`, id);
          if (!topics.length) _.set(draft, `${filterKey}.topicId`, "");
          return draft;
        });
        setAppFilter({ ...app });
      } else {
        let app = produce<any, any>(appFilter, (draft: any) => {
          _.set(draft, `${filterKey}.topics`, []);
          _.set(draft, `${filterKey}.levelId`, id);
          _.set(draft, `${filterKey}.topicId`, "");
          return draft;
        });
        setAppFilter({ ...app });
      }
    }
  };

  const _onChangeSubject = async (id: string) => {
    form?.setFieldsValue({ topicId: "" });
    if (onChangeSubject) {
      onChangeSubject(id);
    }
    if (filterKey) {
      if (id && _.get(appFilter, `${filterKey}.levelId`)) {
        let topics = await getTopicsSelectAsync({
          s_levelId: _.get(appFilter, `${filterKey}.levelId`),
          s_subjectId: id,
        });
        let app = produce<any, any>(appFilter, (draft: any) => {
          _.set(draft, `${filterKey}.topics`, mapTopicsSelect(topics));
          _.set(draft, `${filterKey}.subjectId`, id);
          if (!topics.length) _.set(draft, `${filterKey}.topicId`, "");
          return draft;
        });

        setAppFilter({ ...app });
      } else {
        let app = produce<any, any>(appFilter, (draft: any) => {
          _.set(draft, `${filterKey}.topics`, []);
          _.set(draft, `${filterKey}.subjectId`, id);
          _.set(draft, `${filterKey}.topicId`, "");
          return draft;
        });

        setAppFilter({ ...app });
      }
    }
  };

  const _onChangeTopic = (id: string) => {
    if (onChangeTopic) {
      onChangeTopic(id);
    }
    if (filterKey) {
      let app = produce<any, any>(appFilter, (draft: any) => {
        _.set(draft, `${filterKey}.topicId`, id);
        return draft;
      });
      setAppFilter({ ...app });
    }
  };

  return (
    <Row /* style={{ paddingLeft: '30px' }} */>
      <Col span={24}>
        <TitleItem title={t("Infos")} />
      </Col>
      <Col span={24}>
        <InputForm
          name="title"
          placeholder={t("Title")}
          rules={[
            {
              required: true,
              message: "Please input the title!",
            },
          ]}
        />
      </Col>
      {hasSubtitle && (
        <Col span={24}>
          <InputForm
            name="subtitle"
            placeholder={t("Subtitle")}
            rules={[
              {
                required: true,
                message: "Please input the subtitle!",
              },
            ]}
          />
        </Col>
      )}
      <Col span={24}>
        <InputForm
          name="code"
          placeholder={t("Code")}
          rules={[
            {
              required: true,
              message: "Please input the code!",
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <SelectForm
          onChange={_onChangeSubject}
          name="subjectId"
          placeholder={t("Select subject")}
          rules={[
            {
              required: true,
              message: "Please select the subject!",
            },
          ]}
          dataSource={subjects ? subjects : []}
        />
      </Col>
      <Col span={24}>
        <SelectForm
          onChange={_onChangeLevel}
          name="levelId"
          placeholder={t("Select level")}
          rules={[
            {
              required: true,
              message: "Please select the level!",
            },
          ]}
          dataSource={levels ? levels : []}
        />
      </Col>
      {topics && (
        <Col span={24}>
          <SelectForm
            name="topicId"
            placeholder={t("Select topic")}
            rules={[
              {
                required: true,
                message: "Please select the topic!",
              },
            ]}
            onChange={_onChangeTopic}
            // dataSource={topics}
            // dataSource={
            //   filterKey && _.get(appFilter, `${filterKey}.topics`)
            //     ? _.get(appFilter, `${filterKey}.topics`, [])
            //     : topics
            // }

            dataSource={
              filterKey ? _.get(appFilter, `${filterKey}.topics`, []) : topics
            }
          />
        </Col>
      )}
      {colors && (
        <Col span={24}>
          <SwitchWrapper>
            <ColorPickerForm
              name="color"
              form={form}
              placeholder={t("Color")}
              dataSource={colors.map((item) => item.name)}
              rules={[
                {
                  required: true,
                  message: "Please input the color!",
                },
              ]}
            />
          </SwitchWrapper>
        </Col>
      )}
      {categories && (
        <Col span={24}>
          <SelectForm
            name="categoryId"
            placeholder={t("Select Category")}
            rules={[
              {
                required: true,
                message: "Please select the category!",
              },
            ]}
            dataSource={categories}
          />
        </Col>
      )}
      {hasTestOption && (
        <>
          <Col span={24}>
            <InputNumberForm
              name="duration"
              placeholder={t("Duration")}
              rules={[
                {
                  required: true,
                  message: "Please input the duration!",
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <InputNumberForm
              name="totalScore"
              placeholder={t("Total Score")}
              rules={[
                {
                  required: true,
                  message: "Please input the total score!",
                },
              ]}
              onChange={onChangeInput}
            />
          </Col>
          <Col span={24}>
            <InputForm
              name="testLibraryTitle"
              placeholder={t("Test library")}
              addonAfter={
                <Space>
                  {onCreateLibrary && (
                    <Tooltip title="Create">
                      <PlusCircleTwoTone
                        onClick={() => onCreateLibrary(ExamLibraryType.TEST)}
                      />
                    </Tooltip>
                  )}
                  {onBrowseLibrary && (
                    <Tooltip title="Link">
                      <ApiTwoTone
                        onClick={() => onBrowseLibrary(ExamLibraryType.TEST)}
                      />
                    </Tooltip>
                  )}
                  {onEditLibrary && (
                    <Tooltip title="Edit">
                      <EditTwoTone
                        onClick={() => onEditLibrary(ExamLibraryType.TEST)}
                      />
                    </Tooltip>
                  )}
                  {onDeleteLibrary && (
                    <Tooltip title="Unlink">
                      <MinusCircleTwoTone
                        onClick={() => onDeleteLibrary(ExamLibraryType.TEST)}
                      />
                    </Tooltip>
                  )}
                </Space>
              }
              // disabled={true}
              rules={[
                {
                  required: false,
                  message: "Please input the test library!",
                },
              ]}
            />
            <InputForm name="testLibraryId" hidden={true} />
          </Col>
          <Col span={24}>
            <InputForm
              name="solutionLibraryTitle"
              placeholder={t("Solution library")}
              addonAfter={
                <Space>
                  {onCreateLibrary && (
                    <Tooltip title="Create">
                      <PlusCircleTwoTone
                        onClick={() =>
                          onCreateLibrary(ExamLibraryType.SOLUTION)
                        }
                      />
                    </Tooltip>
                  )}
                  {onBrowseLibrary && (
                    <Tooltip title="Link">
                      <ApiTwoTone
                        onClick={() =>
                          onBrowseLibrary(ExamLibraryType.SOLUTION)
                        }
                      />
                    </Tooltip>
                  )}
                  {onEditLibrary && (
                    <Tooltip title="Edit">
                      <EditTwoTone
                        onClick={() => onEditLibrary(ExamLibraryType.SOLUTION)}
                      />
                    </Tooltip>
                  )}
                  {onDeleteLibrary && (
                    <Tooltip title="Unlink">
                      <MinusCircleTwoTone
                        onClick={() =>
                          onDeleteLibrary(ExamLibraryType.SOLUTION)
                        }
                      />
                    </Tooltip>
                  )}
                </Space>
              }
              // disabled={true}
              rules={[
                {
                  required: false,
                  message: "Please input the solution library!",
                },
              ]}
            />
            <InputForm name="solutionLibraryId" hidden={true} />
          </Col>
        </>
      )}
      {form && dataSource && hasStatusOption && (
        <>
          <Col span={24}>
            <SwitchWrapper>
              <SwitchLabel>{t("Publish")}</SwitchLabel>
              <SwitchForm
                key={_.size(dataSource)}
                // label="Publish"
                name="status"
                size="small"
                defaultChecked={_.get(dataSource, "status")}
                form={form}
              />
            </SwitchWrapper>
          </Col>
          <Col span={24}>
            <SwitchWrapper>
              <SwitchLabel>{t("Premium")}</SwitchLabel>
              <SwitchForm
                key={_.size(dataSource)}
                name="premium"
                size="small"
                defaultChecked={_.get(dataSource, "premium")}
                form={form}
              />
            </SwitchWrapper>
          </Col>
          {/* <Col span={24}>
            <SwitchWrapper>
              <SwitchLabel>{t('ComingSoon')}</SwitchLabel>
              <SwitchForm
                key={_.size(dataSource)}
                name="comingSoon"
                size="small"
                defaultChecked={_.get(dataSource, 'comingSoon')}
                form={form}
              />
            </SwitchWrapper>
          </Col> */}
        </>
      )}
      {children}
    </Row>
  );
};
