import { Col, Form, Row } from "antd";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { Media } from "../../../../atom/media/media.type";
import { InputForm, UploadForm } from "../../../../components/form";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { STORAGE_PATH } from "../../../../constants/default-value.const";
import { storagePath } from "../../../../utils/firebase.utils";
import { useTranslation } from "react-i18next";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

interface MediaFormProps {
  dataSource: any;
  handleCancel: () => void;
  handleOk: (value: Media) => void;
}
export const MediaForm: React.FC<MediaFormProps> = ({ dataSource, handleCancel, handleOk }) => {
  const [form] = Form.useForm();
  useEnterEvent({ form });
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    if (_.size(dataSource))
      form.setFieldsValue({
        ...dataSource,
        media: dataSource.extension
      });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="MediaForm" form={form} onFinish={onFinish}>
        <FormModalHeader title={t("Icon")} handleCancel={handleCancel} />
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ display: "none" }}>
            <InputForm name="id" placeholder="ID" />
          </Col>
          <Col span={24}>
            <InputForm
              name="title"
              placeholder={t("Title")}
              rules={[
                {
                  required: true,
                  message: "Please input the title!"
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <UploadForm
              form={form}
              name="media"
              imageUrl={
                _.get(dataSource, "extension")
                  ? storagePath(
                      `${STORAGE_PATH.MEDIA_ICON}/${dataSource.name}.${dataSource.extension}`
                    )
                  : ""
              }
              required={true}
              rules={[{ required: true, message: "Profile is required" }]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
