import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getPracticeTestAsync } from "../../../../atom/practice-test/practice-test.apis";
import {
  practiceTestLoadingState,
  practiceTestState
} from "../../../../atom/practice-test/practice-test.atoms";
import { practiceTestSelector } from "../../../../atom/practice-test/practice-test.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const usePracticeTest = ({ id, forceRender }: UseHookProps) => {
  const practiceTest = useRecoilValue(practiceTestSelector);
  const setPracticeTest = useSetRecoilState(practiceTestState);
  const [loading, setLoading] = useRecoilState(practiceTestLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getPracticeTestAsync(id)
        .then(res => {
          setPracticeTest(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }
    return () => {
      setPracticeTest({});
    };
  }, [id, setPracticeTest, forceRender, setLoading]);

  return {
    practiceTest,
    loading,
    setLoading,
    setPracticeTest
  };
};
