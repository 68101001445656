import { Button, Col, Progress, Row, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useApp } from '../../../../atom/app/app.hook';
import {
  addVimeoVideoToFolderAsync,
  // addVimeoVideoToFolderAsync,
  createBunnyVideoAsync,
  createVideoAsync,
  deleteBunnyVideoAsync,
  getBunnyFoldersAsync,
  getBunnyVideosByFolderAsync,
  getVimeoFoldersAsync,
  getVimeoVideosByFolderAsync,
  requestUrlUploadVimeoAsync,
  updateBunnyVideoAsync,
  updateVideoAsync,
  uploadBunnyVideoAsync,
} from '../../../../atom/library/video.apis';
import {
  UploadForm,
  TextEditorForm,
  SelectForm,
  InputForm,
} from '../../../../components/form';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import { VideoPreview } from '../../../../components/video-preview/video-preview.component';
import { FORM_TYPE } from '../../../../constants/default-value.const';
import { ImagePaths } from '../../../../constants/images.const';
import useRoute from '../../../../hooks/use-route.hook';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import {
  messageError,
  messageSuccess,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { useLibrary } from '../../library/library.hook';
import { FormModalProps } from '../../library/library.types';
import { useTranslation } from 'react-i18next';
import {
  getVimeoVideoId,
  parseJsonLocalStorage,
  requestTopicDefault,
  setSelectFilter,
} from '../../../../utils/helper.utils';
import * as tus from 'tus-js-client';
import { checkFormRedirect } from '../../../../utils/form.utils';
import {
  ApiOutlined,
  ApiTwoTone,
  InteractionTwoTone,
  PlayCircleOutlined,
  PlayCircleTwoTone,
  ReloadOutlined,
  ThunderboltTwoTone,
} from '@ant-design/icons';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';
import { WrapUpload } from './video-form.styles';
import { YoutubeVideoPreview } from './youtube-video-preview.component';

enum LocalStorageKey {
  VIMEO_FOLDERS = 'VIMEO_FOLDERS',
  BUNNY_FOLDERS = 'BUNNY_FOLDERS',
  BUNNY_VIDEOS = 'BUNNY_VIDEOS',
  VIMEO_VIDEOS = 'VIMEO_VIDEOS',
}

enum PreviewType {
  VIMEO = 'VIMEO',
  BUNNY = 'BUNNY',
}

export const VideoForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false,
}: FormModalProps) => {
  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const [youtubeId, setYoutubeId] = useState('');
  const [visibleYoutube, setVisibleYoutube] = useState<boolean>(false);
  const [vimeoFolders, setVimeoFolders] = useState([]);
  const [bunnyFolders, setBunnyFolders] = useState([]);
  const [vimeoVideos, setVimeoVideos] = useState([]);
  const [bunnyVideos, setBunnyVideos] = useState([]);
  // const videoOptions = useRecoilValue(videoOptionsSelector);
  const [progress, setProgress] = useState(0);
  const [vimeoPhotoUrl, setVimeoPhotoUrl] = useState('');
  const [bunnyPhotoUrl, setBunnyPhotoUrl] = useState('');
  const [forceRender, setForceRender] = useState(false);
  const { levels, subjects, topics } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
  });
  const [previewType, setPreviewType] = useState('');

  const { loading, library } = useLibrary({
    id: formModal ? formId : id,
    forceRender,
  });
  const { setAppLoading, appFilter, setAppFilter } = useApp();
  const { t } = useTranslation();
  const [bunnyReload, setBunnyReload] = useState(0);
  const [vimeoReload, setVimeoReload] = useState(0);
  const [vimeoVideoId, setVimeoVideoId] = useState('');
  const [bunnyVideoId, setBunnyVideoId] = useState('');

  useEnterEvent({ form });

  // useEffect(() => {
  //   if (_.isNil(defaultFilter)) {
  //     return () => {
  //       setAppFilter({});
  //     };
  //   }
  // }, [setAppFilter, defaultFilter]);

  // useEffect(() => {
  //   if (!localStorage.getItem('videos')) {
  //     getVideoForSelectOptionAsync()
  //       .then((res) => {
  //         // console.log(res);
  //         localStorage.setItem('videos', JSON.stringify(res));
  //         setVideoOptions(res);
  //       })
  //       .catch((err) => {
  //         showMessageErrors(err);
  //       });
  //   } else {
  //     setVideoOptions(JSON.parse(localStorage.getItem('videos') as string));
  //   }
  // }, [setVideoOptions]);

  useEffect(() => {
    if (!localStorage.getItem(LocalStorageKey.VIMEO_FOLDERS)) {
      getVimeoFoldersAsync()
        .then((res) => {
          localStorage.setItem(
            LocalStorageKey.VIMEO_FOLDERS,
            JSON.stringify(res)
          );
          setVimeoFolders(res as any);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    } else {
      setVimeoFolders(parseJsonLocalStorage(LocalStorageKey.VIMEO_FOLDERS));
    }
  }, [setVimeoFolders, vimeoReload]);

  useEffect(() => {
    if (!localStorage.getItem(LocalStorageKey.BUNNY_FOLDERS)) {
      getBunnyFoldersAsync()
        .then((res) => {
          localStorage.setItem(
            LocalStorageKey.BUNNY_FOLDERS,
            JSON.stringify(res)
          );
          setBunnyFolders(res as any);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    } else {
      setBunnyFolders(parseJsonLocalStorage(LocalStorageKey.BUNNY_FOLDERS));
    }
  }, [setBunnyFolders, bunnyReload]);

  // useEffect(() => {
  //   if (_.size(library)) {
  //     const videoId = _.get(library, "data.url", "").replace("https://vimeo.com/", "");
  //     if (videoId) requestVideoVimeo(_.get(videoId.split("/"), "0"));
  //   }
  // }, [library]);

  useEffect(() => {
    const vimeoFolderId = _.get(library, 'data.vimeoFolderId');
    const bunnyFolderId = _.get(library, 'data.bunnyFolderId');
    form.setFieldsValue({
      ...library,
      topicId: _.get(library, 'topic.id'),
      subjectId: _.get(library, 'subject.id'),
      levelId: _.get(library, 'level.id'),
      description: _.get(library, 'data.description'),
      vimeoVideoId: getVimeoVideoId(_.get(library, 'data.url', '')),
      bunnyVideoId: _.get(library, 'data.bunnyId'),
      vimeoVideoOptionId: getVimeoVideoId(_.get(library, 'data.url', '')),
      bunnyVideoOptionId: _.get(library, 'data.bunnyId'),
      vimeoFolderId: _.get(library, 'data.vimeoFolderId'),
      bunnyFolderId: _.get(library, 'data.bunnyFolderId'),
      youtubeId: _.get(library, 'data.youtubeId'),
    });

    setVimeoVideoId(getVimeoVideoId(_.get(library, 'data.url', '')));
    setBunnyVideoId(_.get(library, 'data.bunnyId'));
    setYoutubeId(_.get(library, 'data.youtubeId'));
    setSelectFilter({
      dataSource: library,
      defaultFilter,
      form,
      elementId: 'videoRequest',
    });

    if (vimeoFolderId) {
      getVimeoVidoes(vimeoFolderId);
    }

    if (bunnyFolderId) {
      getBunnyVideos(bunnyFolderId);
    }
  }, [library, form, defaultFilter]);

  const getBunnyVideos = (bunnyFolderId: string) => {
    const data = parseJsonLocalStorage(LocalStorageKey.BUNNY_VIDEOS);
    if (data[bunnyFolderId]) {
      setBunnyVideos(data[bunnyFolderId]);
    } else
      getBunnyVideosByFolderAsync(bunnyFolderId)
        .then((res) => {
          localStorage.setItem(
            LocalStorageKey.BUNNY_VIDEOS,
            JSON.stringify({ ...data, [bunnyFolderId]: res })
          );
          setBunnyVideos(res as any);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
  };

  const getVimeoVidoes = (vimeoFolderId: string) => {
    const data = parseJsonLocalStorage(LocalStorageKey.VIMEO_VIDEOS);
    if (data[vimeoFolderId]) {
      setVimeoVideos(data[vimeoFolderId]);
    } else {
      getVimeoVideosByFolderAsync(vimeoFolderId)
        .then((res) => {
          localStorage.setItem(
            LocalStorageKey.VIMEO_VIDEOS,
            JSON.stringify({ ...data, [vimeoFolderId]: res })
          );
          setVimeoVideos(res as any);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    }
  };

  // const requestVideoVimeo = (videoId: string) => {
  //   getVimeoAsync(videoId)
  //     .then(res => {
  //       setVideoUrl(_.get(res, "files[0].link", ""));
  //       setPhotoUrl(_.get(res, "pictures.sizes[0].link", ""));
  //     })
  //     .catch(err => {
  //       showMessageErrors(err);
  //     });
  // };

  const uploadVideoTusJs = (
    uploadUrl: string,
    videoFile: any,
    cb: () => void
  ) => {
    var upload: any = new tus.Upload(videoFile, {
      uploadUrl: uploadUrl,
      // metadata: {
      //   name: "kaka__xs", // not working
      //   description: "Test" // not working
      // },
      onError: (error) => {
        console.log('Failed because: ' + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + '%');
        setProgress(+percentage);
      },
      onSuccess: () => {
        cb();
        console.log('Download %s from %s', upload.file.name, upload.url);
      },
      onAfterResponse: (req, res) => {
        var url = req.getURL();
        var value = res.getHeader('X-My-Header');
        console.log(`Request for ${url} responded with ${value}`);
        // uploadVideoTusJs(uploadUrl, videoFile, cb);
      },
    });

    // Start the upload by default
    upload.start();
  };

  // const onChangeVideo = (value: string) => {
  //   if (value) {
  //     // const videoId = value.replace("https://vimeo.com/", "");
  //     // if (videoId) requestVideoVimeo(_.get(videoId.split("/"), "0"));
  //     // setVideoUrl(value);
  //   }
  // };

  const onChangeVimeoFolder = (value: string) => {
    if (value) {
      getVimeoVidoes(value);
    } else {
      setVimeoVideos([]);
    }
  };

  const onChangeBunnyFolder = (value: string) => {
    if (value) {
      getBunnyVideos(value);
    } else {
      setBunnyVideos([]);
    }
  };

  const onFinish = async (value: any) => {
    setAppLoading(true);
    // const videoTitle = `${value.code}-${value.title}`;

    let tmpBunnyVideoId = _.get(value, 'bunnyVideoOptionId', '');
    if (
      _.get(value, 'bunnyVideoId.file') &&
      !_.get(value, 'bunnyVideoOptionId') &&
      !youtubeId
    ) {
      const { guid } = await createBunnyVideoAsync({
        title: _.get(value, 'bunnyVideoId.file.name'),
        collectionId: value.bunnyFolderId || undefined,
      });

      try {
        await uploadBunnyVideoAsync(guid, _.get(value, 'bunnyVideoId.file'));
        localStorage.removeItem(LocalStorageKey.BUNNY_VIDEOS);
        tmpBunnyVideoId = guid;
      } catch (err) {
        await deleteBunnyVideoAsync(guid);
      }
    }

    const data: any = {
      subjectId: value.subjectId,
      levelId: value.levelId,
      topicId: value.topicId,
      title: value.title,
      code: value.code,
      data: {
        url: value.vimeoVideoOptionId || '',
        description: value.description || '',
        bunnyId: tmpBunnyVideoId || '',
        bunnyFolderId: value.bunnyFolderId || '',
        vimeoFolderId: value.vimeoFolderId || '',
        youtubeId: youtubeId || '',
      },
    };

    if (
      _.get(value, 'vimeoVideoId.file') &&
      !_.get(value, 'vimeoVideoOptionId') &&
      !youtubeId
    ) {
      try {
        let vimeoData = {
          upload: {
            approach: 'tus',
            size: value.vimeoVideoId.file.size,
          },
          name: _.get(value, 'vimeoVideoId.file.name'),
          // description: "My Video Desc",
          privacy: {
            view: 'users',
            embed: 'private',
            download: false,
          },
        };

        const vimeoResponse = await requestUrlUploadVimeoAsync(vimeoData);
        uploadVideoTusJs(
          vimeoResponse.upload.upload_link,
          value.vimeoVideoId.file,
          () => {
            const vimeoId = vimeoResponse.uri.split('/videos/')[1];
            data.data.url = vimeoId;
            let request =
              formType === FORM_TYPE.EDIT
                ? updateVideoAsync(id, data)
                : createVideoAsync(data);
            request
              .then(async (res) => {
                messageSuccess();
                setAppLoading(false);

                if (value.vimeoFolderId) {
                  await addVimeoVideoToFolderAsync(
                    value.vimeoFolderId,
                    vimeoId
                  );
                }

                localStorage.removeItem(LocalStorageKey.VIMEO_VIDEOS);

                let check = checkFormRedirect({
                  history,
                  pathname,
                  id: res.data.id,
                  formType,
                  isBack,
                });
                if (check) setForceRender(!forceRender);
              })
              .catch((err) => {
                showMessageErrors(err);
                setAppLoading(false);
              });
          }
        );

        return;
      } catch (err) {
        showMessageErrors(err);
        setAppLoading(false);
        return;
      }
    }

    // else {
    //   _.set(
    //     data,
    //     'data.url',
    //     _.get(value, 'vimeoVideoOptionId') || value.vimeoVideoId || ''
    //   );

    //   _.set(data, 'data.vimeoFolderId', _.get(value, 'vimeoFolderId', ''));
    //   _.set(data, 'data.bunnyFolderId', _.get(value, 'bunnyFolderId', ''));

    //   _.set(
    //     data,
    //     'data.bunnyId',
    //     _.get(value, 'bunnyVideoOptionId') || value.bunnyVideoId || ''
    //   );
    // }

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateVideoAsync(formId, data)
          : createVideoAsync(data);
      request
        .then((res) => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch((err) => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateVideoAsync(id, data)
        : createVideoAsync(data);
    request
      .then((res) => {
        messageSuccess();
        setAppLoading(false);
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  // const reloadVimeoFolders = () => {
  //   setAppLoading(true);
  //   localStorage.removeItem('vimeoFolders');
  //   setVideoOptions([]);
  //   getVideoForSelectOptionAsync()
  //     .then((res) => {
  //       localStorage.setItem('videos', JSON.stringify(res));
  //       setVideoOptions(res);
  //       setAppLoading(false);
  //     })
  //     .catch((err) => {
  //       showMessageErrors(err);
  //       setAppLoading(false);
  //     });
  // };

  const onGetVimeoData = ({ photoUrl }: { photoUrl: string }) => {
    setVimeoPhotoUrl(photoUrl);
  };

  const onGetBunnyData = ({ photoUrl }: { photoUrl: string }) => {
    setBunnyPhotoUrl(photoUrl);
  };

  // const handleVimeoVideoUpload = () => {
  //   console.log('handleVideoUpload');
  // };

  // const handleBunnyVideoUpload = () => {
  //   console.log('handleBunnyVideoUpload');
  // };

  const handlePreviewVimeoVideoUpload = () => {
    setPreviewType(PreviewType.VIMEO);
  };

  const handlePreviewBunnyVideoUpload = () => {
    setPreviewType(PreviewType.BUNNY);
  };

  const handleTransterFolderBunnyVideo = () => {
    const bunnyFolderId = form.getFieldValue('bunnyFolderId');
    // const title = form.getFieldValue('title');
    // const code = form.getFieldValue('code');
    const bunnyVideoOptionId = form.getFieldValue('bunnyVideoOptionId');
    if (!bunnyFolderId || !bunnyVideoOptionId) {
      messageError('Folder andy video is required');
      return;
    }

    updateBunnyVideoAsync(bunnyVideoOptionId, {
      // title: `${code}-${title}`,
      collectionId: bunnyFolderId,
    })
      .then(() => {
        messageSuccess();
        localStorage.removeItem(LocalStorageKey.BUNNY_VIDEOS);
      })
      .catch((err) => {
        // console.log(err);
        showMessageErrors(err);
      });
  };

  const handleTransterFolderVimeoVideo = () => {
    const vimeoFolderId = form.getFieldValue('vimeoFolderId');
    const vimeoVideoOptionId = form.getFieldValue('vimeoVideoOptionId');
    if (!vimeoFolderId || !vimeoVideoOptionId) {
      messageError('Folder andy video is required');
      return;
    }

    addVimeoVideoToFolderAsync(vimeoFolderId, vimeoVideoOptionId)
      .then(() => {
        messageSuccess();
        localStorage.removeItem(LocalStorageKey.VIMEO_VIDEOS);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleReloadBunnies = () => {
    localStorage.removeItem(LocalStorageKey.BUNNY_FOLDERS);
    localStorage.removeItem(LocalStorageKey.BUNNY_VIDEOS);
    setBunnyReload((state) => state + 1);
  };

  const handleReloadVimeos = () => {
    localStorage.removeItem(LocalStorageKey.VIMEO_FOLDERS);
    localStorage.removeItem(LocalStorageKey.VIMEO_VIDEOS);
    setVimeoReload((state) => state + 1);
  };

  const onChangeBunnyVideo = (value: string) => {
    const tmp = value || '';
    setBunnyVideoId(tmp);
    setPreviewType(tmp ? PreviewType.BUNNY : '');
  };

  const onChangeVimeoVideo = (value: string) => {
    const tmp = value || '';
    setVimeoVideoId(tmp);
    setPreviewType(tmp ? PreviewType.VIMEO : '');
  };

  return (
    <Spin spinning={loading}>
      <div
        onClick={() =>
          requestTopicDefault({
            dataSource: library,
            defaultFilter,
            appFilter,
            setAppFilter,
            filterKey: 'video',
          })
        }
        id='videoRequest'
      />
      <FormWrapper form={form} name='competencyForm' onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <FormInfoSection
              levels={levels}
              topics={topics}
              subjects={subjects}
              filterKey='video'
            />
          </Col>
          <Col span={16}>
            <Row>
              <Col span={24}>
                <TitleItem title={t('Preview')} />

                {/* Vimeo */}
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      <SelectForm
                        name='vimeoFolderId'
                        placeholder={t('Select Vimeo Folder')}
                        onChange={onChangeVimeoFolder}
                        dataSource={vimeoFolders}
                      />
                    </Col>
                    <Col span={8}>
                      <SelectForm
                        name='vimeoVideoOptionId'
                        placeholder={t('Select Vimeo Video')}
                        onChange={onChangeVimeoVideo}
                        dataSource={vimeoVideos}
                      />
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <WrapUpload>
                        <UploadForm
                          form={form}
                          // label="Vimeo Upload"
                          name='vimeoVideoId'
                          accept={'video/*'}
                          callbackUrl={(url: string) => {
                            setVimeoVideoId(url);
                            setPreviewType(PreviewType.VIMEO);
                          }}
                          imageUrl={
                            vimeoPhotoUrl ? vimeoPhotoUrl : ImagePaths.ICON_VIDEO
                          }
                          required={true}
                          rules={[
                            {
                              required:
                                vimeoVideoId || bunnyVideoId || youtubeId
                                  ? false
                                  : true,
                              message: 'required',
                            },
                          ]}
                          isIcon={true}
                        />
                      </WrapUpload>
                      <Tooltip title="Retrive">
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={handleReloadVimeos}
                          icon={<ThunderboltTwoTone />}
                        />
                      </Tooltip>
                      <Tooltip title="Preview">
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={handlePreviewVimeoVideoUpload}
                          icon={<PlayCircleTwoTone />}
                        />
                      </Tooltip>
                      <Tooltip title="Relocate">
                        <Button
                          onClick={handleTransterFolderVimeoVideo}
                          icon={<ApiTwoTone />}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                  
                {/* Bunny */}  
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      <SelectForm
                        name='bunnyFolderId'
                        placeholder={t('Select Bunny Folder')}
                        onChange={onChangeBunnyFolder}
                        dataSource={bunnyFolders}
                      />
                    </Col>
                    <Col span={8}>
                      <SelectForm
                        name='bunnyVideoOptionId'
                        placeholder={t('Select Bunny Video')}
                        onChange={onChangeBunnyVideo}
                        dataSource={bunnyVideos}
                      />
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <WrapUpload>
                        <UploadForm
                          form={form}
                          name='bunnyVideoId'
                          accept={'video/*'}
                          callbackUrl={(url: string) => {
                            setBunnyVideoId(url);
                            setPreviewType(PreviewType.BUNNY);
                          }}
                          imageUrl={
                            bunnyPhotoUrl ? bunnyPhotoUrl : ImagePaths.ICON_VIDEO
                          }
                          required={true}
                          rules={[
                            {
                              required:
                                vimeoVideoId || bunnyVideoId || youtubeId
                                  ? false
                                  : true,
                              message: 'required',
                            },
                          ]}
                          isIcon={true}
                        />
                      </WrapUpload>

                      <Tooltip title="Retrive">
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={handleReloadBunnies}
                          icon={<ThunderboltTwoTone />}
                        />
                      </Tooltip>
                      
                      <Tooltip title="Preview">
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={handlePreviewBunnyVideoUpload}
                          icon={<PlayCircleTwoTone />}
                        />
                      </Tooltip>
                      <Tooltip title="Relocate">
                        <Button
                          onClick={handleTransterFolderBunnyVideo}
                          icon={<ApiTwoTone />}
                        />
                      </Tooltip>
                    </Col>
                  </Row>

                {/* YouTube */}
                  <Row gutter={[24, 24]}>
                    <Col span={16}>
                      <YoutubeVideoPreview
                        key={`visibleYoutube-${visibleYoutube}`}
                        visible={visibleYoutube}
                        onOk={() => setVisibleYoutube(false)}
                        onCancel={() => setVisibleYoutube(false)}
                        videoId={youtubeId}
                      />
                      <InputForm
                        name='youtubeId'
                        placeholder={t('Youtube ID')}
                        onChange={(event) => setYoutubeId(event.target.value)}
                        rules={[
                          {
                            required:
                              vimeoVideoId || bunnyVideoId || youtubeId
                                ? false
                                : true,
                            message: 'required',
                          },
                        ]}
                        addonAfter={
                          <PlayCircleTwoTone
                            onClick={() => setVisibleYoutube(true)}
                          />
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={[24,24]}>
                    <Col span={24}>
                      {/* Upload Progress and Preview */}
                      {progress !== 0 && <Progress percent={progress} />}
                      {previewType}
                      {previewType === PreviewType.VIMEO && (
                        <VideoPreview
                          vimeoId={vimeoVideoId || ''}
                          onGetData={onGetVimeoData}
                        />
                      )}
                      {previewType === PreviewType.BUNNY && (
                        <VideoPreview
                          bunnyId={bunnyVideoId || ''}
                          onGetData={onGetBunnyData}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[24,24]}>
                    <Col span={24}>                                
                      <TitleItem title={t('Description')} />
                      <TextEditorForm
                        name='description'
                        form={form}
                        value={_.get(library, 'data.description', '')}
                        rules={[{ required: false }]}
                      />
                    </Col>
                  </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </FormWrapper>
    </Spin>
  );
};
