import produce from "immer";
import _ from "lodash";
import { selector } from "recoil";
import { SECTION_TYPE } from "../../constants/default-value.const";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate, isSyncable } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { assignIndexKey, mapActivities, mapQuestions } from "../../utils/response.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { mapCompetencies } from "../competency-test/competency-test.selectors";
import { mapLearnTest } from "../learn/learn.selectors";
import { coursesState, courseMetaState, courseState } from "./course.atoms";

export const coursesSelector = selector({
  key: "coursesSelector",
  get: ({ get }) => {
    const courses = produce(get(coursesState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.COURSE);
    return courses.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss"),
        ...permissions
      };
    });
  }
});

const mapSections = (sections: any[]) => {
  const formatSections: any[] = [];
  sections.forEach((section: any, i: number) => {
    const { id, courseSectionSources } = section;

    courseSectionSources.forEach((sectionSource: any) => {
      const {
        id: sectionId,
        rank,
        sourceAbleId,
        sourceAbleType,
        sourceAble: {
          code,
          title,
          testCompetencyCompetencies,
          testPracticeLibraries,
          learnActivities,
          resourceActivities
        }
      } = sectionSource;

      let dataSources: any = [];
      if (sourceAbleType === SECTION_TYPE.COMPETENCY_TEST) {
        dataSources = mapCompetencies({
          dataSources: testCompetencyCompetencies,
          childKey: ["libraries", "libraries"],
          filterKey: SECTION_TYPE.COMPETENCY_TEST,
          initIndexKey: [i]
        });
      } else if (sourceAbleType === SECTION_TYPE.PRACTICE_TEST) {
        dataSources = mapQuestions({
          dataSources: testPracticeLibraries,
          childKey: ["libraries"],
          filterKey: SECTION_TYPE.PRACTICE_TEST,
          initIndexKey: [i]
        });
      } else if (sourceAbleType === SECTION_TYPE.LEARN) {
        dataSources = mapActivities({
          dataSources: learnActivities,
          key: "learnActivityLibraries",
          childKey: ["libraries", "libraries"],
          filterKey: SECTION_TYPE.LEARN
        });
        dataSources = [
          ...dataSources,
          ...mapLearnTest(
            sectionSource.sourceAble,
            ["libraries", "libraries"],
            ["libraries", "children"]
          )
        ];
      } else if (sourceAbleType === SECTION_TYPE.RESOURCE) {
        dataSources = mapActivities({
          dataSources: resourceActivities,
          key: "resourceActivityLibraries",
          childKey: ["libraries", "libraries"],
          filterKey: SECTION_TYPE.RESOURCE
        });
      }

      formatSections.push({
        id,
        sectionId,
        rank,
        sourceAbleId,
        sourceAbleType,
        filterKey: sourceAbleType,
        code,
        title,
        dataSources: dataSources
      });
    });
  });

  return formatSections;
};

export const courseSelector = selector({
  key: "courseSelector",
  get: ({ get }) => {
    const course = produce(get(courseState), draft => draft);
    const sections = _.get(course, "courseSections", []);
    return {
      ...course,
      sections: assignIndexKey({
        dataSources: mapSections(sections),
        childKey: ["dataSources", "libraries", "libraries", "children"]
      })
    };
  }
});

export const courseMetaSelector = selector({
  key: "courseMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(courseMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
