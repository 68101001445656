import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { LNG } from "./constants/default-value.const";
import RouteComponent from "./routes/route.component";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lng") || LNG.KM);
  }, [i18n]);

  // Edit

  return (
    <>
      <RecoilRoot>
        <BrowserRouter>
          <Switch>
            <RouteComponent />
          </Switch>
        </BrowserRouter>
      </RecoilRoot>
    </>
  );
};

export default App;
