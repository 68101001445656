import { Row, Col , List, Card, Avatar, Popover } from "antd"

export const CalendarData = () => {
    const content = (
        <div>
          <p>User Activity: 350</p>
          <p>User Session: 350</p>
        </div>
      );

    return (
        <>
        <Row gutter={[12, 12]}>
            {plusData.map((item, index) => {
                return (
                    <Col span={3} key={index}>
                        <Popover content={content}>
                            <Avatar shape="square" style={{ backgroundColor: '#a1dad073' }} />
                        </Popover>
                    </Col>
                );
            })}
        </Row><Row gutter={[12, 12]} style={{ marginTop: '8px' }}>
                {dataLabel.map((item, index) => {
                    return (
                        <Col span={3} key={index}>
                            <Avatar shape="square" style={{ backgroundColor: '#00a98c63' }}>
                                {item.label}
                            </Avatar>
                        </Col>
                    );
                })}
            </Row>
            </>
    );
}


const dataWidget = [{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8}]
const plusData = [...dataWidget, ...dataWidget, ...dataWidget, ...dataWidget, ...dataWidget];
const dataLabel = [{label:"Mon"},{label:"Tue"},{label:"Wed"},{label:"Thu"},{label:"Fri"},{label:"Sat"},{label:"Sun"},{label:"Sun"}];