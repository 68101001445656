import { SmileOutlined } from "@ant-design/icons";
import { Form, FormInstance, notification } from "antd";
import React from "react";

interface FormWrapperProps {
  name: string;
  layout?: any;
  children: any;
  form?: FormInstance;
  onFinish?: (values: any) => void;
  onFinishFailed?: (values: any) => void;
}

export const FormWrapper: React.FC<FormWrapperProps> = ({
  name,
  layout,
  children,
  onFinish,
  onFinishFailed,
  form
}) => {
  const _onFinishFailed = (value: any) => {
    if (onFinishFailed) onFinishFailed(value);
    const messages = value.errorFields.map((item: any, i: number) => {
      return (
        <p key={i} style={{ color: "red" }}>
          {item.errors.toString()}
        </p>
      );
    });

    notification.open({
      duration: 2,
      message: "Validation Fail",
      description: messages,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />
    });
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={_onFinishFailed}
      layout={layout || "vertical"}
      name={name}
    >
      {children}
    </Form>
  );
};
