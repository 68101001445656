import { Col, Form, Row } from 'antd';
import _ from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { Subject } from '../../../../atom/subject/subject.type';
import {
  InputForm,
  InputNumberForm,
  SelectForm,
  TextEditorForm,
} from '../../../../components/form';
import { FormModalHeader } from '../../../../components/form-modal-header/form-modal-header.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { OptionType } from '../../../../components/form/select/select.type';
import { useTranslation } from 'react-i18next';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';
import { ColorPickerForm } from '../../../../components/form/color-picker/color-picker.component';

interface SubjectFormProps {
  dataSource?: any;
  handleCancel?: () => void;
  handleOk: (value: Subject) => void;
  colors: OptionType[];
  medias: OptionType[];
}
export const SubjectForm: React.FC<SubjectFormProps> = ({
  dataSource,
  handleCancel,
  handleOk,
  colors,
  medias,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  useEnterEvent({ form });

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({ ...dataSource });
    form.setFieldsValue({
      mediaId: _.get(dataSource, 'icon.id'),
      color: _.get(dataSource, 'color') || _.get(dataSource, 'colorCode.code'),
    });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="levelForm" form={form} onFinish={onFinish}>
        <FormModalHeader title={t('Subject')} handleCancel={handleCancel} />
        <InputForm name="id" placeholder="ID" className="d-none" />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputForm
              name="title"
              placeholder={t('Title')}
              rules={[
                {
                  required: true,
                  message: 'Please input the title!',
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <InputForm
              name="code"
              placeholder={t('Code')}
              rules={[
                {
                  required: true,
                  message: 'Please input the code!',
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputNumberForm
              name="rank"
              placeholder={t('Rank')}
              rules={[
                {
                  required: true,
                  message: 'Please input the rank!',
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <SelectForm
              name="mediaId"
              placeholder={t('Icon')}
              rules={[
                {
                  required: true,
                  message: 'Please select the icon!',
                },
              ]}
              dataSource={medias}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <ColorPickerForm
              name="color"
              form={form}
              placeholder={t('Color')}
              dataSource={colors.map((item) => item.name)}
              rules={[
                {
                  required: true,
                  message: 'Please input the color!',
                },
              ]}
            />
          </Col>
          
        </Row>
        <Row>
          <Col>
            <TextEditorForm
              value={_.get(dataSource, `description`)}
              name="description"
              form={form}
              rules={[
                {
                  required: false,
                  message: 'Please input the description!',
                },
              ]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
