import { ClearOutlined } from "@ant-design/icons";
import React from "react";
import { FlexBox } from "./title-item.styles";

interface TitleItemProps {
  title: string;
  onClear?: () => void;
}
export const TitleItem: React.FC<TitleItemProps> = ({ title, onClear }) => {
  return (
    <FlexBox>
      <h2 style={{ fontWeight: "bold" }}>{title}</h2>
      {onClear && <ClearOutlined onClick={onClear} />}
    </FlexBox>
  );
};
