import { Modal } from "antd";
import { PropsType } from "./modal.type";

export const ModalWrapper: React.FC<PropsType> = ({ title, onCancel, onOk, visible, children }) => {
  return (
    <Modal
      key={`key-${visible}`}
      title={title || null}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      maskClosable={false}
      closable={false}
      footer={null}
    >
      {children}
    </Modal>
  );
};
