import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getCompetencyTestAsync } from "../../../../atom/competency-test/competency-test.apis";
import {
  competencyTestLoadingState,
  competencyTestState
} from "../../../../atom/competency-test/competency-test.atoms";
import { competencyTestSelector } from "../../../../atom/competency-test/competency-test.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const useCompetencyTest = ({ id, forceRender }: UseHookProps) => {
  const setCompetencyTest = useSetRecoilState(competencyTestState);
  const competencyTest = useRecoilValue(competencyTestSelector);
  const [loading, setLoading] = useRecoilState(competencyTestLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getCompetencyTestAsync(id)
        .then(res => {
          setCompetencyTest(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }
    return () => {
      setCompetencyTest({});
    };
  }, [id, setCompetencyTest, setLoading, forceRender]);

  return {
    competencyTest,
    loading,
    setLoading,
    setCompetencyTest
  };
};
