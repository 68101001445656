import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { v4 as uuid } from 'uuid';
import { ButtonAddBrowse } from '../../../../components/button-add-browse/button-add-browse.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { SortableItems } from '../../../../components/sortable-items/sortable-items.component';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import { FormActivityModal } from '../../../../components/form-activity-modal/form-activity-modal.component';
import { LibraryModal } from '../../../elements/library/library-modal.component';
import useRoute from '../../../../hooks/use-route.hook';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import { useLearn } from './learn.hook';
import {
  FILTER_SECTION_TYPES,
  FORM_TYPE,
  SECTION_TYPE,
} from '../../../../constants/default-value.const';
import {
  createLearnAsync,
  deleteLearnSourceAsync,
  deleteLearnSourceLibraryAsync,
  updateLearnAsync,
} from '../../../../atom/learn/learn.apis';
import _ from 'lodash';
import {
  messageError,
  messageSuccess,
  messageWarn,
  showDeleteConfirm,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import {
  Activity,
  FormActivityType,
  LooseObject,
  Question,
} from '../../../../types/global.types';
import { LibraryFormModal } from '../../../elements/library/library-form-modal.component';
import { formatActivities } from '../../../../utils/request.utils';
import produce from 'immer';
import { TextEditorForm } from '../../../../components/form';
import {
  exclude,
  requestTopicDefault,
  setSelectFilter,
  toNewRecordActivities,
} from '../../../../utils/helper.utils';
import { FormModalProps } from '../../../elements/library/library.types';
import { SectionModal } from '../../course/components/section-modal.component';
import { SectionFormModal } from '../../course/components/section-form-modal.component';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import { checkFormRedirect } from '../../../../utils/form.utils';
import { assignIndexKey, mapQuestions } from '../../../../utils/response.utils';
import { CompetencyFormModal } from '../../../elements/competency/components/competency-form-modal.component';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';

enum MODAL_TYPE {
  ACTIVITY_FROM = 'ACTIVITY_FROM',
  LIBRARY_ACTIVITY = 'LIBRARY_ACTIVITY',
  LIBRARY_ACTIVITY_FORM = 'LIBRARY_ACTIVITY_FORM',
  LIBRARY_ACTIVITY_FORM_EDIT = 'LIBRARY_ACTIVITY_FORM_EDIT',
  TEST_CREATE = 'TEST_CREATE',
  TEST_EDIT = 'TEST_EDIT',
  TEST_LIST = 'TEST_LIST',
  COMPETENCY_EDIT = 'COMPETENCY_EDIT',
}

type Test = any;

export const LearnForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack,
}: FormModalProps) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [tests, setTests] = useState<any[]>([]);
  const [sectionType, setSectionType] = useState('');
  const [libraryType, setLibraryType] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [modalType, setModalType] = useState('');
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const [forceRender, setForceRender] = useState(false);
  useEnterEvent({ form, enter: !modalType });

  const { t } = useTranslation();

  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, topics, colors } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
    hasColor: true,
  });

  const { setAppLoading, appFilter, setAppFilter } = useApp();
  const { loading, learn, setLearn } = useLearn({
    id: formModal ? formId : id,
    forceRender,
  });


  useEffect(() => {
    if (_.isNil(defaultFilter)) {
      return () => {
        setAppFilter({});
      };
    }
  }, [setAppFilter, defaultFilter]);

  useEffect(() => {
    setLearn({});
  }, [setLearn]);

  useEffect(() => {
    const levelId = _.get(learn, 'level.id');
    const subjectId = _.get(learn, 'subject.id');
    form.resetFields();
    form.setFieldsValue({
      ...learn,
      topicId: _.get(learn, 'topic.id'),
      color: _.get(learn, 'color') || _.get(learn, 'colorCode.code'),
      subjectId,
      levelId,
    });

    setActivities(
      toNewRecordActivities(
        _.get(learn, `activities`, []),
        formType === FORM_TYPE.DUPLICATE,
        'activities'
      )
    );

    setTests(
      toNewRecordActivities(
        _.get(learn, `tests`, []),
        formType === FORM_TYPE.DUPLICATE,
        'libraries'
      )
    );
    setSelectFilter({
      dataSource: learn,
      defaultFilter,
      form,
      elementId: 'learnRequest',
    });
  }, [learn, form, defaultFilter, formType]);

  const onFinish = (value: any) => {
    const sources = [
      ...formatActivities(activities, ['learnActivityLibraries', 'activities']),
    ];

    // if (!sources.length && !tests.length) {
    //   messageWarn("Activities or test is required");
    //   return false;
    // }

    const data: any = {
      code: value.code,
      color: value.color,
      comingSoon: value.comingSoon ? true : false,
      description: value.description,
      levelId: value.levelId,
      premium: value.premium ? true : false,
      status: value.status || formModal ? true : false,
      subjectId: value.subjectId,
      title: value.title,
      topicId: value.topicId,
      learnActivities: sources,
    };

    if (tests.length) {
      data.testAbleId = tests[0].sourceAbleId;
      data.testAbleType = tests[0].sourceAbleType;
    }

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateLearnAsync(formId, data)
          : createLearnAsync(data);
      setAppLoading(true);
      request
        .then((res) => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch((err) => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateLearnAsync(id, data)
        : createLearnAsync(data);

    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEnd = (items: Activity[]) => {
    setActivities(items);
  };

  const onSortEndChild = (items: Activity[]) => {
    setActivities(items);
  };

  const onEdit = (value: Activity) => {
    setDataSource(value);
    setModalType(MODAL_TYPE.ACTIVITY_FROM);
  };

  const onDelete = ({ id, isNewRecord }: Activity) => {
    showDeleteConfirm(() => {
      const filterActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          return draft.filter((item) => item.id !== id);
        }
      );
      if (isNewRecord) setActivities(filterActivities);
      else
        deleteLearnSourceAsync(id)
          .then((res) => {
            setActivities(filterActivities);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
    });
  };

  const onDuplicate = (item: Activity) => {
    const newActivities = produce<Activity[], Activity[]>(
      activities,
      (draft) => {
        const actData = item.activities.map((act) => {
          return { ...act, isNewRecord: true };
        });

        draft.push({
          ...item,
          id: uuid(),
          isNewRecord: true,
          activities: actData,
        });
        return draft;
      }
    );
    setActivities(newActivities);
  };

  const onEditChild = (value: Question) => {
    const { type, questionType, competencyId }: any = value;

    setDataSource(exclude(value, ['isNewRecord']));
    if (competencyId) {
      setModalType(MODAL_TYPE.COMPETENCY_EDIT);
    } else {
      setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT);
      setLibraryType(type);
      setQuestionType(questionType);
    }
  };

  const onDeleteChild = ({ id, parentIndex, isNewRecord }: Question) => {
    showDeleteConfirm(() => {
      const filterActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          const filterChildren = draft[parentIndex].activities.filter(
            (item) => item.id !== id
          );
          draft[parentIndex].activities = filterChildren;
          return draft;
        }
      );
      if (isNewRecord) {
        setActivities(filterActivities);
      } else {
        deleteLearnSourceLibraryAsync(id)
          .then((res) => {
            messageSuccess();
            setActivities(filterActivities);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onDuplicateChild = (item: Question) => {
    setQuestionType(item.questionType);
    setLibraryType(item.type);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM);
  };

  const onCreateActivity = () => {
    setDataSource({});
    setModalType(MODAL_TYPE.ACTIVITY_FROM);
  };

  const onOkFormActivity = (values: FormActivityType) => {
    const { title, id } = values;
    const newActivities = produce<Activity[], Activity[]>(
      activities,
      (draft) => {
        if (id) {
          let index = draft.findIndex((item) => item.id === id);
          draft[index]['title'] = title;
        } else
          draft.push({
            id: uuid(),
            rank: draft.length + 1,
            title,
            isNewRecord: true,
            activities: [],
          });
      }
    );
    setActivities(newActivities);
    onCancel();
  };

  const onOkBrowseLibrary = (values: Question[]) => {
    const { parentIndex } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY) {
      const modifyActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          if (!_.get(draft, `${parentIndex}.activities`))
            _.set(draft, `${parentIndex}.activities`, []);

          const activities = draft[parentIndex].activities;
          const filterValues = values.filter(
            (item) => !activities.map((a) => a.libraryId).includes(item.id)
          );
          if (filterValues.length !== values.length)
            messageError('Some of library is duplicate');
          draft[parentIndex].activities = [...activities, ...filterValues];
          return draft;
        }
      );
      setActivities(
        assignIndexKey({
          dataSources: modifyActivities,
          childKey: ['activities', 'children'],
        })
      );
    }

    onCancel();
  };

  const onCancel = () => {
    setModalType('');
    setDataSource({});
    setLibraryType('');
    setQuestionType('');
  };

  const onBrowseLibrary = (item: Question) => {
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY);
    setLibraryType(item.type);
    setQuestionType(item.questionType);
    setDataSource(item);
  };

  const onCreateLibrary = (item: Activity) => {
    setDataSource(item);
    setLibraryType(item.type || '');
    setQuestionType(item.questionType || '');
    setModalType(MODAL_TYPE.LIBRARY_ACTIVITY_FORM);
  };

  const onOkFormLibrary = (value: LooseObject) => {
    const { id, libraryId, indexKey, parentIndex, filterKey } = dataSource;

    if (filterKey === SECTION_TYPE.COMPETENCY_TEST) {
      const mapTests = produce<any[], any[]>(tests, (draft) => {
        if (indexKey.length === 3)
          draft[indexKey[0]].libraries[indexKey[1]].libraries[indexKey[2]] = {
            ...value,
            id,
          };
        if (indexKey.length === 4)
          draft[indexKey[0]].libraries[indexKey[1]].libraries[
            indexKey[2]
          ].children[indexKey[3]] = {
            ...value,
            id,
          };
        return draft;
      });

      setTests(
        assignIndexKey({
          dataSources: mapTests,
          childKey: ['libraries', 'libraries', 'children'],
          filterKey: SECTION_TYPE.COMPETENCY_TEST,
        })
      );
    } else if (filterKey === SECTION_TYPE.PRACTICE_TEST) {

      const mapTests = produce<any[], any[]>(tests, (draft) => {
        if (indexKey.length === 2)
          draft[indexKey[0]].libraries[indexKey[1]] = {
            ...value,
            libraries: value.children,
            id,
          };
        if (indexKey.length === 3)
          draft[indexKey[0]].libraries[indexKey[1]].libraries[indexKey[2]] = {
            ...value,
            id,
          };
        return draft;
      });
      setTests(
        assignIndexKey({
          dataSources: mapTests,
          childKey: ['libraries', 'libraries', 'children'],
          filterKey: SECTION_TYPE.PRACTICE_TEST,
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM) {

      const modifyActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          if (!_.get(draft, `${parentIndex}.activities`))
            _.set(draft, `${parentIndex}.activities`, []);
          draft[parentIndex].activities.push({
            ...value,
            isNewRecord: true,
            libraryId: value.id,
          });
          return draft;
        }
      );
      setActivities(
        assignIndexKey({
          dataSources: modifyActivities,
          childKey: ['activities', 'children'],
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT) {

      const modifyActivities = produce<Activity[], Activity[]>(
        activities,
        (draft) => {
          if (indexKey.length === 2)
            draft[indexKey[0]].activities[indexKey[1]] = {
              ...value,
              id,
              libraryId,
            };
          if (indexKey.length === 3)
            draft[indexKey[0]].activities[indexKey[1]].children[indexKey[2]] = {
              ...value,
              id,
              libraryId,
            };
          return draft;
        }
      );

      setActivities(
        assignIndexKey({
          dataSources: modifyActivities,
          childKey: ['activities', 'children'],
        })
      );
    }

    onCancel();
  };

  const onCreateTest = (type: string) => {
    if (tests.length) {
      messageWarn('Learn only contain 1 test');
      return;
    }
    setDataSource({});
    setSectionType(type);
    setModalType(MODAL_TYPE.TEST_CREATE);
  };

  const onBrowseTest = (type: string) => {
    if (tests.length) {
      messageWarn('Learn only contain 1 test');
      return;
    }
    setSectionType(type);
    setModalType(MODAL_TYPE.TEST_LIST);
  };

  const onSortEndTest = (items: Test[]) => {
    setTests(items);
  };

  const onEditTest = (value: Test) => {
    setDataSource(exclude(value, ['isNewRecord']));
    setSectionType(value.sourceAbleType);
    setModalType(MODAL_TYPE.TEST_EDIT);
  };

  // const onDuplicateTest = (item: Test) => {
  //   setSectionType(item.sourceAbleType);
  //   setDataSource({ ...item, isNewRecord: true });
  //   setModalType(MODAL_TYPE.TEST_CREATE);
  // };

  const onDeleteTest = ({ id, isNewRecord }: Test) => {
    showDeleteConfirm(() => {
      const filterTests = produce<Test[], Test[]>(tests, (draft) => {
        return draft.filter((item) => item.id !== id);
      });
      if (isNewRecord) setTests(filterTests);
      else {
        updateLearnAsync(learn?.id || '', {
          testAbleId: null,
          testAbleType: null,
        } as any)
          .then((res) => {
            setTests(filterTests);
            messageSuccess('Delete success');
          })
          .catch((err) => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onOkBrowseSection = (values: any[]) => {
    if (values.length) {
      const filterSections = produce<Test[], Test[]>(tests, (draft) => {
        const test = values[0];
        test.libraries = test.dataSources;
        return [test];
      });
      setTests(
        assignIndexKey({
          dataSources: filterSections,
          childKey: ['libraries', 'libraries', 'children'],
          filterKey: sectionType,
        })
      );
    }

    onCancel();
  };

  const onOkFormSection = (value: LooseObject) => {
    const { sourceAbleType, indexKey, competencyId, libraryId, filterKey } =
      dataSource;

    let modifySections = [];
    let libraries: any = [];
    if (value.dataSources)
      libraries = value.dataSources.map((item: any) => {
        const libraries = item.libraries.filter((item2: any) => {
          return item2.libraryId === item.libraryId;
        });

        return { ...item, libraries };
      });

    if (modalType === MODAL_TYPE.TEST_CREATE) {
      modifySections = produce<Test[], Test[]>(tests, (draft) => {
        if (sourceAbleType === SECTION_TYPE.COMPETENCY_TEST) {
          draft[0] = {
            ...value,
            isNewRecord: true,
            id: uuid(),
            libraries,
          };
        } else {
          draft[0] = {
            ...value,
            isNewRecord: true,
            id: uuid(),
            libraries: value.dataSources,
          };
        }
        return draft;
      });
    }
    if (modalType === MODAL_TYPE.TEST_EDIT) {
      modifySections = produce<Test[], Test[]>(tests, (draft) => {
        if (sourceAbleType === SECTION_TYPE.COMPETENCY_TEST) {
          draft[indexKey[0]] = {
            ...dataSource,
            libraries,
            code: value.code,
            title: value.title,
          };
        } else {
          draft[indexKey[0]] = {
            ...dataSource,
            libraries: value.dataSources,
            code: value.code,
            title: value.title,
          };
        }
        return draft;
      });
    }
    if (modalType === MODAL_TYPE.COMPETENCY_EDIT) {
      if (competencyId) {
        let libraries: any = mapQuestions({
          dataSources: _.get(value, `competencySources.questions`, []),
          key: 'competencySourceLibraries',
          childKey: ['libraries', 'children'],
          filterKey: filterKey,
        });

        libraries = libraries.filter(
          (item: any) => item.libraryId === libraryId
        );
        modifySections = produce<Test[], Test[]>(tests, (draft) => {
          draft[indexKey[0]].libraries[indexKey[1]].title = value.title;
          draft[indexKey[0]].libraries[indexKey[1]].code = value.code;
          draft[indexKey[0]].libraries[indexKey[1]].libraries = libraries;

          return draft;
        });
      }
    }
    if (modifySections.length)
      setTests(
        assignIndexKey({
          dataSources: modifySections,
          childKey: ['libraries', 'libraries', 'children'],
          filterKey,
        })
      );
    onCancel();
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div
          onClick={() =>
            requestTopicDefault({
              dataSource: learn,
              defaultFilter,
              appFilter,
              setAppFilter,
              filterKey: 'learn',
            })
          }
          id='learnRequest'
        />
        <FormActivityModal
          visible={modalType === MODAL_TYPE.ACTIVITY_FROM}
          onOk={onOkFormActivity}
          onCancel={onCancel}
          title={t('Form Activity')}
          dataSource={dataSource}
        />
        <LibraryModal
          visible={modalType === MODAL_TYPE.LIBRARY_ACTIVITY}
          onOk={onOkBrowseLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          defaultFilter={_.get(appFilter, 'learn', {})}
        />
        <LibraryFormModal
          visible={
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM ||
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT
          }
          key={`lb-${
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM ||
            modalType === MODAL_TYPE.LIBRARY_ACTIVITY_FORM_EDIT
          }`}
          onOk={onOkFormLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'learn')}
        />
        <SectionModal
          visible={modalType === MODAL_TYPE.TEST_LIST}
          onOk={onOkBrowseSection}
          onCancel={onCancel}
          type={sectionType}
          selectionType='radio'
          defaultFilter={_.get(appFilter, 'learn', {})}
        />
        <SectionFormModal
          visible={
            modalType === MODAL_TYPE.TEST_CREATE ||
            modalType === MODAL_TYPE.TEST_EDIT
          }
          key={`sf-${
            modalType === MODAL_TYPE.TEST_CREATE ||
            modalType === MODAL_TYPE.TEST_EDIT
          }`}
          onOk={onOkFormSection}
          onCancel={onCancel}
          type={sectionType}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'learn')}
        />
        <CompetencyFormModal
          visible={modalType === MODAL_TYPE.COMPETENCY_EDIT}
          key={`cf-${modalType === MODAL_TYPE.COMPETENCY_EDIT}`}
          onOk={onOkFormSection}
          onCancel={onCancel}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'learn', {})}
        />
        <FormWrapper form={form} name='learnForm' onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={8} lg={8}>
              <FormInfoSection
                levels={levels}
                topics={topics}
                subjects={subjects}
                colors={colors}
                form={form}
                dataSource={learn}
                hasStatusOption={true}
                filterKey='learn'
              />
            </Col>
            <Col sm={24} md={16} lg={16}>
              <TitleItem title={t('Description')} />
              <TextEditorForm
                name='description'
                form={form}
                value={_.get(learn, 'description', '')}
                rules={[{ required: false }]}
              />
              <TitleItem title={t('Activities')} />
              <SortableItems
                dataSources={activities}
                onSortEnd={onSortEnd}
                onSortEndChild={onSortEndChild}
                onEdit={onEdit}
                onDuplicate={onDuplicate}
                onDelete={onDelete}
                onEditChild={onEditChild}
                onDuplicateChild={onDuplicateChild}
                onDeleteChild={onDeleteChild}
                onBrowseLibrary={onBrowseLibrary}
                onCreateLibrary={onCreateLibrary}
                childItemsKey={['activities', 'children']}
              />
              <ButtonAddBrowse onCreate={onCreateActivity} />
              <TitleItem title={t('Test')} />
              <SortableItems
                dataSources={tests}
                onSortEnd={onSortEndTest}
                onEdit={onEditTest}
                onEditChild={onEditChild}
                // filterKey="test"
                // onDuplicate={onDuplicateTest}

                onDelete={onDeleteTest}
                childItemsKey={['libraries', 'libraries', 'children']}
              />
              <ButtonAddBrowse
                titleCreate={t('Add Test')}
                titleBrowse={t('Link Test')}
                onCreates={onCreateTest}
                onBrowses={onBrowseTest}
                options={FILTER_SECTION_TYPES.filter(
                  (item) =>
                    ![SECTION_TYPE.LEARN, SECTION_TYPE.RESOURCE].includes(
                      item.value
                    )
                )}
              />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </Fragment>
  );
};
