import { SortData } from "../../types/global.types";
import { axios, config } from "../../utils/axios.utils";
import { LibraryFormType } from "./library.types";

export const createQuestionAsync = (data: LibraryFormType) => {
  return axios.post("/questions", data, config()).then(response => response.data);
};

export const updateQuestionAsync = (id: string, data: LibraryFormType) => {
  return axios.put(`/questions/${id}`, data, config()).then(response => response.data);
};

export const createQuestionGroupAsync = (data: LibraryFormType) => {
  return axios.post("/question-groups", data, config()).then(response => response.data);
};

export const updateQuestionGroupAsync = (id: string, data: LibraryFormType) => {
  return axios.put(`/question-groups/${id}`, data, config()).then(response => response.data);
};

export const createQuestionFillGapAsync = (data: LibraryFormType) => {
  return axios.post("/fill-gap-question", data, config()).then(response => response.data);
};

export const updateQuestionFillGapAsync = (id: string, data: LibraryFormType) => {
  return axios.put(`/fill-gap-question/${id}`, data, config()).then(response => response.data);
};

export const createQuestionDragDropAsync = (data: LibraryFormType) => {
  return axios.post("/drag-drop-questions", data, config()).then(response => response.data);
};

export const updateQuestionDragDropAsync = (id: string, data: LibraryFormType) => {
  return axios.put(`/drag-drop-questions/${id}`, data, config()).then(response => response.data);
};

export const sortQuestionGroupChildrenAsync = (data: SortData) => {
  return axios
    .patch("/question-groups/order-children", data, config())
    .then(response => response.data);
};

export const createQuestionOrderAsync = (data: LibraryFormType) => {
  return axios.post("/order-questions", data, config()).then(response => response.data);
};

export const updateQuestionOrderAsync = (id: string, data: LibraryFormType) => {
  return axios.put(`/order-questions/${id}`, data, config()).then(response => response.data);
};
