import { axios, config } from "../../utils/axios.utils";

export const getPermissionsAsync = (roleId: string) => {
  return axios.get(`permissions/${roleId}`, config()).then(response => response.data.data);
};

export interface UpdatePermission {
  roleId: string;
  names: string[];
}

export const updatePermissionAsync = (data: UpdatePermission) => {
  return axios.put(`/permissions`, data, config()).then(response => response.data);
};
