import { Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
// import { useSetRecoilState } from "recoil";
import { getLibraryAsync } from "../../../atom/library/library.apis";
// import { libraryState } from "../../../atom/library/library.atoms";
import { FormModalHeader } from "../../../components/form-modal-header/form-modal-header.component";
import {
  LIBRARY_TYPE,
  QUESTION_TYPE,
} from "../../../constants/default-value.const";
import { LooseObject } from "../../../types/global.types";
import {
  messageSuccess,
  showMessageErrors,
} from "../../../utils/message.utils";
import { QuestionDragDropForm } from "../question/components/question-drag-drop-form.component";
import { QuestionFillGapForm } from "../question/components/question-fill-gap-form.component";
import { QuestionGroupForm } from "../question/components/question-group-form.component";
import { QuestionInputForm } from "../question/components/question-input-form.component";
import { QuestionMCQForm } from "../question/components/question-mcq-form.component";
import { QuestionOrderForm } from "../question/components/question-order-form.component";
import { TextLibraryForm } from "../text-library/components/text-library-form.component";
import { VideoForm } from "../video/components/video-form.component";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../atom/app/app.hook";
import _ from "lodash";

interface LibraryFormModalProps {
  visible: boolean;
  onOk: (value: LooseObject[]) => void;
  onCancel: () => void;
  // title,
  type: string;
  questionType: string;
  dataSource: LooseObject;
  defaultFilter?: any;
  isEditTest?: boolean;
}
export const LibraryFormModal: React.FC<LibraryFormModalProps> = ({
  visible,
  onOk,
  onCancel,
  type = "QUESTION",
  dataSource,
  questionType,
  defaultFilter,
  isEditTest,
}) => {
  const [form] = useForm();
  // const setLibrary = useSetRecoilState(libraryState);
  const { t } = useTranslation();
  const { appLoading } = useApp();

  // useEffect(() => {
  //   if (!dataSource.libraryId) {
  //     setLibrary({});
  //   }
  // }, [setLibrary, dataSource]);

  const _onOk = (value: any) => {
    getLibraryAsync(value.id)
      .then((res) => {
        // let children = [];
        // if (res.questionType === QUESTION_TYPE.GROUP) {
        const children = _.get(res, "children", []).map((item: any) => {
          return { ...item, libraryId: item.id };
        });
        // }

        onOk({ ...res, libraryId: res.id, children });
      })
      .catch((err) => {
        messageSuccess();
        showMessageErrors(err);
      });
  };

  const renderForm = () => {
    const { libraryId: formId, isNewRecord } = dataSource;

    const dataProps = {
      form,
      formId,
      formDuplicate: isNewRecord,
      formModal: true,
      onOk: _onOk,
      // defaultFilter: formId ? null : defaultFilter
      defaultFilter,
    };

    if (type === LIBRARY_TYPE.TEXT) {
      return <TextLibraryForm {...dataProps} />;
    } else if (type === LIBRARY_TYPE.VIDEO) {
      return <VideoForm {...dataProps} />;
    } else if (questionType === QUESTION_TYPE.INPUT) {
      return <QuestionInputForm {...dataProps} />;
    } else if (questionType === QUESTION_TYPE.MCQ) {
      return <QuestionMCQForm {...dataProps} />;
    } else if (questionType === QUESTION_TYPE.GROUP) {
      return <QuestionGroupForm {...dataProps} isEditTest={isEditTest} />;
    } else if (questionType === QUESTION_TYPE.ORDER) {
      return <QuestionOrderForm {...dataProps} />;
    } else if (questionType === QUESTION_TYPE.DRAG_DROP) {
      return <QuestionDragDropForm {...dataProps} />;
    } else if (questionType === QUESTION_TYPE.FILL_GAP) {
      return <QuestionFillGapForm {...dataProps} />;
    }
    return null;
  };

  const renderTitle = (type: string, questionType: string) => {
    if (type === LIBRARY_TYPE.TEXT) return t("Text Library");
    else if (type === LIBRARY_TYPE.VIDEO) {
      return t("Video");
    } else if (questionType === QUESTION_TYPE.INPUT) {
      return t("Question Input");
    } else if (questionType === QUESTION_TYPE.MCQ) {
      return t("Question MCQ");
    } else if (questionType === QUESTION_TYPE.GROUP) {
      return t("Question Group");
    } else if (questionType === QUESTION_TYPE.ORDER) {
      return t("Question Order");
    } else if (questionType === QUESTION_TYPE.DRAG_DROP) {
      return t("Question Drag & Drop");
    } else if (questionType === QUESTION_TYPE.FILL_GAP) {
      return t("Question Fill in Gap");
    }
    return t("Library");
  };

  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      maskClosable={false}
      width="80%"
      footer={null}
    >
      <Spin spinning={appLoading}>
        <FormModalHeader
          title={renderTitle(type, questionType)}
          handleCancel={onCancel}
          handleOk={() => form.submit()}
        />
        {renderForm()}
      </Spin>
    </Modal>
  );
};
