import { Col, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { TextEditorForm } from "../../components/form";
import { FormWrapper } from "../../components/form/form.component";
import { TextEditorPreview } from "../../components/form/text-editor/text-editor.styles";
import { Layout } from "../../components/layout/layout.component";
import { MathJaxPreview } from "../../components/mathjax-preview/mathjax-preview.component";

export const EditorPage = () => {
  const [form] = useForm();
  const [content, setContent] = useState<string>();
  const onChange = (value: string) => {
    setContent(value);
  };
  return (
    <Layout>
      <FormWrapper form={form} name="competencyForm">
        <Col span={24}>
          <TextEditorForm
            name="questionText"
            form={form}
            value={""}
            onChange={onChange}
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={24}>
          <TextEditorPreview>
            <MathJaxPreview math={content} />
          </TextEditorPreview>
        </Col>
      </FormWrapper>
    </Layout>
  );
};
