const firebaseConfigDev = {
  apiKey: "AIzaSyBZQ9nUXbtBTRnEhAt_xkOM09b3gCpV4A0",
  authDomain: "edemy-gradding.firebaseapp.com",
  databaseURL: "https://edemy-gradding.firebaseio.com",
  projectId: "edemy-gradding",
  storageBucket: "edemy-gradding.appspot.com",
  messagingSenderId: "132916643044",
  appId: "1:132916643044:web:f71c21e4a9827320",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyAafZhBkD3yz3_4D6TkfcOE_OnkvA016F4",
  authDomain: "impacthublms.firebaseapp.com",
  databaseURL: "https://impacthublms.firebaseio.com",
  projectId: "impacthublms",
  storageBucket: "impacthublms.appspot.com",
  messagingSenderId: "541367184413",
  appId: "1:541367184413:web:b8e769904dfc241fcd6af4",
  measurementId: "G-ELR2CQ2Y82",
};

let firebaseConfig = firebaseConfigDev;
// let storagePath = `https://firebasestorage.googleapis.com/v0/b/edemy-gradding.appspot.com/o/@@@?alt=media`;
let storagePath = `https://firebasestorage.googleapis.com/v0/b/tesdopi3-b1d79.appspot.com/o/@@@?alt=media`;
if (process.env.REACT_APP_ENV === "production") {
  firebaseConfig = firebaseConfigProd;
  // storagePath = `https://firebasestorage.googleapis.com/v0/b/impacthublms.appspot.com/o/@@@?alt=media`;
  storagePath = `https://firebasestorage.googleapis.com/v0/b/tesdopi3-b1d79.appspot.com/o/@@@?alt=media`;
}

export const FIREBASE_CONFIG_ENV = firebaseConfig;
export const STORAGE_PATH_ENV = storagePath;
