import { useForm } from "antd/lib/form/Form";
import { Layout } from "../../../components/layout/layout.component";
import { LearnForm } from "./components/learn-form.component";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useRoute from "../../../hooks/use-route.hook";
import { replaceParam } from "../../../utils/helper.utils";
import { RoutePaths } from "../../../constants/route.const";

export const LearnFormPage = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isBack, setIsBack] = useState(false);
  const { param } = useRoute();

  return (
    <Layout
      title={t("Learn")}
      onSubmit={isBack => {
        setIsBack(isBack || false);
        form.submit();
      }}
      onPreview={() => {
        window.open(replaceParam(RoutePaths.MOBILE_PREVIEW_LEARN, param.id), "_blank");
      }}
    >
      <LearnForm form={form} isBack={isBack} />
    </Layout>
  );
};
