import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { Media } from "../../../../atom/media/media.type";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { MediaForm } from "./media-form.component";

interface MediaFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: Media) => void;
  dataSource?: Media;
}
export const MediaFormModal: React.FC<MediaFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <MediaForm dataSource={dataSource} handleCancel={handleCancel} handleOk={handleOk} />
      </Spin>
    </ModalWrapper>
  );
};
