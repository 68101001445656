import produce from "immer";
import _ from "lodash";
import { selector } from "recoil";
import { PrefixPermission } from "../../constants/permission.const";
import { checkPermission, formatDate, isSyncable } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { mapQuestions } from "../../utils/response.utils";
import { authUserSelector } from "../auth/auth.selectors";
import {
  competitionTestsState,
  competitionTestMetaState,
  competitionTestState
} from "./competition-test.atoms";

export const competitionTestsSelector = selector({
  key: "competitionTestsSelector",
  get: ({ get }) => {
    const competitionTests = produce(get(competitionTestsState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.COMPETITION_TEST);
    return competitionTests.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const competitionTestSelector = selector({
  key: "competitionTestSelector",
  get: ({ get }) => {
    const competitionTest = produce(get(competitionTestState), draft => draft);
    const questions = _.get(competitionTest, "competitionTestLibraries", []);
    return {
      ...competitionTest,
      questions: mapQuestions({ dataSources: questions })
    };
  }
});

export const competitionTestMetaSelector = selector({
  key: "competitionTestMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(competitionTestMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
