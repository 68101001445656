import styled from "styled-components";
import { Select } from "antd";
const { Option } = Select;

export const StyleSelect = styled(Select)`
  width: 100%;
`;
export const StyleOption = styled(Option)``;

export const StyleSelectHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  p {
    margin: 0;
  }
`;

export const WrapMedia = styled.div`
  img {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    margin-right: 10px;
    border-radius: 4px;
  }
`;

export const Tag = styled.div`
  Tag {
    color: cyan;
    width: 100px;
  }
`;