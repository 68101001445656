import { SearchInput } from '../search-input/search-input.component';
import { WrapContent } from './search-and-title.styles';

export const SearchAndTitle = ({
  title,
  handleSearch,
}: {
  title: string;
  handleSearch: (value: string) => void;
}) => {
  return (
    <WrapContent>
      <h2>{title}</h2>
      <div>
        <SearchInput handleSearch={handleSearch} isModal={true} />
      </div>
    </WrapContent>
  );
};
