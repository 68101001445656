import MathJax from 'react-mathjax-preview';
import styled from 'styled-components';
import { decode } from 'html-entities';

const WrapMathJax = styled(MathJax)`


  img {
    width: 100%;
    /* border-radius: 20px; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }

  * {
    padding: revert;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
  }

  th {
    // letter-spacing: 1px;
  }

  td {
    // letter-spacing: 1px;
  }

  tbody td {
    padding: 4px;
  }

  tfoot th {
    padding: 4px;
  }

  #react-mathjax-preview-result {
    font-size: 1em;
    line-height: 190%;
    //letter-spacing: 1px;
  }
  ::marker{
    font-color:red;
  }

  div.grey {
    background-color: #FAFAFA;
    padding: 8px;
    margin-bottom: 12px;
    border-top: 0.3px solid #9E9E9E;
    border-bottom: 0.3px solid #9E9E9E;
    border-left: 3px solid #9E9E9E;
    border-right: 0.3px solid #9E9E9E;
  }
  div.amber {
    background-color: #FFF8E1;
    padding: 8px;
    margin-bottom: 12px;
    border-top: 0.3px solid #FFD54F;
    border-bottom: 0.3px solid #FFD54F;
    border-left: 3px solid #FFD54F;
    border-right: 0.3px solid #FFD54F;
  }
  div.blue {
    background-color: #E3F2FD;
    padding: 8px;
    margin-bottom: 12px;
    border-top: 0.3px solid #64B5F6;
    border-bottom: 0.3px solid #64B5F6;
    border-left: 3px solid #64B5F6;
    border-right: 0.3px solid #64B5F6;
  }
  div.red {
    background-color: #FFEBEE;
    padding: 8px;
    margin-bottom: 12px;
    border-top: 0.3px solid #E57373;
    border-bottom: 0.3px solid #E57373;
    border-left: 3px solid #E57373;
    border-right: 0.3px solid #E57373;
  }
  div.green {
    background-color: #E8F5E9;
    padding: 8px;
    margin-bottom: 12px;
    border-top: 0.3px solid #81C784;
    border-bottom: 0.3px solid #81C784;
    border-left: 3px solid #81C784;
    border-right: 0.3px solid #81C784;
  }
  
  div.boxed {
    background-color: #d6f5ff;
    padding: 4px;
    margin-bottom: 12px;
    border: 0.3px solid #4bcffa;
    font-size: 10px;
  }

  div.hint {
    background-color: #f7e3ff;
    padding: 4px;
    margin-bottom: 12px;
    border: 0.3px solid #D980FA;
    font-size: 10px;
  }

  table.grey{background-color: #FAFAFA;}
  table.red{background-color: #FFEBEE;}
  table.amber{background-color: #FFF8E1;}
  table.green{background-color: #E8F5E9;}
  table.blue{background-color: #E3F2FD;}
`;

export const MathJaxPreview = ({ math = '<p></p>' }) => {

  return <WrapMathJax math={math} />;
};
