import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface ButtonAddBrowseProps {
  onCreate?: () => void;
  onBrowse?: () => void;
  onCreates?: (type: string) => void;
  onBrowses?: (type: string) => void;
  // onCreateSection?: (type: string) => void;
  // onBrowseSection?: (type: string) => void;
  titleCreate?: string;
  titleBrowse?: string;
  exclude?: string[];
  options?: any[];
}
export const ButtonAddBrowse: React.FC<ButtonAddBrowseProps> = ({
  titleCreate = "Add",
  titleBrowse = "Table",
  onCreate,
  onBrowse,
  onCreates,
  onBrowses,
  // exclude,
  // onCreateSection,
  // onBrowseSection,
  options
}) => {
  const { t } = useTranslation();
  const renderMenu = (func: (value: string) => void) => {
    return (
      <Menu>
        {options &&
          options.map(item => {
            // if (exclude?.includes(item.value)) return null;
            return (
              <Menu.Item key={item.value} onClick={() => func(item.value)}>
                {t(item.text)}
              </Menu.Item>
            );
          })}
      </Menu>
    );
  };

  const menuCreates = () => {
    if (!onCreates) return <Menu />;

    return renderMenu(onCreates);
  };

  const menuBrowses = () => {
    if (!onBrowses) return <Menu />;

    return renderMenu(onBrowses);
  };

  return (
    <Space>
      {onCreates && (
        <Dropdown overlay={menuCreates} trigger={["click"]}>
          <Button>
            {t(titleCreate)} <DownOutlined />
          </Button>
        </Dropdown>
      )}
      {onBrowses && (
        <Dropdown overlay={menuBrowses} trigger={["click"]}>
          <Button>
            {titleBrowse} <DownOutlined />
          </Button>
        </Dropdown>
      )}
      {onCreate && <Button onClick={onCreate}>{t(titleCreate)}</Button>}
      {onBrowse && <Button onClick={onBrowse}>{titleBrowse}</Button>}
    </Space>
  );
};
