import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atom/auth/auth.atoms";
import { RoutePaths } from "../constants/route.const";
import { LooseObject } from "../types/global.types";

export const PrivateRoute = ({ component: Component, ...rest }: LooseObject) => {
  const authUser = useRecoilValue(authState);
  return (
    <Route
      {...rest}
      render={props => (authUser ? <Component {...props} /> : <Redirect to={RoutePaths.SIGN_IN} />)}
    />
  );
};
