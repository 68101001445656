import { Layout } from "../../../components/layout/layout.component";
import { Row, Col, Avatar, Space, Button, Input, Card } from 'antd';
import { 
  PlusOutlined, 
  FileSearchOutlined, 
  CloudUploadOutlined, 
  EditOutlined, 
  CopyOutlined, 
  DeleteOutlined,
  CalendarOutlined 
} from '@ant-design/icons';
import { Link } from "react-router-dom"
import {  ElementFlex, FlexContainer, FlexTitle, Container, FlexWrap } from "./dashboard.style"
import { FlexElement } from "../../elements/question/components/question.style";
import { RoutePaths } from "../../../constants/route.const";
const { Meta } = Card;

export const DashboardPage = () => {
  return(
  <Layout title="Report Dashboard">
    
    {/* Summary Data */}
    
    <Row gutter={1}>
      {dataWidget.map((item, index)=> {
        return (
        <Col span={6} key={index}>
          <FlexContainer style={{margin:"5px"}}>
            <ElementFlex style={{width: '30%'}}>
              <Avatar shape="square" size={86} style={{ backgroundColor: '#AADD11' }} />
            </ElementFlex>
            <ElementFlex style={{width: '70%'}}>
              <ElementFlex style={{float: 'left'}}>
                <FlexTitle>
                  Feature Title<br/>
                  <p style={{fontSize:"30px"}}>120</p>
                </FlexTitle>
              </ElementFlex>
              <ElementFlex style={{float: 'right'}}>
                <Link to={RoutePaths.USAGE_BY_COURSE_LEARN}>
                  <FileSearchOutlined/></Link>
                  <PlusOutlined/>
              </ElementFlex>
            </ElementFlex>
          </FlexContainer>
        </Col>)
      })}
    </Row>
    
    <hr/>
    
    {/* Graph Data */}

    <Row gutter={1} style={{margin: "10px"}}>
      <Col span={6}>
      <Container>
          <FlexTitle>Quick Actions (Batch Upload)</FlexTitle>
            {[...dataWidget, ...dataWidget].map((item, index)=> {
              return (
              <Space key={index}>
                <CloudUploadOutlined />
                <FlexTitle style={{marginTop: '10px'}}>Tesdopi Weekly </FlexTitle>
              </Space>
              )
            })}
        </Container>
      </Col>
      <Col span={18}>
        <Container>
          <FlexWrap>
            <FlexElement style={{width: '90%'}}>
              <FlexTitle>Tesdopi Weekly Champion</FlexTitle>
            </FlexElement>
            <ElementFlex  style={{width: '10%', float: 'right'}}>
              <Button type="primary" size="small"><PlusOutlined/>New</Button>
            </ElementFlex>
          </FlexWrap>
          {[...dataWidget, ...dataWidget].map((item, index)=> {
            const rank = index + 1;
              return (
              <FlexWrap key={index}>
                <ElementFlex style={{width: '40%'}} >
                  <Space >
                    <FlexTitle style={{marginTop: '10px'}}>{rank}. </FlexTitle>
                    <FlexTitle style={{marginTop: '10px'}}>Tesdopi Weekly </FlexTitle>
                  </Space>
                </ElementFlex>
                <ElementFlex style={{width: '30%'}}>
                  <Space >
                    <CalendarOutlined />
                    <FlexTitle style={{marginTop: '10px'}}>Tesdopi Weekly </FlexTitle>
                  </Space>
                </ElementFlex>
                <ElementFlex style={{width: '18%'}}>
                  <Space >
                    <FlexTitle style={{marginTop: '10px',color: rank%2 === 0 ? "green" : "blue" }} >
                      {rank%2 === 0 ? "Live" : "Soon"}
                    </FlexTitle>
                  </Space>
                </ElementFlex>
                <ElementFlex style={{width: '12%'}}>
                  <Space >
                    <FileSearchOutlined style={{marginTop: '15px'}}/>
                    <EditOutlined style={{marginTop: '15px'}}/>
                    <CopyOutlined style={{marginTop: '15px'}}/>
                    <DeleteOutlined style={{marginTop: '15px'}}/>
                  </Space>
                </ElementFlex>
              </FlexWrap>)
            })}
        </Container>
      </Col>
    </Row>


    <hr/>
    
    <Row gutter={1} style={{margin: "10px"}}>
      <Col span={12}>
        <Container>
          <FlexWrap>
            <ElementFlex>
              <FlexTitle>Recent Videos</FlexTitle>
            </ElementFlex>
            <ElementFlex style={{marginLeft: '24px'}}>
              <Button type="primary" size="small"><PlusOutlined/>New</Button>
            </ElementFlex>
          </FlexWrap>
        </Container>
      </Col>
      <Col span={12}>
        <Container style={{height: '100%'}}>
          <ElementFlex style={{float: 'right'}}>
            <FlexWrap>
              <ElementFlex>
                <Input placeholder="Search" type='text' size='small' />
              </ElementFlex>
              <ElementFlex style={{marginLeft: '8px'}}>
                <FileSearchOutlined/>
              </ElementFlex>
            </FlexWrap>
          </ElementFlex>
        </Container>
      </Col>
    </Row>
    
    <Row gutter={1} style={{margin: "10px"}}>
      {dataWidget.map((item,index)=> {
        return (
        <Col key={index} span={6}>
          <Container style={{padding: '8px'}}>
            <Card
            hoverable
            cover={<ElementFlex style={{height: '120px',background: '#00a98c63' }} />}
          >
            <Meta title="Video Title" description="5h ago" />
          </Card>
          </Container>
        </Col>)
      })}
    </Row>
  </Layout>
)}

const dataWidget = [{id:1},{id:2},{id:3},{id:4}]