import { Dropdown, Menu } from "antd";
import { Button } from "antd/lib/radio";
import { useState } from "react";
import { Layout } from "../../../components/layout/layout.component";
import { FILTER_QUESTION_TYPES } from "../../../constants/default-value.const";
import useRoute from "../../../hooks/use-route.hook";
import { removeParam } from "../../../utils/helper.utils";
import { LibraryComponent } from "../library/library.component";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../constants/permission.const";

export const QuestionPage = () => {
  const { t } = useTranslation();
  const title = t("Question");
  const { history, pathname } = useRoute();
  const [searchQuery, setSearchQuery] = useState("");

  const onCreate = (value: string) => {
    history.push(removeParam(`${pathname}-${value.toLowerCase()}/form`));
  };

  const menu = () => {
    return (
      <Menu>
        {FILTER_QUESTION_TYPES.map(item => (
          <Menu.Item key={item.value} onClick={() => onCreate(item.value)}>
            {t(item.text)}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Layout
      onSearch={value => setSearchQuery(value)}
      title={title}
      render={
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button
            type="primary"
            style={{
              color: "#fff",
              background: "#00A98C",
              borderColor: "#00A98C",
              fontWeight: "bold",
              borderRadius: "3px"
            }}
          >
            {t("Add")}
          </Button>
        </Dropdown>
      }
      prefixPermission={PrefixPermission.LIBRARY}
    >
      <LibraryComponent searchQuery={searchQuery} />
    </Layout>
  );
};
