import React, { Fragment, useState, useEffect } from 'react';
import { TableComponent } from '../../../../components/table/table.component';
import { MediaFormModal } from './media-form-modal.component';
import { TableActionButton } from '../../../../components/table/table-action-button.component';
import { generateRequest } from '../../../../utils/request.utils';
import useRoute from '../../../../hooks/use-route.hook';
import { Media } from '../../../../atom/media/media.type';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  mediasState,
  mediaMetaState,
  mediaLoadingState,
} from '../../../../atom/media/media.atoms';
import {
  getMediasAsync,
  createMediaAsync,
  updateMediaAsync,
  deleteMediaAsync,
  getMediasTrashAsync,
} from '../../../../atom/media/media.apis';
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
} from '../../../../utils/message.utils';
import {
  canShowAll,
  exclude,
  removeUndefined,
  toSort,
} from '../../../../utils/helper.utils';
import { Meta, VisibleProps } from '../../../../types/global.types';
import {
  mediasSelector,
  mediaMetaSelector,
} from '../../../../atom/media/media.slectors';
import { storagePath, uploadPhoto } from '../../../../utils/firebase.utils';
import _ from 'lodash';
import {
  MEDIA_TYPE,
  QUERY_TYPE,
  STORAGE_PATH,
} from '../../../../constants/default-value.const';
import { Avatar, Switch } from 'antd';
import { SourceDataTab } from '../../../../components/source-data-tab/source-data-tab.component';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import { PrefixPermission } from '../../../../constants/permission.const';
import { ListFeatureOption } from '../../../../components/list-feature-option/list-feature-option.component';

const requestMedias = (
  query: any,
  setMedias: (items: Media[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getMediasTrashAsync
      : getMediasAsync;

  return request(option)
    .then(({ items, meta }) => {
      setMedias(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setLoading(false);
    });
};

interface MediaProps extends VisibleProps {}
export const MediaComponent: React.FC<MediaProps> = ({
  visible,
  setVisible,
}) => {
  const { query, history, pathname } = useRoute();
  const setMedias = useSetRecoilState(mediasState);
  const setMeta = useSetRecoilState(mediaMetaState);
  const meta = useRecoilValue(mediaMetaSelector);
  const medias = useRecoilValue(mediasSelector);
  const [loading, setLoading] = useRecoilState(mediaLoadingState);
  const [dataSource, setDataSource] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const { setAppLoading } = useApp();
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestMedias(option, setMedias, setMeta, setLoading);
    setSortedInfo({ ...toSort(query) });
    setLoading(true);
  }, []);

  useEffect(() => {
    setColumns([
      {
        title: t('Sort'),
        dataIndex: 'sort',
        width: 30,
        isDraggable: true,
      },
      {
        title: t('Icon'),
        dataIndex: 'icon',
        render: (text: string, { extension, name }: Media) => (
          <Avatar
            src={storagePath(`${STORAGE_PATH.MEDIA_ICON}/${name}.${extension}`)}
          />
        ),
      },
      {
        title: t('Title'),
        dataIndex: 'title',
        isSearchable: true,
        sorter: (a: Media, b: Media) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
        sortDirections: ['ascend' , 'descend'],
        editable: true,
      },
      {
        title: t('Publish'),
        dataIndex: 'status',
        sorter: (a: Media, b: Media) => Number(a.status) - Number(b.status),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        render: (text: boolean, { id }: Media) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      {
        title: t('Action'),
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: Media) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            record={record}
          />
        ),
      },
    ]);
  }, [sortedInfo]);

  const handleSwitch = (id: string, value: boolean, dataIndex: string) => {
    updateMediaAsync(id, { [dataIndex]: value } as any)
      .then(() => {
        messageSuccess();
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handlePublish = (id: string, value: boolean) => {
    handleSwitch(id, value, 'status');
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
    };

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    requestMedias(option, setMedias, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestMedias(option, setMedias, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const handleEdit = (record: Media) => {
    setDataSource(record);
    setVisible(true);
  };

  const handleOk = async (value: Media) => {
    let { id, title } = value;
    setAppLoading(true);

    let name = dataSource?.name || '';
    let extension = dataSource?.extension || '';
    if (_.get(value, 'media.file')) {
      const photo = await uploadPhoto(
        _.get(value, 'media.file'),
        STORAGE_PATH.MEDIA_ICON,
        () => {
          const data: any = {
            title,
            name: photo.name,
            extension: photo.type,
            type: MEDIA_TYPE.ICON,
          };

          const request = id
            ? updateMediaAsync(id, data)
            : createMediaAsync(data);
          request
            .then(({ message }) => {
              messageSuccess(message);
              requestMedias(query, setMedias, setMeta, setLoading);
              handleCancel();
              setAppLoading(false);
            })
            .catch((err) => {
              showMessageErrors(err);
              setAppLoading(false);
            });
        }
      );

      return;
    }

    const data: any = {
      title,
      name,
      extension,
      type: MEDIA_TYPE.ICON,
    };

    const request = id ? updateMediaAsync(id, data) : createMediaAsync(data);
    request
      .then(({ message }) => {
        messageSuccess(message);
        requestMedias(query, setMedias, setMeta, setLoading);
        handleCancel();
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSaveEditable = (value: Media) => {
    const { id, title } = value;

    updateMediaAsync(id, { title } as Media)
      .then(({ message }) => {
        messageSuccess(message);
        requestMedias(query, setMedias, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setDataSource({} as Media);
  };

  const handleDuplicate = (record: Media) => {
    setDataSource(exclude(record, ['id']));
    setVisible(true);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteMediaAsync(id)
        .then(() => {
          requestMedias(query, setMedias, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  const onChangeTab = (key: string) => {
    requestMedias(
      { ...exclude(query, ['sortField', 'sortOrder']), queryType: key },
      setMedias,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ['sortField', 'sortOrder']),
        queryType: key,
      })
    );
  };

  const onShowAll = () => {
    const option = { ...query, showAll: 'yes' };
    requestMedias(option, setMedias, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setSortedInfo({});
    requestMedias({}, setMedias, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.MEDIA}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        showAll={canShowAll(query, {})}
        query={query}
      />
      <MediaFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={dataSource}
      />
      <TableComponent
        key={`table-${loading}-${medias.length}`}
        loading={loading}
        dataSource={medias}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        onSaveEditable={onSaveEditable}
      />
    </Fragment>
  );
};
