import { Layout } from "../../../components/layout/layout.component";
import {  useEffect, useState } from "react";
import { TableComponent } from "../../../components/table/table.component";
import { FileSearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { RoutePaths } from "../../../constants/route.const"
export interface Report {
  key?: string;
  id: string;
  name: string;
  grade: string;
  school: string;
  course_learn: string;
  course_complete: string;
  mastery: string;
  coins: string;
}

export const ReportUserPage = () => {

  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    setColumns([
      {
        title: "ID",
        dataIndex: "id",
        isSearchable: true,
        sorter: (a: Report, b: Report) => a.id.length - b.id.length,
        editable: false,
        width: 250
      },
      {
        title: "Name",
        dataIndex: "name",
        isSearchable: true,
        sorter: (a: Report, b: Report) => a.name.length - b.name.length,
        editable: false,
        width: 200
      },
      {
        title: "Grade",
        dataIndex: "grade",
        isSearchable: true,
        sorter: (a: Report, b: Report) => a.grade.length - b.grade.length,
        editable: false,
        width: 200
      },
      {
        title: "School",
        dataIndex: "school",
        filters: [],
        render: (text: string, record: Report) => record.school,
      },
      {
        title: "Course Learn",
        dataIndex: "course_learn",
        filters: [],
        render: (text: string, record: Report) => record.course_learn
      },
      {
        title: "Course Complete",
        dataIndex: "course_complete",
        sorter: (a: Report, b: Report) => a.course_complete.length - b.course_complete.length,
      },
      {
        title: "Mastery",
        dataIndex: "mastery",
        sorter: (a: Report, b: Report) => a.mastery.length - b.mastery.length,
      },
      {
        title: "Coins",
        dataIndex: "coins",
        sorter: (a: Report, b: Report) => a.coins.length - b.coins.length,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text: string, record: Report) => (
          <Link to={RoutePaths.REPORT_USER_COURSE}>
            <FileSearchOutlined />
          </Link>
        )
      }
    ]);
  }, []);

  return (
    <Layout>
      <TableComponent
        dataSource={dataTest}
        columns={columns}
        onChange={()=>console.log("[onTableChange]")}
        onColumnSearch={()=>console.log("[onColumnSearch]")}
        pagination={{}}
      />
    </Layout>
  );
};

let dataTest = [
  {
    id: "R-001",
    index: 0,
    key: "0",
    name: "Nullam blandit viverra nostra.",
    grade: '12',
    school: "Nullam blandit viverra",
    course_learn: "Nullam blandit viverra",
    course_complete: "Nullam blandit viverra",
    mastery: "Nullam blandit viverra",
    coins: "1009",
  },
  {
    id: "R-002",
    index: 1,
    key: "1",
    name: "Nullam blandit viverra nostra.",
    grade: '12',
    school: "Nullam blandit viverra",
    course_learn: "Nullam blandit viverra",
    course_complete: "Nullam blandit viverra",
    mastery: "Nullam blandit viverra",
    coins: "1009",
  },
];
