import { axios, config } from '../../utils/axios.utils';
import {
  CategoriesData,
  CategoryData,
  SubjectsData,
} from './mange-feature.type';

export const getManageDatasAsync = () => {
  const url = '/firebase/manage/datas';
  return axios.get(url, config()).then((response) => response.data.data);
};

export const setFirebaseSubjectAsync = (data: any) => {
  return axios
    .put('/firebase/subjects', data, config())
    .then((response) => response.data);
};

export const getManageFeaturesAsync = (type: string) => {
  const url = `/firebase/manage/get-features?type=${type}`;
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getManageFeatureAsync = (type: string) => {
  const url = `/firebase/manage/get-feature?type=${type}`;
  return axios.get(url, config()).then((response) => response.data.data);
};

export const setManageSubjectsAsync = (data: SubjectsData) => {
  const url = '/firebase/manage/subjects';
  return axios.put(url, data, config()).then((response) => response.data);
};

export const setManageCategoryAsync = (data: CategoryData) => {
  const url = '/firebase/manage/category';
  return axios.put(url, data, config()).then((response) => response.data);
};

export const setManageCategoriesAsync = (data: CategoriesData) => {
  const url = '/firebase/manage/categories';
  return axios.put(url, data, config()).then((response) => response.data);
};

export const setManageExamAsync = (data: CategoryData) => {
  const url = '/firebase/manage/exam';
  return axios.put(url, data, config()).then((response) => response.data);
};

export const deleteManageAsync = (type: string, id: string) => {
  return axios
    .delete(`/firebase/manage/${type}/${id}`, config())
    .then((response) => response.data);
};

export const manageRemoveColecitionDoc = (path: string) => {
  return axios
    .delete(`/firebase/manage/remove-colection-doc?path=${path}`, config())
    .then((response) => response.data);
};
