import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Media, MediaSelect } from "./media.type";

export const mediasState = atom<Media[]>({
  key: "mediasState",
  default: []
});

export const mediaMetaState = atom<Meta>({
  key: "mediaMetaState",
  default: {}
});

export const mediasSelectState = atom<MediaSelect[]>({
  key: "mediasSelectState",
  default: []
});

export const mediaLoadingState = atom<boolean>({
  key: "mediaLoadingState",
  default: false
});
