import { useForm } from "antd/lib/form/Form";
import { Layout } from "../../../components/layout/layout.component";
import { TextLibraryForm } from "./components/text-library-form.component";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const TextLibraryFormPage = () => {
  const { t } = useTranslation();
  const title = t("Text Library");
  const [form] = useForm();
  const [isBack, setIsBack] = useState(false);

  return (
    <Layout
      title={title}
      onSubmit={isBack => {
        setIsBack(isBack || false);
        form.submit();
      }}
    >
      <TextLibraryForm form={form} isBack={isBack} />
    </Layout>
  );
};
