import { atom } from "recoil";

export const authState = atom({
  key: "authState",
  default: localStorage.getItem("accessToken") ? true : false
});

export const authUserState = atom({
  key: "authUserState",
  default: null
});

export const authErrorState = atom({
  key: "authErrorState",
  default: ""
});

export const authLoadingState = atom({
  key: "authLoadingState",
  default: false
});
