import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import { v4 as uuid } from 'uuid';
import { STORAGE_BASE_PATH } from '../constants/default-value.const';
import _ from 'lodash';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
export const storageRef = firebase.storage();

export const uploadPhotoMedia = async (media: any, uploadPath: string) => {
  return await uploadPhoto(media, uploadPath);
};

export const uploadPhoto = async (
  media: any,
  uploadPath: any,
  callback: (param?: string) => void = () => {}
) => {
  let type = media['type'].split('/')[1];
  const name = uuid();
  let nameAndExt = `${name}.${type}`;
  let path = `${uploadPath}/${nameAndExt}`;
  await storageRef
    .ref(path)
    .put(media)
    .on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        if (callback) callback(path);
        console.log('success');
      }
    );

  return {
    path,
    type,
    name,
    nameAndExt,
  };
};

export const storagePath = (path: string) => {
  if (!path) return '';
  return STORAGE_BASE_PATH.replace(
    '@@@@@',
    _.trim(path, '/').replace('/', '%2F')
  );
};

// const login = () => {
//   firebase.auth().signOut();

//   firebase
//     .firestore()
//     .collection('subjects')
//     .get()
//     .then((res) => {
//       console.log('-------------', res.docs.length);
//     });

//   return;

//   firebase
//     .auth()
//     .signInWithCustomToken(
//       'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY0Njk5MjY2MSwiZXhwIjoxNjQ2OTk2MjYxLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay0yZW05N0B0ZXNkb3BpLWFlYTVlLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstMmVtOTdAdGVzZG9waS1hZWE1ZS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6Imtha2EifQ.cKaflEIykfFpUUdph_wiT8ehax2hpBlenwZR4V9OtVkkRe6nMU8oIe7VeqBjahTVsi1ty0WWsxDxGcXUNbp6YlhPA2hew5ooB5dmWDWbJM4det0wxPQA7_hbsCTCVoy9DQek0GG5DD48UE7-LnvEPSP9ZLre1fnnpv0DeYF9U3tpDok3q5VrQol12DWFQNL0sDVd1k9PS9LVaD30s0MNPy36wqBJCFCljPGfsm0a_Ei-vuo_7AN0xllk4Q_oZkWeAbpizWCM3fho0GObW5gQlRZeqswJ0YwXNi6TjWZ8SVAAgVJPAsRTCuv6mTZmPN0_NWNVwYxPdMVLWdpsKShiVg'
//     )
//     .then((usr) => {
//       console.log({ usr });

//       firebase
//         .firestore()
//         .collection('subjects')
//         .get()
//         .then((res) => {
//           console.log('-------------', res.docs.length);
//         });
//     })
//     .catch((err) => {
//       console.log('------–', err.message);
//     });
// };

// login();

// const db = async () => {
//   const maxAccountIdDoc = await firebase
//     .database()
//     .ref('users/maxAccountId')
//     .get();

//   let maxAccountId = maxAccountIdDoc.val();

//   if (!maxAccountId) {
//     let lastUser = await firebase
//       .firestore()
//       .collection(`users`)
//       .orderBy('accountId', 'desc')
//       .orderBy('created', 'desc')
//       .limit(1)
//       .get();
//     if (lastUser.docs.length) {
//       maxAccountId = lastUser.docs[0].data().accountId;
//     }
//   }

//   const x = next(maxAccountId);

//   firebase.database().ref('users/maxAccountId').set(x);
//   console.log(111111, maxAccountId, x);
// };

// db();

export const testInsertUser = async () => {
  for (let i = 0; i < 100; i++) {
    console.log(i);
    firebase.firestore().collection(`users`).doc(`${i}`).set({ id: i });
  }
};

export const testDeleteUser = async () => {
  let arr = [];
  for (let i = 0; i < 100; i++) {
    arr.push(firebase.firestore().collection(`users`).doc(`${i}`).delete());
  }
  await Promise.all(arr);
};

export const incre = async () => {
  // let x = await firebase
  //   .database()
  //   .ref(`/users/maxAccountId`)
  //   .set(firebase.database.ServerValue.increment(1));

  // await firebase
  //   .database()
  //   .ref(`/users/${'xxx'}`)
  //   .set({
  //     accountId:
  //       (await firebase.database().ref(`/users/maxAccountId`).get()).val() ||
  //       0 + 1,
  //     userId: 'xxx',
  //   });

  // firebase
  //   .database()
  //   .ref(`/users/${'yyy'}`)
  //   .once('value', (value) => {
  //     console.log(22222222, value.val());
  //   });
  // let arr = [];
  // for (let i = 0; i < 99; i++) {
  // arr.push(
  firebase
    .database()
    .ref(`/users/maxAccountId`)
    .set(firebase.database.ServerValue.increment(1))
    .then(async () => {
      const v = (
        await firebase.database().ref(`/users/maxAccountId`).get()
      ).val();
      console.log(v);
      firebase.database().ref(`/users/${'yyyy'}`).set(v);
    });
  // );
  // }

  // await Promise.all(arr);
};

incre();

const sleep = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(time);
    }, time);
  });
};

export const retryNew = async (userId: string, retryCount = 0) => {
  await sleep(1000);
  const maxAccountId = (
    await firebase.database().ref(`/users/maxAccountId`).get()
  ).val();
  const userIdx = (
    await firebase.database().ref(`/users/${maxAccountId}`).get()
  ).val();

  if (!userIdx) {
    await firebase.database().ref(`/users/${maxAccountId}`).set(userId);
    return maxAccountId;
  } else if (userId === userIdx) {
    return maxAccountId;
  }
  retryCount = retryCount + 1;

  console.log(11111);

  if (retryCount > 10) return '000000';
  await retryNew(userId, retryCount);
};

// test();

// const testInsertUserDb = async () => {
//   for (let i = 0; i < 100; i++) {
//     console.log(i);
//     firebase.database().ref(`/users/${i}`).set({ id: i });
//     firebase.database().ref(`/users/counter`).set({ id: i });
//   }
// };

// testInsertUserDb();

// const transction = async () => {
//   for (let i = 0; i < 100; i++) {
//     console.log(i);
//     firebase.firestore().collection(`users`).doc(`${i}`).set({ id: i });
//   }
// };

// testInsertUser();
