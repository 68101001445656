import produce from "immer";
import { selector } from "recoil";
import { checkPermission, formatDate, isSyncable } from "../../utils/helper.utils";
import { toPaginationProperty } from "../../utils/request.utils";
import { reviewsState, reviewMetaState, reviewState } from "./review.atoms";
import _ from "lodash";
import { mapActivities } from "../../utils/response.utils";
import { authUserSelector } from "../auth/auth.selectors";
import { PrefixPermission } from "../../constants/permission.const";

export const reviewsSelector = selector({
  key: "reviewsSelector",
  get: ({ get }) => {
    const reviews = produce(get(reviewsState), draft => draft);
    const permissions = checkPermission(get(authUserSelector), PrefixPermission.COMPETENCY);
    return reviews.map((item, i) => {
      const { deletedAt, updatedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, "DD/MM/YYYY HH:mm:ss"),
        deletedAt: formatDate(deletedAt, "DD/MM/YYYY HH:mm:ss")
      };
    });
  }
});

export const reviewSelector = selector({
  key: "reviewSelector",
  get: ({ get }) => {
    const resource = produce(get(reviewState), draft => draft);
    const activities = _.get(resource, `reviewActivities`, []);

    return {
      ...resource,
      activities: mapActivities({ dataSources: activities, key: "reviewActivityLibraries" })
    };
  }
});

export const reviewMetaSelector = selector({
  key: "reviewMetaSelector",
  get: ({ get }) => {
    const meta = produce(get(reviewMetaState), draft => draft);
    return toPaginationProperty(meta);
  }
});
