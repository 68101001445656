import React, { Fragment } from "react";
import { VisibleProps } from "../../../types/global.types";
import { UserProfileFormModal } from "./user-profile-form-modal.component";
import { updateProfileUserAsync } from "../../../atom/user/user.apis";
import { messageSuccess, showMessageErrors } from "../../../utils/message.utils";
import { UserProfile } from "../../../atom/user/user.types";
import { STORAGE_PATH } from "../../../constants/default-value.const";
import { uploadPhoto } from "../../../utils/firebase.utils";
import _ from "lodash";
import { useApp } from "../../../atom/app/app.hook";
import { useAuth } from "../../../hooks/use-auth";

export const UserProfileComponent: React.FC<VisibleProps> = ({ visible, setVisible }) => {
  const { profile } = useAuth();
  const { setAppLoading } = useApp();

  // useEffect(() => {
  //   /*eslint-disable react-hooks/exhaustive-deps*/
  //   getProfile()
  //     .then(res => {
  //       setProfile(res);
  //     })
  //     .catch(err => {
  //       showMessageErrors(err);
  //       setProfile(null);
  //     });
  // }, []);

  const handleOk = async (value: UserProfile) => {
    setAppLoading(true);
    let profileUrl = profile?.profile;
    if (_.get(value, "profile.file")) {
      const photo = await uploadPhoto(_.get(value, "profile.file"), STORAGE_PATH.ADMIN_USER);
      profileUrl = photo.path;
    }

    const request = updateProfileUserAsync({ ...value, profile: profileUrl });
    request
      .then(({ message }) => {
        messageSuccess(message);
        handleCancel();
        setAppLoading(false);
      })
      .catch(err => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Fragment>
      <UserProfileFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={profile}
      />
    </Fragment>
  );
};
