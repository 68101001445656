import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { User, UserProfile } from "./user.types";
import { RestoreType, SortData } from "../../types/global.types";

export const getUsersAsync = (option: {}) => {
  const url = generateQuery("/admin-users", option, ["status", "roleId"]);
  return axios.get(url, config()).then(response => response.data);
};

export const getUsersSelectAsync = () => {
  const url = generateQuery("/admin-users/for/select-option");
  return axios.get(url, config()).then(response => response.data.data);
};

export const getUsersTrashAsync = (option: {}) => {
  const url = generateQuery("/admin-users/list/trash", option, ["status", "roleId"]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const createUserAsync = (data: User) => {
  return axios.post("/admin-users", data, config()).then(response => response.data);
};

export const restoreUsersAsync = (data: RestoreType) => {
  return axios.patch("/admin-users/restore", data, config()).then(response => response.data);
};

export const updateUserAsync = (id: string, data: User) => {
  return axios.put(`/admin-users/${id}`, data, config()).then(response => response.data);
};

export const deleteUserAsync = (id: string) => {
  return axios.delete(`/admin-users/${id}`, config()).then(response => response.data);
};

export const sortUsersAsync = (data: SortData) => {
  if (Math.random() > 0.5) return Promise.resolve(1);
  return Promise.reject("something went wrong");
  //   return axios.put(`/admin-users/${id}/status`, data, config()).then(response => response.data);
};

export const updateProfileUserAsync = (data: UserProfile) => {
  return axios.post(`/auth/update-profile`, data, config()).then(response => response.data);
};
