import { useEffect, useState } from "react";
import { Layout } from "../../../components/layout/layout.component";
import { Row, Col, Table } from "antd";
import { Container, FlexWrap, ElementFlex, FlexTitle } from "./usage.style";
import { PrinterOutlined } from "@ant-design/icons";
import { SelectForm } from "../../../components/form/select/select.component";
import { Column } from "@ant-design/charts";

export const UsageByWeekReportPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://gw.alipayobjects.com/os/antfincdn/mor%26R5yBI9/stack-group-column.json")
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => {
        console.log("fetch data failed", error);
      });
  }, []);

  const config = {
    data: data,
    xField: "product_type",
    yField: "order_amt",
    isGroup: true,
    isStack: true,
    seriesField: "product_sub_type",
    groupField: "sex"
  };

  return (
    <Layout>
      <Row gutter={1}>
        <Col span={8}>
          <Container>
            <FlexWrap>
              <ElementFlex>
                <FlexTitle>Usage Summary</FlexTitle>
              </ElementFlex>
              <ElementFlex style={{ marginLeft: "16px" }}>
                <PrinterOutlined />
              </ElementFlex>
            </FlexWrap>
          </Container>
        </Col>
        <Col span={16}>
          <Container style={{ height: "100%" }}>
            <FlexWrap style={{ float: "right", marginBottom: "-18px" }}>
              <ElementFlex style={{ width: "150px", marginRight: "16px" }}>
                <SelectForm name="grad" placeholder="Select Grade" dataSource={[]} />
              </ElementFlex>
              <ElementFlex style={{ width: "150px", marginRight: "16px" }}>
                <SelectForm name="date" placeholder="Filter Date" dataSource={[]} />
              </ElementFlex>
              <ElementFlex style={{ width: "150px", marginRight: "16px" }}>
                <SelectForm name="type" placeholder="User Type" dataSource={[]} />
              </ElementFlex>
            </FlexWrap>
          </Container>
        </Col>
      </Row>
      <Row gutter={1} style={{ marginTop: "1px" }}>
        <Col span={24}>
          <Container>
            <Column {...config} />
          </Container>
        </Col>
      </Row>
      <Row gutter={1} style={{ marginTop: "1px" }}>
        <Col span={24}>
          <Container>
            <Table scroll={{ x: 100 }} columns={columns} dataSource={dataTable} />
          </Container>
        </Col>
      </Row>
    </Layout>
  );
};

const dataTable = [
  {
    key: "1",
    name: "Math",
    age: 32,
    address: "1000",
    tags: ["nice", "developer"]
  },
  {
    key: "2",
    name: "Physics",
    age: 42,
    address: "2000",
    tags: ["loser"]
  },
  {
    key: "3",
    name: "Khmer",
    age: 32,
    address: "100",
    tags: ["cool", "teacher"]
  },
  {
    key: "4",
    name: "English",
    age: 32,
    address: "300",
    tags: ["cool", "teacher"]
  },
  {
    key: "5",
    name: "Biology",
    age: 32,
    address: "100",
    tags: ["cool", "teacher"]
  }
];
const columns = [
  {
    title: "Subject",
    dataIndex: "name",
    key: "name",
    render: (text: any) => <a href="#/">{text}</a>
  },
  {
    title: "Monday",
    dataIndex: "age",
    key: "age"
  },
  {
    title: "Tuesday",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Wednesday",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Thursday",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Friday",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Saturday",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Sunday",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Total",
    dataIndex: "address",
    key: "address"
  }
];
