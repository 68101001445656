import { Modal, Row, Col } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from '../../atom/app/app.hook';
import { useSelectOption } from '../../hooks/use-select-option.hook';
import { FormWrapper } from '../form/form.component';
import { SelectForm } from '../form/select/select.component';

interface PrivacyModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (value: any) => void;
}

export const PrivacyModal: React.FC<PrivacyModalProps> = ({
  visible,
  onCancel,
  onOk,
}) => {
  const { appAssignPrivacy } = useApp();

  const [form] = useForm();
  const { t } = useTranslation();
  const { users } = useSelectOption({ hasUser: true });

  useEffect(() => {
    form.setFieldsValue({
      privacy: appAssignPrivacy.public ? 'PUBLIC' : 'PRIVATE',
      createdBy: appAssignPrivacy.createdBy,
    });
  }, [appAssignPrivacy, form]);

  const _onOk = () => {
    // onOk({ id: 1 });

    form
      .validateFields()
      .then(({ createdBy, privacy }) => {
        // form.resetFields();
        // onCreate(values);
        // console.log(values);
        onOk({
          id: appAssignPrivacy.id,
          createdBy,
          public: privacy === 'PUBLIC' ? true : false,
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  // const onFinish = (value: any) => {
  //   console.log(value);
  // };

  return (
    <Modal
      title="Change Privacy"
      visible={visible}
      onOk={_onOk}
      onCancel={onCancel}
      maskClosable={false}
    >
      <FormWrapper form={form} name="competencyForm">
        <Row>
          <Col span={24}>
            <SelectForm
              name="createdBy"
              label={t('User')}
              placeholder={t('Select user')}
              rules={[
                {
                  required: true,
                  message: 'Please select the user!',
                },
              ]}
              dataSource={users}
            />
          </Col>
          <Col span={24}>
            <SelectForm
              name="privacy"
              label={t('Privacy')}
              placeholder={t('Select Pravacy')}
              rules={[
                {
                  required: true,
                  message: 'Please select the privacy!',
                },
              ]}
              dataSource={[
                { id: 'PRIVATE', name: 'PRIVATE' },
                { id: 'PUBLIC', name: 'PUBLIC' },
              ]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Modal>
  );
};
