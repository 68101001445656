import React from "react";
import { Switch, Route } from "react-router-dom";
import { routes } from "./routes";
import { RoutePaths } from "../constants/route.const";
import { PrivateRoute } from "./private.component";
import { PublicRoute } from "./public.component";
import { Loading } from "../components/loading/loading.component";
import { SignInPage } from "../pages/sign-in/sign-in.component";
import { SignUpPage } from "../pages/sign-up/sign-up.component";
import { NotFoundPage } from "../pages/not-found/not-found.component";
import { HomePage } from "../pages/home/home.component";

function RouteComponent() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <PrivateRoute exact path={RoutePaths.HOME} component={HomePage} />
        <PublicRoute restricted={false} path={RoutePaths.SIGN_IN} component={SignInPage} />
        <PublicRoute restricted={false} path={RoutePaths.SIGN_UP} component={SignUpPage} />

        {routes.map((route, i) => (
          <PrivateRoute exact key={i} {...route} />
        ))}

        <Route component={NotFoundPage} />
      </Switch>
    </React.Suspense>
  );
}

export default RouteComponent;
