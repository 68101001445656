import { useEffect, useState, ReactElement } from "react";
import { Button, Col, Row, Spin, message } from "antd";
import _ from "lodash";
import {
  createQuestionOrderAsync,
  updateQuestionOrderAsync
} from "../../../../atom/library/question.apis";
import { InputForm } from "../../../../components/form";
import { FormInfoSection } from "../../../../components/form-info-section/form-info-section.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { FORM_TYPE, QUESTION_TYPE } from "../../../../constants/default-value.const";
import useRoute from "../../../../hooks/use-route.hook";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { messageSuccess, showMessageErrors } from "../../../../utils/message.utils";
import { useLibrary } from "../../library/library.hook";
import { QuestionSection } from "./question-section.component";
import { TitleItem } from "../../../../components/title-item/title-item.component";
import { ContainFlex, FlexElement } from "./question.style";
import { AimOutlined, DeleteOutlined } from "@ant-design/icons";
import { LooseObject } from "../../../../types/global.types";
import { v4 as uuid } from "uuid";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { FormModalProps, LibraryFormValue } from "../../library/library.types";
import { useApp } from "../../../../atom/app/app.hook";
import { useTranslation } from "react-i18next";
import { requestTopicDefault, setSelectFilter } from "../../../../utils/helper.utils";
import { checkFormRedirect } from "../../../../utils/form.utils";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

interface Answer {
  key: string;
  value: string;
}

export interface FormValue extends LibraryFormValue {
  answer: LooseObject;
}

const defaultAnswersData = [
  { value: "", key: uuid() },
  { value: "", key: uuid() }
];

export const QuestionOrderForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false
}: FormModalProps) => {
  const {
    history,
    pathname,
    query: { formType },
    param: { id }
  } = useRoute();
  const { levels, subjects, topics } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true
  });
  const [forceRender, setForceRender] = useState(false);
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  const { loading, library } = useLibrary({ id: formModal ? formId : id, forceRender });
  const [dataAnswer, setDataAnswer] = useState<Answer[]>(defaultAnswersData);
  const { t } = useTranslation();
  useEnterEvent({ form });

  useEffect(() => {
    if (_.size(library)) {
      const { data }: LooseObject = library;

      let mapAnswers = _.get(data, "answer", []).map((item: Answer) => {
        return {
          ...item,
          key: uuid()
        };
      });

      setDataAnswer([...mapAnswers]);

      const answer = {};
      mapAnswers.forEach((item: Answer) => {
        const { value, key } = item;
        _.set(answer, `${key}.value`, value);
      });
      form.resetFields();
      form.setFieldsValue({
        ...library,
        topicId: _.get(library, "topic.id"),
        subjectId: _.get(library, "subject.id"),
        levelId: _.get(library, "level.id"),
        questionText: _.get(library, "data.questionText"),
        solution: _.get(library, "data.solution"),
        answer
      });
    }
    setSelectFilter({ dataSource: library, defaultFilter, form, elementId: "orderRequest" });
  }, [library, form, defaultFilter]);

  const addAnswerChoice = () => {
    setDataAnswer([...dataAnswer, { value: "", key: uuid() }]);
  };

  const onRemoveChoice = (key: string) => {
    setDataAnswer([...dataAnswer.filter((item: Answer) => item.key !== key)]);
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    let newSort = arrayMove(dataAnswer, oldIndex, newIndex);
    setDataAnswer([...newSort]);
  };

  const onFinish = (value: FormValue) => {
    const { questionText, solution, code, title, answer } = value;

    let dataAn = dataAnswer.map((value: Answer, index: number) => {
      const option = answer[value.key];
      return {
        value: option.value,
        order: index
      };
    });

    if (dataAn.length < 2) {
      message.error("Answer at least two needed.");
      return;
    }

    const data = {
      ...value,
      code: code,
      title: title,
      questionType: QUESTION_TYPE.ORDER,
      data: {
        questionText,
        answer: dataAn,
        solution: solution,
        type: QUESTION_TYPE.ORDER
      }
    };

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateQuestionOrderAsync(formId, data)
          : createQuestionOrderAsync(data);
      setAppLoading(true);
      request
        .then(res => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch(err => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateQuestionOrderAsync(id, data)
        : createQuestionOrderAsync(data);

    setAppLoading(true);
    request
      .then(res => {
        messageSuccess();
        let check = checkFormRedirect({ history, pathname, id: res.data.id, formType, isBack });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch(err => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div
        onClick={() =>
          requestTopicDefault({
            dataSource: library,
            defaultFilter,
            appFilter,
            setAppFilter,
            filterKey: "question"
          })
        }
        id="orderRequest"
      />
      <FormWrapper form={form} name="questionOrderForm" onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8} lg={8}>
            <FormInfoSection
              levels={levels}
              topics={topics}
              subjects={subjects}
              filterKey="question"
            />
          </Col>
          <Col sm={24} md={16} lg={16}>
            <QuestionSection form={form} dataSource={library}>
              <TitleItem title={t("Answers")} />
              <SorQuestionList
                onRemoveChoice={onRemoveChoice}
                dataAnswer={dataAnswer}
                onSortEnd={onSortEnd}
              />
              <FlexElement>
                <Button onClick={addAnswerChoice}>{t("Add choice")}</Button>
              </FlexElement>
            </QuestionSection>
          </Col>
        </Row>
      </FormWrapper>
    </Spin>
  );
};

const SorQuestionList = SortableContainer(
  ({
    dataAnswer,
    onRemoveChoice
  }: {
    dataAnswer: Answer[];
    onRemoveChoice: (key: string) => void;
  }) => {
    return (
      <div>
        {dataAnswer.map((item: Answer, index: number) => (
          <SortableItem
            key={item.key}
            index={index}
            value={
              <ContainFlex>
                <FlexElement style={{ width: "100%" }}>
                  <InputForm
                    name={["answer", item.key, "value"]}
                    className="w-100"
                    addonBefore={<AimOutlined style={{ color: "#00A98C" }} />}
                    addonAfter={<DeleteOutlined onClick={() => onRemoveChoice(item.key)} />}
                    placeholder="Answers"
                    rules={[{ required: true, message: "Answer is required!" }]}
                  />
                </FlexElement>
              </ContainFlex>
            }
          />
        ))}
      </div>
    );
  }
);

const SortableItem = SortableElement(({ value }: { value: ReactElement }) => (
  <div tabIndex={0}>{value}</div>
));
