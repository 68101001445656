import { Layout } from "../../../components/layout/layout.component";
import { useEffect, useState } from "react";
import { TableComponent } from "../../../components/table/table.component";
import { Switch } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import { columnTableEllipsis } from "../../../utils/table.utils";
interface DataRef {
  id: string;
  title: string;
}
export interface Report {
  key?: string;
  id: string;
  date: string;
  code: string;
  title: string;
  description: string;
  resolve: boolean;
  subject: DataRef;
  level: DataRef;
}

export const ReportPage = () => {
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    setColumns([
      {
        title: "Description",
        dataIndex: "description",
        isSearchable: true,
        sorter: (a: Report, b: Report) => a.title.length - b.title.length,
        editable: false,
        width: 250
      },
      {
        title: "Element Title",
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: Report, b: Report) => a.title.length - b.title.length,
        editable: false,
        width: 200
      },
      {
        title: "Element Code",
        dataIndex: "code",
        isSearchable: true,
        sorter: (a: Report, b: Report) => a.code.length - b.code.length,
        editable: false,
        width: 200
      },
      {
        title: "Subject",
        dataIndex: "subjectId",
        filters: [],
        ...columnTableEllipsis("subject")
      },
      {
        title: "Level",
        dataIndex: "levelId",
        filters: [],
        ...columnTableEllipsis("level")
      },
      {
        title: "Resolve",
        dataIndex: "resolve",
        filters: [],
        sorter: (a: Report, b: Report) => Number(a.resolve) - Number(b.resolve),
        render: (text: boolean, { id }: Report) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => console.log(id, value)}
          />
        )
      },

      {
        title: "Date",
        dataIndex: "date",
        sorter: (a: Report, b: Report) => a.date.length - b.date.length
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text: string, record: Report) => (
          <FileSearchOutlined onClick={() => console.log("[print]")} />
        )
      }
    ]);
  }, []);

  return (
    <Layout>
      <TableComponent
        dataSource={dataTest}
        columns={columns}
        onChange={() => console.log("[onTableChange]")}
        onColumnSearch={() => console.log("[onColumnSearch]")}
        pagination={{}}
      />
    </Layout>
  );
};

let dataTest = [
  {
    code: "R-001",
    id: "942e5102-1b29-494a-94ce-a765f6fabdb6",
    index: 0,
    key: "0",
    level: { id: "0d0cd08c-4d31-4748-93dc-07625a5b5b32", title: "Level 1" },
    resolve: true,
    subject: { id: "d0b85fd6-4278-4db0-aec5-ff4630804722", title: "Subject​ 1" },
    title: "Nullam blandit viverra nostra.",
    description: "Nullam blandit viverra nostra tristique litora pretium sed fames",
    date: "23/08/2021 14:13:33"
  },
  {
    code: "R-001",
    id: "942e5102-1b29-494a-94ce-a765f6fabdb6",
    index: 1,
    key: "1",
    level: { id: "0d0cd08c-4d31-4748-93dc-07625a5b5b32", title: "Level 1" },
    resolve: true,
    subject: { id: "d0b85fd6-4278-4db0-aec5-ff4630804722", title: "Subject​ 1" },
    title: "Nullam blandit viverra nostra.",
    description: "Nullam blandit viverra nostra tristique litora pretium sed fames",
    date: "23/08/2021 14:13:33"
  }
];
