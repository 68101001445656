import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atom/auth/auth.atoms";
import { RoutePaths } from "../constants/route.const";
import { LooseObject } from "../types/global.types";

export const PublicRoute = ({ component: Component, restricted, ...rest }: LooseObject) => {
  const authUser = useRecoilValue(authState);

  return (
    <Route
      {...rest}
      render={props =>
        authUser && restricted ? <Redirect to={RoutePaths.HOME} /> : <Component {...props} />
      }
    />
  );
};
