import { useForm } from "antd/lib/form/Form";
import { Layout } from "../../../components/layout/layout.component";
import { ExamTestForm } from "./components/exam-test-form.component";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RoutePaths } from "../../../constants/route.const";
import useRoute from "../../../hooks/use-route.hook";
import { replaceParam } from "../../../utils/helper.utils";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { examTestsSyncToFirestoreAsync } from "../../../atom/exam-test/exam-test.apis";

export const ExamTestFormPage = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isBack, setIsBack] = useState(false);
  const { param } = useRoute();

  const onSync = () => {
    showSyncConfirm(() => {
      examTestsSyncToFirestoreAsync([param.id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  return (
    <Layout
      title={t("Exam Test")}
      onSubmit={(isBack) => {
        setIsBack(isBack || false);
        form.submit();
      }}
      onPreview={() => {
        window.open(
          replaceParam(RoutePaths.MOBILE_PREVIEW_EXAM_TEST, param.id),
          "_blank"
        );
      }}
      onSync={onSync}
    >
      <ExamTestForm form={form} isBack={isBack} />
    </Layout>
  );
};
