import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { CompetencyTest } from './competency-test.types';

export const getCompetencyTestsAsync = (option: {}) => {
  const url = generateQuery('/test-competencies', option, [
    'levelId',
    'subjectId',
    'topicId',
    'status',
    'comingSoon',
    'premium',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getCompetencyTestAsync = (id: string) => {
  return axios
    .get(`/test-competencies/${id}`, config())
    .then((response) => response.data.data);
};

export const getCompetencyTestsTrashAsync = (option: {}) => {
  const url = generateQuery('/test-competencies/list/trash', option, [
    'levelId',
    'subjectId',
    'topicId',
    'status',
    'comingSoon',
    'premium',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getCompetencyTestSelectCompetencyTestsAsync = (
  data: RestoreType
) => {
  return axios
    .post('/test-competencies/list/selected-competency-test', data, config())
    .then((response) => response.data.data);
};

export const restoreCompetencyTestsAsync = (data: RestoreType) => {
  return axios
    .patch('/test-competencies/restore', data, config())
    .then((response) => response.data);
};

export const createCompetencyTestAsync = (data: CompetencyTest) => {
  return axios
    .post('/test-competencies', data, config())
    .then((response) => response.data);
};

export const updateCompetencyTestAsync = (id: string, data: CompetencyTest) => {
  return axios
    .put(`/test-competencies/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteCompetencyTestAsync = (id: string) => {
  return axios
    .delete(`/test-competencies/${id}`, config())
    .then((response) => response.data);
};

export const deleteCompetencyTestCompetenciesAsync = (ids: string[]) => {
  return axios
    .post(`/test-competency-competencies/deletes`, { ids }, config())
    .then((response) => response.data);
};

export const deleteCompetencyTestCompetencyAsync = (id: string) => {
  return axios
    .delete(`/test-competency-competencies/${id}`, config())
    .then((response) => response.data);
};

export const sortCompetencyTestsAsync = (data: SortData) => {
  return axios
    .patch(`test-competencies/set-rank`, data, config())
    .then((response) => response.data);
};

export const getCompetencyTestReferenceUsingAsync = (id: string) => {
  return axios
    .get(`/test-competencies/reference-using/${id}`, config())
    .then((response) => response.data.data);
};

export const competencyTestsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/test-competencies/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};

export const testCompetencyUnsyncFromFirestore = async (id: string) => {
  return axios
    .delete(`/test-competencies/unsync-from-firestore/${id}`, config())
    .then((response) => response.data);
};
