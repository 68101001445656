export const systemPermissions = {
  User: [
    { Create: "admin-user:user:create" },
    { List: "admin-user:user:findAll" },
    { View: "admin-user:user:findOne" },
    { Update: "admin-user:user:update" },
    { Delete: "admin-user:user:remove" },
    { "List Trash": "admin-user:user:trash" },
    { Restore: "admin-user:user:restore" }
  ],
  Role: [
    { Create: "admin-user:role:create" },
    { List: "admin-user:role:findAll" },
    { View: "admin-user:role:findOne" },
    { Update: "admin-user:role:update" },
    { Delete: "admin-user:role:remove" },
    { "List Trash": "admin-user:role:trash" },
    { Restore: "admin-user:role:restore" }
  ],
  Permission: [{ Create: "admin-user:permission:update" }, { View: "admin-user:permission:view" }],
  Category: [
    { Create: "master:category:create" },
    { List: "master:category:findAll" },
    { View: "master:category:findOne" },
    { Update: "master:category:update" },
    { Delete: "master:category:remove" },
    { "List Trash": "master:category:trash" },
    { Restore: "master:category:restore" }
  ],
  "Color Code": [
    { Create: "master:colorcode:create" },
    { List: "master:colorcode:findAll" },
    { View: "master:colorcode:findOne" },
    { Update: "master:colorcode:update" },
    { Delete: "master:colorcode:remove" },
    { "List Trash": "master:colorcode:trash" },
    { Restore: "master:colorcode:restore" }
  ],
  Level: [
    { Create: "master:level:create" },
    { List: "master:level:findAll" },
    { View: "master:level:findOne" },
    { Update: "master:level:update" },
    { Delete: "master:level:remove" },
    { "List Trash": "master:level:trash" },
    { Restore: "master:level:restore" }
  ],
  Subject: [
    { Create: "master:subject:create" },
    { List: "master:subject:findAll" },
    { View: "master:subject:findOne" },
    { Update: "master:subject:update" },
    { Delete: "master:subject:remove" },
    { "List Trash": "master:subject:trash" },
    { Restore: "master:subject:restore" }
  ],
  Topic: [
    { Create: "master:topic:create" },
    { List: "master:topic:findAll" },
    { View: "master:topic:findOne" },
    { Update: "master:topic:update" },
    { Delete: "master:topic:remove" },
    { "List Trash": "master:topic:trash" },
    { Restore: "master:topic:restore" }
  ],
  Media: [
    { Create: "master:media:create" },
    { List: "master:media:findAll" },
    { View: "master:media:findOne" },
    { Update: "master:media:update" },
    { Delete: "master:media:remove" },
    { "List Trash": "master:media:trash" },
    { Restore: "master:media:restore" }
  ],
  Library: [
    { Create: "library:library:create" },
    { List: "library:library:findAll" },
    { View: "library:library:findOne" },
    { Update: "library:library:update" },
    { Delete: "library:library:remove" },
    { "List Trash": "library:library:trash" },
    { Restore: "library:library:restore" }
  ],
  "Text Library": [
    { Create: "library:textlibrary:create" },
    { Update: "library:textlibrary:update" }
  ],
  "Question (MCQ & Input)": [
    { Create: "library:question:create" },
    { Update: "library:question:update" }
  ],
  "Order Question": [
    { Create: "library:orderquestion:create" },
    { Update: "library:orderquestion:update" }
  ],
  "Fill Gap Question": [
    { Create: "library:fillgapquestion:create" },
    { Update: "library:fillgapquestion:update" }
  ],
  "Drag Drop Question": [
    { Create: "library:dragdropquestion:create" },
    { Update: "library:dragdropquestion:update" }
  ],
  "Question Group": [
    { Create: "library:questiongroup:create" },
    { Update: "library:questiongroup:update" }
  ],
  "Video Library": [{ Create: "library:video:create" }, { Update: "library:video:update" }],
  "Audio Library": [{ Create: "library:audio:create" }, { Update: "library:audio:update" }],
  Competency: [
    { Create: "competency-group:competency:create" },
    { List: "competency-group:competency:findAll" },
    { View: "competency-group:competency:findOne" },
    { Update: "competency-group:competency:update" },
    { Delete: "competency-group:competency:remove" },
    { "List Trash": "competency-group:competency:trash" },
    { Restore: "competency-group:competency:restore" }
  ],
  "Competency Test": [
    { Create: "competency-group:testcompetency:create" },
    { List: "competency-group:testcompetency:findAll" },
    { View: "competency-group:testcompetency:findOne" },
    { Update: "competency-group:testcompetency:update" },
    { Delete: "competency-group:testcompetency:remove" },
    { "List Trash": "competency-group:testcompetency:trash" },
    { Restore: "competency-group:testcompetency:restore" }
  ],
  Learn: [
    { Create: "learn:learn:create" },
    { List: "learn:learn:findAll" },
    { View: "learn:learn:findOne" },
    { Update: "learn:learn:update" },
    { Delete: "learn:learn:remove" },
    { "List Trash": "learn:learn:trash" },
    { Restore: "learn:learn:restore" }
  ],
  Review: [
    { Create: "review:review:create" },
    { List: "review:review:findAll" },
    { View: "review:review:findOne" },
    { Update: "review:review:update" },
    { Delete: "review:review:remove" },
    { "List Trash": "review:review:trash" },
    { Restore: "review:review:restore" }
  ],
  Resource: [
    { Create: "resource:resource:create" },
    { List: "resource:resource:findAll" },
    { View: "resource:resource:findOne" },
    { Update: "resource:resource:update" },
    { Delete: "resource:resource:remove" },
    { "List Trash": "resource:resource:trash" },
    { Restore: "resource:resource:restore" }
  ],
  "Practice Test": [
    { Create: "practice:testpractice:create" },
    { List: "practice:testpractice:findAll" },
    { View: "practice:testpractice:findOne" },
    { Update: "practice:testpractice:update" },
    { Delete: "practice:testpractice:remove" },
    { "List Trash": "practice:testpractice:trash" },
    { Restore: "practice:testpractice:restore" }
  ],
  "Exam Test": [
    { Create: "exam:examtest:create" },
    { List: "exam:examtest:findAll" },
    { View: "exam:examtest:findOne" },
    { Update: "exam:examtest:update" },
    { Delete: "exam:examtest:remove" },
    { "List Trash": "exam:examtest:trash" },
    { Restore: "exam:examtest:restore" }
  ],
  "Competition Test": [
    { Create: "competition:competitiontest:create" },
    { List: "competition:competitiontest:findAll" },
    { View: "competition:competitiontest:findOne" },
    { Update: "competition:competitiontest:update" },
    { Delete: "competition:competitiontest:remove" },
    { "List Trash": "competition:competitiontest:trash" },
    { Restore: "competition:competitiontest:restore" }
  ],
  Course: [
    { Create: "course:course:create" },
    { List: "course:course:findAll" },
    { View: "course:course:findOne" },
    { Update: "course:course:update" },
    { Delete: "course:course:remove" },
    { "List Trash": "course:course:trash" },
    { Restore: "course:course:restore" }
  ]
};

export enum PrefixPermission {
  COURSE = "course:course",
  COMPETITION_TEST = "competition:competitiontest",
  EXAM_TEST = "exam:examtest",
  PRACTICE_TEST = "practice:testpractice",
  RESOURCE = "resource:resource",
  REVIEW = "review:review",
  LEARN = "learn:learn",
  COMPETENCY_TEST = "competency-group:testcompetency",
  COMPETENCY = "competency-group:competency",
  LIBRARY = "library:library",
  TEXT_LIBRARY = "library:textlibrary",
  VIDEO = "library:video",
  MEDIA = "master:media",
  TOPIC = "master:topic",
  SUBJECT = "master:subject",
  LEVEL = "master:level",
  COLOR_CODE = "master:colorcode",
  CATEGORY = "master:category",
  PERMISSION = "admin-user:permission",
  ROLE = "admin-user:role",
  USER = "admin-user:user"
}
