import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getResourceAsync } from "../../../../atom/resource/resource.apis";
import { resourceLoadingState, resourceState } from "../../../../atom/resource/resource.atoms";
import { resourceSelector } from "../../../../atom/resource/resource.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const useResource = ({ id, forceRender }: UseHookProps) => {
  const resource = useRecoilValue(resourceSelector);
  const setResource = useSetRecoilState(resourceState);
  const [loading, setLoading] = useRecoilState(resourceLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getResourceAsync(id)
        .then(res => {
          setResource(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }

    return () => {
      setResource({});
    };
  }, [id, forceRender, setResource, setLoading]);

  return {
    resource,
    loading,
    setLoading,
    setResource
  };
};
