import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { Media } from "./media.type";
import { SortData } from "../../types/global.types";

export const getMediasAsync = (option: {}) => {
  const url = generateQuery("/medias", option);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getMediasTrashAsync = (option: {}) => {
  const url = generateQuery("/medias/list/trash", option);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getMediasSelectAsync = () => {
  return axios.get("/medias/for/select-option", config()).then(response => response.data.data);
};

export const createMediaAsync = (data: Media) => {
  return axios.post("/medias", data, config()).then(response => response.data);
};

export const updateMediaAsync = (id: string, data: Media) => {
  return axios.put(`/medias/${id}`, data, config()).then(response => response.data);
};

export const deleteMediaAsync = (id: string) => {
  return axios.delete(`/medias/${id}`, config()).then(response => response.data);
};

export const sortMediasAsync = (data: SortData) => {
  if (Math.random() > 0.5) return Promise.resolve(1);
  return Promise.reject("something went wrong");
  //   return axios.put(`/medias/${id}/status`, data, config()).then(response => response.data);
};
