import { useForm } from 'antd/lib/form/Form';
import { Layout } from '../../../components/layout/layout.component';
import { PracticeTestForm } from './components/practice-test-form.component';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { replaceParam } from '../../../utils/helper.utils';
import { RoutePaths } from '../../../constants/route.const';
import useRoute from '../../../hooks/use-route.hook';
import { messageSuccess, showMessageErrors, showSyncConfirm } from '../../../utils/message.utils';
import { practiceTestsSyncToFirestoreAsync } from '../../../atom/practice-test/practice-test.apis';

export const PracticeTestFormPage = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [isBack, setIsBack] = useState(false);
  const { param } = useRoute();

  const onSync = () => {
    showSyncConfirm(() => {
      practiceTestsSyncToFirestoreAsync([param.id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  return (
    <Layout
      title={t('Practice Test')}
      onSubmit={(isBack) => {
        setIsBack(isBack || false);
        form.submit();
      }}
      onPreview={() => {
        window.open(
          replaceParam(RoutePaths.MOBILE_PREVIEW_PRACTICE_TEST, param.id),
          '_blank'
        );
      }}
      onSync={onSync}
    >
      <PracticeTestForm form={form} isBack={isBack} />
    </Layout>
  );
};
