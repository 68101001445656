import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getColorsSelectAsync } from '../atom/color/color.apis';
import { colorsSelectState } from '../atom/color/color.atoms';
import { colorsSelectSelector } from '../atom/color/color.selectors';
import { getSubjectsSelectAsync } from '../atom/subject/subject.apis';
import { subjectsSelectState } from '../atom/subject/subject.atoms';
import { subjectsSelectSelector } from '../atom/subject/subject.selectors';
import { topicsSelectSelector } from '../atom/topic/topic.selectors';
import {
  topicLevelIdState,
  topicsSelectState,
  topicSubjectIdState,
} from '../atom/topic/topic.atoms';
import { getTopicsSelectAsync } from '../atom/topic/topic.apis';
import { showMessageErrors } from '../utils/message.utils';
import { levelsSelectState } from '../atom/level/level.atoms';
import { levelsSelectSelector } from '../atom/level/level.selectors';
import { getLevelsSelectAsync } from '../atom/level/level.apis';
import { categoriesSelectState } from '../atom/category/category.atoms';
import { categoriesSelectSelector } from '../atom/category/category.selectors';
import { CATEGORY_TYPE } from '../constants/default-value.const';
import { getCategoriesSelectAsync } from '../atom/category/category.apis';
// import { levelIdState, subjectIdState } from "../atom/filter/filter.atoms";
import { mediasSelectState } from '../atom/media/media.atoms';
import { mediasSelectSelector } from '../atom/media/media.slectors';
import { getMediasSelectAsync } from '../atom/media/media.apis';
import { rolesSelectState } from '../atom/role/role.atoms';
import { rolesSelectSelector } from '../atom/role/role.selectors';
import { getRolesSelectAsync } from '../atom/role/role.apis';
import { usersSelectState } from '../atom/user/user.atoms';
import { usersSelectSelector } from '../atom/user/user.selectors';
import { getUsersSelectAsync } from '../atom/user/user.apis';
// import { appFilterKeyState, appFilterState } from "../atom/app/app.atoms";
// import _ from "lodash";
// import produce from "immer";

export interface UseSelectOptionParams {
  hasSubject?: boolean;
  hasUser?: boolean;
  hasTopic?: boolean;
  hasLevel?: boolean;
  hasColor?: boolean;
  hasResourceCategory?: boolean;
  hasTestCategory?: boolean;
  hasMedia?: boolean;
  hasRole?: boolean;
  filterKey?: string;
}

export const useSelectOption = ({
  hasSubject,
  hasTopic,
  hasLevel,
  hasColor,
  hasResourceCategory,
  hasTestCategory,
  hasMedia,
  hasRole,
  hasUser,
}: UseSelectOptionParams) => {
  const setUsers = useSetRecoilState(usersSelectState);
  const users = useRecoilValue(usersSelectSelector);
  const setSubjects = useSetRecoilState(subjectsSelectState);
  const subjects = useRecoilValue(subjectsSelectSelector);
  const setColors = useSetRecoilState(colorsSelectState);
  const colors = useRecoilValue(colorsSelectSelector);
  const setTopics = useSetRecoilState(topicsSelectState);
  const topics = useRecoilValue(topicsSelectSelector);
  const setLevels = useSetRecoilState(levelsSelectState);
  const levels = useRecoilValue(levelsSelectSelector);
  const setCategories = useSetRecoilState(categoriesSelectState);
  const categories = useRecoilValue(categoriesSelectSelector);
  const setMedias = useSetRecoilState(mediasSelectState);
  const medias = useRecoilValue(mediasSelectSelector);
  const subjectId = useRecoilValue(topicSubjectIdState);
  const levelId = useRecoilValue(topicLevelIdState);
  const setRoles = useSetRecoilState(rolesSelectState);
  const roles = useRecoilValue(rolesSelectSelector);
  // const [appFilter, setAppFilter] = useRecoilState(appFilterState);
  // const [appFilterKey, setAppFilterKey] = useRecoilValue(appFilterKeyState);

  useEffect(() => {
    if (hasMedia)
      getMediasSelectAsync()
        .then((res) => setMedias(res))
        .catch((err) => showMessageErrors(err));
  }, [setMedias, hasMedia]);

  useEffect(() => {
    if (hasSubject)
      getSubjectsSelectAsync()
        .then((res) => setSubjects(res))
        .catch((err) => showMessageErrors(err));
  }, [setSubjects, hasSubject]);

  useEffect(() => {
    if (hasUser)
      getUsersSelectAsync()
        .then((res) => setUsers(res))
        .catch((err) => showMessageErrors(err));
  }, [setUsers, hasUser]);

  useEffect(() => {
    if (hasRole)
      getRolesSelectAsync()
        .then((res) => {
          setRoles(res);
        })
        .catch((err) => showMessageErrors(err));
  }, [setRoles, hasRole]);

  useEffect(() => {
    if (hasColor)
      getColorsSelectAsync()
        .then((res) => setColors(res))
        .catch((err) => showMessageErrors(err));
  }, [setColors, hasColor]);

  useEffect(() => {
    if (hasTopic && subjectId && levelId)
      getTopicsSelectAsync({ s_subjectId: subjectId, s_levelId: levelId })
        .then((res) => {
          setTopics(res);
        })
        .catch((err) => showMessageErrors(err));
  }, [setTopics, hasTopic, subjectId, levelId]);

  useEffect(() => {
    if (hasLevel)
      getLevelsSelectAsync()
        .then((res) => {
          setLevels(res);
        })
        .catch((err) => showMessageErrors(err));
  }, [setLevels, hasLevel]);

  useEffect(() => {
    if (hasResourceCategory || hasTestCategory) {
      const type = hasResourceCategory
        ? CATEGORY_TYPE.RESOURCE
        : CATEGORY_TYPE.TEST;
      getCategoriesSelectAsync(type)
        .then((res) => setCategories(res))
        .catch((err) => showMessageErrors(err));
    }
  }, [setCategories, hasResourceCategory, hasTestCategory]);

  return {
    subjects,
    colors,
    topics,
    levels,
    medias,
    categories,
    roles,
    setTopics,
    users,
  };
};
