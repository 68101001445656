import React, { useEffect, useState } from 'react';
import { Col, Collapse, List, Row, Select, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { WrapFeature } from './app-feature.styles';
import {
  deleteManageAsync,
  getManageDatasAsync,
  getManageFeatureAsync,
  // setFirebaseSubjectAsync,
  setManageCategoriesAsync,
  setManageSubjectsAsync,
} from '../../../../atom/manage-feature/mange-feature.apis';
import {
  messageSuccess,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { CloseCircleTwoTone , SaveTwoTone } from '@ant-design/icons';
import _ from 'lodash';
import { ManageFeatureType } from '../../../../atom/manage-feature/mange-feature.type';

export const mapKey = (data: { id: string; title: string }[]) => {
  return data.reduce(
    (acc: { [key: string]: any }, cur: { id: string; title: string }) => {
      acc[cur.id] = cur;
      return acc;
    },
    {}
  );
};

const { Panel } = Collapse;
const { Option } = Select;

const check: any = {};
export const AppFeatureComponent: React.FC<any> = () => {
  const [type, setType] = useState<ManageFeatureType>(ManageFeatureType.COURSE);

  const [subjects, setSubjects] = useState({});
  const [levels, setLevels] = useState({});
  const [categories, setCategories] = useState({});
  const [objFeature, setObjFeature] = useState<any>({});

  const { t } = useTranslation();
  
  useEffect(() => {
    getManageDatasAsync()
      .then((res) => {
        setSubjects(mapKey(res.subjects));
        setLevels(mapKey(res.levels));
        setCategories(mapKey(res.categories));
      })
      .catch((err) => showMessageErrors(err));
  }, []);

  useEffect(() => {
    const func = async () => {
      if (_.size(subjects) && _.size(levels) && _.size(categories))
        try {
          // if (check[type] || type === ManageFeatureType.SUBJECT) {
          //   return;
          // }

          check[type] = true;
          const feature = await getManageFeatureAsync(type);

          if (
            type === ManageFeatureType.RESOURCE ||
            type === ManageFeatureType.TEST_EXAM
          ) {
            // const results = await getManageFeaturesAsync(type);
            const categoriesData = Object.entries(
              _.get(feature, 'category', {})
            ).map(([key, value]: any) => {
              return {
                id: key,
                subject: value.subject,
              };
            });

            const categoryResults = categoriesData.map((item: any) => {
              const category: any = _.get(categories, item.id, {});
              const subject = Object.entries(_.get(item, 'subject', {})).map(
                ([key, value]: any) => {
                  const subject: any = _.get(subjects, key);
                  const level = Object.entries(value.level).map(
                    ([key, value]: any) => {
                      const level: any = _.get(levels, key);
                      return {
                        id: key,
                        title: level.title,
                        rank: level.rank,
                        status: value,
                      };
                    }
                  );

                  return {
                    id: key,
                    title: subject.title,
                    rank: subject.rank,
                    status: value.status,
                    level: _.sortBy(level, ['rank']),
                  };
                }
              );
              return {
                id: item.id,
                title: category.title,
                rank: category.rank,
                subject: _.sortBy(subject, ['rank']),
              };
            });

            setObjFeature((state: any) => {
              return {
                ...state,
                [type]: _.sortBy(categoryResults, ['rank']),
              };
            });
            return;
          }
          //  else if (type === ManageFeatureType.TEST_EXAM) {
          //   const results = await getManageFeaturesAsync(type);
          //   const examResults = results.map(
          //     (item: {
          //       id: string;
          //       status: boolean;
          //       type: string;
          //       levelCategory: LooseObject;
          //     }) => {
          //       const subject = _.get(subjects, item.id, {});
          //       console.log(1);
          //       const level: any = Object.entries(item.levelCategory).map(
          //         ([key, value]) => {
          //           const level = _.get(levels, key);
          //           const category = Object.entries(value).map(
          //             ([key, value]) => {
          //               const category = _.get(categories, key);
          //               return {
          //                 id: key,
          //                 title: category.title,
          //                 status: value,
          //               };
          //             }
          //           );
          //           console.log(2);

          //           return {
          //             id: key,
          //             title: level.title,
          //             status: _.get(
          //               feature,
          //               `subjectLevel.${item.id}.${key}.status`,
          //               false
          //             ),
          //             level: category,
          //           };
          //         }
          //       );
          //       console.log(3);

          //       return {
          //         id: item.id,
          //         title: subject.title,
          //         subject: level,
          //       };
          //     }
          //   );

          //   setObjFeature((state: any) => {
          //     return {
          //       ...state,
          //       [type]: examResults,
          //     };
          //   });
          //   return;
          // }

          const subjectsData = Object.entries(
            _.get(feature, 'subject', {})
          ).map(([key, value]: any) => {
            return {
              id: key,
              level: value.level,
              status: value.status,
            };
          });

          const subjectResults = subjectsData.map((item: any) => {
            const subject: any = _.get(subjects, item.id, {});
            const level = Object.entries(item.level).map(([key, value]) => {
              const level = _.get(levels, key);
              return {
                id: key,
                title: level.title,
                rank: level.rank,
                status: value,
              };
            });
            return {
              id: item.id,
              title: subject.title,
              rank: subject.rank,
              status: item.status,
              level: _.sortBy(level, ['rank']),
            };
          });

          setObjFeature((state: any) => {
            return {
              ...state,
              [type]: _.sortBy(subjectResults, ['rank']),
            };
          });
        } catch (err) {
          showMessageErrors(err);
        }
    };
    func();
  }, [categories, levels, subjects, type]);

  const onChangeFeature = (value: ManageFeatureType) => {
    setType(value);
  };

  const enableSubject = (
    value: boolean,
    { rIndex, sIndex }: { rIndex: number; sIndex: number }
  ) => {
    setObjFeature((state: any) => {
      if (
        type === ManageFeatureType.RESOURCE ||
        type === ManageFeatureType.TEST_EXAM
      ) {
        _.set(state, `${type}.${rIndex}.subject.${sIndex}.status`, value);
      } else {
        _.set(state, `${type}.${sIndex}.status`, value);
      }
      return {
        ...state,
      };
    });
  };

  // const enabledCategory = (value: boolean, index: number) => {
  //   setObjFeature((state: any) => {
  //     _.set(state, `${type}.${index}.status`, value);

  //     return {
  //       ...state,
  //     };
  //   });
  // };

  const enabledLevel = (
    value: boolean,
    {
      rIndex,
      sIndex,
      lIndex,
    }: { rIndex: number; sIndex: number; lIndex: number }
  ) => {
    setObjFeature((state: any) => {
      if (
        type === ManageFeatureType.RESOURCE ||
        type === ManageFeatureType.TEST_EXAM
      ) {
        _.set(
          state,
          `${type}.${rIndex}.subject.${sIndex}.level.${lIndex}.status`,
          value
        );
      } else {
        _.set(state, `${type}.${sIndex}.level.${lIndex}.status`, value);
      }
      return {
        ...state,
      };
    });
  };

  const genExtraSubject = (
    subject: {
      id: string;
      title: string;
      status: boolean;
    },
    { rIndex, sIndex }: { rIndex: number; sIndex: number }
  ) => (
    <Space>
      {/* {rIndex < 0 && (
        <SaveOutlined
          onClick={(event) => {
            event.stopPropagation();
            saveSubject(sIndex);
          }}
        />
      )} */}
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Switch
          size="small"
          checked={subject.status}
          onChange={(value) => enableSubject(value, { rIndex, sIndex })}
        />
      </div>
      <CloseCircleTwoTone
        onClick={(event) => {
          event.stopPropagation();
          deleteSubject({ rIndex, sIndex });
        }}
      />
    </Space>
  );

  const saveData = async () => {
    try {
      if (
        [ManageFeatureType.COURSE, ManageFeatureType.TEST_PRACTICE].includes(
          type
        )
      ) {
        const subjects = objFeature[type];
        const dataSubjects = subjects.map((subject: any) => {
          const level = _.get(subject, 'level', [])
            .filter((item: any) => item)
            .map((item: { id: string; status: boolean }) => {
              return { id: item.id, status: item.status };
            });

          return {
            id: subject.id,
            status: subject.status,
            level,
          };
        });

        await setManageSubjectsAsync({
          type,
          subjects: dataSubjects,
        });
      } else if (
        type === ManageFeatureType.RESOURCE ||
        type === ManageFeatureType.TEST_EXAM
      ) {
        const categories = objFeature[type];
        const dataCategories = categories.map((category: any) => {
          const subject = _.get(category, 'subject', []).map((subject: any) => {
            const level = _.get(subject, 'level', []).map((level: any) => {
              return {
                id: level.id,
                status: level.status,
              };
            });
            return {
              id: subject.id,
              status: subject.status,
              value: level,
            };
          });

          return {
            id: category.id,
            data: subject,
          };
        });

        await setManageCategoriesAsync({
          type,
          data: dataCategories,
        });
      }

      messageSuccess();
    } catch (err) {
      showMessageErrors(err);
    }
  };

  const genExtraLevel = (
    level: {
      id: string;
      title: string;
      status: boolean;
    },
    {
      rIndex,
      sIndex,
      lIndex,
    }: { rIndex: number; sIndex: number; lIndex: number }
  ) => (
    <Space>
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Switch
          size="small"
          checked={level.status}
          onChange={(value) =>
            enabledLevel(value, {
              rIndex,
              sIndex,
              lIndex,
            })
          }
        />
      </div>
      <CloseCircleTwoTone
        onClick={(event) => {
          event.stopPropagation();
          deleteLevel({
            rIndex,
            sIndex,
            lIndex,
          });
        }}
      />
    </Space>
  );

  // const saveCategory = async (index: number) => {
  //   try {
  //     const category = objFeature[type][index];

  //     const data = _.get(category, 'subject', [])
  //       .filter((item: any) => item)
  //       .map(
  //         (item: {
  //           id: string;
  //           status: boolean;
  //           level: { id: string; status: boolean }[];
  //         }) => {
  //           const level = item.level
  //             .filter((item2) => item2)
  //             .map((item2: { id: string; status: boolean }) => {
  //               return { id: item2.id, status: item2.status };
  //             });
  //           return { id: item.id, status: item.status, value: level };
  //         }
  //       );

  //     if (type === ManageFeatureType.RESOURCE)
  //       await setManageCategoryAsync({
  //         id: category.id,
  //         data,
  //         // type,
  //       });
  //     else {
  //       await setManageExamAsync({
  //         id: category.id,
  //         data,
  //         // type,
  //       });
  //     }

  //     messageSuccess();
  //   } catch (err) {
  //     showMessageErrors(err);
  //   }
  // };

  const genExtraCategory = (
    category: {
      id: string;
      title: string;
      status: boolean;
    },
    index: number
  ) => (
    <Space>
      {/* <SaveOutlined
        onClick={(event) => {
          event.stopPropagation();
          saveCategory(index);
        }}
      /> */}
      {/* <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Switch
          size="small"
          checked={category.status}
          onChange={(value) => enabledCategory(value, index)}
        />
      </div> */}
      <CloseCircleTwoTone
        onClick={(event) => {
          event.stopPropagation();
          deleteCategory(index);
        }}
      />
    </Space>
  );

  // const genExtraMainSubject = (subject: any) => (
  //   <Space>
  //     <SaveOutlined
  //       onClick={(event) => {
  //         event.stopPropagation();
  //         saveMainSubject(subject);
  //       }}
  //     />
  //   </Space>
  // );

  // const saveMainSubject = async (subject: any) => {
  //   try {
  //     const data = {
  //       id: subject.id,
  //       statusFeature: {
  //         COURSE: false,
  //         REVIEW: false,
  //         TEST_EXAM: false,
  //         TEST_COMPETENCY: false,
  //         TEST_COMPETITION: false,
  //         TEST_PRACTICE: false,
  //         RESOURCE: false,
  //         ...subject.statusFeature,
  //       },
  //     };
  //     await setFirebaseSubjectAsync(data);
  //     messageSuccess();
  //   } catch (err) {
  //     showMessageErrors(err);
  //   }
  // };

  // const enabledFeature = (value: boolean, subject: any, feature: string) => {
  //   setSubjects((state) => {
  //     _.set(state, `${subject.id}.statusFeature.${feature}`, value);
  //     return {
  //       ...state,
  //     };
  //   });
  // };

  // const genExtraMainFeature = (
  //   subject: any,
  //   feature: string,
  //   status: boolean
  // ) => (
  //   <Space>
  //     <div
  //       onClick={(event) => {
  //         event.stopPropagation();
  //       }}
  //     >
  //       <Switch
  //         size="small"
  //         checked={status}
  //         onChange={(value) => enabledFeature(value, subject, feature)}
  //       />
  //     </div>
  //   </Space>
  // );

  const deleteLevel = ({
    rIndex,
    sIndex,
    lIndex,
  }: {
    rIndex: number;
    sIndex: number;
    lIndex: number;
  }) => {
    setObjFeature((state: any) => {
      if (
        type === ManageFeatureType.RESOURCE ||
        type === ManageFeatureType.TEST_EXAM
      ) {
        delete state[type][rIndex]['subject'][sIndex]['level'][lIndex];
      } else {
        delete state[type][sIndex]['level'][lIndex];
      }
      return {
        ...state,
      };
    });
  };

  const deleteSubject = ({
    rIndex,
    sIndex,
  }: {
    rIndex: number;
    sIndex: number;
  }) => {
    setObjFeature((state: any) => {
      if (
        type === ManageFeatureType.RESOURCE ||
        type === ManageFeatureType.TEST_EXAM
      ) {
        // delete state[type][rIndex]['subject'][sIndex];
        state[type][rIndex]['subject'] = state[type][rIndex]['subject'].filter(
          (item: any, i: number) => i !== sIndex
        );
      } else {
        // delete state[type][sIndex];
        state[type] = state[type].filter(
          (item: any, i: number) => i !== sIndex
        );
      }
      return {
        ...state,
      };
    });
  };

  const deleteCategory = async (index: number) => {
    try {
      let id = '';
      setObjFeature((state: any) => {
        id = state[type][index].id;
        state[type] = state[type].filter((item: any, i: number) => i !== index);

        return {
          ...state,
        };
      });
      await deleteManageAsync(type, id);
      messageSuccess();
    } catch (err) {
      showMessageErrors(err);
    }
  };

  const onLevelChange = (
    value: string,
    { rIndex, sIndex }: { rIndex: number; sIndex: number },
    option: any
  ) => {
    const level = _.get(option, value, {});
    const data = {
      ...level,
      status: true,
    };
    setObjFeature((state: any) => {
      if (
        type === ManageFeatureType.RESOURCE ||
        type === ManageFeatureType.TEST_EXAM
      ) {
        state[type][rIndex]['subject'][sIndex]['level'].push(data);
      } else {
        state[type][sIndex]['level'].push(data);
      }
      return {
        ...state,
      };
    });
  };

  const onSubjectChange = (
    value: string,
    { rIndex }: { rIndex: number },
    option: any
  ) => {
    const subject: any = _.get(option, value, {});

    const data = {
      id: subject.id,
      title: subject.title,
      status: true,
      level: [],
    };
    setObjFeature((state: any) => {
      if (
        type === ManageFeatureType.RESOURCE ||
        type === ManageFeatureType.TEST_EXAM
      ) {
        state[type][rIndex]['subject'].push(data);
      } else {
        state[type].push(data);
      }
      return {
        ...state,
      };
    });
  };

  const onCategoryChange = (value: string, option: any) => {
    const subject: any = _.get(option, value, {});

    const data = {
      id: subject.id,
      title: subject.title,
      status: true,
      subject: [],
    };
    setObjFeature((state: any) => {
      state[type].push(data);
      return {
        ...state,
      };
    });
  };

  const selectLevel = (
    {
      rIndex,
      sIndex,
    }: {
      rIndex: number;
      sIndex: number;
    },
    option: any = null,
    label: string = 'Select Level'
  ) => (
    <Select
      placeholder={t(label)}
      style={{ width: '100%' }}
      onChange={(value) =>
        onLevelChange(value as string, { rIndex, sIndex }, option || levels)
      }
    >
      {Object.values(option || levels).map((item: any) => (
        <Option value={item.id} key={item.id}>
          {item.title}
        </Option>
      ))}
    </Select>
  );

  const selectSubject = (
    { rIndex }: { rIndex: number },
    option: any = null,
    label: string = 'Select Subject'
  ) => (
    <Select
      placeholder={t(label)}
      style={{ width: '100%' }}
      onChange={(event) =>
        onSubjectChange(event as string, { rIndex }, option || subjects)
      }
    >
      {Object.values(option || subjects).map((item: any) => (
        <Option value={item.id} key={item.id}>
          {item.title}
        </Option>
      ))}
    </Select>
  );

  const selectCategory = (option: any = null, label = 'Select Category') => (
    <Select
      placeholder={t(label)}
      style={{ width: '100%' }}
      onChange={(event) =>
        onCategoryChange(event as string, option || categories)
      }
    >
      {Object.values(option || categories).map((item: any) => (
        <Option value={item.id} key={item.id}>
          {item.title}
        </Option>
      ))}
    </Select>
  );

  return (
    <WrapFeature>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <List
            bordered
            dataSource={Object.values(ManageFeatureType)}
            renderItem={(item) => (
              <List.Item
                className={type === item ? 'active' : ''}
                onClick={() => onChangeFeature(item)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>{_.startCase(_.camelCase(item.split('_').join(' ')))}</div>
                {/* {![
                  ManageFeatureType.SUBJECT,
                ].includes(item) && ( */}
                <div>
                  <SaveTwoTone  onClick={saveData}></SaveTwoTone>
                </div>
                {/* )} */}
              </List.Item>
            )}
          />
        </Col>
        <Col span={16}>
          {(type === ManageFeatureType.RESOURCE ||
            type === ManageFeatureType.TEST_EXAM) && (
            <>
              {_.get(objFeature, type, []).map(
                (
                  category: {
                    id: string;
                    title: string;
                    status: boolean;
                    subject: any[];
                  },
                  rIndex: number
                ) => {
                  return (
                    <Collapse>
                      <Panel
                        header={category.title}
                        key={category.id}
                        extra={genExtraCategory(category, rIndex)}
                      >
                        {category.subject.map(
                          (
                            subject: {
                              id: string;
                              title: string;
                              status: boolean;
                              level: any[];
                            },
                            sIndex
                          ) => {
                            return (
                              <Collapse>
                                <Panel
                                  header={subject.title}
                                  key={subject.id}
                                  extra={genExtraSubject(subject, {
                                    rIndex,
                                    sIndex,
                                  })}
                                >
                                  {subject.level.map(
                                    (
                                      level: {
                                        id: string;
                                        title: string;
                                        status: boolean;
                                      },
                                      lIndex: number
                                    ) => {
                                      return (
                                        <Collapse collapsible={'disabled'}>
                                          <Panel
                                            header={level.title}
                                            key={level.id}
                                            extra={genExtraLevel(level, {
                                              rIndex,
                                              sIndex,
                                              lIndex,
                                            })}
                                          ></Panel>
                                        </Collapse>
                                      );
                                    }
                                  )}
                                  {selectLevel({ rIndex, sIndex })}
                                </Panel>
                              </Collapse>
                            );
                          }
                        )}
                        {selectSubject({ rIndex })}
                      </Panel>
                    </Collapse>
                  );
                }
              )}
              {selectCategory()}
            </>
          )}

          {/* {type === ManageFeatureType.TEST_EXAM && (
            <>
              {_.get(objFeature, type, []).map(
                (
                  category: {
                    id: string;
                    title: string;
                    status: boolean;
                    subject: any[];
                  },
                  rIndex: number
                ) => {
                  return (
                    <Collapse>
                      <Panel
                        header={category.title}
                        key={category.id}
                        extra={genExtraCategory(category, rIndex)}
                      >
                        {category.subject.map(
                          (
                            subject: {
                              id: string;
                              title: string;
                              status: boolean;
                              level: any[];
                            },
                            sIndex
                          ) => {
                            return (
                              <Collapse>
                                <Panel
                                  header={subject.title}
                                  key={subject.id}
                                  extra={genExtraSubject(subject, {
                                    rIndex,
                                    sIndex,
                                  })}
                                >
                                  {subject.level.map(
                                    (
                                      level: {
                                        id: string;
                                        title: string;
                                        status: boolean;
                                      },
                                      lIndex: number
                                    ) => {
                                      return (
                                        <Collapse collapsible={'disabled'}>
                                          <Panel
                                            header={level.title}
                                            key={level.id}
                                            extra={genExtraLevel(level, {
                                              rIndex,
                                              sIndex,
                                              lIndex,
                                            })}
                                          ></Panel>
                                        </Collapse>
                                      );
                                    }
                                  )}
                                  {selectLevel(
                                    { rIndex, sIndex },
                                    categories,
                                    'Select Category'
                                  )}
                                </Panel>
                              </Collapse>
                            );
                          }
                        )}
                        {selectSubject({ rIndex }, levels, 'Select Level')}
                      </Panel>
                    </Collapse>
                  );
                }
              )}
              {selectCategory(subjects, 'Select Subject')}
            </>
          )} */}

          {/* {type === ManageFeatureType.SUBJECT &&
            Object.values(subjects).map((subject: any, index) => {
              return (
                <Collapse>
                  <Panel
                    header={subject.title}
                    key={subject.id}
                    extra={genExtraMainSubject(subject)}
                  >
                    {Object.values(ManageFeatureType)
                      .filter((item) => item !== ManageFeatureType.SUBJECT)
                      .map((item) => {
                        const status = subject.statusFeature[item] || false;
                        return (
                          <Collapse collapsible={'disabled'}>
                            <Panel
                              header={_.startCase(
                                _.camelCase(item.split('_').join(' '))
                              )}
                              key={item}
                              extra={genExtraMainFeature(subject, item, status)}
                            ></Panel>
                          </Collapse>
                        );
                      })}
                  </Panel>
                </Collapse>
              );
            })} */}

          {[ManageFeatureType.COURSE, ManageFeatureType.TEST_PRACTICE].includes(
            type
          ) && (
            <>
              {_.get(objFeature, type, []).map(
                (
                  subject: {
                    id: string;
                    title: string;
                    status: boolean;
                    level: { id: string; title: string; status: boolean }[];
                  },
                  sIndex: number
                ) => {
                  return (
                    <Collapse>
                      <Panel
                        header={subject.title}
                        key={subject.id}
                        extra={genExtraSubject(subject, {
                          rIndex: -1,
                          sIndex,
                        })}
                      >
                        {subject.level.map(
                          (
                            level: {
                              id: string;
                              title: string;
                              status: boolean;
                            },
                            lIndex: number
                          ) => {
                            return (
                              <Collapse collapsible={'disabled'}>
                                <Panel
                                  header={level.title}
                                  key={level.id}
                                  extra={genExtraLevel(level, {
                                    rIndex: -1,
                                    sIndex,
                                    lIndex,
                                  })}
                                ></Panel>
                              </Collapse>
                            );
                          }
                        )}
                        {selectLevel({ rIndex: -1, sIndex })}
                      </Panel>
                    </Collapse>
                  );
                }
              )}
              {selectSubject({ rIndex: -1 })}
            </>
          )}
        </Col>
      </Row>
    </WrapFeature>
  );
};
