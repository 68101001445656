// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Subject, SubjectSelect } from "./subject.type";

export const subjectsState = atom<Subject[]>({
  key: "subjectsState",
  default: []
});

export const subjectsSelectState = atom<SubjectSelect[]>({
  key: "subjectsSelectState",
  default: []
});

export const subjectMetaState = atom<Meta>({
  key: "subjectMetaState",
  default: {}
});

export const subjectLoadingState = atom<boolean>({
  key: "subjectLoadingState",
  default: false
});
