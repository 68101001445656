import { SearchResponse } from '../atom/search/search.types';
import {
  EntityName,
  FORM_TYPE,
  LIBRARY_TYPE,
  QUESTION_TYPE,
} from '../constants/default-value.const';
import { RoutePaths } from '../constants/route.const';
import { replaceParam } from './helper.utils';

export const checkFormRedirect = ({
  history,
  pathname,
  id,
  formType,
  isBack,
}: {
  history: any;
  pathname: string;
  id: string;
  formType: string;
  isBack: boolean | undefined;
}): boolean => {
  if (isBack) {
    historyBackList({ history, pathname, formType });
    return false;
  }

  if (formType === FORM_TYPE.EDIT) return true;

  if (formType === FORM_TYPE.DUPLICATE) {
    const paths = pathname.split('/');
    paths.pop();
    history.replace(`${paths.join('/')}/${id}?formType=${FORM_TYPE.EDIT}`);
    // history.goBack();
    return false;
  }

  history.replace(`${pathname}/${id}?formType=${FORM_TYPE.EDIT}`);
  // history.goBack();
  return false;
};

export const historyBackList = ({
  history,
  pathname,
  formType,
}: {
  history: any;
  pathname?: string;
  formType?: string;
}) => {
  // const paths = pathname.split('/');
  // paths.pop();
  // if (formType) paths.pop();
  // if (paths.join('/').includes('questions')) {
  //   paths.pop();
  //   paths.push('questions');
  //   history.replace(paths.join('/'));
  //   history.goBack();
  // } else {
  //   history.replace(paths.join('/'));
  //   history.goBack();
  // }

  history.goBack();
};

export const switchQuestionForm = (type: string, id: string) => {
  // const type = _.get(record, "data.type");

  if (type === LIBRARY_TYPE.TEXT)
    return replaceParam(RoutePaths.TEXT_LIBRARIES_FORM, id);
  if (type === LIBRARY_TYPE.VIDEO)
    return replaceParam(RoutePaths.VIDEOS_FORM, id);
  if (type === QUESTION_TYPE.DRAG_DROP)
    return replaceParam(RoutePaths.QUESTIONS_DRAG_DROP_FORM, id);
  if (type === QUESTION_TYPE.INPUT)
    return replaceParam(RoutePaths.QUESTIONS_INPUT_FORM, id);
  if (type === QUESTION_TYPE.MCQ)
    return replaceParam(RoutePaths.QUESTIONS_MCQ_FORM, id);
  if (type === QUESTION_TYPE.FILL_GAP)
    return replaceParam(RoutePaths.QUESTIONS_FILL_GAP_FORM, id);
  if (type === QUESTION_TYPE.ORDER)
    return replaceParam(RoutePaths.QUESTIONS_ORDER_FORM, id);
  return replaceParam(RoutePaths.QUESTIONS_GROUP_FORM, id);
};

export const redirectRoute = (data: SearchResponse) => {
  if (data.entityName === EntityName.LIBRARY)
    return `${switchQuestionForm(
      data.questionType || data.type,
      data.id
    )}?formType=${FORM_TYPE.EDIT}`;
  else if (data.entityName === EntityName.COMPETENCY)
    return `${replaceParam(RoutePaths.COMPETENCIES_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  else if (data.entityName === EntityName.LEARN)
    return `${replaceParam(RoutePaths.LEARNS_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  else if (data.entityName === EntityName.RESOURCE)
    return `${replaceParam(RoutePaths.RESOURCES_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  else if (data.entityName === EntityName.REVIEW)
    return `${replaceParam(RoutePaths.REVIEWS_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  else if (data.entityName === EntityName.COURSE)
    return `${replaceParam(RoutePaths.COURSES_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  else if (data.entityName === EntityName.COMPETITION_TEST)
    return `${replaceParam(
      RoutePaths.COMPETENCY_TESTS_FORM,
      data.id
    )}?formType=${FORM_TYPE.EDIT}`;
  else if (data.entityName === EntityName.EXAM_TEST)
    return `${replaceParam(RoutePaths.EXAM_TESTS_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  else if (data.entityName === EntityName.TEST_PRACTICE)
    return `${replaceParam(RoutePaths.PRACTICE_TESTS_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  else if (data.entityName === EntityName.TEST_PRACTICE)
    return `${replaceParam(RoutePaths.PRACTICE_TESTS_FORM, data.id)}?formType=${
      FORM_TYPE.EDIT
    }`;
  // else if (data.entityName === EntityName.SUBJECT)
  //   return `${replaceParam(RoutePaths.SUBJECTS, data.id)}?formType=${FORM_TYPE.EDIT}`;
  // else if (data.entityName === EntityName.LEVEL)
  //   return `${replaceParam(RoutePaths.LEVELS, data.id)}?formType=${FORM_TYPE.EDIT}`;
  // else if (data.entityName === EntityName.TOPIC)
  //   return `${replaceParam(RoutePaths.TOPICS, data.id)}?formType=${FORM_TYPE.EDIT}`;
  // else if (data.entityName === EntityName.MEDIA)
  //   return `${replaceParam(RoutePaths.IMAGES, data.id)}?formType=${FORM_TYPE.EDIT}`;
  // else if (data.entityName === EntityName.CATEGORY)
  //   return `${replaceParam(RoutePaths.RESOURCE_CATEGORIES, data.id)}?formType=${FORM_TYPE.EDIT}`;

  return '/';
};
