import { Form, Tag, Tooltip } from 'antd';
import { PropsType } from './select.type';
import { StyleSelect, StyleOption, WrapMedia } from './select.styles';
import { ColorPreview } from '../../color-preview/color-preview.component';
import { getTagCode } from '../../../utils/helper.utils';

export const SelectForm: React.FC<PropsType> = ({
  label,
  required,
  tooltip,
  placeholder,
  disabled,
  dataSource,
  name,
  rules,
  onChange,
}) => {
  return (
    <>
      <Tooltip placement="topLeft" title={placeholder}>
        <Form.Item
          name={name}
          rules={rules}
          tooltip={tooltip}
          labelCol={{ span: 24 }}
          required={required}
          label={label}
        >
          <StyleSelect
            disabled={disabled}
            allowClear={true}
            placeholder={placeholder}
            onChange={onChange}
            showSearch={true}
            filterOption={(input, option: any) => {
              if (typeof option.props.children !== 'string') {
                const index = dataSource.findIndex((item) =>
                  item.name.toLowerCase().includes(input.toLowerCase())
                );

                return (
                  index >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }

              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              );
            }}
          >
            {dataSource.map(({ id, name: textName, path }) => {
              const { text, code } = getTagCode(textName);
              return (
                <StyleOption value={id} key={id}>
                  {(() => {
                    if (name === 'colorCodeId')
                      return <ColorPreview color={textName} />;
                    if (name === 'mediaId')
                      return (
                        <WrapMedia>
                          <img
                            alt="example"
                            className="img-select"
                            src={path ? path : ''}
                          />{' '}
                          {textName}
                        </WrapMedia>
                      );
                    return code ? (
                      <>
                        <Tag color="cyan" style={{minWidth:100,textAlign:'center'}}>{code}</Tag>
                        {text}
                      </>
                    ) : (
                      textName
                    );
                  })()}
                </StyleOption>
              );
            })}
          </StyleSelect>
        </Form.Item>
      </Tooltip>
    </>
  );
};
