import { Col, Form, Row } from "antd";
import React, { Fragment, useEffect } from "react";
import { Role } from "../../../../atom/role/role.type";
import { InputForm, SelectForm } from "../../../../components/form";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { useTranslation } from "react-i18next";
import { ROLE_TYPES } from "../../../../constants/default-value.const";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

interface RoleFormProps {
  dataSource?: any;
  handleCancel?: () => void;
  handleOk: (value: Role) => void;
}
export const RoleForm: React.FC<RoleFormProps> = ({ dataSource, handleCancel, handleOk }) => {
  const [form] = Form.useForm();
  useEnterEvent({ form });
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({ ...dataSource });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="roleForm" form={form} onFinish={onFinish}>
        <FormModalHeader title={t("Role")} handleCancel={handleCancel} />
        <InputForm name="id" placeholder="ID" className="d-none" />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputForm
              name="name"
              placeholder={t("Name")}
              rules={[
                {
                  required: true,
                  message: "Please input the name!"
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <SelectForm
              name="alias"
              placeholder={t("Alias")}
              rules={[
                {
                  required: true,
                  message: "Please select the alias!"
                }
              ]}
              dataSource={ROLE_TYPES}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
