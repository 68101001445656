// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Color, ColorSelect } from "./color.types";

export const colorsState = atom<Color[]>({
  key: "colorsState",
  default: []
});

export const colorsSelectState = atom<ColorSelect[]>({
  key: "colorsSelectState",
  default: []
});

export const colorMetaState = atom<Meta>({
  key: "colorMetaState",
  default: {}
});

export const colorLoadingState = atom<boolean>({
  key: "colorLoadingState",
  default: false
});
