import { atom } from "recoil";

export const searchErrorState = atom({
  key: "searchErrorState",
  default: ""
});

export const searchLoadingState = atom({
  key: "searchLoadingState",
  default: false
});

export const searchesState = atom({
  key: "searchesState",
  default: {}
});
