import produce from 'immer';
import { selector } from 'recoil';
import { PrefixPermission } from '../../constants/permission.const';
import { checkPermission, formatDate } from '../../utils/helper.utils';
import { toPaginationProperty } from '../../utils/request.utils';
import { authUserSelector } from '../auth/auth.selectors';
import { usersState, userMetaState, usersSelectState } from './user.atoms';

export const usersSelector = selector({
  key: 'usersSelector',
  get: ({ get }) => {
    const users = produce(get(usersState), (draft) => draft);
    const permissions = checkPermission(
      get(authUserSelector),
      PrefixPermission.USER
    );
    return users.map((item, i) => {
      const { updatedAt, deletedAt, firstName, lastName } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        username: `${lastName} ${firstName}`,
        ...permissions,
        updatedAt: formatDate(updatedAt, 'DD/MM/YYYY HH:mm:ss'),
        deletedAt: formatDate(deletedAt, 'DD/MM/YYYY HH:mm:ss'),
      };
    });
  },
});

export const usersSelectSelector = selector({
  key: 'usersSelectSelector',
  get: ({ get }) => {
    const subjects = produce(get(usersSelectState), (draft) => draft);
    return subjects.map((item, i) => {
      const { firstName, lastName } = item;
      return { ...item, name: `${lastName} ${firstName}` };
    });
  },
});

export const userMetaSelector = selector({
  key: 'userMetaSelector',
  get: ({ get }) => {
    const meta = produce(get(userMetaState), (draft) => draft);
    return toPaginationProperty(meta);
  },
});

// export const userProfileSelector = selector({
//   key: "userProfileSelector",
//   get: ({ get }) => {
//     const user = produce(get(userProfileState), draft => draft);
//     const {lastName, firstName, profile} = user;
//     return {
//       profile: profile,
//       firstName: firstName,
//       lastName: lastName,
//       username: `${lastName} ${firstName}`,
//     }
//   }
// });
