import styled from "styled-components";
import { InputNumberForm } from "../form";

export const WrapSortable = styled.div`
  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
  .ant-input-number {
    padding: 6px;
  }

  .ant-collapse {
    border: 1px solid #eee;
    background-color: #fcfcfc;
  }
  
  Ratio {
    margin-top: 100px;
  }
`;

export const InputNumber = styled(InputNumberForm)`
  /* width: 100px !important; */
`;

export const WrapInputNumber = styled.div`
  width: 85px;
  padding-left: 5px;

  .ant-input-number {
    width: 85px !important;
  }
`;
