import { useState } from 'react';
import { getSearchAsync } from '../../atom/search/search.apis';
import { useSearch } from '../../atom/search/search.hook';
import { Layout } from '../../components/layout/layout.component';
import { SearchResult } from '../../components/search-result/search-result.component';
import { showMessageErrors } from '../../utils/message.utils';
import { ContentFeature } from './feature.component';

export interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = () => {
  const [search, setSearch] = useState('');
  const { searches, setSearches } = useSearch();
  const onSearch = (value: string) => {
    setSearch(value);

    if (value)
      getSearchAsync({ query: value, limit: 10 })
        .then((res) => {
          setSearches(res);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    else setSearches({});
  };

  // const s = 'My cow {{{always gives}}}  milk, thier cow {{{also gives}}} milk';
  // const matches = s.matchAll(/{{{(.*?)}}}/g);

  // const s = '[always gives]-Google.com';
  // const matches = s.matchAll(/\[(.*?)\]/g);

  // console.log('-----', s.match(/\[(.*?)\]/g));
  // let matchString = Array.from(matches, (x) => x[1]);

  // if (matchString.length) {
  //   let x = matchString[0];
  //   console.log('xxxx', x);
  //   const m = s.match(/\[(.*?)\]/g);
  //   if (m) {
  //     // m[0];
  //     let z = s.replace(m[0], 'xxxx');
  //     console.log(z);
  //   }
  //   // let y = s.replace(, '');
  //   // console.log({ m });
  // }

  // console.log('------------');

  // console.log(
  //   Array.from(matches, x => x[1]),
  //   matches
  // );

  // const reg = /{{{(.*?)}}}/g;
  // const str = "Java3foobar4Script";
  // const newStr = s.replace(reg, `<input type="text" value="ddd">`);

  // let matchString = Array.from(matches, (x) => x[1]);

  // console.log("----", newStr, matchString[0]);

  // let r = s.split('}}}').map((item: string, i: number) => {
  //   return item.search('{{{') !== -1
  //     ? `${item}}}}`.replace(
  //         /{{{(.*?)}}}/g,
  //         `<input type="text" value="${matchString[i]}">`
  //       )
  //     : item;
  // });

  // console.log('------------', matchString);

  // console.log(r);

  return (
    <Layout onSearch={onSearch}>
      {/* <div dangerouslySetInnerHTML={{ __html: r.join("") }}></div> */}
      {/* {newStr} */}
      {search && <SearchResult search={search} dataSources={searches} />}
      {/* <ContentFeature/> */}


    </Layout>
  );
};
