import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getLearnAsync } from "../../../../atom/learn/learn.apis";
import { learnLoadingState, learnState } from "../../../../atom/learn/learn.atoms";
import { learnSelector } from "../../../../atom/learn/learn.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const useLearn = ({ id, forceRender }: UseHookProps) => {
  const learn = useRecoilValue(learnSelector);
  const setLearn = useSetRecoilState(learnState);
  const [loading, setLoading] = useRecoilState(learnLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getLearnAsync(id)
        .then(res => {
          setLearn(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }

    return () => {
      setLearn({});
    };
  }, [id, setLearn, setLoading, forceRender]);

  return {
    learn,
    loading,
    setLoading,
    setLearn
  };
};
