import produce from 'immer';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { getLibraryAsync } from '../../../atom/library/library.apis';
import {
  libraryLoadingState,
  libraryState,
  questionGroupState,
} from '../../../atom/library/library.atoms';
import { Library } from '../../../atom/library/library.types';
import { showMessageErrors } from '../../../utils/message.utils';

interface UseLibraryParam {
  id: string;
  questionGroupId?: string;
  forceRender?: boolean;
}
export const useLibrary = ({
  id,
  questionGroupId,
  forceRender,
}: UseLibraryParam) => {
  const [library, setLibrary] = useRecoilState(libraryState);
  const [questionGroup, setQuestionGroup] = useRecoilState(questionGroupState);
  const [loading, setLoading] = useRecoilState(libraryLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getLibraryAsync(id)
        .then((res) => {
          setLibrary(produce<Library, Library>(res, (draft) => draft));
          setLoading(false);
        })
        .catch((err) => {
          showMessageErrors(err);
          setLoading(false);
        });
    }
    return () => {
      setLibrary({});
    };
  }, [id, setLibrary, setLoading, forceRender]);

  useEffect(() => {
    if (questionGroupId) {
      setLoading(true);
      getLibraryAsync(questionGroupId)
        .then((res) => {
          setQuestionGroup(produce<Library, Library>(res, (draft) => draft));
          setLoading(false);
        })
        .catch((err) => {
          showMessageErrors(err);
          setLoading(false);
        });
    }
    return () => {
      if (questionGroupId) setQuestionGroup({});
    };
  }, [questionGroupId, setQuestionGroup, setLoading, forceRender]);

  return {
    library,
    loading,
    setLoading,
    setLibrary,
    questionGroup,
    setQuestionGroup,
  };
};
