import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { Level } from "./level.types";
import { RestoreType, SortData } from "../../types/global.types";

export const getLevelsAsync = (option: {}) => {
  const url = generateQuery("/levels", option, [
    "comingSoon",
    "status", 
    "grade",
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getLevelsSelectAsync = () => {
  const url = generateQuery("/levels/for/select-option");
  return axios.get(url, config()).then(response => response.data.data);
};

export const getLevelsTrashAsync = (option: {}) => {
  const url = generateQuery("/levels/list/trash", option, ["status", "grade"]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const createLevelAsync = (data: Level) => {
  return axios.post("/levels", data, config()).then(response => response.data);
};

export const restoreLevelsAsync = (data: RestoreType) => {
  return axios.patch("/levels/restore", data, config()).then(response => response.data);
};

export const updateLevelAsync = (id: string, data: Level) => {
  return axios.put(`/levels/${id}`, data, config()).then(response => response.data);
};

export const deleteLevelAsync = (id: string) => {
  return axios.delete(`/levels/${id}`, config()).then(response => response.data);
};

export const sortLevelsAsync = (data: SortData) => {
  return axios.patch(`/levels/set-rank`, data, config()).then(response => response.data);
};

export const levelsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/levels/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};
