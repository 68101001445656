import React, { useState, useEffect } from "react";
import { Upload, message, Modal, Form, FormInstance } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import _ from "lodash";

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

interface CustomUploadProps {
  [key: string]: any;
  form: FormInstance;
  // callbackUrl?: string;
}

export const UploadForm: React.FC<CustomUploadProps> = ({
  imageUrl,
  form,
  names,
  fieldKey,
  name,
  label,
  rules,
  tooltip,
  required,
  disabled,
  accept,
  callbackUrl,
  isIcon
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    if (imageUrl)
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: imageUrl
        }
      ]);
  }, [imageUrl]);

  useEffect(() => {
    if (!fileList.length) form.setFieldsValue({ [name]: null });
  }, [fileList, form, name]);

  const beforeUpload = (file: any) => {
    if (accept !== "video/*") {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return false;
      }
    }
    if (_.isString(name)) {
      form.setFieldsValue({ [name]: file });
      if (callbackUrl) callbackUrl(URL.createObjectURL(file));
    } else if (_.isArray(name)) {
      let arrNames = form.getFieldValue(name[0]);
      const newName = [...name];
      newName.shift();

      _.set(arrNames, newName.join("."), file);
      form.setFieldsValue({ [name[0]]: arrNames });
    }
    return false;
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleChange = ({ fileList }: any) => {
    setFileList(fileList);
  };

  const onRemove = (file: any) => {
    setFileList([]);
    if (names) {
      let arrNames = form.getFieldValue(names);
      if (arrNames[fieldKey]) {
        arrNames[fieldKey][name] = null;
        form.setFieldsValue({ arrNames });
      }
    } else {
      form.setFieldsValue({ [name]: null });
      if (callbackUrl) callbackUrl("");
    }
  };

  const uploadButton = (
    <div>
      <CloudUploadOutlined color={"#00000"} height="20px" width="20px" />
    </div>
  );

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={rules}
        tooltip={tooltip}
        required={required}
        valuePropName={name}
        fieldKey={fieldKey}
        labelCol={{ span: 24 }}
      >
        <Upload
          listType="picture-card"
          disabled={disabled}
          beforeUpload={beforeUpload}
          onPreview={handlePreview}
          accept={accept || "image/*"}
          fileList={fileList}
          onChange={handleChange}
          onRemove={onRemove}
          style={{ borderRadius: "100%" }}
        >
          {fileList.length > 0 ? null : uploadButton}
        </Upload>
      </Form.Item>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img style={{ width: "100%" }} src={previewImage} alt="example" />
      </Modal>
    </>
  );
};
