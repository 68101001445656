import styled from 'styled-components';

export const WrapFeature = styled.div`
  .ant-collapse {
    margin-bottom: 10px;
  }

  .ant-list-items {
    cursor: pointer;

    .active {
      background: #ddd;
    }
  }

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    cursor: default;
    color: inherit;
  }

  span.anticon.anticon-close-square {
    color: red;
  }

  span.anticon.anticon-save {
    color: #008271;
  }
`;
