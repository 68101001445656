import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { librariesSyncToFirestoreAsync } from '../../../atom/library/library.apis';
import { Layout } from '../../../components/layout/layout.component';
import useRoute from '../../../hooks/use-route.hook';
import { messageSuccess, showMessageErrors, showSyncConfirm } from '../../../utils/message.utils';
import { QuestionOrderForm } from './components/question-order-form.component';

export const QuestionOrderFormPage = () => {
  const [form] = useForm();
  const [isBack, setIsBack] = useState(false);
  const { param } = useRoute();

  const onSync = () => {
    showSyncConfirm(() => {
      librariesSyncToFirestoreAsync(param.id)
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  return (
    <Layout
      title="Question Order"
      onSubmit={(isBack) => {
        setIsBack(isBack || false);
        form.submit();
      }}
      onSync={onSync}
    >
      <QuestionOrderForm form={form} isBack={isBack} />
    </Layout>
  );
};
