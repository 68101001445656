import { axios, config } from '../../utils/axios.utils';
import { Library } from './library.types';
import Axios from 'axios';
import _ from 'lodash';
import { IRequest } from '../../pages/elements/video-option/video-option.component';

const VIMEO_USER_ID = process.env.REACT_APP_VIMEO_USER_ID;
const BUNNY_LIBRARY_ID = process.env.REACT_APP_BUNNY_LIBRARY_ID;
const BUNNY_ACCESS_KEY = process.env.REACT_APP_BUNNY_ACCESS_KEY;
const VIMEO_API_KEY = process.env.REACT_APP_VIMEO_API_KEY;

const vimeoHeader = {
  headers: {
    Accept: 'application/vnd.vimeo.*+json;version=3.4',
    Authorization: `Bearer ${VIMEO_API_KEY}`,
  },
};

const bunnyHeader = {
  headers: {
    Accept: 'application/json',
    AccessKey: `${BUNNY_ACCESS_KEY}`,
    'Content-Type': 'application/*+json',
  },
};

export const axiosInstance = Axios.create({
  baseURL: 'https://api.vimeo.com/me/videos',
  // timeout: 60 * 60 * 60 * 1000,
  ...vimeoHeader,
});

export const getBunnyFoldersAsync = () => {
  return Axios.get(
    `https://video.bunnycdn.com/library/${BUNNY_LIBRARY_ID}/collections`,
    bunnyHeader
  ).then((res) =>
    _.sortBy(
      res.data.items.map((item: { name: string; guid: string }) => {
        return {
          id: item.guid,
          name: item.name,
        };
      }),
      ['name']
    )
  );
};

export const getBunnyVideoAsync = (videoId: string) => {
  return Axios.get(
    `https://video.bunnycdn.com/library/${BUNNY_LIBRARY_ID}/videos/${videoId}`,
    bunnyHeader
  ).then((res) => res.data);
};

export const createBunnyVideoAsync = (data: {
  title: string;
  collectionId: string;
}) => {
  return Axios.post(
    `https://video.bunnycdn.com/library/${BUNNY_LIBRARY_ID}/videos`,
    data,
    bunnyHeader
  ).then((res) => res.data);
};

export const updateBunnyVideoAsync = (
  videoId: string,
  data: { collectionId: string }
) => {
  return Axios.post(
    `https://video.bunnycdn.com/library/${BUNNY_LIBRARY_ID}/videos/${videoId}`,
    { ...data },
    bunnyHeader
  ).then((res) => res.data);
};

export const uploadBunnyVideoAsync = (videoId: string, data: File) => {
  return Axios.put(
    `https://video.bunnycdn.com/library/${BUNNY_LIBRARY_ID}/videos/${videoId}`,
    data,
    bunnyHeader
  ).then((res) => res.data);
};

export const deleteBunnyVideoAsync = (videoId: string) => {
  return Axios.delete(
    `https://video.bunnycdn.com/library/${BUNNY_LIBRARY_ID}/videos/${videoId}`,
    bunnyHeader
  ).then((res) => res.data);
};

export const getBunnyVideosByFolderAsync = (
  folderId: string,
  page = 1,
  offset = 10000
) => {
  return Axios.get(
    `https://video.bunnycdn.com/library/${BUNNY_LIBRARY_ID}/videos?page=${page}&itemsPerPage=${offset}&collection=${folderId}`,
    bunnyHeader
  ).then((res) =>
    _.sortBy(
      res.data.items.map((item: { title: string; guid: string }) => {
        return { id: item.guid, name: item.title, folderId };
      }),
      ['name']
    )
  );
};

export const createVideoAsync = (data: Library) => {
  return axios
    .post('/videos', data, config())
    .then((response) => response.data);
};

export const updateVideoAsync = (id: string, data: Library) => {
  return axios
    .put(`/videos/${id}`, data, config())
    .then((response) => response.data);
};

export const uploadVideoAsync = (data: any) => {
  return axios
    .post(`/videos/upload`, data, config())
    .then((response) => response.data);
};

export const getVimeoFoldersAsync = () => {
  return Axios.get(
    `https://api.vimeo.com/users/${VIMEO_USER_ID}/projects/?per_page=100`,
    vimeoHeader
  ).then((response) =>
    _.sortBy(
      response.data.data.map((item: { name: string; uri: string }) => {
        return { name: item.name, id: item.uri.split('/projects/')[1] };
      }),
      ['name']
    )
  );
};

export const getVimeoVideosByFolderAsync = (folderId: string) => {
  return Axios.get(
    `https://api.vimeo.com/users/${VIMEO_USER_ID}/projects/${folderId}/videos?per_page=100`,
    vimeoHeader
  ).then((response) =>
    _.sortBy(
      response.data.data.map((item: { name: string; uri: string }) => {
        return { name: item.name, id: item.uri.split('/videos/')[1], folderId };
      }),
      ['name']
    )
  );
};

// export const getBunnyFoldersAsync = () => {
//   return Axios.get(
//     `https://api.vimeo.com/users/${VIMEO_USER_ID}/projects?per_page=100`,
//     vimeoHeader
//   ).then((response) =>
//     response.data.data.map((item: { name: string; uri: string }) => {
//       return { name: item.name, id: item.uri.split('/projects/')[1] };
//     })
//   );
// };

export const requestUrlUploadVimeoAsync = (data: any) => {
  return Axios.post('https://api.vimeo.com/me/videos', data, vimeoHeader).then(
    (response) => response.data
  );
};

export const addVimeoVideoToFolderAsync = (
  folderId: string,
  videoId: string
) => {
  return Axios.put(
    `https://api.vimeo.com/users/100275452/projects/${folderId}/videos/${videoId}`,
    {},
    vimeoHeader
  ).then((response) => response.data);
};

// export const addVimeoVideoToFolderAsync = (
//   folderId: string,
//   videoId: string
// ) => {
//   return axios
//     .put(
//       `/source-videos/vimeo-change-folder/${folderId}/videos/${videoId}`,
//       {},
//       config()
//     )
//     .then((response) => response.data);
// };

export const deleteUploadVimeoAsync = (videoId: string) => {
  return Axios.delete(
    `https://api.vimeo.com/videos/${videoId}`,
    vimeoHeader
  ).then((response) => response.data);
};

export const getVimeoVideoAsync = (videoId: string) => {
  return Axios.get(`https://api.vimeo.com/videos/${videoId}`, vimeoHeader).then(
    (response) => response.data
  );
};

export const uploadUploadVimeoAsync = (url: string, data: any) => {
  const header: any = _.cloneDeep(vimeoHeader);
  header.headers.Accept = 'application/vnd.vimeo.*+json;version=3.4';

  return Axios.post(url, data, header).then((response) => response.data);
};

export const getVideoForSelectOptionAsync = () => {
  return axios
    .get(`/source-videos/for/select-option`, config())
    .then((response) => response.data.data);
};

export const getVideosMigrateAsync = () => {
  return axios
    .post(`/source-videos/migrate-videos`, {}, config())
    .then((response) => response.data.data);
};

export const getVideosFromVimeo = async () => {
  console.log('start');
  const result = await axiosInstance.get('/?per_page=1');
  const total = _.get(result, 'data.total');
  const arrPromise = [];
  // const errPromise = [];

  const totalPage = Math.ceil(total / 100);
  for (let i = 1; i <= totalPage; i++) {
    const result = axiosInstance
      .get(`/?per_page=100&page=${i}`)
      .then((res) => res.data.data)
      .catch((err) => {
        console.log(`/?per_page=100&page=${i}` + err.message);
        // errPromise.push(axiosInstance.get(`/?per_page=100&page=${i}`).then(res => res.data.data));
      });

    // await sleep(5000);
    // if (result) await this.insertData(result, userLogined);

    // console.log(i);
    arrPromise.push(result);
  }

  let results = await Promise.all(arrPromise);

  console.log({ results });
};

export const getVideoVimeoTotalPage = async () => {
  const result = await axiosInstance.get('/?per_page=1');
  const total = _.get(result, 'data.total');
  console.log(result);
  return Math.ceil(total / 100);
};

export const getVideoVimeoRequest = async (page: number) => {
  return await axiosInstance.get(`/?per_page=100&page=${page}`);
};

export const insertVideoVimeo = async (data: { data: IRequest }) => {
  return axios.post(`/source-videos/inserts`, data, config());
};
