import { Tooltip } from "antd";
import _ from "lodash";

export const columnEllipsis = {
  ellipsis: true,
  render: (text: string) => (
    <Tooltip placement="topLeft" title={text}>
      {text}
    </Tooltip>
  )
};

export const columnTableEllipsis = (key: string, prop = "title") => {
  return {
    ellipsis: true,
    render: (text: string, record: any) => (
      <Tooltip placement="topLeft" title={_.get(record, `${key}.${prop}`)}>
        {_.get(record, `${key}.${prop}`)}
      </Tooltip>
    )
  };
};
