import { Checkbox, Form, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import { useEffect } from "react";
import { FormWrapper } from "../../../../components/form/form.component";
import { PERMISSION_OPTIONS } from "../../../../constants/default-value.const";
import { TablePermission } from "./permission.modal.styles";

export const PermissionModal = ({ visible, onCancel, onOk, dataSource }: any) => {
  const [form] = useForm();

  useEffect(() => {
    if (dataSource.rolePermission) {
      let data: any = {};
      dataSource.rolePermission.forEach((item: any) => {
        const name = item.split(":");
        const convertName = [name[0], name[1]].join(":");
        if (!data[convertName]) {
          data[convertName] = [];
        }

        if (item.includes(convertName)) {
          data[convertName].push(item);
        }
      });

      form.setFieldsValue({
        ...data
      });
    }
  }, [form, dataSource]);

  const _onOk = () => {
    form
      .validateFields()
      .then(values => {
        // form.resetFields();
        onOk(values);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  const _onCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title={"Permissions"}
      closable={false}
      okText="Submit"
      cancelText="Cancel"
      onCancel={_onCancel}
      maskClosable={false}
      width="80%"
      onOk={_onOk}
    >
      <FormWrapper name="permissionForm" form={form}>
        <TablePermission>
          {dataSource.permissionList &&
            Object.entries(dataSource.permissionList).map(([key, value]: [string, any], i) => {
              const valuePermissions = _.flatten(
                value.map((v: any) => {
                  return Object.values(v);
                })
              );

              const name = _.get(valuePermissions, "0").split(":");
              const convertName = [name[0], name[1]].join(":");
              return (
                <tr>
                  <th>{key}</th>
                  <Form.Item name={`${convertName}`}>
                    <Checkbox.Group>
                      {PERMISSION_OPTIONS.filter(item => {
                        return valuePermissions.toString().includes(item.value);
                      }).map((item: any) => {
                        const v2 = valuePermissions.find((item2: any) =>
                          item2.includes(item.value)
                        );

                        return (
                          <>
                            <td
                              style={{
                                paddingTop: "10px",
                                paddingLeft: "10px",
                                paddingRight: "10px"
                              }}
                            >
                              <Checkbox value={v2}>{item.text}​</Checkbox>
                            </td>
                          </>
                        );
                      })}
                    </Checkbox.Group>
                  </Form.Item>
                </tr>
              );
            })}
        </TablePermission>
      </FormWrapper>
    </Modal>
  );
};
