// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { PracticeTest } from "./practice-test.types";

export const practiceTestsState = atom<PracticeTest[]>({
  key: "practiceTestsState",
  default: []
});

export const practiceTestMetaState = atom<Meta>({
  key: "practiceTestMetaState",
  default: {}
});

export const practiceTestState = atom<PracticeTest | {}>({
  key: "practiceTestState",
  default: {}
});

export const practiceTestLoadingState = atom<boolean>({
  key: "practiceTestLoadingState",
  default: false
});
