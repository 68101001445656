import { Layout } from "../../components/layout/layout.component";
import CKEditor4 from "ckeditor4-react-advanced";
import React, { Component } from "react";
import { storagePath, uploadPhoto } from "../../utils/firebase.utils";
import { STORAGE_PATH } from "../../constants/default-value.const";
import { sleep } from "../../utils/helper.utils";
import { CloudUploadOutlined } from "@ant-design/icons";


let config = {
  extraPlugins: "justify,font,colorbutton",
  toolbarGroups: [
    { name: "document", groups: ["mode"] },
    { name: "clipboard", groups: ["undo"] },
    // { name: "editing", groups: ["find", "selection", "spellchecker"] },
    // { name: "forms" },
    // "/",
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align"] // 'align' -> 'justify' plugin
    },
    { name: "links" },
    { name: "insert" },
    // "/",
    // { name: "styles" }, // 'font and fontsize' -> 'font' plugin
    { name: "colors" }, // 'colors' -> 'colorbutton' plugin
    { name: "tools" },
    // { name: "others" }
    // { name: "about" }
  ]
};


export class EditorTestPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      content: ""
    };

    this.myRef = React.createRef();
  }

  onChangeValue = (evt) => {
    // const { formChange, formName, name, onChange } = this.props;
    var html = evt.editor.getData();
    // if (!html.includes("MathJax_Preview")) {
    //   formChange(formName, name, html);
    // }

    this.setState({ content: html })

    // if (onChange)
    //   onChange(html);
  }

  upload = async (e) => {
    if (e.target.files[0]) {
      const { path } = await uploadPhoto(e.target.files[0], STORAGE_PATH.EDITOR);
      let link = storagePath(path);

      const img = `<p><img alt="" src="${link}" width="100%" height="100%"/></p>`;
      await sleep(2000);
      this.setState({ content: `${this.state.content}${img}` })
    }
  }

  render() {
    return <Layout  >
      <input type="file" accept="image/*" ref={this.myRef}
        name="avatar" onChange={this.upload} className="d-none" />
      <CloudUploadOutlined onClick={() => this.myRef.current.click()} />
      <CKEditor4
        config={config}
        data={this.state.content}
        onChange={this.onChangeValue}
      />
    </Layout>;
  }

};
