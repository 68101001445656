import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;

  .anticon-clear {
    margin-top: 10px;
    color: red;
  }
`;
