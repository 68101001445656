// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Topic, TopicSelect } from "./topic.types";

export const topicsState = atom<Topic[]>({
  key: "topicsState",
  default: []
});

export const topicsSelectState = atom<TopicSelect[]>({
  key: "topicsSelectState",
  default: []
});

export const topicMetaState = atom<Meta>({
  key: "topicMetaState",
  default: {}
});

export const topicLoadingState = atom<boolean>({
  key: "topicLoadingState",
  default: false
});

export const topicSubjectIdState = atom<string>({
  key: "topicSubjectIdState",
  default: ""
});

export const topicLevelIdState = atom<string>({
  key: "topicLevelIdState",
  default: ""
});

export const topicTopicIdState = atom<string>({
  key: "topicTopicIdState",
  default: ""
});
