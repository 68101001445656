import styled from "styled-components"

export const Container = styled.div`
  padding: 32px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e5e5f7;
  opacity: 0.8;
  // background-image:  radial-gradient(#444cf7 0.5px, transparent 0.5px), radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  // background-size: 20px 20px;
  // background-position: 0 0,10px 10px;
  background: linear-gradient(to top, #a1c4fd, #c2e9fb);
`;

export const GreetCard = styled.div`
  height: 100%;
  min-width: 360px;
  box-sizing: border-box;
  padding: 32px;
  border-radius: 10px;
  box-shadow: 8px 8px 16px 0 rgba(83, 100, 113, .24);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const GreetLogo = styled.div`
  font-size: 32px;
  font-weight: 900;
  position: absolute;
  top: 32px;
  left: 32px;
  color: #eeeee;
`;

export const GreetTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  align-self: flex-start;
`;

export const LoginSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FormWrapper = styled.div`
  flex: 1;
  flex-basis: 400px;
  min-width: 280px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 32px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  
  h5 { margin-top: 8px !important; }
  form { 
    margin: 16px 0;
    width: 100%;
  }
`

export const AlertInfo = styled.div`
  margin-top: 16px;
  width: 100%;
  font-weight: 500;
`
