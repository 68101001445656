import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { Category } from "../../../../atom/category/category.types";
import { OptionType } from "../../../../components/form/select/select.type";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { CategoryForm } from "./category-form.component";

interface CategoryFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: Category) => void;
  dataSource?: Category;
  colors: OptionType[];
  medias: OptionType[];
}
export const CategoryFormModal: React.FC<CategoryFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource,
  colors,
  medias
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <CategoryForm
          dataSource={dataSource}
          handleCancel={handleCancel}
          handleOk={handleOk}
          colors={colors}
          medias={medias}
        />
      </Spin>
    </ModalWrapper>
  );
};
