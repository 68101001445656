import { Fragment, useState } from 'react';
import {
  MainHeader,
  HeaderTitle,
  HeaderAction,
  ButtonDrawerMenu,
  ButtonMobile,
  HeaderContainer,
} from './header.styles';
import { Button, Typography } from 'antd';
import { ContentTab } from '../../content-tab/content-tab.component';
import { CategoryTab } from '../../category-tab/category-tab.component';
import { SearchInput } from '../../search-input/search-input.component';
import useRoute from '../../../hooks/use-route.hook';
import { ColorTab } from '../../color-tab/color-tab.component';
import {
  ArrowLeftOutlined,
  PlusOutlined,
  SearchOutlined,
  CloseOutlined,
  SyncOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import React from 'react';
import { SaveButton } from '../../button-save/button-save.component';
import { UserTab } from '../../user-tab/user-tab.component';
import { RoutePaths } from '../../../constants/route.const';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/use-auth';
import { checkPermission, isAdmin } from '../../../utils/helper.utils';
import { drawerMenuVisibleState } from '../../../atom/app/app.atoms';
import { useRecoilState } from 'recoil';
import { VideoTab } from '../../video-tab/video-tab.component';
// import { historyBackList } from '../../../utils/form.utils';

const { Title } = Typography;

export interface HeaderProps {
  onCreate?: () => void;
  onSync?: () => void;
  onSearch?: (e: string) => void;
  onSubmit?: (isBack?: boolean) => void;
  onPreview?: () => void;
  prefixPermission?: string;
  title?: string;
  render?: any;
}

export const SwitchTab = () => {
  const { pathname } = useRoute();
  const renderTab = () => {
    if (pathname.includes(RoutePaths.CONTENT_CATEGORIES)) return <ContentTab />;
    else if (pathname.includes(RoutePaths.CATEGORY_TYPES))
      return <CategoryTab />;
    else if (pathname.includes(RoutePaths.COLOR_AND_PLACEHOLDERS))
      return <ColorTab />;
    else if (pathname.includes(RoutePaths.USER_ROLES)) return <UserTab />;
    else if (
      pathname.includes(RoutePaths.VIDEOS) &&
      !pathname.includes(RoutePaths.VIDEOS + '/form')
    )
      return <VideoTab />;
    return null;
  };

  return (
    <div style={{ display: 'flex', flexGrow: 1, alignSelf: 'center' }}>
      {renderTab()}
    </div>
  );
};

export const CustomComponent: React.FC<any> = ({ render }) => {
  return render;
};

export const Header: React.FC<HeaderProps> = ({
  onCreate,
  onSearch,
  onPreview,
  onSync,
  title,
  onSubmit,
  render,
  prefixPermission,
}) => {
  const { history, param } = useRoute();
  const { profile } = useAuth();
  const [, setDrawerMenuVisibleState] = useRecoilState(drawerMenuVisibleState);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleGoBack = () => {
    // historyBackList({ history, pathname, formType: query.formType });
    // checkFormRedirect({ history, pathname, id: "", formType: "", isBack: true });
    history.goBack();
  };

  return (
    <Fragment>
      {/* desktop menu */}
      <HeaderContainer>
        <MainHeader className="menu-desktop">
          {onSubmit && (
            <div>
              <ArrowLeftOutlined onClick={handleGoBack} />
            </div>
          )}
          <HeaderTitle>
            <Title level={4}>{title}</Title>
          </HeaderTitle>
          <SwitchTab />
          <HeaderAction>
            {onSearch && <SearchInput handleSearch={onSearch} />}
            {(onCreate && checkPermission(profile, prefixPermission).create) ||
            (onCreate && isAdmin(profile)) ? (
              <SaveButton
                handleClick={onCreate}
                title={t('Add')}
                type="button"
              />
            ) : null}
            {(render && checkPermission(profile, prefixPermission).create) ||
            (render && isAdmin(profile)) ? (
              <CustomComponent render={render} />
            ) : null}
            {onSubmit && (
              <SaveButton
                handleClick={() => onSubmit(false)}
                title={t('Save')}
                type="button"
              />
            )}
            {onSubmit && (
              <SaveButton
                handleClick={() => onSubmit(true)}
                title={t('Save & Back')}
                type="button"
              />
            )}
            {onPreview && param.id && (
              <Button onClick={onPreview} icon={<EyeOutlined />} />
            )}
            {onSync && param.id && (
              <Button onClick={onSync} icon={<SyncOutlined />} />
            )}
          </HeaderAction>
        </MainHeader>
        {/* mobile menu */}
        <MainHeader className="menu-mobile">
          {!onSubmit && (
            <div>
              <ButtonDrawerMenu
                onClick={() => setDrawerMenuVisibleState(true)}
              />
            </div>
          )}
          {onSubmit && (
            <div>
              <ArrowLeftOutlined onClick={() => history.goBack()} />
            </div>
          )}
          {isSearch ? (
            <span onClick={() => setIsSearch(false)}>
              <ButtonMobile
                style={{ backgroundColor: '#ff4d4f' }}
                icon={<CloseOutlined />}
              />
            </span>
          ) : (
            <HeaderTitle>
              <Title level={4}>{title} </Title>
            </HeaderTitle>
          )}
          <SwitchTab />
          <HeaderAction>
            {onSearch && isSearch && <SearchInput handleSearch={onSearch} />}
            {!isSearch && onSearch && (
              <span onClick={() => setIsSearch(true)}>
                <ButtonMobile icon={<SearchOutlined />} />
              </span>
            )}
            {(onCreate && checkPermission(profile, prefixPermission).create) ||
            (onCreate && isAdmin(profile)) ? (
              <span onClick={onCreate}>
                <ButtonMobile
                  style={{ backgroundColor: '#00A98C' }}
                  icon={<PlusOutlined />}
                />
              </span>
            ) : null}
            {(render && checkPermission(profile, prefixPermission).create) ||
            (render && isAdmin(profile)) ? (
              <CustomComponent render={render} />
            ) : null}
            {onSubmit && (
              <SaveButton
                handleClick={onSubmit}
                title={t('Save')}
                type="button"
              />
            )}
          </HeaderAction>
        </MainHeader>
      </HeaderContainer>
    </Fragment>
  );
};
