import styled from "styled-components";

export const FlexContainer = styled.div`
  padding: 8px;
  background: #fdb9ba47;
  display: flex;
`;
export const FlexWrap = styled.div`
  display: flex;
  
`;

export const Container = styled.div`
  padding: 8px;
  background: #fafafa;
`;

export const FlexTitleNoBottom = styled.p`
  color: #536471;
  margin-bottom:0px;
`;

export const ElementFlex = styled.div`
  &.avatar{
    width: 80px;
    height: 80px;
    background: #ffbc92;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    border:  15px solid #df782c;
    font-size: 18px;
    font-weight: bold;
    margin: 5% 0% 0% 20%;
    display: block;
  }
`;

export const FlexTitle = styled.p`
  color: #536471;
  &.bold{
    font-weight: bold;
  }
`;