// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Level, LevelSelect } from "./level.types";

export const levelsState = atom<Level[]>({
  key: "levelsState",
  default: []
});

export const levelsSelectState = atom<LevelSelect[]>({
  key: "levelsSelectState",
  default: []
});

export const levelMetaState = atom<Meta>({
  key: "levelMetaState",
  default: {}
});

export const levelLoadingState = atom<boolean>({
  key: "levelLoadingState",
  default: false
});
