import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { RestoreType } from "../../types/global.types";
import { Color } from "./color.types";

export const getColorsAsync = (option: {}) => {
  const url = generateQuery("/color-codes", option);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getColorsSelectAsync = () => {
  const url = generateQuery("/color-codes/for/select-option");
  return axios.get(url, config()).then(response => response.data.data);
};

export const getColorsTrashAsync = (option: {}) => {
  const url = generateQuery("/color-codes/list/trash", option);
  return axios.get(url, config()).then(response => response.data.data);
};

export const restoreColorsAsync = (data: RestoreType) => {
  return axios.patch("/color-codes/restore", data, config()).then(response => response.data);
};

export const createColorAsync = (data: Color) => {
  return axios.post("/color-codes", data, config()).then(response => response.data);
};

export const updateColorAsync = (id: string, data: Color) => {
  return axios.put(`/color-codes/${id}`, data, config()).then(response => response.data);
};

export const deleteColorAsync = (id: string) => {
  return axios.delete(`/color-codes/${id}`, config()).then(response => response.data);
};
