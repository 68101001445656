import { axios, config } from "../../utils/axios.utils";
import { Library } from "./library.types";

export const createTextLibraryAsync = (data: Library) => {
  return axios.post("/text-libraries", data, config()).then(response => response.data);
};

export const updateTextLibraryAsync = (id: string, data: Library) => {
  return axios.put(`/text-libraries/${id}`, data, config()).then(response => response.data);
};
