import { Col, Form, Row } from "antd";
import React, { Fragment, useEffect } from "react";
import { UserChangePassword } from "../../../atom/auth/auth.types";
import { InputPasswordForm } from "../../../components/form";
import { FormModalHeader } from "../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../components/form/form.component";
import { useTranslation } from "react-i18next";

interface ChangePasswordFormProps {
  handleCancel?: () => void;
  handleOk: (value: UserChangePassword) => void;
}
export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  handleCancel,
  handleOk,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  });

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="userProfileForm" form={form} onFinish={onFinish}>
        <FormModalHeader title={t("Change password")} handleCancel={handleCancel} />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputPasswordForm
              required={true}
              label={t("Old Password")}
              name="oldPassword"
              placeholder={t("Old Password")}
              rules={[
                {
                  required: true,
                  message: "Please input the old password!"
                }
              ]}
            />
          </Col>
          <Col span={24}>
            <InputPasswordForm
              required={true}
              label={t("New Password")}
              name="password"
              placeholder={t("New Password")}
              rules={[
                {
                  required: true,
                  message: "Please input the new password!"
                }
              ]}
            />
          </Col>
          <Col span={24}>
            <InputPasswordForm
              label={t("Confirm Password")}
              name="confirm"
              placeholder={t("Confirm Password")}
              dependencies={['password']}
              required={true}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue } :any) => ({
                  validator(_:any, value:any) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
