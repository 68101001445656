import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import { practiceTestsSyncToFirestoreAsync } from "../../../atom/practice-test/practice-test.apis";
import useRoute from "../../../hooks/use-route.hook";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { toSwiper } from "../../../utils/response.utils";
import { usePracticeTest } from "../../contents/practice-test/components/practice-test.hook";
import { SwiperComponent } from "../components/swiper/swiper.component";

export const FlowPracticeTestPage = () => {
  const {
    param: { id },
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { practiceTest } = usePracticeTest({ id, forceRender });
  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      practiceTestsSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };
  return (
    <SwiperComponent
      title={practiceTest.title}
      data={practiceTest}
      //onSync={() => handleSyncTofirestore(id)}
      dataSources={toSwiper(practiceTest.questions || [], [
        "children",
        "children",
      ])}
    />
  );
};
