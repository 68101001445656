import { FormInstance } from "antd";
import { useEffect } from "react";

export const useEnterEvent = ({ form, enter = true }: { form: FormInstance; enter?: boolean }) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        // callMyFunction();
        if (enter) form.submit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [form, enter]);
};
