import React, { Fragment, useEffect, useState } from "react";
import { Switch } from "antd";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta, VisibleProps } from "../../../../types/global.types";
import { CategoryFormModal } from "./category-form-modal.component";
import {
  canShowAll,
  canSortRow,
  checkPermission,
  exclude,
  isAdmin,
  removeUndefined,
  // toFilterSelect,
  toNumberFilter,
  toSort,
  toSortRank,
  toString,
  // toStringFilter,
} from "../../../../utils/helper.utils";
import {
  categoriesSyncToFirestoreAsync,
  createCategoryAsync,
  deleteCategoryAsync,
  getCategoriesAsync,
  getCategoriesTrashAsync,
  restoreCategoriesAsync,
  sortCategoriesAsync,
  updateCategoryAsync,
} from "../../../../atom/category/category.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
  showSyncConfirm,
} from "../../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  categoryLoadingState,
  categoryMetaState,
  categoriesState,
} from "../../../../atom/category/category.atoms";
import {
  categoryMetaSelector,
  categoriesSelector,
} from "../../../../atom/category/category.selectors";
import useRoute from "../../../../hooks/use-route.hook";
import {
  CATEGORY_TYPE,
  QUERY_TYPE,
  FILTER_STATUS,
  SORT,
} from "../../../../constants/default-value.const";
import { TableActionButton } from "../../../../components/table/table-action-button.component";
import { arrangeData, generateRequest } from "../../../../utils/request.utils";
import { SourceDataTab } from "../../../../components/source-data-tab/source-data-tab.component";
import { Category } from "../../../../atom/category/category.types";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { ColorPreview } from "../../../../components/color-preview/color-preview.component";
import { useApp } from "../../../../atom/app/app.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../../constants/permission.const";
import { useAuth } from "../../../../hooks/use-auth";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestCategories = (
  query: any,
  setCategories: (items: Category[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getCategoriesTrashAsync
      : getCategoriesAsync;
  return request(option)
    .then(({ items, meta }) => {
      setCategories(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setCategories([]);
      setMeta({});
      setLoading(false);
    });
};

interface CategoryProps extends VisibleProps {
  searchQuery: string;
}
export const CategoryComponent: React.FC<CategoryProps> = ({
  visible,
  setVisible,
  searchQuery,
}) => {
  const setCategories = useSetRecoilState(categoriesState);
  const setMeta = useSetRecoilState(categoryMetaState);
  const categories = useRecoilValue(categoriesSelector);
  const meta = useRecoilValue(categoryMetaSelector);
  const { profile } = useAuth();
  const [loading, setLoading] = useRecoilState(categoryLoadingState);
  const [dataSource, setDataSource] = useState<Category>();
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [enableSort, setEnableSort] = useState(false);
  const { setAppLoading } = useApp();
  const { colors, medias } = useSelectOption({
    hasColor: true,
    hasMedia: true,
  });
  const type = pathname.includes("/test-categories")
    ? CATEGORY_TYPE.TEST
    : CATEGORY_TYPE.RESOURCE;
  query.type = type;
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestCategories(
      { ...option, query: searchQuery || query.query },
      setCategories,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      status: toNumberFilter(query.status),
      grade: toNumberFilter(query.grade),
      comingSoon: toNumberFilter(query.comingSoon),
      // colorCodeId: toStringFilter(query.colorCodeId),
    });
  }, [searchQuery, query.query]);

  useEffect(() => {
    setColumns([
      {
        title: t("Sort"),
        dataIndex: "sort",
        width: 30,
        isDraggable: true,
      },
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Category, b: Category) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: Category, b: Category) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Rank"),
        dataIndex: "rank",
        sorter: (a: Category, b: Category) => a.rank - b.rank,
        sortOrder: sortedInfo.columnKey === "rank" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Color"),
        dataIndex: "color",
        filteredValue: filteredInfo.color || null,
        render: (text: string, record: Category) => (
          <ColorPreview color={record.color || record.colorCode?.code || ""} />
        ),
      },
      {
        title: t("Status"),
        dataIndex: "status",
        sorter: (a: Category, b: Category) =>
          Number(a.status) - Number(b.status),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Category) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      // {
      //   title: t('ComingSoon'),
      //   dataIndex: 'comingSoon',
      //   sorter: (a: Category, b: Category) =>
      //     Number(a.comingSoon) - Number(b.comingSoon),
      //   sortDirections: ['descend', 'ascend'],
      //   filters: FILTER_STATUS,
      //   onFilter: () => true,
      //   filteredValue: filteredInfo.comingSoon || null,
      //   sortOrder: sortedInfo.columnKey === 'comingSoon' && sortedInfo.order,
      //   render: (text: boolean, { id }: Category) => (
      //     <Switch
      //       defaultChecked={text}
      //       size="small"
      //       onChange={(value: boolean) => handleComingSoon(id, value)}
      //     />
      //   ),
      // },
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Category, b: Category) =>
          a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Deleted At"),
        dataIndex: "deletedAt",
        sorter: (a: Category, b: Category) =>
          a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: Category) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            //handleSyncTofirestore={handleSyncTofirestore}
            record={record}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo, colors]);

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<Category>(data);
    sortCategoriesAsync(sortData)
      .then(() => {
        messageSuccess();
        requestCategories(query, setCategories, setMeta, setLoading);
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      status,
      comingSoon,
      grade,
      // colorCodeId,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      status: toString(status),
      comingSoon: toString(comingSoon),
      grade: toString(grade),
      // colorCodeId: toString(colorCodeId),
    };

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ status, grade, comingSoon });
    requestCategories(option, setCategories, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestCategories(option, setCategories, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const handleEdit = (record: Category) => {
    setDataSource(record);
    setVisible(true);
  };

  const handleOk = async (value: Category) => {
    const { id } = value;

    setAppLoading(true);
    const request = id
      ? updateCategoryAsync(id, value)
      : createCategoryAsync({ ...value, type });
    request
      .then(({ message }) => {
        messageSuccess(message);
        requestCategories(query, setCategories, setMeta, setLoading);
        handleCancel();
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSaveEditable = (value: Category) => {
    const { id, title, code, rank } = value;

    updateCategoryAsync(id, { title, code, rank: Number(rank) } as Category)
      .then(({ message }) => {
        messageSuccess(message);
        requestCategories(query, setCategories, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setDataSource({} as Category);
  };

  const handleDuplicate = (record: Category) => {
    setDataSource(exclude(record, ["id"]));
    setVisible(true);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteCategoryAsync(id)
        .then(() => {
          messageSuccess();
          requestCategories(query, setCategories, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      categoriesSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  // const handleComingSoon = (id: string, value: boolean) => {
  //   updateCategoryAsync(id, { comingSoon: value } as Category)
  //     .then(() => messageSuccess())
  //     .catch((err) => {
  //       showMessageErrors(err);
  //     });
  // };

  const handlePublish = (id: string, value: boolean) => {
    updateCategoryAsync(id, { status: value } as Category)
      .then(() => messageSuccess())
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const onChangeTab = (key: string) => {
    requestCategories(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setCategories,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Category[]) => {
    showRestoreConfirm(() => {
      restoreCategoriesAsync({
        ids: selectedRows.map((item: Category) => item.id),
      })
        .then(() => {
          messageSuccess();
          requestCategories(query, setCategories, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onEnableSort = () => {
    setEnableSort(true);
    const option = {
      ...query,
      showAll: "yes",
      sortField: "rank",
      sortOrder: SORT.ASC,
    };
    setSortedInfo(toSortRank());
    requestCategories(option, setCategories, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestCategories(option, setCategories, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setEnableSort(false);
    requestCategories({ type: query.type }, setCategories, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.CATEGORY}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        onEnableSort={onEnableSort}
        showAll={canShowAll(query, filteredInfo)}
        enableSort={canSortRow(query, ["type"])}
        query={query}
      />
      <CategoryFormModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        dataSource={dataSource}
        colors={colors}
        medias={medias}
      />
      <TableComponent
        key={`table-${loading}-${categories.length}`}
        dataSource={categories}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.CATEGORY).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
        enableSort={enableSort && canSortRow(query, ["type"])}
      />
    </Fragment>
  );
};
