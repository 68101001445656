import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { User } from "../../../../atom/user/user.types";
import { OptionType } from "../../../../components/form/select/select.type";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { UserForm } from "./user-form.component";

interface UserFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: User) => void;
  dataSource?: User;
  roles: OptionType[];
}
export const UserFormModal: React.FC<UserFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource,
  roles
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <UserForm
          dataSource={dataSource}
          handleCancel={handleCancel}
          handleOk={handleOk}
          roles={roles}
        />
      </Spin>
    </ModalWrapper>
  );
};
