import { Form, Tooltip } from "antd";
import { PropsType } from "./input-number.type";
import { StyleInput } from "./input-number.styles";

export const InputNumberForm: React.FC<PropsType> = ({
  label,
  required,
  tooltip,
  placeholder,
  disabled,
  name,
  rules,
  fieldKey,
  value,
  onChange
}) => {
  const _onChange = (value: any) => {
    if (onChange) onChange(value);
  };

  return (
    <>
      <Tooltip placement="topLeft" title={placeholder}>
        <Form.Item
          name={name}
          label={label}
          rules={rules}
          required={required}
          tooltip={tooltip}
          labelCol={{ span: 24 }}
          fieldKey={fieldKey}
        >
          <StyleInput
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={_onChange}
          />
        </Form.Item>
      </Tooltip>
    </>
  );
};
