export enum ManageFeatureType {
  // SUBJECT = 'SUBJECT',
  COURSE = 'COURSE',
  // TEST_COMPETENCY = 'TEST_COMPETENCY',
  TEST_PRACTICE = 'TEST_PRACTICE',
  // TEST_COMPETITION = 'TEST_COMPETITION',
  TEST_EXAM = 'TEST_EXAM',
  RESOURCE = 'RESOURCE',
  // REVIEW = 'REVIEW',
}

interface PropertyType {
  id: string;
  status: boolean;
}

interface PropertiesType {
  id: string;
  status: boolean;
  value: PropertyType[];
}

export interface SubjectData {
  id: string;
  status: boolean;
  level: PropertyType[];
}

export interface SubjectsData {
  subjects: SubjectData[];
  type: ManageFeatureType;
}

export interface CategoryData {
  id: string;
  // status: boolean;
  data: PropertiesType[];
  // type: ManageFeatureType;
}

export interface CategoriesData {
  data: CategoryData[];
  type: ManageFeatureType;
}
