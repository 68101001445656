import { Tabs } from "antd";
import { RoutePaths } from "../../constants/route.const";
import useRoute from "../../hooks/use-route.hook";
import { HeaderMenu } from "../layout/header/header.styles";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
export const UserTab = () => {
  const { history, pathname } = useRoute();
  const { t } = useTranslation();
  const callback = (key: string) => {
    history.push(key);
  };

  return (
    <HeaderMenu>
      <Tabs defaultActiveKey={pathname} onChange={callback}>
        <TabPane tab={t("Users")} key={RoutePaths.USERS} />
        <TabPane tab={`${t("Roles")} & ${t("Permission")}`}   key={RoutePaths.ROLES} />
      </Tabs>
    </HeaderMenu>
  );
};
