// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { CompetencyTest } from "./competency-test.types";

export const competencyTestsState = atom<CompetencyTest[]>({
  key: "competencyTestsState",
  default: []
});

export const competencyTestMetaState = atom<Meta>({
  key: "competencyTestMetaState",
  default: {}
});

export const competencyTestState = atom<CompetencyTest | {}>({
  key: "competencyTestState",
  default: {}
});

export const competencyTestLoadingState = atom<boolean>({
  key: "competencyTestLoadingState",
  default: false
});
