import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { RestoreType, SortData } from "../../types/global.types";
import { Learn } from "./learn.types";

export const getLearnsAsync = (option: {}) => {
  const url = generateQuery("/learns", option, [
    "levelId",
    "subjectId",
    "topicId",
    "status",
    "premium",
    "comingSoon"
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getLearnAsync = (id: string) => {
  return axios.get(`/learns/${id}`, config()).then(response => response.data.data);
};

export const getLearnsSelectAsync = (option: {}) => {
  const url = generateQuery("/learns/for/select-option", option, [
    "levelId",
    "subjectId",
    "topicId",
    "status",
    "premium",
    "comingSoon"
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getLearnsTrashAsync = (option: {}) => {
  const url = generateQuery("/learns/list/trash", option, [
    "levelId",
    "subjectId",
    "topicId",
    "categoryId",
    "status",
    "comingSoon",
    "premium"
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getLearnsSelectLearnsAsync = (data: RestoreType) => {
  return axios
    .post("/learns/list/selected-learn", data, config())
    .then(response => response.data.data);
};

export const restoreLearnsAsync = (data: RestoreType) => {
  return axios.patch("/learns/restore", data, config()).then(response => response.data);
};

export const createLearnAsync = (data: Learn) => {
  return axios.post("/learns", data, config()).then(response => response.data);
};

export const updateLearnAsync = (id: string, data: Learn) => {
  return axios.put(`/learns/${id}`, data, config()).then(response => response.data);
};

export const deleteLearnAsync = (id: string) => {
  return axios.delete(`/learns/${id}`, config()).then(response => response.data);
};

export const deleteLearnSourceAsync = (id: string) => {
  return axios.delete(`/learn-activities/${id}`, config()).then(response => response.data);
};

export const deleteLearnSourceLibraryAsync = (id: string) => {
  return axios.delete(`/learn-activity-libraries/${id}`, config()).then(response => response.data);
};

export const sortLearnsAsync = (data: SortData) => {
  return axios.patch(`/learns/set-rank`, data, config()).then(response => response.data);
};

export const getLearnReferenceUsingAsync = (id: string) => {
  return axios.get(`/learns/reference-using/${id}`, config()).then(response => response.data.data);
};
