import { ChangeEvent, ReactElement, useState } from 'react';
import { Button, Col, Row, Spin, message, Space, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import _ from 'lodash';
import { useEffect } from 'react';
import {
  createQuestionAsync,
  updateQuestionAsync,
} from '../../../../atom/library/question.apis';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import { FormWrapper } from '../../../../components/form/form.component';
import {
  FORM_TYPE,
  QUESTION_TYPE,
  STORAGE_PATH,
} from '../../../../constants/default-value.const';
import useRoute from '../../../../hooks/use-route.hook';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import {
  messageSuccess,
  messageWarn,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { useLibrary } from '../../library/library.hook';
import { QuestionSection } from './question-section.component';
import {
  AudioOutlined,
  AudioTwoTone,
  DeleteOutlined,
  DeleteTwoTone,
  DragOutlined,
  FileImageOutlined,
  FileImageTwoTone,
  MinusCircleTwoTone,
  PictureTwoTone,
  SoundTwoTone,
} from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { ContainFlex, FlexElement } from './question.style';
import { LooseObject } from '../../../../types/global.types';
import { InputForm, SwitchForm } from '../../../../components/form';
import { v4 as uuid } from 'uuid';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import { FormModalProps, LibraryFormValue } from '../../library/library.types';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import {
  requestTopicDefault,
  setSelectFilter,
} from '../../../../utils/helper.utils';
import { checkFormRedirect } from '../../../../utils/form.utils';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';
import { storagePath, uploadPhoto } from '../../../../utils/firebase.utils';

const SortableItem = SortableElement(({ value }: { value: ReactElement }) => (
  <div tabIndex={0}>{value}</div>
));

const SorQuestionList = SortableContainer(
  ({
    dataAnswer,
    onRemoveChoice,
    form,
  }: {
    dataAnswer: Answer[];
    onRemoveChoice: (key: string) => void;
    form: FormInstance;
  }) => {
    const { setAppLoading } = useApp();
    const upload = async (e: any, key: string) => {
      if (e.target.files[0]) {
        if (e.target.files[0].size / 10240 > 250) {
          messageWarn('Image size limit to 250KB');
          return;
        }

        setAppLoading(true);
        await uploadPhoto(e.target.files[0], STORAGE_PATH.EDITOR, (path) => {
          const img = `<img alt="" src="${storagePath(
            path || ''
          )}" width="100%" height="100%"/>`;
          form.setFieldsValue({
            answer: {
              [key]: {
                text: img,
              },
            },
          });
          setAppLoading(false);
        });
      }
    };

    const uploadAudio = async (e: any, key: string) => {
      if (e.target.files[0]) {
        // if (e.target.files[0].size / 1024 > 250) {
        //   messageWarn('Image size limit to 250KB');
        //   return;
        // }

        setAppLoading(true);
        await uploadPhoto(e.target.files[0], STORAGE_PATH.EDITOR, (path) => {
          const audio = `<audio controls  class='cus-audio'><source src="${storagePath(
            path || ''
          )}" type="audio/ogg" /></audio>
          `;
          form.setFieldsValue({
            answer: {
              [key]: {
                text: audio,
              },
            },
          });
          setAppLoading(false);
        });
      }
    };

    const handleUpload = (index: string) => {
      document.getElementById(`upload_${index}`)?.click();
    };

    const handleUploadAudio = (index: string) => {
      document.getElementById(`upload_audio_${index}`)?.click();
    };

    return (
      <div>
        {dataAnswer.map((item: Answer, index: number) => (
          <SortableItem
            key={item.key}
            index={index}
            value={
              <ContainFlex>
                <FlexElement style={{ width: '100%' }}>
                  <InputForm
                    name={['answer', item.key, 'text']}
                    className="w-100"
                    addonBefore={<DragOutlined style={{ color: '#00A98C' }} />}
                    addonAfter={
                      <Space>
                        <Tooltip title="Image">
                          <PictureTwoTone
                            onClick={() => handleUpload(item.key)}
                          />
                        </Tooltip>
                        <Tooltip title="Audio">
                          <SoundTwoTone
                            onClick={() => handleUploadAudio(item.key)}
                          />
                        </Tooltip>
                        <Tooltip title="Remove">
                          <MinusCircleTwoTone
                            onClick={() => onRemoveChoice(item.key)}
                          />
                        </Tooltip>
                      </Space>
                    }
                    placeholder="Answers"
                    rules={[{ required: true, message: 'Option is required!' }]}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    id={`upload_${item.key}`}
                    name={`upload_${item.key}`}
                    onChange={(e: ChangeEvent) => upload(e, item.key)}
                    className="d-none"
                  />
                  <input
                    type="file"
                    accept=".mp3,audio/*"
                    id={`upload_audio_${item.key}`}
                    name={`upload_${item.key}`}
                    onChange={(e: ChangeEvent) => uploadAudio(e, item.key)}
                    className="d-none"
                  />
                </FlexElement>
                <FlexElement
                  style={{ marginLeft: '20px', marginRight: '20px' }}
                >
                  <SwitchForm
                    name={['answer', item.key, 'isRight']}
                    defaultChecked={item.isRight ? item.isRight : false}
                    size="small"
                    form={form}
                  />
                </FlexElement>
              </ContainFlex>
            }
          />
        ))}
      </div>
    );
  }
);

interface Answer {
  key: string;
  text: string;
  isRight: boolean;
}

export interface FormValue extends LibraryFormValue {
  answer: LooseObject;
}

const defaultAnswersData = [
  { isRight: false, text: '', key: uuid() },
  { isRight: false, text: '', key: uuid() },
];

export const QuestionMCQForm = ({
  form,
  formId,
  formModal,
  formDuplicate,
  onOk,
  defaultFilter,
  isBack = false,
}: FormModalProps) => {
  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, topics } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
  });
  const { t } = useTranslation();
  useEnterEvent({ form });
  const [forceRender, setForceRender] = useState(false);
  const { setAppLoading, appFilter, setAppFilter } = useApp();
  const { loading, library, setLibrary } = useLibrary({
    id: formModal ? formId : id,
    forceRender,
  });
  const [dataAnswer, setDataAnswer] = useState<Answer[]>(defaultAnswersData);

  useEffect(() => {
    setLibrary({});
  }, [setLibrary]);

  useEffect(() => {
    if (_.size(library)) {
      const { data }: LooseObject = library;
      let mapAnswers = _.get(data, 'answer', []).map((item: Answer) => {
        return {
          ...item,
          key: uuid(),
        };
      });

      setDataAnswer([...mapAnswers]);
      const answer = {};
      mapAnswers.forEach((item: Answer) => {
        const { text, key, isRight } = item;
        _.set(answer, `${key}.text`, text);
        _.set(answer, `${key}.isRight`, isRight);
      });
      form.resetFields();
      form.setFieldsValue({
        ...library,
        topicId: _.get(library, 'topic.id'),
        subjectId: _.get(library, 'subject.id'),
        levelId: _.get(library, 'level.id'),
        questionText: _.get(library, 'data.questionText'),
        solution: _.get(library, 'data.solution'),
        answer,
      });
    }
    setSelectFilter({
      dataSource: library,
      defaultFilter,
      form,
      elementId: 'mcqTopicRequest',
    });
  }, [library, form, defaultFilter]);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    let newSort = arrayMove(dataAnswer, oldIndex, newIndex);
    setDataAnswer([...newSort]);
  };

  const addAnswerChoice = () => {
    setDataAnswer([...dataAnswer, { text: '', isRight: false, key: uuid() }]);
  };

  const onRemoveChoice = (key: string) => {
    setDataAnswer([...dataAnswer.filter((item: Answer) => item.key !== key)]);
  };

  const onFinish = (value: FormValue) => {
    const { questionText, solution, answer, code, title } = value;

    let dataAn = dataAnswer.map((value: Answer, index: number) => {
      const option = answer[value.key];
      return {
        text: option.text,
        isRight: option?.isRight ? true : false,
        order: index,
      };
    });

    if (!dataAn.some((item: LooseObject) => item.isRight)) {
      message.error('Answer at least one choose needed.');
      return;
    }

    const data = {
      ...value,
      code: code,
      title: title,
      questionType: QUESTION_TYPE.MCQ,
      data: {
        questionText,
        answer: dataAn,
        solution: solution,
        type: QUESTION_TYPE.MCQ,
      },
    };

    if (formModal) {
      let request =
        formId && !formDuplicate
          ? updateQuestionAsync(formId, data)
          : createQuestionAsync(data);
      setAppLoading(true);
      request
        .then((res) => {
          messageSuccess();
          setAppLoading(false);
          if (onOk) onOk({ ...data, id: res.data.id });
        })
        .catch((err) => {
          showMessageErrors(err);
          setAppLoading(false);
        });

      return;
    }

    let request =
      formType === FORM_TYPE.EDIT
        ? updateQuestionAsync(id, data)
        : createQuestionAsync(data);
    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div
        onClick={() =>
          requestTopicDefault({
            dataSource: library,
            defaultFilter,
            appFilter,
            setAppFilter,
            filterKey: 'question',
          })
        }
        id="mcqTopicRequest"
      />
      <FormWrapper form={form} name="questionMcqForm" onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={8} lg={8}>
            <FormInfoSection
              levels={levels}
              topics={topics}
              subjects={subjects}
              filterKey="question"
            />
          </Col>
          <Col sm={24} md={16} lg={16}>
            <QuestionSection form={form} dataSource={library}>
              <TitleItem title={t('Answer')} />
              <SorQuestionList
                onRemoveChoice={onRemoveChoice}
                dataAnswer={dataAnswer}
                onSortEnd={onSortEnd}
                form={form}
              />
              <FlexElement>
                <Button onClick={addAnswerChoice}>Add choice</Button>
              </FlexElement>
            </QuestionSection>
          </Col>
        </Row>
      </FormWrapper>
    </Spin>
  );
};
