import produce from 'immer';
import _ from 'lodash';
import { selector } from 'recoil';
import { PrefixPermission } from '../../constants/permission.const';
import { checkPermission, formatDate, isSyncable } from '../../utils/helper.utils';
import { toPaginationProperty } from '../../utils/request.utils';
import { mapActivities, mapQuestions } from '../../utils/response.utils';
import { authUserSelector } from '../auth/auth.selectors';
import {
  competenciesState,
  competencyMetaState,
  competencyState,
} from './competency.atoms';

export const competenciesSelector = selector({
  key: 'competenciesSelector',
  get: ({ get }) => {
    const competencies = produce(get(competenciesState), (draft) => draft);
    const permissions = checkPermission(
      get(authUserSelector),
      PrefixPermission.COMPETENCY
    );
    return competencies.map((item, i) => {
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(item.updatedAt, 'DD/MM/YYYY HH:mm:ss'),
        deletedAt: formatDate(item.deletedAt, 'DD/MM/YYYY HH:mm:ss'),
      };
    });
  },
});

export const competencySelector = selector({
  key: 'competencySelector',
  get: ({ get }) => {
    const competency = produce(get(competencyState), (draft) => draft);
    const activities = _.get(competency, `competencySources.activities`, []);
    const questions = _.get(competency, `competencySources.questions`, []);

    return {
      ...competency,
      activities: mapActivities({
        dataSources: activities,
        key: 'competencySourceLibraries',
      }),
      questions: mapQuestions({
        dataSources: questions,
        key: 'competencySourceLibraries',
      }),
    };
  },
});

export const competencyMetaSelector = selector({
  key: 'competencyMetaSelector',
  get: ({ get }) => {
    const meta = produce(get(competencyMetaState), (draft) => draft);
    return toPaginationProperty(meta);
  },
});
