import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../atom/app/app.hook";
import { UserProfile } from "../../../atom/user/user.types";
import { ModalWrapper } from "../../../components/modal/modal.component";
import { UserProfileForm } from "./user-profile-form.component";

interface UserProfileFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: UserProfile) => void;
  dataSource?: any;
}
export const UserProfileFormModal: React.FC<UserProfileFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <UserProfileForm dataSource={dataSource} handleCancel={handleCancel} handleOk={handleOk} />
      </Spin>
    </ModalWrapper>
  );
};
