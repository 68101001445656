import { useForm } from 'antd/lib/form/Form';
import { Layout } from '../../../components/layout/layout.component';
import { VideoForm } from './components/video-form.component';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from '../../../utils/message.utils';
import { librariesSyncToFirestoreAsync } from '../../../atom/library/library.apis';
import useRoute from '../../../hooks/use-route.hook';

export const VideoFormPage = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isBack, setIsBack] = useState(false);
  const { param } = useRoute();

  const onSync = () => {
    showSyncConfirm(() => {
      librariesSyncToFirestoreAsync(param.id)
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  return (
    <Layout
      title={t('Videos')}
      onSubmit={(isBack) => {
        setIsBack(isBack || false);
        form.submit();
      }}
      onSync={onSync}
    >
      <VideoForm form={form} isBack={isBack} />
    </Layout>
  );
};
