import { useState } from "react";
import { Layout } from "../../../components/layout/layout.component";
import useRoute from "../../../hooks/use-route.hook";
import { CompetencyComponent } from "./components/competency.component";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../constants/permission.const";

export const CompetencyListPage = () => {
  const { t } = useTranslation();
  const title = t("Competency");
  const { history, pathname } = useRoute();
  const [searchQuery, setSearchQuery] = useState("");

  const onCreate = () => {
    history.push(`${pathname}/form`);
  };

  return (
    <Layout
      onSearch={value => setSearchQuery(value)}
      title={title}
      onCreate={onCreate}
      prefixPermission={PrefixPermission.COMPETENCY}
    >
      <CompetencyComponent searchQuery={searchQuery} />
    </Layout>
  );
};
