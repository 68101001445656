import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta } from "../../../../types/global.types";
import {
  removeUndefined,
  toFilterSelect,
  toSort,
  toStringFilter,
  toNumberFilter,
  toString,
  exclude,
  checkPermission,
  isAdmin,
  canSortRow,
  canShowAll,
  replaceParam,
} from "../../../../utils/helper.utils";
import {
  coursesSyncToFirestoreAsync,
  courseUnsyncFromFirestore,
  deleteCourseAsync,
  getCoursesAsync,
  getCoursesTrashAsync,
  restoreCoursesAsync,
  sortCoursesAsync,
  updateCourseAsync,
} from "../../../../atom/course/course.apis";
import {
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
  showRestoreConfirm,
  showSyncConfirm,
  showUnSyncConfirm,
} from "../../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  courseLoadingState,
  courseMetaState,
  coursesState,
} from "../../../../atom/course/course.atoms";
import {
  courseMetaSelector,
  coursesSelector,
} from "../../../../atom/course/course.selectors";
import useRoute from "../../../../hooks/use-route.hook";
import {
  FORM_TYPE,
  QUERY_TYPE,
  FILTER_STATUS,
  SORT,
} from "../../../../constants/default-value.const";
import { TableActionButton } from "../../../../components/table/table-action-button.component";
import { arrangeData, generateRequest } from "../../../../utils/request.utils";
import { SourceDataTab } from "../../../../components/source-data-tab/source-data-tab.component";
import { Course } from "../../../../atom/course/course.types";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { Switch } from "antd";
import { useTopic } from "../../../../atom/topic/topic.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../../constants/permission.const";
import { useAuth } from "../../../../hooks/use-auth";
import {
  columnEllipsis,
  columnTableEllipsis,
} from "../../../../utils/table.utils";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";
import { RoutePaths } from "../../../../constants/route.const";
import { PrivacyModal } from "../../../../components/privacy-modal/privacy-modal.component";
import { useApp } from "../../../../atom/app/app.hook";
import { Ellipse } from "react-ionicons";

const requestCourses = (
  query: any,
  setCourses: (items: Course[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  const request =
    option.queryType === QUERY_TYPE.TRASH
      ? getCoursesTrashAsync
      : getCoursesAsync;
  return request(option)
    .then(({ items, meta }) => {
      setCourses(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setCourses([]);
      setMeta({});
      setLoading(false);
    });
};

interface CourseProps {
  searchQuery: string;
}
export const CourseComponent: React.FC<CourseProps> = ({ searchQuery }) => {
  const { profile } = useAuth();
  const setCourses = useSetRecoilState(coursesState);
  const setMeta = useSetRecoilState(courseMetaState);
  const courses = useRecoilValue(coursesSelector);
  const meta = useRecoilValue(courseMetaSelector);
  const [loading, setLoading] = useRecoilState(courseLoadingState);
  const { query, history, pathname } = useRoute();
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [enableSort, setEnableSort] = useState(false);
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const { appOwnContent, setAppAssignPrivacy } = useApp();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const { topics, levels, subjects } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
  });
  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined({ ...query });
    requestCourses(
      { ...option, query: searchQuery || query.query },
      setCourses,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      subjectId: toStringFilter(query.subjectId),
      levelId: toStringFilter(query.levelId),
      topicId: toStringFilter(query.topicId),
      status: toNumberFilter(query.status),
      premium: toNumberFilter(query.premium),
      comingSoon: toNumberFilter(query.comingSoon),
    });
  }, [searchQuery, appOwnContent, query.query]);

  useEffect(() => {
    setColumns([
      {
        title: t("Sort"),
        dataIndex: "sort",
        width: 30,
        isDraggable: true,
      },
      {
        title: t("Rank"),
        dataIndex: "rank",
        //fixed: "left",
        width: 30,
        sorter: (a: Course, b: Course) => a.rank - b.rank,
        sortOrder: sortedInfo.columnKey === "rank" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Code"),
        //fixed: "left",
        ellipsis: true,
        width: 180,
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: Course, b: Course) => a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        width: 300,
        isSearchable: true,
        sorter: (a: Course, b: Course) => a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
        ...columnEllipsis,
      },
      {
        title: t("Subtitle"),
        dataIndex: "subtitle",
        width: 300,
        sorter: (a: Course, b: Course) => a.subtitle.length - b.subtitle.length,
        sortOrder: sortedInfo.columnKey === "subtitle" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: true,
      },
      {
        title: t("Subject"),
        dataIndex: "subjectId",
        width: 200,
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis("subject"),
      },
      {
        title: t("Level"),
        dataIndex: "levelId",
        width: 200,
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis("level"),
      },
      {
        title: t("Topic"),
        dataIndex: "topicId",
        width: 200,
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis("topic"),
      },
      {
        title: t("Publish"),
        dataIndex: "status",
        sorter: (a: Course, b: Course) => Number(a.status) - Number(b.status),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.status || null,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        render: (text: boolean, { id }: Course) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePublish(id, value)}
          />
        ),
      },
      {
        title: t("Premium"),
        dataIndex: "premium",
        sorter: (a: Course, b: Course) => Number(a.premium) - Number(b.premium),
        sortDirections: ["descend", "ascend"],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.premium || null,
        sortOrder: sortedInfo.columnKey === "premium" && sortedInfo.order,
        render: (text: boolean, { id }: Course) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handlePremium(id, value)}
          />
        ),
      },
      /* {
        title: t('ComingSoon'),
        dataIndex: 'comingSoon',
        sorter: (a: Course, b: Course) =>
          Number(a.comingSoon) - Number(b.comingSoon),
        sortDirections: ['descend', 'ascend'],
        filters: FILTER_STATUS,
        onFilter: () => true,
        filteredValue: filteredInfo.comingSoon || null,
        sortOrder: sortedInfo.columnKey === 'comingSoon' && sortedInfo.order,
        render: (text: boolean, { id }: Course) => (
          <Switch
            defaultChecked={text}
            size="small"
            onChange={(value: boolean) => handleComingSoon(id, value)}
          />
        ),
      }, */
      {
        title: t("Updated At"),
        dataIndex: "updatedAt",
        sorter: (a: Course, b: Course) =>
          a.updatedAt.length - b.updatedAt.length,
        sortOrder: sortedInfo.columnKey === "updatedAt" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        ...columnEllipsis,
      },
      {
        title: t("Deleted At"),
        dataIndex: "deletedAt",
        sorter: (a: Course, b: Course) =>
          a.deletedAt.length - b.deletedAt.length,
        sortOrder: sortedInfo.columnKey === "deletedAt" && sortedInfo.deletedAt,
        sortDirections: ["descend", "ascend"],
        ...columnEllipsis,
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text: string, record: Course) => (
          <TableActionButton
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDelete}
            // handleFirestoreDelete={handleFirestoreDelete}
            // handleSyncTofirestore={handleSyncTofirestore}
            // handleChangePrivacy={handleChangePrivacy}
            handlePreview={handlePreview}
            record={record}
          />
        ),
      },
    ]);
  }, [filteredInfo, sortedInfo, subjects, levels, topics]);

  const handleSwitch = (id: string, value: boolean, dataIndex: string) => {
    updateCourseAsync(id, { [dataIndex]: value } as any)
      .then(() => {
        messageSuccess();
        requestCourses(query, setCourses, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  // const handleComingSoon = (id: string, value: boolean) => {
  //   handleSwitch(id, value, 'comingSoon');
  // };

  const handlePublish = (id: string, value: boolean) => {
    handleSwitch(id, value, "status");
  };

  const handlePremium = (id: string, value: boolean) => {
    handleSwitch(id, value, "premium");
  };

  const onSortEnd = (data: any) => {
    const sortData = arrangeData<Course>(data);
    sortCoursesAsync(sortData)
      .then(() => {
        messageSuccess();
        requestCourses(query, setCourses, setMeta, setLoading);
      })
      .catch((err) => showMessageErrors(err));
  };

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
      status,
      premium,
      comingSoon,
    } = data;

    const option = {
      ...query,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
      status: toString(status),
      premium: toString(premium),
      comingSoon: toString(comingSoon),
    };
    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));

    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({
      subjectId,
      levelId,
      topicId,
      status,
      premium,
      comingSoon,
    });
    requestCourses(option, setCourses, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
    });
    requestCourses(option, setCourses, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onSaveEditable = (value: Course) => {
    const { id, title, code, rank, subtitle } = value;

    updateCourseAsync(id, {
      title,
      code,
      rank: Number(rank),
      subtitle,
    } as Course)
      .then(({ message }) => {
        messageSuccess(message);
        requestCourses(query, setCourses, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  const handleEdit = (record: Course) => {
    history.push(`${pathname}/form/${record.id}?formType=${FORM_TYPE.EDIT}`);
  };

  const handleDuplicate = (record: Course) => {
    history.push(
      `${pathname}/form/${record.id}?formType=${FORM_TYPE.DUPLICATE}`
    );
  };

  const handlePreview = (record: Course) => {
    window.open(
      replaceParam(RoutePaths.MOBILE_PREVIEW_COURSE, record.id),
      "_blank"
    );
  };

  const handleChangePrivacy = (value: Course) => {
    setVisible(true);
    setAppAssignPrivacy(value);
  };

  const handleDelete = (id: string) => {
    showDeleteConfirm(() => {
      deleteCourseAsync(id)
        .then(() => {
          messageSuccess();
          requestCourses(query, setCourses, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err));
    });
  };

  const handleFirestoreDelete = (id: string) => {
    showUnSyncConfirm(() => {
      courseUnsyncFromFirestore(id)
        .then(() => {
          messageSuccess();
          requestCourses(query, setCourses, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      coursesSyncToFirestoreAsync(id)
        .then(() => {
          messageSuccess();
          requestCourses(query, setCourses, setMeta, setLoading);
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  const onChangeTab = (key: string) => {
    requestCourses(
      { ...exclude(query, ["sortField", "sortOrder"]), queryType: key },
      setCourses,
      setMeta,
      setLoading
    );
    history.push(
      generateRequest(pathname, {
        ...exclude(query, ["sortField", "sortOrder"]),
        queryType: key,
      })
    );
  };

  const onRestore = (selectedRows: Course[]) => {
    showRestoreConfirm(() => {
      restoreCoursesAsync({ ids: selectedRows.map((item: Course) => item.id) })
        .then(() => {
          messageSuccess();
          requestCourses(query, setCourses, setMeta, setLoading);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    });
  };

  const onEnableSort = () => {
    setEnableSort(true);
    const option = {
      ...query,
      showAll: "yes",
      sortField: "rank",
      sortOrder: SORT.ASC,
    };
    setSortedInfo(toSort({ sortField: "rank", sortOrder: SORT.ASC }));
    requestCourses(option, setCourses, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onShowAll = () => {
    const option = { ...query, showAll: "yes" };
    requestCourses(option, setCourses, setMeta, setLoading);
    history.push(generateRequest(pathname, option));
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setEnableSort(false);
    requestCourses({}, setCourses, setMeta, setLoading);
    history.push(generateRequest(pathname, {}));
  };

  const onOkPrivacy = (value: any) => {
    setVisible(false);
    updateCourseAsync(value.id, {
      ...value,
    } as Course)
      .then(({ message }) => {
        messageSuccess(message);
        requestCourses(query, setCourses, setMeta, setLoading);
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  return (
    <Fragment>
      <SourceDataTab
        onChange={onChangeTab}
        prefixPermission={PrefixPermission.COURSE}
      />
      <PrivacyModal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOkPrivacy}
      />
      <ListFeatureOption
        onShowAll={onShowAll}
        onClearFilter={onClearFilter}
        onEnableSort={onEnableSort}
        showAll={canShowAll(query, filteredInfo)}
        enableSort={canSortRow(query)}
        query={query}
      />
      <TableComponent
        key={`table-${loading}-${courses.length}`}
        dataSource={courses}
        onSortEnd={onSortEnd}
        columns={columns}
        onChange={onTableChange}
        onColumnSearch={onColumnSearch}
        pagination={meta}
        loading={loading}
        onSaveEditable={onSaveEditable}
        query={query}
        hasSelectedRows={
          (query.queryType === QUERY_TYPE.TRASH &&
            checkPermission(profile, PrefixPermission.COURSE).restore) ||
          (query.queryType === QUERY_TYPE.TRASH && isAdmin(profile))
        }
        onRestore={onRestore}
        enableSort={enableSort && canSortRow(query)}
      />
    </Fragment>
  );
};
