import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { CompetitionTest } from './competition-test.types';

export const getCompetitionTestsAsync = (option: {}) => {
  const url = generateQuery('/competition-tests', option, [
    'levelId',
    'subjectId',
    'categoryId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getCompetitionTestAsync = (id: string) => {
  return axios
    .get(`/competition-tests/${id}`, config())
    .then((response) => response.data.data);
};

export const getCompetitionTestsTrashAsync = (option: {}) => {
  const url = generateQuery('/competition-tests/list/trash', option);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const restoreCompetitionTestsAsync = (data: RestoreType) => {
  return axios
    .patch('/competition-tests/restore', data, config())
    .then((response) => response.data);
};

export const createCompetitionTestAsync = (data: CompetitionTest) => {
  return axios
    .post('/competition-tests', data, config())
    .then((response) => response.data);
};

export const updateCompetitionTestAsync = (
  id: string,
  data: CompetitionTest
) => {
  return axios
    .put(`/competition-tests/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteCompetitionTestAsync = (id: string) => {
  return axios
    .delete(`/competition-tests/${id}`, config())
    .then((response) => response.data);
};

export const deleteCompetitionTestLibraryAsync = (id: string) => {
  return axios
    .delete(`/competition-test-libraries/${id}`, config())
    .then((response) => response.data);
};

export const sortCompetitionTestsAsync = (data: SortData) => {
  return axios
    .patch(`/competition-tests/set-rank`, data, config())
    .then((response) => response.data);
};

export const competitionTestsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/competition-tests/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};

export const testCompetitionUnsyncFromFirestore = async (id: string) => {
  return axios
    .delete(`/competition-tests/unsync-from-firestore/${id}`, config())
    .then((response) => response.data);
};
