import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { Category } from './category.types';

export const getCategoriesAsync = (option: {}) => {
  const url = generateQuery('/categories', option, ['comingSoon', 'status']);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getCategoriesSelectAsync = (type: string) => {
  return axios
    .get(`/categories/for/select-option?type=${type}`, config())
    .then((response) => response.data.data);
};

export const getCategoriesTrashAsync = (option: {}) => {
  const url = generateQuery('/categories/list/trash', option, [
    'comingSoon',
    'status',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const restoreCategoriesAsync = (data: RestoreType) => {
  return axios
    .patch('/categories/restore', data, config())
    .then((response) => response.data);
};

export const createCategoryAsync = (data: Category) => {
  return axios
    .post('/categories', data, config())
    .then((response) => response.data);
};

export const updateCategoryAsync = (id: string, data: Category) => {
  return axios
    .put(`/categories/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteCategoryAsync = (id: string) => {
  return axios
    .delete(`/categories/${id}`, config())
    .then((response) => response.data);
};

export const sortCategoriesAsync = (data: SortData) => {
  return axios
    .patch('/categories/set-rank', data, config())
    .then((response) => response.data);
};

export const categoriesSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/categories/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};
