import styled from "styled-components";

export const WrapLearn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  background-color: #e5e5f7;
  // opacity: 0.8;
  // background-image: radial-gradient(#1e81b0 0.5px, transparent 0.5px),
  //   radial-gradient(#1e81b0 0.5px, #e5e5f7 0.5px);
  // background-size: 20px 20px;
  // background-position: 0 0, 10px 10px;
  padding: 50px 0px;
  background: linear-gradient(to top, #93a5cf, #e4efe9);
  .swiper {
    width: 500px;
    height: 100%;
    border-radius: 20px;
    border: 1px solid #ddd;

    background: #ecf7fd;
    height: calc(100vh - 100px);
    padding: 20px 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .blue {
    border: 10px solid #91caff;
  }
`;

export const Section = styled.div`
  /* color: red; */
  width: 100%;
  text-align: center;
  border-radius: 20px;
  height: 100px;
  border: 0px solid #ddd;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #e6f4ff /* #48dbff */;
  border: 1px solid #91caff;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  cursor: pointer;

  &.competencyTest {
    background: #fff2f0 /* #9c27b0 */;
    float: center;
    border: 1px solid #ffccc7;
    // color: #fff;
  }

  &.resource {
    background: #fffbe6 /* #cd94d7 */;
    border: 1px solid #ffe58f;
  }
`;
// export const Section = styled.div`
//   /* color: red; */
//   width: 100%;
//   text-align: center;
//   border-radius: 20px;
//   height: 100px;
//   border: 0px solid #ddd;
//   font-weight: bold;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   background: #3FC9F4 /* #48dbff */;
//   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
//   cursor: pointer;

//   &.competencyTest {
//     background: #E19FFF /* #9c27b0 */;
//     color: #fff;
//   }

//   &.resource {
//     background: #F1B875 /* #cd94d7 */;
//   }
// `;
