import React from "react";
import { CancelButton } from "../button-cancel/button-cancel.component";
import styled from "styled-components";
import { SaveButton } from "../button-save/button-save.component";
import { Space } from "antd";
import { useTranslation } from "react-i18next";

const WrapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

interface FormModalHeaderProps {
  title: string;
  handleCancel?: any;
  handleOk?: any;
}
export const FormModalHeader: React.FC<FormModalHeaderProps> = ({
  title,
  handleCancel,
  handleOk
}) => {
  const { t } = useTranslation();

  return (
    <WrapHeader>
      <Title>{t(title)}</Title>
      <div>
        <Space>
          <CancelButton handleClick={handleCancel} />
          <SaveButton handleClick={handleOk} />
        </Space>
      </div>
    </WrapHeader>
  );
};
