import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import { competencyTestsSyncToFirestoreAsync } from "../../../atom/competency-test/competency-test.apis";
import useRoute from "../../../hooks/use-route.hook";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { toSwiper } from "../../../utils/response.utils";
import { useCompetencyTest } from "../../contents/competency-test/components/competency-test.hook";
import { SwiperComponent } from "../components/swiper/swiper.component";

export const FlowCompetencyTestPage = () => {
  const {
    param: { id },
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { competencyTest } = useCompetencyTest({ id, forceRender });

  const competencies = competencyTest.competencies.map((item) => {
    return {
      ...item,
      libraries: item.libraries.filter((item2: any) => {
        return item2.libraryId === item.libraryId;
      }),
    };
  });

  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      competencyTestsSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };

  return (
    <SwiperComponent
      title={competencyTest.title}
      data={competencyTest}
      //onSync={() => handleSyncTofirestore(id)}
      dataSources={toSwiper(competencies, ["libraries", "children"]) || []}
    />
  );
};
