import { Layout } from "../../../components/layout/layout.component";
import { MediaComponent } from "./components/media.component";
import { useVisible } from "../../../hooks/use-visible.hook";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../constants/permission.const";

export const IconPage = () => {
  const { visible, setVisible } = useVisible();
  const { t } = useTranslation();

  return (
    <Layout
      title={t("Icon")}
      onCreate={() => setVisible(true)}
      prefixPermission={PrefixPermission.MEDIA}
    >
      <MediaComponent visible={visible} setVisible={setVisible} />
    </Layout>
  );
};
