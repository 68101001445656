import produce from 'immer';
import { selector } from 'recoil';
import { PrefixPermission } from '../../constants/permission.const';
import {
  checkPermission,
  formatDate,
  isSyncable,
} from '../../utils/helper.utils';
import { toPaginationProperty } from '../../utils/request.utils';
import { authUserSelector } from '../auth/auth.selectors';
import { levelsState, levelMetaState, levelsSelectState } from './level.atoms';

export const levelsSelector = selector({
  key: 'levelsSelector',
  get: ({ get }) => {
    const levels = produce(get(levelsState), (draft) => draft);
    const permissions = checkPermission(
      get(authUserSelector),
      PrefixPermission.COMPETENCY
    );
    return levels.map((item, i) => {
      const { updatedAt, deletedAt } = item;
      return {
        ...item,
        key: i.toString(),
        index: i,
        ...permissions,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, 'DD/MM/YYYY HH:mm:ss'),
        deletedAt: formatDate(deletedAt, 'DD/MM/YYYY HH:mm:ss'),
      };
    });
  },
});

export const levelsSelectSelector = selector({
  key: 'levelsSelectSelector',
  get: ({ get }) => {
    const levels = produce(get(levelsSelectState), (draft) => draft);
    return levels.map((item, i) => {
      const { title, code } = item;
      return { ...item, name: `[${code}]-${title}` };
    });
  },
});

export const levelMetaSelector = selector({
  key: 'levelMetaSelector',
  get: ({ get }) => {
    const meta = produce(get(levelMetaState), (draft) => draft);
    return toPaginationProperty(meta);
  },
});
