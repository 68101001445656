import { Col, Form, Row } from "antd";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { User } from "../../../../atom/user/user.types";
import { UploadForm, InputForm, SelectForm } from "../../../../components/form";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { OptionType } from "../../../../components/form/select/select.type";
import { storagePath } from "../../../../utils/firebase.utils";
import { useTranslation } from "react-i18next";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

interface UserFormProps {
  dataSource?: any;
  handleCancel?: () => void;
  handleOk: (value: User) => void;
  roles: OptionType[];
}
export const UserForm: React.FC<UserFormProps> = ({
  dataSource,
  handleCancel,
  handleOk,
  roles
}) => {
  const [form] = Form.useForm();
  useEnterEvent({ form });
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({ ...dataSource, roleId: _.get(dataSource, "role.id") });
  }, [dataSource, form]);

  const onFinish = (value: any) => {
    handleOk(value);
  };

  return (
    <Fragment>
      <FormWrapper name="userForm" form={form} onFinish={onFinish}>
        <FormModalHeader title="User" handleCancel={handleCancel} />
        <InputForm name="id" placeholder="ID" className="d-none" />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputForm
              name="firstName"
              placeholder={t("First Name")}
              rules={[
                {
                  required: true,
                  message: "Please input the first name!"
                }
              ]}
            />
          </Col>
          <Col span={12}>
            <InputForm
              name="lastName"
              placeholder={t("Last Name")}
              rules={[
                {
                  required: true,
                  message: "Please input the last name!"
                }
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InputForm
              name="email"
              placeholder={t("Email")}
              rules={[
                {
                  required: true,
                  message: "Please input the email!",
                  type: "email"
                }
              ]}
            />
          </Col>
          {!_.get(dataSource, "id") && (
            <Col span={12}>
              <InputForm
                name="password"
                placeholder={t("password")}
                rules={[
                  {
                    required: true,
                    message: "Please input the password!"
                  },
                  {
                    max: 16,
                    min: 8
                  }
                ]}
              />
            </Col>
          )}
          <Col span={12}>
            <SelectForm
              name="roleId"
              placeholder={t("Role")}
              rules={[
                {
                  required: true,
                  message: "Please select the role!"
                }
              ]}
              dataSource={roles}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <UploadForm
              form={form}
              name="profile"
              imageUrl={storagePath(_.get(dataSource, "profile"))}
              required={true}
              // rules={[{ required: true, message: "Profile is required" }]}
            />
          </Col>
        </Row>
      </FormWrapper>
    </Fragment>
  );
};
