import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { Topic } from "../../../../atom/topic/topic.types";
import { OptionType } from "../../../../components/form/select/select.type";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { TopicForm } from "./topic-form.component";

interface TopicFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: Topic) => void;
  dataSource?: Topic;
  levels: OptionType[];
  subjects: OptionType[];
  medias: OptionType[];
}
export const TopicFormModal: React.FC<TopicFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource,
  levels,
  subjects,
  medias
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <TopicForm
          dataSource={dataSource}
          handleCancel={handleCancel}
          handleOk={handleOk}
          levels={levels}
          subjects={subjects}
          medias={medias}
        />
      </Spin>
    </ModalWrapper>
  );
};
