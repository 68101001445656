import _ from "lodash";
import { TextEditorForm } from "../../../../components/form";
import { TitleItem } from "../../../../components/title-item/title-item.component";
import { useTranslation } from "react-i18next";

export const QuestionSection: React.FC<any> = ({ form, dataSource, children }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* {_.get(dataSource, "data.questionText", "")}
      {_.get(dataSource, "data.solution", "")} */}
      <TitleItem title={t("Question Text")} />
      <TextEditorForm
        name="questionText"
        form={form}
        value={_.get(dataSource, "data.questionText", "")}
        rules={[{ required: true }]}
      />
      {children}
      <TitleItem title={t("Solution")} />
      <TextEditorForm
        name="solution"
        form={form}
        value={_.get(dataSource, "data.solution", "")}
        rules={[{ required: false }]}
      />
    </>
  );
};
