import { Tag } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { getVimeoVideoAsync } from '../../atom/library/video.apis';
import { getVimeoVideoId } from '../../utils/helper.utils';
import { showMessageErrors } from '../../utils/message.utils';

export const VideoPreview = ({
  vimeoId,
  bunnyId,
  youtubeId,
  onGetData,
}: {
  vimeoId?: string;
  bunnyId?: string;
  youtubeId?: string;
  onGetData?: (value: { videoId: string; photoUrl: string }) => void;
}) => {
  const [videoUrl, setVideoUrl] = useState('');
  useEffect(() => {
    if (vimeoId && vimeoId.search('blob:http') === -1) {
      const videoId = getVimeoVideoId(vimeoId);
      getVimeoVideoAsync(videoId)
        .then((res) => {
          const file = res.files.find(
            (item: { quality: string; link: string }) => {
              return item.quality === 'hls';
            }
          );

          const vimeoLink = _.get(file, 'link', '');

          if (onGetData)
            onGetData({
              videoId,
              photoUrl: _.get(res, 'pictures.sizes[0].link', ''),
            });
          setVideoUrl(vimeoLink);
        })
        .catch((err) => {
          showMessageErrors(err);
        });
    } else if (vimeoId && vimeoId?.search('blob:http') !== -1) {
      setVideoUrl(vimeoId || '');
    }
  }, [vimeoId, onGetData]);

  useEffect(() => {
    if (bunnyId && bunnyId.search('blob:http') === -1) {
      setVideoUrl(`https://vz-e362e405-7f7.b-cdn.net/${bunnyId}/playlist.m3u8`);
      if (onGetData)
        onGetData({
          videoId: bunnyId,
          photoUrl: `https://vz-e362e405-7f7.b-cdn.net/${bunnyId}/thumbnail.jpg`,
        });
    } else if (bunnyId && bunnyId?.search('blob:http') !== -1) {
      setVideoUrl(bunnyId || '');
    }
  }, [bunnyId, onGetData]);

  useEffect(() => {
    if (youtubeId) setVideoUrl(`https://www.youtube.com/watch?v=${youtubeId}`);
  }, [youtubeId]);

  if (!vimeoId && !bunnyId && !youtubeId) {
    return (
      <div
        style={{
          padding: '20px 0 20px 0',
          textAlign: 'center',
          minHeight: '270px',
          borderRadius: '20px',
        }}
      >
        <Tag color='magenta' style={{ marginTop: '110px' }}>
          No Video...!
        </Tag>
      </div>
    );
  }

  return (
    <div>
      {/* {videoUrl} */}
      {/* {vimeoId} */}
      <ReactPlayer width='100%' height='270px' url={videoUrl} controls={true} />
    </div>
  );
};
