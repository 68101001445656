// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Competency } from "./competency.types";

export const competenciesState = atom<Competency[]>({
  key: "competenciesState",
  default: []
});

export const competencyMetaState = atom<Meta>({
  key: "competencyMetaState",
  default: {}
});

export const competencyState = atom<Competency | {}>({
  key: "competencyState",
  default: {}
});

export const competencyLoadingState = atom<boolean>({
  key: "competencyLoadingState",
  default: false
});
