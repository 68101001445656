export const FILTER_STATUS = [
  { text: 'Publish', value: 1 },
  { text: 'Un Publish', value: 0 },
];

export const FILTER_LEVELS = [
  { text: 'General', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
  { text: '11', value: 11 },
  { text: '12', value: 12 },
];

export enum USER_ROLE {
  ADMIN = 'ADMIN',
  CREATOR = 'CREATOR',
}

export const FILTER_ROLES = [
  { text: 'admin', value: 'admin' },
  { text: 'superAdmin', value: 'superAdmin' },
  { text: 'editor', value: 'editor' },
  { text: 'creator', value: 'create' },
];

export enum CONTENT_TYPE {
  SUBJECT = 'SUBJECT',
  LEVEL = 'LEVEL',
  TOPIC = 'TOPIC',
}

export enum CATEGORY_TYPE {
  RESOURCE = 'RESOURCE',
  TEST = 'TEST',
}

export enum LIBRARY_TYPE {
  VIDEO = 'VIDEO',
  QUESTION = 'QUESTION',
  TEXT = 'TEXT',
}

export enum FORM_TYPE {
  DUPLICATE = 'DUPLICATE',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export enum QUESTION_TYPE {
  INPUT = 'INPUT',
  MCQ = 'MCQ',
  GROUP = 'GROUP',
  DRAG_DROP = 'DRAG_DROP',
  ORDER = 'ORDER',
  FILL_GAP = 'FILL_GAP',
}

export const FILTER_QUESTION_TYPES = [
  { text: 'Input', value: QUESTION_TYPE.INPUT },
  { text: 'MCQ', value: QUESTION_TYPE.MCQ },
  { text: 'Group', value: QUESTION_TYPE.GROUP },
  { text: 'Drag and drop', value: QUESTION_TYPE.DRAG_DROP },
  { text: 'Order', value: QUESTION_TYPE.ORDER },
  { text: 'Fill in gap', value: QUESTION_TYPE.FILL_GAP },
];

export enum SECTION_TYPE {
  TEST = 'TEST',
  RESOURCE = 'RESOURCE',
  LEARN = 'LEARN',
  PRACTICE_TEST = 'TEST_PRACTICE',
  COMPETENCY_TEST = 'TEST_COMPETENCY',
}

export const FILTER_SECTION_TYPES = [
  { text: 'Resource', value: SECTION_TYPE.RESOURCE },
  { text: 'Learn', value: SECTION_TYPE.LEARN },
  { text: 'Practice Test', value: SECTION_TYPE.PRACTICE_TEST },
  { text: 'Competency Test', value: SECTION_TYPE.COMPETENCY_TEST },
];

export enum QUERY_TYPE {
  SOURCE = 'SOURCE',
  TRASH = 'TRASH',
}

export enum LIBRARY_SOURCE_TYPE {
  ACTIVITY = 'ACTIVITY',
  QUESTION = 'QUESTION',
}

export const SHOW_ALL_OFFSET = 10000;

export const STATUS_FEATURES = [
  {
    text: 'Course',
    value: 'course',
  },
  {
    text: 'Review',
    value: 'review',
  },
  {
    text: 'Exam Test',
    value: 'examTest',
  },
  {
    text: 'Competency Test',
    value: 'competencyTest',
  },
  {
    text: 'Competition Test',
    value: 'competitionTest',
  },
  {
    text: 'Practice Test',
    value: 'practiceTest',
  },
  {
    text: 'Resource',
    value: 'resource',
  },
];

export enum SORT {
  ASC = 'ascend',
  DESC = 'descend',
}

export const STORAGE_BASE_PATH = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/@@@@@?alt=media`;
export enum STORAGE_PATH {
  ADMIN_USER = 'admin-profiles',
  MEDIA_ICON = 'images',
  EDITOR = 'editors',
}

export enum MEDIA_TYPE {
  ICON = 'ICON',
  PHOTO = 'PHOTO',
}

export const PERMISSION_OPTIONS = [
  {
    value: 'create',
    text: 'Create',
  },
  {
    value: 'findAll',
    text: 'List',
  },
  {
    value: 'findOne',
    text: 'View',
  },
  {
    value: 'update',
    text: 'Update',
  },
  {
    value: 'remove',
    text: 'Delete',
  },
  {
    value: 'trash',
    text: 'List Trash',
  },
  {
    value: 'restore',
    text: 'Restore',
  },
];

export enum LNG {
  KM = 'km-KH',
  EN = 'en-US',
}

export enum ROLE_TYPE {
  ADMIN = 'ADMIN',
  CONTENT_CREATOR = 'CONTENT_CREATOR',
  CONTENT_EDITOR = 'CONTENT_EDITOR',
}

export const ROLE_TYPES = [
  { id: ROLE_TYPE.ADMIN, name: ROLE_TYPE.ADMIN },
  { id: ROLE_TYPE.CONTENT_CREATOR, name: ROLE_TYPE.CONTENT_CREATOR },
  { id: ROLE_TYPE.CONTENT_EDITOR, name: ROLE_TYPE.CONTENT_EDITOR },
];

export enum EntityName {
  COURSE = 'COURSE',
  CATEGORY = 'CATEGORY',
  LEVEL = 'LEVEL',
  COMPETENCY = 'COMPETENCY',
  COLOR_CODE = 'COLOR_CODE',
  COMPETITION_TEST = 'COMPETITION_TEST',
  EXAM_TEST = 'EXAM_TEST',
  LEARN = 'LEARN',
  LIBRARY = 'LIBRARY',
  MEDIA = 'MEDIA',
  RESOURCE = 'RESOURCE',
  REVIEW = 'REVIEW',
  SUBJECT = 'SUBJECT',
  TEST_COMPETENCY = 'TEST_COMPETENCY',
  TEST_PRACTICE = 'TEST_PRACTICE',
  TOPIC = 'TOPIC',
}

export const KHMER_ALPHABET = [
  'ក',
  'ខ',
  'គ',
  'ឃ',
  'ង',
  'ច',
  'ឆ',
  'ជ',
  'ឈ',
  'ញ',
];

export enum ExamLibraryType {
  SOLUTION = 'SOLUTION',
  TEST = 'TEST',
}
