import _ from 'lodash';
import {
  LIBRARY_SOURCE_TYPE,
  SHOW_ALL_OFFSET,
} from '../constants/default-value.const';
import { LooseObject, Meta } from '../types/global.types';
import { exclude } from './helper.utils';

export const generateRequest = (
  requestUrl: string,
  option: LooseObject = {}
) => {
  let url = `${requestUrl}?`;
  if (option) {
    Object.entries({ ...option }).forEach(
      ([key, value]: [string, string | number]) => {
        url = `${url}&${key}=${value}`;
      }
    );
  }
  return url;
};

export const toStringBool = (value: string) => {
  return value
    .split(',')
    .map((item: string) => (item === '1' ? 'true' : 'false'))
    .join(',');
};

export const toColumnFilter = (key: string, value: any) => {
  if (
    typeof value === 'string' &&
    ['status', 'comingSoon', 'premium'].includes(key)
  )
    return toStringBool(value);
  return value;
};

export const generateQuery = (
  requestUrl: string,
  option: LooseObject = {},
  columnFilters: string[] = []
) => {
  let url = `${requestUrl}?`;
  if (option) {
    option = mapSortOrder(option);
    let defaultParam = {};
    if (!option.noDefaultParam) defaultParam = { page: 1, offset: 10 };
    if (option.showAll) {
      option.offset = SHOW_ALL_OFFSET;
      option.page = 1;
    }

    if (localStorage.getItem('ownContent') === 'true') {
      option.ownContent = localStorage.getItem('ownContent');
    }

    if (option.noDefaultOwnContent) {
      delete option.ownContent;
      delete option.noDefaultOwnContent;
    }

    option = exclude(option, ['noDefaultParam']);
    Object.entries({ ...defaultParam, ...option }).forEach(
      ([key, value]: [string, any]) => {
        if (columnFilters.includes(key)) {
          if (value) url = `${url}&s_${key}=${toColumnFilter(key, value)}`;
        } else if (value && value !== 'null') {
          url = `${url}&${key}=${value}`;
        }
      }
    );
  }
  return url;
};

const mapSortOrder = (option: LooseObject) => {
  if (option) {
    const { sortOrder } = option;
    if (sortOrder === 'ascend') return { ...option, sortOrder: 'ASC' };
    else if (sortOrder === 'descend') return { ...option, sortOrder: 'DESC' };
    else return exclude(option, ['sortOrder', 'sortField']);
  }
  return option;
};

export const toPaginationProperty = (meta: Meta) => {
  return {
    total: _.get(meta, 'totalItems', 0),
    current: _.get(meta, 'currentPage', 1),
    pageSize: _.get(meta, 'itemsPerPage', 10),
  };
};

export const arrangeData = <T extends LooseObject>(sorted: T[]) => {
  const data = sorted.map((item: T, i: number) => {
    const { id } = item;
    return { id, rank: i };
  });

  return { ranks: data };
};

export const formatActivities = (activities: any[], key: string[] = []) => {
  return activities
    .map((activity: any, i: number) => {
      const { id, title, isNewRecord } = activity;
      const sourceLibraries = _.get(activity, key[1], []).map(
        (library: any, j: number) => {
          const { id, libraryId, isNewRecord } = library;
          return isNewRecord
            ? { libraryId, rank: j }
            : { id, libraryId, rank: j };
        }
      );

      return isNewRecord
        ? {
            title,
            rank: i,
            [key[0]]: sourceLibraries,
            type: LIBRARY_SOURCE_TYPE.ACTIVITY,
          }
        : {
            id,
            title,
            rank: i,
            [key[0]]: sourceLibraries,
            type: LIBRARY_SOURCE_TYPE.ACTIVITY,
          };
    })
    .filter((activity: any) => activity[key[0]].length);
};

export const formatQuestions = (
  questions: any[],
  key: string,
  length: number = 0
) => {
  return questions.map((question: any, i: number) => {
    const { id, sourceId, title, libraryId, isNewRecord } = question;
    const sourceLibrary = isNewRecord
      ? { libraryId, rank: i }
      : { id: sourceId, libraryId, rank: i };
    const sourceLibraries = [{ ...sourceLibrary }];

    return isNewRecord
      ? {
          title,
          rank: i + length,
          [key]: sourceLibraries,
          type: LIBRARY_SOURCE_TYPE.QUESTION,
        }
      : {
          id,
          title,
          rank: i + length,
          [key]: sourceLibraries,
          type: LIBRARY_SOURCE_TYPE.QUESTION,
        };
  });
};
