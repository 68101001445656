import produce from 'immer';
import { selector } from 'recoil';
import { PrefixPermission } from '../../constants/permission.const';
import {
  checkPermission,
  formatDate,
  isSyncable,
} from '../../utils/helper.utils';
import { toPaginationProperty } from '../../utils/request.utils';
import { authUserSelector } from '../auth/auth.selectors';
import {
  librariesState,
  libraryMetaState,
  VideoOption,
  videoOptionsState,
} from './library.atoms';

export const librariesSelector = selector({
  key: 'librariesSelector',
  get: ({ get }) => {
    const libraries = produce(get(librariesState), (draft) => draft);
    const permissions = checkPermission(
      get(authUserSelector),
      PrefixPermission.LIBRARY
    );
    return libraries.map((item, i) => {
      const { updatedAt, deletedAt } = item;
      return {
        ...item,
        key: i.toString(),
        // title:
        // '<p>នារីម្នាក់អូសវាលីមួយបង្កើតបានមុំ (m 	heta =30^{circ}) ធៀនទៅនឹងអ័ក្សដេក។ គាត់អូសដោយកម្លាំង (m F=40m N) ។ ចូរកំណត់តម្លៃរបស់កម្លាំងផ្គុំដេក និងម្លាំងផ្គុំឈរ។</p><p><img src="https://firebasestorage.googleapis.com/v0/b/tesdopi-demo.appspot.com/o/editor%2F3e6f41ca-72a2-4866-86e8-6ae12ad20868.png?alt=media"></p>',
        index: i,
        isSyncable: isSyncable(item),
        updatedAt: formatDate(updatedAt, 'DD/MM/YYYY HH:mm:ss'),
        deletedAt: formatDate(deletedAt, 'DD/MM/YYYY HH:mm:ss'),
        ...permissions,
      };
    });
  },
});

export const libraryMetaSelector = selector({
  key: 'libraryMetaSelector',
  get: ({ get }) => {
    const meta = produce(get(libraryMetaState), (draft) => draft);
    return toPaginationProperty(meta);
  },
});

export const videoOptionsSelector = selector({
  key: 'videoOptionsSelector',
  get: ({ get }) => {
    const data = produce(get(videoOptionsState), (draft) => draft);

    return data.map((item: VideoOption) => {
      return {
        id: item.url,
        name: item.title,
      };
    });
  },
});
