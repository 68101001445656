import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta } from "../../../../types/global.types";
import {
  canShowAll,
  convertObjectArrayToString,
  removeUndefined,
  toFilterSelect,
  toSort,
  toString,
  toStringFilter,
} from "../../../../utils/helper.utils";
import { getPracticeTestsAsync } from "../../../../atom/practice-test/practice-test.apis";
import { showMessageErrors } from "../../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  practiceTestLoadingState,
  practiceTestMetaState,
  practiceTestsState,
} from "../../../../atom/practice-test/practice-test.atoms";
import {
  practiceTestMetaSelector,
  practiceTestsSelector,
} from "../../../../atom/practice-test/practice-test.selectors";
import { PracticeTest } from "../../../../atom/practice-test/practice-test.types";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { Modal } from "antd";
import { useTopic } from "../../../../atom/topic/topic.hook";
import { SearchAndTitle } from "../../../../components/search-and-title/search-and-title.component";
import { useTranslation } from "react-i18next";
import { columnTableEllipsis } from "../../../../utils/table.utils";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestPracticeTests = (
  query: any,
  setPracticeTests: (items: PracticeTest[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);

  return getPracticeTestsAsync({ ...option, status: true })
    .then(({ items, meta }) => {
      setPracticeTests(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setPracticeTests([]);
      setMeta({});
      setLoading(false);
    });
};

interface PracticeTestModalProps {
  visible: boolean;
  onOk: (value: any) => void;
  onCancel: () => void;
  selectionType?: any;
  defaultFilter?: any;
}

export const PracticeTestModal: React.FC<PracticeTestModalProps> = ({
  visible,
  onOk,
  onCancel,
  selectionType,
  defaultFilter,
}) => {
  const setPracticeTests = useSetRecoilState(practiceTestsState);
  const setMeta = useSetRecoilState(practiceTestMetaState);
  const practiceTests = useRecoilValue(practiceTestsSelector);
  const meta = useRecoilValue(practiceTestMetaSelector);
  const [loading, setLoading] = useRecoilState(practiceTestLoadingState);
  const query: any = defaultFilter;
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [searchInfo, setSearchInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const { topics, levels, subjects } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
  });
  const { t } = useTranslation();

  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    if (visible) {
      const option = removeUndefined(query);
      requestPracticeTests(
        { ...option, query: searchQuery },
        setPracticeTests,
        setMeta,
        setLoading
      );
      setSortedInfo({ ...toSort(query) });
      setFilteredInfo({
        subjectId: toStringFilter(query.subjectId),
        levelId: toStringFilter(query.levelId),
        topicId: toStringFilter(query.topicId),
      });
      setTopicLevelId(query.levelId);
      setTopicSubjectId(query.subjectId);
    }
    return () => {
      setSearchInfo({});
    };
  }, [searchQuery, setTopicLevelId, setTopicSubjectId]);

  useEffect(() => {
    setColumns([
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: PracticeTest, b: PracticeTest) =>
          a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: false,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: PracticeTest, b: PracticeTest) =>
          a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: false,
      },
      {
        title: t("Question"),
        dataIndex: "numberOfQuestion",
        isSearchable: true,
        sorter: (a: PracticeTest, b: PracticeTest) =>
          a.numberOfQuestion - b.numberOfQuestion,
        sortOrder:
          sortedInfo.columnKey === "numberOfQuestion" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: t("Subject"),
        dataIndex: "subjectId",
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis("subject"),
      },
      {
        title: t("Level"),
        dataIndex: "levelId",
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis("level"),
      },
      {
        title: t("Topic"),
        dataIndex: "topicId",
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis("topic"),
      },
    ]);
  }, [filteredInfo, sortedInfo, topics, levels, subjects]);

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
    } = data;

    const option = {
      ...query,
      ...searchInfo,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      query: searchQuery,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
    };

    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));
    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ subjectId, levelId, topicId });
    requestPracticeTests(option, setPracticeTests, setMeta, setLoading);
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
      query: searchQuery,
    });
    setSearchInfo({ searchField: column, searchValue: value });
    requestPracticeTests(option, setPracticeTests, setMeta, setLoading);
  };

  const onSelectedRows = (selectedRows: PracticeTest[]) => {
    setSelectedRows(selectedRows);
  };

  const _onOk = () => {
    if (selectedRows.length) {
      onOk(selectedRows);
    } else {
      onCancel();
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const onShowAll = () => {
    const option = {
      ...convertObjectArrayToString(filteredInfo),
      ...searchInfo,
      query: searchQuery,
      showAll: "yes",
    };
    requestPracticeTests(option, setPracticeTests, setMeta, setLoading);
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchInfo({});
    requestPracticeTests({}, setPracticeTests, setMeta, setLoading);
  };

  return (
    <Fragment>
      <Modal
        visible={visible}
        title={null}
        closable={false}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        onCancel={onCancel}
        maskClosable={false}
        width="80%"
        onOk={_onOk}
      >
        <SearchAndTitle
          title={t("Practice Tests")}
          handleSearch={handleSearch}
        />
        <ListFeatureOption
          onShowAll={onShowAll}
          onClearFilter={onClearFilter}
          showAll={canShowAll(searchInfo, filteredInfo)}
          query={query}
        />
        <TableComponent
          key={`table-${loading}-${practiceTests.length}`}
          dataSource={practiceTests}
          columns={columns}
          onChange={onTableChange}
          onColumnSearch={onColumnSearch}
          pagination={meta}
          loading={loading}
          query={query}
          hasSelectedRows={true}
          onSelectedRows={onSelectedRows}
          selectionType={selectionType}
        />
      </Modal>
    </Fragment>
  );
};
