import {
  Container,
  // GreetCard,
  // GreetTitle,
  GreetLogo,
  LoginSide,
  FormWrapper,
  // AlertInfo,
} from './sign-in.styles';
// import { ReactComponent as LoginIcon } from '../../assets/images/account-sign-in-amico.svg';
import Title from 'antd/lib/typography/Title';
import { Divider } from 'antd';
import { FormWrapper as Form } from '../../components/form/form.component';
import { InputForm, InputPasswordForm } from '../../components/form';
import { SaveButton } from '../../components/button-save/button-save.component';
import { useForm } from 'antd/lib/form/Form';
import {
  messageSuccess,
  // messageWarn,
  showMessageErrors,
} from '../../utils/message.utils';
import { signInAsync, SignIn } from '../../atom/auth/auth.apis';
import useRoute from '../../hooks/use-route.hook';
import { useSetRecoilState } from 'recoil';
import { authState } from '../../atom/auth/auth.atoms';
import { useAuth } from '../../hooks/use-auth';

export interface SignInPageProps {}

export const SignInPage: React.FC<SignInPageProps> = () => {
  const [form] = useForm();
  const { history } = useRoute();
  const setAuth = useSetRecoilState(authState);
  const { setProfile } = useAuth();

  const onFinish = (value: SignIn) => {
    signInAsync(value)
      .then(({ accessToken }) => {
        setProfile(null);
        messageSuccess('Login success!');
        localStorage.setItem('accessToken', accessToken);
        setAuth(true);
        history.push('/');
      })
      .catch((err) => {
        showMessageErrors(err);
      });
  };

  return (
    <Container>
      {/* <GreetCard>
        <GreetLogo>TESDOPI🌌CONTENT</GreetLogo>
        <GreetTitle>Hi, Welcome back</GreetTitle>
        <LoginIcon />
      </GreetCard> */}
      <LoginSide>
        <FormWrapper>
          <GreetLogo>TESDOPI CONTENT</GreetLogo>
          <Title level={4}>Sign In | Sign Up</Title>
          {/* <Title level={5} type="secondary">
            Enter your details below
          </Title> */}
         {/*  <AlertInfo>
            <Alert
              message="Use email : demo@tesdopi.com |
               Password: 12345678"
              type="info"
              showIcon
            />
          </AlertInfo> */}
          
          <Form name="loginForm" form={form} onFinish={onFinish}>
          <Divider/>
            <InputForm
              name="email"
              label=""
              required
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input email',
                },
              ]}
            />
            <InputPasswordForm
              name="password"
              label=""
              required
              rules={[
                { required: true, message: 'Please input password' },
                { min: 8 },
                { max: 16 },
              ]}
            />

            {/* <Row style={{ fontWeight: 600 }}>
              <Col span={12}>
                <CheckBox
                  name="remember"
                  label="Remember me"
                  required={false}
                  onChange={onChange}
                />
              </Col>
              <Col span={12} style={{ lineHeight: 2.5, textAlign: 'right' }}>
                <Tooltip title="Contact Super admin for help!">
                  <p
                    onClick={() => messageWarn(`Contact Super admin for help!`)}
                  >
                    Forgot password?
                  </p>
                </Tooltip>
              </Col>
            </Row> */}
            <SaveButton style={{ width: '100%' }}/>
            <Divider/>
          </Form>
        </FormWrapper>
      </LoginSide>
    </Container>
  );
};
