import _ from 'lodash';
import { useEffect, useState } from 'react';
// import { useAuth } from './use-auth-from-context-or-state-management.js';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getProfile } from '../atom/auth/auth.apis';
import { authLoadingState, authUserState } from '../atom/auth/auth.atoms';
import { authUserSelector } from '../atom/auth/auth.selectors';
import { showMessageErrors } from '../utils/message.utils';

export function useAuth({ hasProfile }: { hasProfile?: boolean } = {}) {
  const setProfile = useSetRecoilState(authUserState);
  const profile = useRecoilValue(authUserSelector);
  const [loading, setLoading] = useRecoilState(authLoadingState);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    if (!_.size(profile) && !loading && hasProfile && retry <= 2) {
      setLoading(true);
      getProfile()
        .then((res) => {
          setProfile(res);
          setLoading(false);
          setRetry(0);
        })
        .catch((err) => {
          showMessageErrors(err);
          setLoading(false);
          setRetry((s) => s + 1);
        });
    }
  }, [setProfile, profile, setLoading, loading, hasProfile, retry]);

  return {
    profile,
    setProfile,
    loading,
    setLoading,
  };
}
