import { Fragment, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Col, Row, Spin } from 'antd';
import { ButtonAddBrowse } from '../../../../components/button-add-browse/button-add-browse.component';
import { FormWrapper } from '../../../../components/form/form.component';
import { SortableItems } from '../../../../components/sortable-items/sortable-items.component';
import { TitleItem } from '../../../../components/title-item/title-item.component';
import _ from 'lodash';
import {
  FILTER_SECTION_TYPES,
  FORM_TYPE,
  SECTION_TYPE,
} from '../../../../constants/default-value.const';
import { FormInfoSection } from '../../../../components/form-info-section/form-info-section.component';
import useRoute from '../../../../hooks/use-route.hook';
import { FormInstance } from 'antd/lib/form/Form';
import { useSelectOption } from '../../../../hooks/use-select-option.hook';
import {
  messageError,
  messageSuccess,
  showDeleteConfirm,
  showMessageErrors,
} from '../../../../utils/message.utils';
import { LooseObject } from '../../../../types/global.types';
import produce from 'immer';
import { TextEditorForm } from '../../../../components/form';
import { useCourse } from './course.hook';
import { SectionModal } from './section-modal.component';
import { SectionFormModal } from './section-form-modal.component';
import { exclude, toNewRecords } from '../../../../utils/helper.utils';
import {
  createCourseAsync,
  deleteCourseSectionAsync,
  updateCourseAsync,
} from '../../../../atom/course/course.apis';
import { useApp } from '../../../../atom/app/app.hook';
import { useTranslation } from 'react-i18next';
import { getTopicsSelectAsync } from '../../../../atom/topic/topic.apis';
import { mapTopicsSelect } from '../../../../atom/topic/topic.selectors';
import { checkFormRedirect } from '../../../../utils/form.utils';
import { LibraryFormModal } from '../../../elements/library/library-form-modal.component';
import { CompetencyFormModal } from '../../../elements/competency/components/competency-form-modal.component';
import { assignIndexKey, mapQuestions } from '../../../../utils/response.utils';
import { useEnterEvent } from '../../../../hooks/enter-event.hook';

enum MODAL_TYPE {
  SECTION_BROWSE = 'SECTION_BROWSE',
  SECTION_CREATE = 'SECTION_CREATE',
  SECTION_EDIT = 'SECTION_EDIT',
  SECTION_RESOURCE_CREATE = 'SECTION_RESOURCE_CREATE',
  COMPETENCY_EDIT = 'COMPETENCY_EDIT',
  LIBRARY_EDIT = 'LIBRARY_EDIT',
}

type Section = any;

export const CourseForm = ({
  form,
  isBack,
}: {
  form: FormInstance;
  isBack: boolean;
}) => {
  const [sections, setSections] = useState<Section[]>([]);
  const [sectionType, setSectionType] = useState('');
  const [modalType, setModalType] = useState('');
  const [libraryType, setLibraryType] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const [forceRender, setForceRender] = useState(false);
  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, topics, colors } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
    hasColor: true,
  });
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  // const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const { t } = useTranslation();
  const { loading, course } = useCourse({ id, forceRender });
  useEnterEvent({ form, enter: !modalType });

  useEffect(() => {
    return () => {
      setAppFilter({});
    };
  }, [setAppFilter]);

  useEffect(() => {
    if (_.size(course)) {
      const levelId = _.get(course, 'level.id');
      const subjectId = _.get(course, 'subject.id');
      form.setFieldsValue({
        ...course,
        levelId,
        subjectId,
        topicId: _.get(course, 'topic.id'),
        color: _.get(course, 'color') || _.get(course, 'colorCode.code'),
      });

      if (subjectId && levelId) {
        document.getElementById('course-request')?.click();
      }

      setSections(
        toNewRecords(
          _.get(course, 'sections', []),
          formType === FORM_TYPE.DUPLICATE
        )
      );
    }
  }, [course, form, formType]);

  const forceRequest = () => {
    const levelId = _.get(course, 'level.id');
    const subjectId = _.get(course, 'subject.id');
    const topicId = _.get(course, 'topic.id');

    getTopicsSelectAsync({
      s_subjectId: subjectId,
      s_levelId: levelId,
    }).then((res) => {
      let app = produce<any, any>(appFilter, (draft: any) => {
        _.set(draft, `course.topics`, mapTopicsSelect(res));
        _.set(draft, `course.subjectId`, subjectId);
        _.set(draft, `course.levelId`, levelId);
        _.set(draft, `course.topicId`, topicId);
        return draft;
      });
      setAppFilter({ ...app });
    });
  };

  const onCancel = () => {
    setModalType('');
    setDataSource({});
    setSectionType('');
    setLibraryType('');
    setQuestionType('');
  };

  const onOkBrowseSection = (values: any[]) => {
    const filterSections = produce<Section[], Section[]>(sections, (draft) => {
      const filterValues = values.filter(
        (item) =>
          !sections.map((a) => a.sourceAbleId).includes(item.sourceAbleId)
      );

      if (filterValues.length !== values.length)
        messageError('Some of section is duplicate');

      return [...draft, ...filterValues];
    });
    setSections(
      assignIndexKey({
        dataSources: filterSections,
        childKey: ['dataSources', 'libraries', 'libraries', 'children'],
      })
    );

    onCancel();
  };

  const onOkFormSection = (value: LooseObject) => {
    const { id, indexKey, filterKey } = dataSource;

    let modifySections = [];
    if (modalType === MODAL_TYPE.SECTION_CREATE) {
      modifySections = produce<Section[], Section[]>(sections, (draft) => {
        draft.push({
          ...value,
          isNewRecord: true,
          id: uuid(),
        });
        return draft;
      });
    } else if (modalType === MODAL_TYPE.SECTION_EDIT) {
      modifySections = produce<Section[], Section[]>(sections, (draft) => {
        let libraries: any = value.dataSources.map((item: any) => {
          const libraries = item.libraries.filter((item2: any) => {
            return item2.libraryId === item.libraryId;
          });

          return { ...item, libraries };
        });

        if (filterKey === SECTION_TYPE.RESOURCE)
          draft[indexKey[0]] = {
            ...dataSource,
            dataSources: value.dataSources,
            code: value.code,
            title: value.title,
          };
        else if (filterKey === SECTION_TYPE.PRACTICE_TEST) {
          draft[indexKey[0]] = {
            ...dataSource,
            dataSources: value.dataSources,
            code: value.code,
            title: value.title,
          };
        } else if (filterKey === SECTION_TYPE.COMPETENCY_TEST) {
          if (indexKey.length === 1) {
            draft[indexKey[0]] = {
              ...dataSource,
              dataSources: libraries,
              code: value.code,
              title: value.title,
            };
          } else if (indexKey.length === 2) {
            draft[indexKey[0]].dataSources[indexKey[1]] = {
              ...dataSource,
              libraries,
              code: value.code,
              title: value.title,
            };
          }
        } else if (filterKey === SECTION_TYPE.LEARN) {
          draft[indexKey[0]] = {
            ...dataSource,
            dataSources: value.dataSources,
            code: value.code,
            title: value.title,
          };
        }

        draft[indexKey[0]].dataSources = assignIndexKey({
          dataSources: draft[indexKey[0]].dataSources,
          childKey: ['libraries', 'libraries', 'children'],
          filterKey: filterKey,
        });

        return draft;
      });
    } else if (modalType === MODAL_TYPE.SECTION_BROWSE) {
      modifySections = produce<Section[], Section[]>(sections, (draft) => {
        const foundIndex = draft.findIndex((item) => item.id === id);
        draft[foundIndex] = { ...value, id } as Section;
        return draft;
      });
    }

    if (modifySections.length)
      setSections(
        assignIndexKey({
          dataSources: modifySections,
          childKey: ['dataSources', 'libraries', 'libraries', 'children'],
        })
      );
    onCancel();
  };

  const onOkFormCompetency = (value: LooseObject) => {
    const { filterKey, indexKey, libraryId } = dataSource;

    let libraries: any = mapQuestions({
      dataSources: _.get(value, `competencySources.questions`, []),
      key: 'competencySourceLibraries',
      childKey: ['libraries', 'children'],
      filterKey: filterKey,
    });

    libraries = libraries.filter((item: any) => item.libraryId === libraryId);
    const modifySections = produce<Section[], Section[]>(sections, (draft) => {
      if (indexKey.length === 2) {
        draft[indexKey[0]].dataSources[indexKey[1]].title = value.title;
        draft[indexKey[0]].dataSources[indexKey[1]].code = value.code;
        draft[indexKey[0]].dataSources[indexKey[1]].libraries = libraries;
      } else if (indexKey.length === 3) {
        draft[indexKey[0]].dataSources[indexKey[1]].libraries[
          indexKey[2]
        ].title = value.title;
        draft[indexKey[0]].dataSources[indexKey[1]].libraries[
          indexKey[2]
        ].code = value.code;
        draft[indexKey[0]].dataSources[indexKey[1]].libraries[
          indexKey[2]
        ].libraries = libraries;
      }
      return draft;
    });

    setSections(
      assignIndexKey({
        dataSources: modifySections,
        childKey: ['dataSources', 'libraries', 'libraries', 'children'],
      })
    );
    onCancel();
  };

  const onOkFormLibrary = (value: LooseObject) => {
    const { id, indexKey, filterKey } = dataSource;

    const modifySections = produce<any[], any[]>(sections, (draft) => {
      if (indexKey.length === 2)
        draft[indexKey[0]].dataSources[indexKey[1]] = {
          ...value,
          libraries: value.libraries ? value.libraries : value.children,
          id,
        };
      if (indexKey.length === 3)
        draft[indexKey[0]].dataSources[indexKey[1]].libraries[indexKey[2]] = {
          ...value,
          id,
        };
      if (indexKey.length === 4)
        draft[indexKey[0]].dataSources[indexKey[1]].libraries[
          indexKey[2]
        ].libraries[indexKey[3]] = {
          ...value,
          libraries: value.libraries ? value.libraries : value.children,
          id,
        };

      if (indexKey.length === 5)
        draft[indexKey[0]].dataSources[indexKey[1]].libraries[
          indexKey[2]
        ].libraries[indexKey[3]].children[indexKey[4]] = {
          ...value,
          id,
        };

      draft[indexKey[0]].dataSources = assignIndexKey({
        dataSources: draft[indexKey[0]].dataSources,
        childKey: ['libraries', 'libraries', 'children'],
        filterKey: filterKey,
      });

      return draft;
    });
    // } else if (filterKey === SECTION_TYPE.PRACTICE_TEST) {

    // }
    setSections(
      assignIndexKey({
        dataSources: modifySections,
        childKey: ['dataSources', 'libraries', 'libraries', 'children'],
      })
    );
    onCancel();
  };

  const onFinish = (value: any) => {
    // if (!sections.length) {
    //   messageWarn("Sections is required");
    //   return;
    // }

    const courseSections = sections.map((item: any, i: number) => {
      const {
        id,
        sectionId,
        title,
        sourceAbleType,
        sourceAbleId,
        isNewRecord,
      } = item;

      const type = [
        SECTION_TYPE.PRACTICE_TEST,
        SECTION_TYPE.COMPETENCY_TEST,
      ].includes(sourceAbleType)
        ? SECTION_TYPE.TEST
        : sourceAbleType;

      if (isNewRecord)
        return {
          title,
          rank: i + 1,
          type,
          courseSectionSources: [
            {
              sourceAbleId,
              sourceAbleType,
              rank: 1,
            },
          ],
        };

      return {
        id,
        title,
        rank: i,
        type,
        courseSectionSources: [
          {
            id: sectionId,
            sourceAbleId,
            sourceAbleType,
            rank: 1,
          },
        ],
      };
    });

    const data: any = {
      code: value.code,
      color: value.color,
      comingSoon: value.comingSoon ? true : false,
      description: value.description,
      levelId: value.levelId,
      premium: value.premium ? true : false,
      status: value.status ? true : false,
      subjectId: value.subjectId,
      subtitle: value.subtitle,
      title: value.title,
      topicId: value.topicId,
      courseSections,
    };

    let request =
      formType === FORM_TYPE.EDIT
        ? updateCourseAsync(id, data)
        : createCourseAsync(data);

    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEndSection = (items: Section[]) => {
    setSections(items);
  };

  const onEditSection = (value: Section) => {
    setDataSource(exclude(value, ['isNewRecord']));
    setSectionType(value.sourceAbleType);
    setModalType(MODAL_TYPE.SECTION_EDIT);
  };

  const onEditSectionChild = (value: Section) => {
    setDataSource(exclude(value, ['isNewRecord']));
    if (value.competencyId) {
      setModalType(MODAL_TYPE.COMPETENCY_EDIT);
    } else if (value.sourceAbleType) {
      setModalType(MODAL_TYPE.SECTION_EDIT);
      setSectionType(value.sourceAbleType);
    } else {
      setQuestionType(value.questionType);
      setLibraryType(value.type);
      setModalType(MODAL_TYPE.LIBRARY_EDIT);
    }
  };

  const onDuplicateSection = (item: Section) => {
    setSectionType(item.sourceAbleType);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.SECTION_CREATE);
  };

  const onDeleteSection = ({ id, isNewRecord }: Section) => {
    showDeleteConfirm(() => {
      const filterSections = produce<Section[], Section[]>(
        sections,
        (draft) => {
          return draft.filter((item) => item.id !== id);
        }
      );
      if (isNewRecord) setSections(filterSections);
      else {
        deleteCourseSectionAsync(id)
          .then((res) => {
            setSections(filterSections);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onCreateSection = (type: string) => {
    setDataSource({});
    setSectionType(type);
    setModalType(MODAL_TYPE.SECTION_CREATE);
  };

  const onBrowseSection = (type: string) => {
    setSectionType(type);
    setModalType(MODAL_TYPE.SECTION_BROWSE);
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div id="course-request" onClick={forceRequest} />
        <SectionModal
          visible={modalType === MODAL_TYPE.SECTION_BROWSE}
          onOk={onOkBrowseSection}
          onCancel={onCancel}
          type={sectionType}
          defaultFilter={_.get(appFilter, 'course', {})}
        />
        <SectionFormModal
          visible={
            modalType === MODAL_TYPE.SECTION_CREATE ||
            modalType === MODAL_TYPE.SECTION_EDIT
          }
          key={`csf-${
            modalType === MODAL_TYPE.SECTION_CREATE ||
            modalType === MODAL_TYPE.SECTION_EDIT
          }`}
          onOk={onOkFormSection}
          onCancel={onCancel}
          type={sectionType}
          dataSource={dataSource}
          defaultFilter={_.get(appFilter, 'course', {})}
        />
        <CompetencyFormModal
          visible={modalType === MODAL_TYPE.COMPETENCY_EDIT}
          onOk={onOkFormCompetency}
          onCancel={onCancel}
          dataSource={dataSource}
          // defaultFilter={_.get(appFilter, "competencyTest", {})}
        />
        <LibraryFormModal
          visible={modalType === MODAL_TYPE.LIBRARY_EDIT}
          key={`clf-${modalType === MODAL_TYPE.LIBRARY_EDIT}`}
          onOk={onOkFormLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          dataSource={dataSource}
          // defaultFilter={_.get(appFilter, "course")}
        />
        <FormWrapper form={form} name="formForm" onFinish={onFinish}>
          <Row gutter={[16, 16]} /* style={{width:1000 , margin: "auto" , border:2}} */>
            <Col sm={24} md={24} lg={8}>
              <FormInfoSection
                levels={levels}
                topics={topics}
                subjects={subjects}
                colors={colors}
                hasSubtitle={true}
                form={form}
                dataSource={course}
                hasStatusOption={true}
                filterKey="course"
              />
            </Col>
            <Col sm={24} md={24} lg={16}>
              <TitleItem title={t('Description')} />
              <TextEditorForm
                name="description"
                form={form}
                value={_.get(course, 'description', '')}
                rules={[{ required: false }]}
              />

              <TitleItem title={t('Section')} />
              <SortableItems
                dataSources={sections}
                onSortEnd={onSortEndSection}
                onEdit={onEditSection}
                onEditChild={onEditSectionChild}
                onDuplicate={onDuplicateSection}
                onDelete={onDeleteSection}
                childItemsKey={[
                  'dataSources',
                  'libraries',
                  'libraries',
                  'children',
                ]}
              />
              <ButtonAddBrowse
                titleCreate={t('Add Section')}
                titleBrowse={t('Link Section')}
                onCreates={onCreateSection}
                onBrowses={onBrowseSection}
                options={FILTER_SECTION_TYPES}
              />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </Fragment>
  );
};
