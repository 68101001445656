import React, { Fragment, useEffect, useState } from "react";
import { TableComponent } from "../../../../components/table/table.component";
import { Meta } from "../../../../types/global.types";
import {
  canShowAll,
  convertObjectArrayToString,
  removeUndefined,
  toFilterSelect,
  toSort,
  toString,
  toStringFilter,
} from "../../../../utils/helper.utils";
import { getCompetencyTestsAsync } from "../../../../atom/competency-test/competency-test.apis";
import { showMessageErrors } from "../../../../utils/message.utils";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  competencyTestLoadingState,
  competencyTestMetaState,
  competencyTestsState,
} from "../../../../atom/competency-test/competency-test.atoms";
import {
  competencyTestMetaSelector,
  competencyTestsSelector,
} from "../../../../atom/competency-test/competency-test.selectors";
import { CompetencyTest } from "../../../../atom/competency-test/competency-test.types";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import { Modal } from "antd";
import { useTopic } from "../../../../atom/topic/topic.hook";
import { SearchAndTitle } from "../../../../components/search-and-title/search-and-title.component";
import { useTranslation } from "react-i18next";
import { columnTableEllipsis } from "../../../../utils/table.utils";
import { ListFeatureOption } from "../../../../components/list-feature-option/list-feature-option.component";

const requestCompetencyTests = (
  query: any,
  setCompetencyTests: (items: CompetencyTest[]) => void,
  setMeta: (meta: Meta) => void,
  setLoading: (loading: boolean) => void
) => {
  const option = removeUndefined(query);
  setLoading(true);
  return getCompetencyTestsAsync({ ...option, status: true })
    .then(({ items, meta }) => {
      setCompetencyTests(items);
      setMeta(meta);
      setLoading(false);
    })
    .catch((err) => {
      showMessageErrors(err);
      setCompetencyTests([]);
      setMeta({});
      setLoading(false);
    });
};

interface CompetencyTestModalProps {
  visible: boolean;
  onOk: (value: any) => void;
  onCancel: () => void;
  selectionType?: any;
  defaultFilter?: any;
}

export const CompetencyTestModal: React.FC<CompetencyTestModalProps> = ({
  visible,
  onOk,
  onCancel,
  selectionType,
  defaultFilter,
}) => {
  const setCompetencyTests = useSetRecoilState(competencyTestsState);
  const setMeta = useSetRecoilState(competencyTestMetaState);
  const competencyTests = useRecoilValue(competencyTestsSelector);
  const meta = useRecoilValue(competencyTestMetaSelector);
  const [loading, setLoading] = useRecoilState(competencyTestLoadingState);
  const query: any = defaultFilter;
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [searchInfo, setSearchInfo] = useState<any>({});
  const [columns, setColumns] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const { setTopicLevelId, setTopicSubjectId } = useTopic();
  const { topics, levels, subjects } = useSelectOption({
    hasTopic: true,
    hasLevel: true,
    hasSubject: true,
  });
  const { t } = useTranslation();
  useEffect(() => {
    /*eslint-disable react-hooks/exhaustive-deps*/
    const option = removeUndefined(query);
    requestCompetencyTests(
      { ...option, query: searchQuery },
      setCompetencyTests,
      setMeta,
      setLoading
    );
    setSortedInfo({ ...toSort(query) });
    setFilteredInfo({
      subjectId: toStringFilter(query.subjectId),
      levelId: toStringFilter(query.levelId),
      topicId: toStringFilter(query.topicId),
    });
    setTopicLevelId(query.levelId);
    setTopicSubjectId(query.subjectId);
    return () => {
      setSearchInfo({});
    };
  }, [searchQuery]);

  useEffect(() => {
    setColumns([
      {
        title: t("Code"),
        //fixed: 'left',
        dataIndex: "code",
        className: "drag-visible",
        isSearchable: true,
        sorter: (a: CompetencyTest, b: CompetencyTest) =>
          a.code.length - b.code.length,
        sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: false,
      },
      {
        title: t("Title"),
        //fixed: 'left',
        dataIndex: "title",
        isSearchable: true,
        sorter: (a: CompetencyTest, b: CompetencyTest) =>
          a.title.length - b.title.length,
        sortOrder: sortedInfo.columnKey === "title" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
        editable: false,
      },
      {
        title: t("Competency"),
        dataIndex: "numberOfCompetency",
        isSearchable: true,
        sorter: (a: CompetencyTest, b: CompetencyTest) =>
          a.numberOfCompetency - b.numberOfCompetency,
        sortOrder:
          sortedInfo.columnKey === "numberOfCompetency" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: t("Subject"),
        dataIndex: "subjectId",
        filteredValue: filteredInfo.subjectId || null,
        filters: toFilterSelect(subjects),
        onFilter: () => true,
        ...columnTableEllipsis("subject"),
      },
      {
        title: t("Level"),
        dataIndex: "levelId",
        filteredValue: filteredInfo.levelId || null,
        filters: toFilterSelect(levels),
        onFilter: () => true,
        ...columnTableEllipsis("level"),
      },
      {
        title: t("Topic"),
        dataIndex: "topicId",
        filteredValue: filteredInfo.topicId || null,
        filters: toFilterSelect(topics),
        onFilter: () => true,
        ...columnTableEllipsis("topic"),
      },
    ]);
  }, [filteredInfo, sortedInfo, subjects, levels, topics]);

  const onTableChange = (data: any) => {
    const {
      pagination: { current, pageSize },
      sortField,
      sortOrder,
      subjectId,
      levelId,
      topicId,
    } = data;

    const option = {
      ...query,
      ...searchInfo,
      page: current,
      offset: pageSize,
      sortField,
      sortOrder,
      query: searchQuery,
      subjectId: toString(subjectId),
      levelId: toString(levelId),
      topicId: toString(topicId),
    };

    setTopicLevelId(toString(levelId));
    setTopicSubjectId(toString(subjectId));
    setSortedInfo({ ...toSort({ sortField, sortOrder }) });
    setFilteredInfo({ subjectId, levelId, topicId });
    requestCompetencyTests(option, setCompetencyTests, setMeta, setLoading);
  };

  const onColumnSearch = (column: string, value: string) => {
    const option = removeUndefined({
      ...query,
      searchField: column,
      searchValue: value,
      query: searchQuery,
    });
    setSearchInfo({ searchField: column, searchValue: value });
    requestCompetencyTests(option, setCompetencyTests, setMeta, setLoading);
  };

  const onSelectedRows = (selectedRows: CompetencyTest[]) => {
    setSelectedRows(selectedRows);
  };

  const _onOk = () => {
    if (selectedRows.length) {
      onOk(selectedRows);
    } else {
      onCancel();
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const onShowAll = () => {
    const option = {
      ...convertObjectArrayToString(filteredInfo),
      ...searchInfo,
      query: searchQuery,
      showAll: "yes",
    };
    requestCompetencyTests(option, setCompetencyTests, setMeta, setLoading);
  };

  const onClearFilter = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchInfo({});
    requestCompetencyTests({}, setCompetencyTests, setMeta, setLoading);
  };

  return (
    <Fragment>
      <Modal
        visible={visible}
        title={null}
        closable={false}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        onCancel={onCancel}
        maskClosable={false}
        width="80%"
        onOk={_onOk}
      >
        <SearchAndTitle
          title={t("Competency Tests")}
          handleSearch={handleSearch}
        />
        <ListFeatureOption
          onShowAll={onShowAll}
          onClearFilter={onClearFilter}
          showAll={canShowAll(searchInfo, filteredInfo)}
          query={query}
        />
        <TableComponent
          key={`table-${loading}-${competencyTests.length}`}
          dataSource={competencyTests}
          columns={columns}
          onChange={onTableChange}
          onColumnSearch={onColumnSearch}
          pagination={meta}
          loading={loading}
          query={query}
          hasSelectedRows={true}
          onSelectedRows={onSelectedRows}
          selectionType={selectionType}
        />
      </Modal>
    </Fragment>
  );
};
