import styled from "styled-components";
import { Layout } from "antd";

export const MainSidebarContainer = styled.div``;

export const MainSidebar = styled(Layout.Sider)`
  position: fixed;
  min-height: 100vh;
  z-index: 999;
  background-color: #fff;

  /* .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  } */

  .ant-layout-sider-children {
    height: 100vh;
    overflow: hidden auto;
  }
`;

export const Logo = styled.div`
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px;
  margin: 8px;
  border-radius: 8px;
  background: #34ace0;
  color: #ffffff;

  .ant-avatar {
    margin: 12px 20px 12px 0;
    padding: 2px;
    background-color: #fff;
  }
`;

export const Name = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

export const Role = styled.span`
  font-size: 10px;
`;

export const Version = styled.span`
  font-size: 10px;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  flex: 1 1 0%;
  overflow: hidden auto;
  font-weight: 500;

  .ant-menu {
    height: 100%;
    border-right: 0;

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: 12px !important;
    }
    .ant-menu-item-only-child {
      padding-left: 36px !important;
    }
  }
`;

export const MenuFooter = styled.div`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
`;

export const Container = styled.div`
  position: relative;
  width: 120px;
  @media screen and (max-width: 750px) {
    &.desktop {
      display: none !important;
    }
    width: 0px;
  }
`;
