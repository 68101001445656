import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { QUERY_TYPE } from "../../constants/default-value.const";
import { useAuth } from "../../hooks/use-auth";
import useRoute from "../../hooks/use-route.hook";
import { checkPermission, isAdmin } from "../../utils/helper.utils";

const { TabPane } = Tabs;
export const SourceDataTab = ({
  onChange,
  prefixPermission
}: {
  onChange: (key: string) => void;
  prefixPermission?: string;
}) => {
  const { t } = useTranslation();
  const { profile } = useAuth();
  const {
    query: { queryType }
  } = useRoute();

  return (
    <Tabs defaultActiveKey={queryType || QUERY_TYPE.SOURCE} onChange={onChange}>
      <TabPane tab={t("Data")} key={QUERY_TYPE.SOURCE} />
      {checkPermission(profile, prefixPermission).listTrash || isAdmin(profile) ? (
        <TabPane tab={t("Trash")} key={QUERY_TYPE.TRASH} />
      ) : null}
    </Tabs>
  );
};
