import { Layout } from "../../../components/layout/layout.component";
import { LevelComponent } from "./components/level.component";
import { useVisible } from "../../../hooks/use-visible.hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrefixPermission } from "../../../constants/permission.const";

export const LevelPage = () => {
  const { visible, setVisible } = useVisible();
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  return (
    <Layout
      title={t("Manage")}
      onCreate={() => setVisible(true)}
      onSearch={value => setSearchQuery(value)}
      prefixPermission={PrefixPermission.LEVEL}
    >
      <LevelComponent visible={visible} setVisible={setVisible} searchQuery={searchQuery} />
    </Layout>
  );
};
