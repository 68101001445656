import { axios, config } from "../../utils/axios.utils";
import { UserChangePassword } from "./auth.types"

export interface SignIn {
  email: string;
  password: string;
}

export const signInAsync = async (data: SignIn) => {
  const response = await axios.post("/auth/login", data);

  return response.data;
};

export const getProfile = () => {
  return axios.get("/auth/profile", config()).then(response => response.data.data);
};

export const updatePasswordAsync = (data: UserChangePassword) => {
  return axios.post(`/auth/change-password`, data, config()).then(response => response.data);
};