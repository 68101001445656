import { Modal, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { getCompetencyAsync } from "../../../../atom/competency/competency.apis";
import { FormModalHeader } from "../../../../components/form-modal-header/form-modal-header.component";
import { LooseObject } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";
import { CompetencyForm } from "./competency-form.component";

interface CompetencyFormModalProps {
  visible: boolean;
  onOk: (value: LooseObject[]) => void;
  onCancel: () => void;
  dataSource: LooseObject;
  defaultFilter?: any;
}

export const CompetencyFormModal: React.FC<CompetencyFormModalProps> = ({
  visible,
  onOk,
  onCancel,
  dataSource,
  defaultFilter
}) => {
  const [form] = useForm();
  const { appLoading } = useApp();

  const _onOk = (value: any) => {
    getCompetencyAsync(value.id)
      .then(res => {
        onOk(res);
      })
      .catch(err => {
        showMessageErrors(err);
      });
  };

  const renderForm = () => {
    const { competencyId, isNewRecord } = dataSource;

    const dataProps = {
      form,
      formId: competencyId,
      formDuplicate: isNewRecord,
      formModal: true,
      onOk: _onOk,
      // defaultFilter: competencyId ? null : defaultFilter,
      defaultFilter
    };

    return <CompetencyForm {...dataProps} />;
  };

  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      maskClosable={false}
      width="80%"
      footer={null}
    >
      <Spin spinning={appLoading}>
        <FormModalHeader
          title="Competency"
          handleCancel={onCancel}
          handleOk={() => form.submit()}
        />
        {renderForm()}
      </Spin>
    </Modal>
  );
};
