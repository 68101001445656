import { atom } from 'recoil';
import { LooseObject } from '../../types/global.types';

export const appErrorState = atom({
  key: 'appErrorState',
  default: '',
});

export const appLoadingState = atom({
  key: 'appLoadingState',
  default: false,
});

export const drawerMenuVisibleState = atom({
  key: 'drawerMenuVisibleState',
  default: false,
});

export const appForceRenderState = atom({
  key: 'appForceRenderState',
  default: 0,
});

export const appOwnContentState = atom({
  key: 'appOwnContentState',
  default: localStorage.getItem('ownContent') === 'true' ? true : false,
});

export const appAssignPrivacyState = atom<LooseObject>({
  key: 'appAssignPrivacyState',
  default: {},
});

// let example = {
//   question: {
//     subjectId: "x",
//     topicId: "x",
//     levelId: "x",
// topics:[]
//   },
//   resource: {
//     subjectId: "x",
//     topicId: "x",
//     levelId: "x",
// topics:[]
//   }
// };
export const appFilterState = atom<any>({
  key: 'appFilterState',
  default: {},
});

export const appFilterKeyState = atom({
  key: 'appFilterKeyState',
  default: '',
});
