import { Fragment, useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { ButtonAddBrowse } from "../../../../components/button-add-browse/button-add-browse.component";
import { FormWrapper } from "../../../../components/form/form.component";
import { SortableItems } from "../../../../components/sortable-items/sortable-items.component";
import { TitleItem } from "../../../../components/title-item/title-item.component";
import _ from "lodash";
import {
  ExamLibraryType,
  FILTER_QUESTION_TYPES,
  FORM_TYPE,
  LIBRARY_TYPE,
  QUESTION_TYPE,
} from "../../../../constants/default-value.const";
import { FormInfoSection } from "../../../../components/form-info-section/form-info-section.component";
import useRoute from "../../../../hooks/use-route.hook";
import { FormInstance } from "antd/lib/form/Form";
import { useSelectOption } from "../../../../hooks/use-select-option.hook";
import {
  messageError,
  messageSuccess,
  messageWarn,
  showDeleteConfirm,
  showMessageErrors,
} from "../../../../utils/message.utils";
import { LooseObject, Question } from "../../../../types/global.types";
import produce from "immer";
import { LibraryFormModal } from "../../../elements/library/library-form-modal.component";
import { LibraryModal } from "../../../elements/library/library-modal.component";
import { TextEditorForm } from "../../../../components/form";
import { useCompetitionTest } from "./competition-test.hook";
import {
  createCompetitionTestAsync,
  deleteCompetitionTestLibraryAsync,
  updateCompetitionTestAsync,
} from "../../../../atom/competition-test/competition-test.apis";
import {
  exclude,
  requestTopicDefault,
  setSelectFilter,
  toNewRecordQuestions,
} from "../../../../utils/helper.utils";
import { useApp } from "../../../../atom/app/app.hook";
import { useTranslation } from "react-i18next";
import { checkFormRedirect } from "../../../../utils/form.utils";
import { assignIndexKey } from "../../../../utils/response.utils";
import { useEnterEvent } from "../../../../hooks/enter-event.hook";

enum MODAL_TYPE {
  LIBRARY_QUESTION = "LIBRARY_QUESTION",
  LIBRARY_QUESTION_TEST = "LIBRARY_QUESTION_TEST",
  LIBRARY_QUESTION_SOLUTION = "LIBRARY_QUESTION_SOLUTION",
  LIBRARY_QUESTION_FORM = "LIBRARY_QUESTION_FORM",
  LIBRARY_QUESTION_FORM_EDIT = "LIBRARY_QUESTION_FORM_EDIT",
  LIBRARY_QUESTION_TEST_FORM = "LIBRARY_QUESTION_TEST_FORM",
  // LIBRARY_QUESTION_TEST_FORM_EDIT = 'LIBRARY_QUESTION_TEST_FORM_EDIT',
}

export const CompetitionTestForm = ({
  form,
  isBack,
}: {
  form: FormInstance;
  isBack: boolean;
}) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [libraryType, setLibraryType] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [modalType, setModalType] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [dataSource, setDataSource] = useState<LooseObject>({});
  const [selectionType, setSelectionType] = useState("");
  const [forceRender, setForceRender] = useState(false);
  const [textLibraryType, setTextLibraryType] = useState("");
  useEnterEvent({ form, enter: !modalType });
  const { t } = useTranslation();

  const {
    history,
    pathname,
    query: { formType },
    param: { id },
  } = useRoute();
  const { levels, subjects, colors, categories } = useSelectOption({
    hasLevel: true,
    hasSubject: true,
    hasTopic: true,
    hasColor: true,
    hasTestCategory: true,
  });
  const { setAppLoading, setAppFilter, appFilter } = useApp();
  const { loading, competitionTest } = useCompetitionTest({ id, forceRender });

  useEffect(() => {
    return () => {
      setAppFilter({});
    };
  }, [setAppFilter]);

  useEffect(() => {
    if (_.size(competitionTest)) {
      let scores = {};

      const questions = _.get(competitionTest, "questions", []);
      questions.forEach((item: any) => {
        const { id, score } = item;
        _.set(scores, `scores.${id}.parent`, score);
        _.get(item, "questions", []).forEach((child: any) => {
          _.set(
            scores,
            `scores.${id}.${child.id}.child`,
            _.get(child, "score", 0)
          );
        });
      });
      form.setFieldsValue({
        ...competitionTest,
        topicId: _.get(competitionTest, "topic.id"),
        subjectId: _.get(competitionTest, "subject.id"),
        levelId: _.get(competitionTest, "level.id"),
        color:
          _.get(competitionTest, "color") ||
          _.get(competitionTest, "colorCode.code"),
        categoryId: _.get(competitionTest, "category.id"),
        testLibraryTitle: _.get(competitionTest, "testLibrary.title"),
        solutionLibraryTitle: _.get(competitionTest, "solutionLibrary.title"),
        ...scores,
      });

      setTotalScore(_.get(competitionTest, "totalScore", 0));
      setQuestions(
        toNewRecordQuestions(questions, formType === FORM_TYPE.DUPLICATE, true)
      );
    }
    setSelectFilter({
      dataSource: competitionTest,
      defaultFilter: {},
      form,
      elementId: "competitionTestRequest",
    });
  }, [competitionTest, form, formType]);

  const onCancel = () => {
    setModalType("");
    setDataSource({});
    setLibraryType("");
    setQuestionType("");
    setSelectionType("");
    setTextLibraryType("");
  };

  const onOkBrowseLibrary = (values: Question[]) => {
    if (modalType === MODAL_TYPE.LIBRARY_QUESTION) {
      const filterValues = values
        .filter((item) => !questions.map((a) => a.libraryId).includes(item.id))
        .map((item) => {
          if (item.questionType === QUESTION_TYPE.GROUP) {
            return { ...item, questions: item.children };
          }

          return item;
        });
      if (filterValues.length !== values.length)
        messageError("Some of competitionTest is duplicate");
      setQuestions(
        assignIndexKey({
          dataSources: [...questions, ...filterValues],
          childKey: ["questions"],
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_TEST) {
      form.setFieldsValue({
        testLibraryId: _.get(values, `0.id`),
        testLibraryTitle: _.get(values, `0.title`),
      });
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_SOLUTION) {
      form.setFieldsValue({
        solutionLibraryId: _.get(values, `0.id`),
        solutionLibraryTitle: _.get(values, `0.title`),
      });
    }
    onCancel();
  };

  const onOkFormLibrary = (value: LooseObject) => {
    const { id, libraryId, indexKey } = dataSource;
    if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM) {
      if (value.questionType === QUESTION_TYPE.GROUP) {
        value.questions = value.children;
      }
      setQuestions(
        assignIndexKey({
          dataSources: [
            ...questions,
            { ...value, isNewRecord: true, libraryId: value.id } as Question,
          ],
          childKey: ["questions"],
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT) {
      const modifyQuestions = produce<any[], any[]>(questions, (draft) => {
        if (indexKey.length === 1)
          draft[indexKey[0]] = {
            ...value,
            questions: value.children,
            id,
            libraryId,
          } as any;
        if (indexKey.length > 1) {
          draft[indexKey[0]].questions[indexKey[1]] = {
            ...value,
            id,
            libraryId,
          };
        }
        return draft;
      });
      setQuestions(
        assignIndexKey({
          dataSources: modifyQuestions,
          childKey: ["questions"],
        })
      );
    } else if (modalType === MODAL_TYPE.LIBRARY_QUESTION_TEST_FORM) {
      if (textLibraryType === ExamLibraryType.TEST)
        form.setFieldsValue({
          testLibraryId: _.get(value, `id`),
          testLibraryTitle: _.get(value, `title`),
        });
      else if (textLibraryType === ExamLibraryType.SOLUTION)
        form.setFieldsValue({
          solutionLibraryId: _.get(value, `id`),
          solutionLibraryTitle: _.get(value, `title`),
        });
    }
    onCancel();
  };

  const onFinish = (value: any) => {
    // if (!questions.length) {
    //   messageWarn("Questions is required");
    //   return;
    // }

    const {
      categoryId,
      code,
      color,
      comingSoon,
      description,
      duration,
      levelId,
      premium,
      solutionLibraryId,
      status,
      subjectId,
      testLibraryId,
      title,
      subtitle,
      scores,
      totalScore,
    } = value;

    const requestData = {
      categoryId,
      code,
      color,
      comingSoon: comingSoon ? true : false,
      description,
      duration: Number(duration),
      levelId,
      premium: premium ? true : false,
      solutionLibraryId,
      status: status ? true : false,
      subjectId,
      testLibraryId,
      title,
      subtitle,
      totalScore: Number(totalScore),
    };

    let check = false;
    let total = 0;
    let checkSubScore = false;
    const competitionTestLibraries = produce<Question[], Question[]>(
      questions,
      (draft) => {
        return draft.map((item, i) => {
          const { id, libraryId, isNewRecord } = item;

          let subScore = 0;
          const childQuestions = _.get(item, "questions", []).map(
            (child: any) => {
              const score = _.get(
                scores,
                `${id}.${child.id}.child`,
                child.score
              );
              subScore = +(subScore + score).toFixed(2);
              return { id: child.id, score };
            }
          );

          if (childQuestions.length && !_.get(childQuestions, "0.score")) {
            check = true;
          }

          const score = _.get(scores, `${id}.parent`);

          if (score !== subScore && subScore !== 0) {
            checkSubScore = true;
          }

          total = +(total + score).toFixed(2);

          if (isNewRecord)
            return {
              libraryId,
              rank: i,
              score,
              children: childQuestions,
            };

          return {
            id,
            libraryId,
            rank: i,
            score,
            children: childQuestions,
          };
        });
      }
    );

    if (check) {
      messageWarn("Child score is required");
      return;
    }

    if (Number(totalScore) != total) {
      messageWarn("Total Score invalid");
      return;
    }

    if (checkSubScore) {
      messageWarn("Child score is not match with parent score");
      return;
    }

    const data: any = {
      ...requestData,
      competitionTestLibraries,
    };

    let request =
      formType === FORM_TYPE.EDIT
        ? updateCompetitionTestAsync(id, data)
        : createCompetitionTestAsync(data);

    setAppLoading(true);
    request
      .then((res) => {
        messageSuccess();
        let check = checkFormRedirect({
          history,
          pathname,
          id: res.data.id,
          formType,
          isBack,
        });
        if (check) setForceRender(!forceRender);
        setAppLoading(false);
      })
      .catch((err) => {
        showMessageErrors(err);
        setAppLoading(false);
      });
  };

  const onSortEndQuestion = (items: Question[]) => {
    setQuestions(items);
  };

  const onEditQuestion = (value: Question) => {
    setDataSource(exclude(value, ["isNewRecord"]));
    setLibraryType(value.type);
    setQuestionType(value.questionType);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT);
  };

  const onDuplicateQuestion = (item: Question) => {
    setQuestionType(item.questionType);
    setLibraryType(item.type);
    setDataSource({ ...item, isNewRecord: true });
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onDeleteQuestion = ({ id, isNewRecord }: Question) => {
    showDeleteConfirm(() => {
      const filterQuestions = produce<Question[], Question[]>(
        questions,
        (draft) => {
          return draft.filter((item) => item.id !== id);
        }
      );
      if (isNewRecord) setQuestions(filterQuestions);
      else {
        deleteCompetitionTestLibraryAsync(id)
          .then((res) => {
            setQuestions(filterQuestions);
          })
          .catch((err) => {
            showMessageErrors(err);
          });
      }
    });
  };

  const onCreateQuestion = (type: string) => {
    setQuestionType(type);
    setDataSource({});
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_FORM);
  };

  const onBrowseQuestion = (type: string) => {
    setLibraryType(LIBRARY_TYPE.QUESTION);
    setQuestionType(type);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION);
  };

  // const onBrowseTestLibrary = () => {
  //   setSelectionType('radio');
  //   setLibraryType(LIBRARY_TYPE.TEXT);
  //   setModalType(MODAL_TYPE.LIBRARY_QUESTION_TEST);
  // };

  // const onBrowseSolutionLibrary = () => {
  //   setSelectionType('radio');
  //   setLibraryType(LIBRARY_TYPE.TEXT);
  //   setModalType(MODAL_TYPE.LIBRARY_QUESTION_SOLUTION);
  // };

  const onChangeInput = (value: number) => {
    setTotalScore(value || 0);
  };

  const onBrowseLibrary = (value: string) => {
    setSelectionType("radio");
    setLibraryType(LIBRARY_TYPE.TEXT);
    setModalType(
      value === ExamLibraryType.TEST
        ? MODAL_TYPE.LIBRARY_QUESTION_TEST
        : MODAL_TYPE.LIBRARY_QUESTION_SOLUTION
    );
  };

  const onCreateLibrary = (value: string) => {
    setTextLibraryType(value);
    setLibraryType(LIBRARY_TYPE.TEXT);
    setDataSource({});
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_TEST_FORM);
  };

  const onEditLibrary = (value: string) => {
    setTextLibraryType(value);

    const solutionLibraryId = form.getFieldValue("solutionLibraryId");
    const testLibraryId = form.getFieldValue("testLibraryId");

    let libraryId = "";
    if (value === ExamLibraryType.SOLUTION) {
      libraryId = solutionLibraryId;
    } else if (value === ExamLibraryType.TEST) {
      libraryId = testLibraryId;
    }

    setDataSource({
      libraryId,
    });
    setLibraryType(LIBRARY_TYPE.TEXT);
    setModalType(MODAL_TYPE.LIBRARY_QUESTION_TEST_FORM);
  };

  const onDeleteLibrary = (value: string) => {
    if (value === ExamLibraryType.TEST) {
      form.setFieldsValue({
        testLibraryId: "",
        testLibraryTitle: "",
      });
    } else if (value === ExamLibraryType.SOLUTION) {
      form.setFieldsValue({
        solutionLibraryId: "",
        solutionLibraryTitle: "",
      });
    }
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div
          onClick={() =>
            requestTopicDefault({
              dataSource: competitionTest,
              defaultFilter: {},
              appFilter,
              setAppFilter,
              filterKey: "competitionTest",
            })
          }
          id="competitionTestRequest"
        />
        <LibraryModal
          visible={
            modalType === MODAL_TYPE.LIBRARY_QUESTION ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_TEST ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_SOLUTION
          }
          onOk={onOkBrowseLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          selectionType={selectionType}
          // defaultFilter={exclude(_.get(appFilter, "competitionTest", {}), ["topicId"])}
          defaultFilter={_.get(appFilter, "competitionTest", {})}
        />
        <LibraryFormModal
          visible={
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_TEST_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT
          }
          key={`${
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_TEST_FORM ||
            modalType === MODAL_TYPE.LIBRARY_QUESTION_FORM_EDIT
          }`}
          onOk={onOkFormLibrary}
          onCancel={onCancel}
          type={libraryType}
          questionType={questionType}
          dataSource={dataSource}
          isEditTest={true}
          defaultFilter={_.get(appFilter, "competitionTest")}
        />
        <FormWrapper form={form} name="competitionTestForm" onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={8} lg={8}>
              <FormInfoSection
                levels={levels}
                subjects={subjects}
                colors={colors}
                categories={categories}
                onChangeInput={onChangeInput}
                form={form}
                dataSource={competitionTest}
                hasStatusOption={true}
                hasTestOption={true}
                // onBrowseTestLibrary={onBrowseTestLibrary}
                // onBrowseSolutionLibrary={onBrowseSolutionLibrary}
                onBrowseLibrary={onBrowseLibrary}
                onCreateLibrary={onCreateLibrary}
                onEditLibrary={onEditLibrary}
                onDeleteLibrary={onDeleteLibrary}
                hasSubtitle={true}
                filterKey="competitionTest"
              />
            </Col>
            <Col sm={24} md={16} lg={16}>
              <TitleItem title={t("Description")} />
              <TextEditorForm
                name="description"
                // key={_.get(competitionTest, "description", "").length + 1}
                form={form}
                value={_.get(competitionTest, "description", "")}
                rules={[{ required: false }]}
              />

              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                  <TitleItem title={t("Question")} />
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  {t("Total score")}:&nbsp; <b>{totalScore}</b>
                </div>
              </div>
              <SortableItems
                dataSources={questions}
                onSortEnd={onSortEndQuestion}
                onSortEndChild={onSortEndQuestion}
                onEdit={onEditQuestion}
                onEditChild={onEditQuestion}
                onDuplicate={onDuplicateQuestion}
                onDelete={onDeleteQuestion}
                childItemsKey={["questions"]}
                hasScore={true}
              />
              <ButtonAddBrowse
                titleCreate={t("Add Question")}
                titleBrowse={t("Link Question")}
                onCreates={onCreateQuestion}
                onBrowses={onBrowseQuestion}
                options={FILTER_QUESTION_TYPES}
              />
            </Col>
          </Row>
        </FormWrapper>
      </Spin>
    </Fragment>
  );
};
