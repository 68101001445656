export const resources = {
  en: {
    translation: {
      "Add Element": "Add Element",
      "Add choice": "Add choice",
      "Add Question": "Add Question",
      "Add Competency": "Add Competency",
      "Add Section": "Add Section",
      "Add Test": "Add Test",
      Action: "Action",
      Activities: "Activities",
      Alias: "Alias",
      Answers: "Answers",
      Add: "Add",
      Code: "Code",
      ComingSoon: "ComingSoon",
      Color: "Color",
      Category: "Category",
      Competency: "Competency",
      Contents: "Contents",
      Competencies: "Competencies",
      Course: "Course",
      Courses: "Courses",
      Cancel: "Cancel",
      Colors: "Colors",
      "Clear filters": "Clear filters",
      "Confirm Password": "Confirm Password",
      "Competency Tests": "Competency Tests",
      "Competition Tests": "Competition Tests",
      "Content Categories": "Content Categorie",
      "Category Types": "Category Types",
      "Colors and Placeholders": "Colors and Placeholders",
      "Change password": "Change password",
      "Competency Test": "Competency Test",
      "Competition Test": "Competition Test",
      "Deleted At": "Deleted At",
      Duration: "Duration",
      Data: "Data",
      Description: "Description",
      "Exam Test": "Exam Test",
      "Exam Tests": "Exam Tests",
      "First Name": "First Name",
      "Form Activity": "Form Activity",
      "Edit Profile": "Edit Profile",
      Email: "Email",
      Elements: "Elements",
      Editor: "Playground",
      "Enable sort": "Enable sort",
      Grade: "Grade",
      Group: "Group",
      Home: "Home",
      Infos: "Infos",
      Icons: "Icons",
      Icon: "Icon",
      "Link Section": "Link Section",
      "Link Test": "Link Test",
      "Link Competency": "Link Competency",
      "Link Question": "Link Question",
      "Last Name": "Last Name",
      "Link Element": "Link Element",
      Levels: "Levels",
      Logout: "Logout",
      Library: "Library",
      Level: "Level",
      Learns: "Learns",
      Learn: "Learn",
      Manage: "Manage",
      Manages: "Manages",
      "Manage App Feature": "Manage App Feature",
      Name: "Name",
      "New Password": "New Password",
      "Old Password": "Old Password",
      Publish: "Publish",
      Premium: "Premium",
      Profile: "Profile",
      Preview: "Preview",
      Permission: "Permission",
      "Practice Tests": "Practice Tests",
      "Practice Test": "Practice Test",
      "Resource Types": "Resource Types",
      "Question Type": "Question Type",
      "Question Text": "Question Text",
      Questions: "Questions",
      Question: "Question",
      Resource: "Resource",
      Resources: "Resources",
      Review: "Review",
      Reviews: "Reviews",
      Role: "Role",
      Roles: "Roles",
      Rank: "Rank",
      Restore: "Restore",
      Sort: "Sort",
      Subtitle: "Subtitle",
      Subject: "Subject",
      Status: "Status",
      Solution: "Solution",
      Save: "Save",
      Search: "Search",
      Section: "Section",
      Subjects: "Subjects",
      "Show all": "Show all",
      Submit: "Submit",
      "Select subject": "Select subject",
      "Select level": "Select level",
      "Select topic": "Select topic",
      "Select Color": "Select Color",
      "Select Category": "Select Category",
      "Solution library": "Solution library",
      "Total Score": "Total Score",
      "Test library": "Test library",
      "Total score": "Total score",
      "Text Library": "Text Library",
      "Text Libraries": "Text Libraries",
      "Test Types": "Test Types",
      Trash: "Deleted",
      Title: "Title",
      Test: "Test",
      Topic: "Topic",
      Topics: "Topics",
      Users: "Users",
      "Updated At": "Updated At",
      "Users And Roles": "Users And Roles",
      Videos: "Videos",
      "Video ID": "Video ID"
    }
  },
  km: {
    translation: {
      "Add Section": "បន្ថែមផ្នែកមេរៀន",
      "Add Test": "បន្ថែមតេស្ត",
      "Add Question": "បន្ថែមសំណួរ",
      "Add Competency": "បន្ថែមចំណុចសមត្ថភាព",
      "Add Element": "បន្ថែមធាតុ",
      "Add choice": "បន្ថែមជម្រើស",
      Alias: "ឈ្មោះក្រៅ",
      Activities: "សកម្មភាព",
      Add: "បន្ថែម",
      Answers: "ចម្លើយ",
      Action: "សកម្មភាព",
      Courses: "វគ្គសិក្សា",
      Competency: "ចំណុចសមត្ថភាព",
      Course: "វគ្គសិក្សា",
      ComingSoon: "ឆាប់ៗនេះ",
      Code: "លេខកូដ",
      Color: "ពណ៌",
      Category: "ប្រភេទ",
      Contents: "មាតិកា",
      Competencies: "សមត្ថភាព",
      Colors: "​ពណ៌​",
      "Clear filters": "លុបតម្រង",
      Cancel: "បោះបង់",
      "Confirm Password": "ផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់ថ្មី",
      "Competency Test": "តេស្តសមត្ថភាព",
      "Competition Test": "តេស្តប្រកួតប្រជែង",
      "Change password": "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
      "Competency Tests": "តេស្តសមត្ថភាព",
      "Competition Tests": "តេស្តប្រកួតប្រជែង",
      "Content Categories": "​ប្រភេទមាតិកាចំបង",
      "Category Types": "​ប្រភេទមាតិកា",
      "Colors and Placeholders": "​ពណ៌​ នឹង ស្លាកសញ្ញា",
      "Deleted At": "បានលុប",
      Description: "ពិពណ៌នា",
      Duration: "រយៈពេល",
      Data: "ទិន្នន័យ",
      Email: "អុីម៉ែល",
      Elements: "ធាតុ",
      Editor: "រៀនវាយអត្ថបទ",
      "Enable sort": "ដាក់តម្រៀប",
      "Edit Profile": "កែសម្រួលប្រវត្តិរូប",
      "Exam Test": "តេស្តត្រៀមប្រឡង",
      "Exam Tests": "តេស្តត្រៀមប្រឡង",
      "First Name": "នាមខ្លួន",
      "Form Activity": "Form Activity",
      Group: "ក្រុម",
      Grade: "ថ្នាក់",
      Home: "ទំព័រដើម",
      Infos: "ព័ត៌មាន",
      Icons: "​រូបតំណាង",
      Icon: "​រូបតំណាង",
      "Link Element": "ភ្ជាប់ធាតុ",
      "Link Section": "ភ្ជាប់ផ្នែកមេរៀន",
      "Link Test": "ភ្ជាប់តេស្ត",
      "Link Competency": "ភ្ជាប់ចំណុចសមត្ថភាព",
      "Link Question": "ភ្ជាប់សំណួរ",
      "Last Name": "នាមត្រកូល",
      Levels: "កម្រិត",
      Logout: "ចាកចេញ",
      Learns: "រៀន",
      Learn: "រៀន",
      Level: "កម្រិត",
      Library: "បណ្ណាល័យ",
      Manages: "គ្រប់គ្រង",
      Manage: "គ្រប់គ្រង",
      "Manage App Feature": "គ្រប់គ្រងមេរៀនក្នុងអេប",
      Name: "ឈ្មោះ",
      "New Password": "ពាក្យសម្ងាត់ថ្មី",
      "Old Password": "ពាក្យសម្ងាត់",
      Publish: "ផ្សាយ",
      Profile: "រូបតំណាង",
      Preview: "មើលទុកមុន",
      Premium: "ព្រីមៀម",
      Permission: "ការអនុញ្ញាត",
      "Practice Test": "តេស្តអនុវត្ត",
      "Practice Tests": "តេស្តអនុវត្ត",
      "Question Type": "ប្រភេទសំណួរ",
      "Question Text": "សំណួរ",
      Question: "សំណួរ",
      Questions: "សំណួរ",
      "Resource Types": "ប្រភេទធនធាន",
      Rank: "លំដាប់",
      Resource: "ធនធាន",
      Review: "មេរៀនរំលឹក",
      Role: "តួនាទី",
      Reviews: "មេរៀនរំលឹក",
      Resources: "ធនធាន",
      Roles: "តួនាទី ",
      Restore: "ទាញមកប្រើឡើងវិញ",
      Solution: "ដំណោះស្រាយ",
      Status: "ស្ថានភាព",
      Sort: "តំរៀប",
      Subtitle: "ចំណងជើងរង",
      Subject: "មុខវិជ្ជា",
      Save: "​រក្សាទុក",
      "Save & Back": "​រក្សាទុក & ត្រឡប់ក្រោយ",
      Search: "ស្វែងរក",
      Section: "ផ្នែកមេរៀន",
      Subjects: "មុខវិជ្ជា",
      "Show all": "បង្ហាញទាំងអស់",
      Submit: "បញ្ជូន",
      "Select subject": "​រើសមុខវិជ្ជា",
      "Select level": "​រើសកម្រិត",
      "Select topic": "​រើសមេរៀន",
      "Select Color": "​រើសពណ៌",
      "Solution library": "ចម្លើយតេស្ត",
      "Select Category": "​រើសប្រភេទ",
      "Total Score": "ពិន្ទុសរុប",
      "Test library": "ប្រធានតេស្ត",
      "Total score": "ពិន្ទុសរុប",
      "Text Library": "អត្ថបទ",
      "Text Libraries": "អត្ថបទ",
      "Test Types": "ប្រភេទតេស្ត",
      Trash: "ទិន្នន័យបានលុប",
      Title: "ចំណងជើង",
      Test: "តេស្ត",
      Topics: "មេរៀន",
      Topic: "មេរៀន",
      "Updated At": "បានកែប្រែ",
      "Users And Roles": "អ្នកប្រើប្រាស់ នឹង​​​ តួនាទី",
      Users: "អ្នកប្រើប្រាស់",
      Videos: "វីដេអូ",
      "Video ID": "វីដេអូ ID"
    }
  }
};
