import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getReviewAsync } from "../../../../atom/review/review.apis";
import { reviewLoadingState, reviewState } from "../../../../atom/review/review.atoms";
import { showMessageErrors } from "../../../../utils/message.utils";
import { reviewSelector } from "../../../../atom/review/review.selectors";
import { UseHookProps } from "../../../../types/global.types";

export const useReview = ({ id, forceRender }: UseHookProps) => {
  const review = useRecoilValue(reviewSelector);
  const setReview = useSetRecoilState(reviewState);
  const [loading, setLoading] = useRecoilState(reviewLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getReviewAsync(id)
        .then(res => {
          setReview(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }
    return () => {
      setReview({});
    };
  }, [id, setReview, setLoading, forceRender]);

  return {
    review,
    loading,
    setLoading
  };
};
