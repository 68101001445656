import styled from "styled-components";

export const WrapCourse = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  background-color: #e5e5f7;
  opacity: 0.8;
  background: linear-gradient(to top, #93a5cf, #e4efe9);
  .swiper {
    width: 500px;
    height: 100%;
    border-radius: 20px;
    border: 1px solid #ddd;
    background: #ecf7fd;
    height: calc(100vh - 100px);
    padding: 20px 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const Section = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 12px;
  height: 100px;
  padding: 0px 10px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #e6f4ff;
  border: 1px solid #91caff;
  cursor: pointer;

  &.competencyTest {
    background: #fff2f0 /* #9c27b0 */;
    float: center;
    border: 1px solid #ffccc7;
    // color: #fff;
  }

  &.resource {
    background: #fffbe6 /* #cd94d7 */;
    border: 1px solid #ffe58f;
  }
`;
