import { Modal } from 'antd';
import { FC } from 'react';
import ReactPlayer from 'react-player';

interface PropTypes {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  videoId?: string;
}

export const YoutubeVideoPreview: FC<PropTypes> = ({
  onOk,
  onCancel,
  visible,
  videoId,
}) => {
  console.log(2222, videoId);

  return (
    <div>
      <Modal
        title='Youtube Preview'
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <ReactPlayer
          width='100%'
          height='270px'
          url={`https://www.youtube.com/watch?v=${videoId}`}
          controls={false}
        />
      </Modal>
    </div>
  );
};
