import React, { Fragment, ReactElement } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {
  Button,
  Collapse,
  Dropdown,
  Form,
  Menu,
  Radio,
  Space,
  Tag,
} from "antd";
import {
  CopyTwoTone,
  DeleteTwoTone,
  DownOutlined,
  EditTwoTone,
  SettingOutlined,
} from "@ant-design/icons";
import { SortableListChildProps } from "./sortable-items.types";
import { LooseObject } from "../../types/global.types";
import { InputNumberForm } from "../form";
import {
  FILTER_QUESTION_TYPES,
  LIBRARY_TYPE,
  QUESTION_TYPE,
} from "../../constants/default-value.const";
import { LibraryViewDetail } from "./library-view-detail.component";
import produce from "immer";
import _ from "lodash";
import { WrapInputNumber, WrapSortable } from "./sortable-items.styles";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export const SortableItem = SortableElement(
  ({ value }: { value: ReactElement }) => <div tabIndex={0}>{value}</div>
);

export const panelTitle = (item: any) => {
  if (item.code && item.title && item.sourceAbleType)
    // return `[${item.code}] ${item.title} [${item.sourceAbleType}]`;
    return (
      <Space>
        <Tag color="cyan" style={{ minWidth: 150, textAlign: "center" }}>
          {item.code}
        </Tag>
        <Tag color="geekblue" style={{ minWidth: 150, textAlign: "center" }}>
          {item.sourceAbleType}
        </Tag>
        <b>{item.title}</b>
      </Space>
    );

  if (item.code && item.title)
    return (
      <Space>
        <Tag color="cyan" style={{ minWidth: 200, textAlign: "center" }}>
          {item.code}
        </Tag>{" "}
        <b>{item.title}</b>
      </Space>
    );
  return <b>{item.title}</b>;
};

const BrowseLibrary: React.FC<any> = ({ onBrowse, item, parentIndex }) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() =>
          onBrowse({ ...item, parentIndex, type: LIBRARY_TYPE.TEXT })
        }
      >
        {t("Text Libraries")}
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() =>
          onBrowse({ ...item, parentIndex, type: LIBRARY_TYPE.VIDEO })
        }
      >
        {t("Videos")}
      </Menu.Item>
      {FILTER_QUESTION_TYPES.map((question) => (
        <Menu.Item
          key={question.value}
          onClick={() =>
            onBrowse({
              ...item,
              parentIndex,
              type: LIBRARY_TYPE.QUESTION,
              questionType: question.value || "",
            })
          }
        >
          {t(question.text)}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Button>
        {t("Link Element")} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

const CreateLibrary: React.FC<any> = ({ onBrowse, item, parentIndex }) => {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() =>
          onBrowse({ ...item, parentIndex, type: LIBRARY_TYPE.TEXT })
        }
      >
        {t("Text Library")}
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() =>
          onBrowse({ ...item, parentIndex, type: LIBRARY_TYPE.VIDEO })
        }
      >
        {t("Videos")}
      </Menu.Item>
      {FILTER_QUESTION_TYPES.map((question) => (
        <Menu.Item
          key={question.value}
          onClick={() =>
            onBrowse({
              ...item,
              parentIndex,
              type: LIBRARY_TYPE.QUESTION,
              questionType: question.value || "",
            })
          }
        >
          {t(question.text)}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <Button>
        {t("Add Element")} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

const GenerateExtra: React.FC<any> = ({
  onEdit,
  onDelete,
  onDuplicate,
  onPublish,
  onComingSoon,
  onPremium,
  value,
}) => {
  const { t } = useTranslation();
  const menu = (
    <Menu>
      {onPublish && (
        <Menu.Item onClick={() => onPublish(value)} key={1}>
          {t("Publish")}
        </Menu.Item>
      )}
      {onComingSoon && (
        <Menu.Item onClick={() => onComingSoon(value)} key={2}>
          {t("ComingSoon")}
        </Menu.Item>
      )}
      {onPremium && (
        <Menu.Item onClick={() => onPremium(value)} key={3}>
          {t("Premium")}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Space>
      {onEdit && (
        <EditTwoTone
          onClick={(event) => {
            event.stopPropagation();
            onEdit(value);
          }}
        />
      )}
      {onDuplicate && (
        <CopyTwoTone
          onClick={(event) => {
            event.stopPropagation();
            onDuplicate(value);
          }}
        />
      )}
      {onDelete && (
        <DeleteTwoTone
          onClick={(event) => {
            event.stopPropagation();
            onDelete(value);
          }}
        />
      )}
      {onPublish && (
        <Dropdown overlay={menu}>
          <SettingOutlined
            onClick={(event) => {
              event.stopPropagation();
              onDelete(value);
            }}
          />
        </Dropdown>
      )}
    </Space>
  );
};

const CollapseChildComponent: React.FC<any> = ({
  value,
  onDelete,
  onEdit,
  onDuplicate,
  parentIndex,
  hasRadioGroup,
  hasScore,
  parentValue,
  childItemsKey,
}) => {
  const { id, selectedId } = value;

  let key = _.get(childItemsKey, "1", "children");
  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          {hasRadioGroup && selectedId && (
            <Radio value={selectedId} style={{ top: 12 }} />
          )}
        </div>
        <div style={{ flexGrow: 1 }}>
          <Collapse
            key={id}
            expandIconPosition={"right"}
            style={{ marginBottom: "10px" }}
          >
            <Panel
              header={panelTitle(value)}
              key={id}
              extra={
                <GenerateExtra
                  onDelete={onDelete}
                  onEdit={value.code ? onEdit : null}
                  onDuplicate={onDuplicate}
                  value={{ ...value, parentIndex }}
                />
              }
            >
              {_.get(value, key, []).length > 0 &&
                value[key].map((item: any) => (
                  <Collapse
                    key={item.id}
                    expandIconPosition={"right"}
                    accordion={true}
                    defaultActiveKey={
                      hasScore || hasRadioGroup ? [item.id] : []
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <Panel
                      header={panelTitle(item)}
                      key={item.id}
                      extra={
                        <GenerateExtra
                          // onDelete={onDelete}
                          onEdit={item.code ? onEdit : null}
                          // onDuplicate={onDuplicate}
                          value={{ ...item }}
                        />
                      }
                    >
                      <LibraryViewDetail item={item} />
                      {item[_.get(childItemsKey, "2")] &&
                        item[_.get(childItemsKey, "2")].map((item2: any) => {
                          return (
                            <Collapse
                              key={item2.id}
                              expandIconPosition={"right"}
                              style={{ marginBottom: "10px" }}
                            >
                              <Panel
                                header={panelTitle(item2)}
                                key={item2.id}
                                extra={
                                  <GenerateExtra
                                    // onDelete={onDelete}
                                    onEdit={onEdit}
                                    // onDuplicate={onDuplicate}
                                    value={{ ...item2 }}
                                  />
                                }
                              >
                                {item2[_.get(childItemsKey, "3")] &&
                                  item2[_.get(childItemsKey, "3")].map(
                                    (item3: any) => {
                                      return (
                                        <Collapse
                                          key={item3.id}
                                          expandIconPosition={"right"}
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <Panel
                                            header={panelTitle(item3)}
                                            key={item3.id}
                                            extra={
                                              <GenerateExtra
                                                // onDelete={onDelete}
                                                onEdit={onEdit}
                                                // onDuplicate={onDuplicate}
                                                value={{ ...item3 }}
                                              />
                                            }
                                          >
                                            <LibraryViewDetail item={item3} />
                                          </Panel>
                                        </Collapse>
                                      );
                                    }
                                  )}
                                <LibraryViewDetail item={item2} />
                              </Panel>
                            </Collapse>
                          );
                        })}
                    </Panel>
                  </Collapse>
                ))}
              {value.questionType !== QUESTION_TYPE.GROUP && (
                <LibraryViewDetail item={value} />
              )}
            </Panel>
          </Collapse>
        </div>
        {hasScore && (
          <WrapInputNumber>
            <InputNumberForm
              name={["scores", parentValue.id, id, "child"]}
              rules={[{ required: true, message: "score is required" }]}
            />
          </WrapInputNumber>
        )}
      </div>
    </>
  );
};

const CollapseComponent = ({
  value,
  onSortEndChild,
  index,
  childItemsKey,
  onDelete,
  onEdit,
  onDuplicate,
  onPublish,
  onComingSoon,
  onPremium,
  onDeleteChild,
  onEditChild,
  onDuplicateChild,
  onBrowseLibrary,
  hasRadioGroup,
  onCreateLibrary,
  hasScore,
}: any) => {
  const { id } = value;

  let key = childItemsKey;
  if (_.isArray(key)) {
    key = _.get(key, "0", "");
  }

  const activities = value[key];
  const _onSortEndChild = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (onSortEndChild)
      onSortEndChild(index, arrayMove(activities, oldIndex, newIndex));
  };

  return (
    <Collapse
      expandIconPosition={"right"}
      defaultActiveKey={hasScore || hasRadioGroup ? [id] : []}
      style={{ marginBottom: "10px" }}
    >
      <Panel
        header={panelTitle(value)}
        key={id}
        extra={
          <GenerateExtra
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
            onPublish={onPublish}
            onComingSoon={onComingSoon}
            onPremium={onPremium}
            value={value}
          />
        }
      >
        {activities && (
          <>
            <SortableListChild
              items={activities}
              onSortEnd={_onSortEndChild}
              onDelete={onDeleteChild}
              onEdit={onEditChild}
              onDuplicate={onDuplicateChild}
              pressDelay={500}
              parentIndex={index}
              hasRadioGroup={hasRadioGroup}
              hasScore={hasScore}
              parentValue={value}
              childItemsKey={childItemsKey}
              onSortEndChild={onSortEndChild}
            />
          </>
        )}
        <Space>
          {onCreateLibrary && (
            <CreateLibrary
              onBrowse={onCreateLibrary}
              item={value}
              parentIndex={index}
            />
          )}
          {onBrowseLibrary && (
            <BrowseLibrary
              onBrowse={onBrowseLibrary}
              item={value}
              parentIndex={index}
            />
          )}
        </Space>
        {!onBrowseLibrary && !_.get(value, "children", []).length && (
          <LibraryViewDetail item={value} />
        )}
      </Panel>
    </Collapse>
  );
};

const SortableListChild = SortableContainer(
  ({
    items,
    onDelete,
    onEdit,
    onDuplicate,
    parentIndex,
    hasRadioGroup,
    hasScore,
    parentValue,
    childItemsKey,
    onSortEndChild,
  }: SortableListChildProps) => {
    return (
      <ul>
        <Form.Item
          // label={["options", parentValue.id, "selected"].toString()}
          name={["options", parentValue.id, "selected"]}
          rules={[
            {
              required: hasRadioGroup ? true : false,
              message: "option is required",
            },
          ]}
        >
          <Radio.Group style={{ width: "100%" }}>
            {items.map((value: LooseObject, index: number) => (
              <SortableItem
                key={`item-${value.id}`}
                index={index}
                disabled={onSortEndChild ? false : true}
                value={
                  <CollapseChildComponent
                    value={value}
                    parentIndex={parentIndex}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onDuplicate={onDuplicate}
                    hasRadioGroup={hasRadioGroup}
                    hasScore={hasScore}
                    parentValue={parentValue}
                    childItemsKey={childItemsKey}
                  />
                }
              />
            ))}
          </Radio.Group>
        </Form.Item>
      </ul>
    );
  }
);

const SortableListParent = SortableContainer(
  ({
    items,
    onSortEndChild,
    childItemsKey,
    onDelete,
    onEdit,
    onDuplicate,
    onPublish,
    onComingSoon,
    onPremium,
    onDeleteChild,
    onEditChild,
    onDuplicateChild,
    onBrowseLibrary,
    onCreateLibrary,
    hasScore,
    hasRadioGroup,
  }: any) => {
    return (
      <ul>
        {items &&
          items.map((value: LooseObject, index: number) => (
            <>
              <SortableItem
                key={`item-${index}`}
                index={index}
                value={
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ flexGrow: 1 }}>
                        <CollapseComponent
                          value={value}
                          onSortEndChild={onSortEndChild}
                          index={index}
                          childItemsKey={childItemsKey}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onDuplicate={onDuplicate}
                          onPublish={onPublish}
                          onComingSoon={onComingSoon}
                          onPremium={onPremium}
                          onDeleteChild={onDeleteChild}
                          onEditChild={onEditChild}
                          onDuplicateChild={onDuplicateChild}
                          onBrowseLibrary={onBrowseLibrary}
                          onCreateLibrary={onCreateLibrary}
                          hasRadioGroup={hasRadioGroup}
                          hasScore={hasScore}
                        />
                      </div>
                      {hasScore && (
                        <WrapInputNumber>
                          <InputNumberForm
                            name={["scores", value.id, "parent"]}
                            rules={[
                              { required: true, message: "score is required" },
                            ]}
                          />
                        </WrapInputNumber>
                      )}
                    </div>
                  </>
                }
              ></SortableItem>
            </>
          ))}
      </ul>
    );
  }
);

export const SortableItems: React.FC<any> = ({
  dataSources,
  onEdit,
  onDuplicate,
  onDelete,
  onPublish,
  onComingSoon,
  onPremium,
  onEditChild,
  onDuplicateChild,
  onDeleteChild,
  onSortEnd,
  onSortEndChild,
  childItemsKey,
  onBrowseLibrary,
  onCreateLibrary,
  hasScore,
  hasRadioGroup,
}) => {
  const _onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    onSortEnd(arrayMove(dataSources, oldIndex, newIndex));
  };

  const _onSortEndChild = (index: number, items: LooseObject) => {
    const sortsData = produce(dataSources, (draft: any) => {
      draft[index][childItemsKey[0]] = items;
      return draft;
    });
    if (onSortEndChild) {
      onSortEndChild(sortsData);
    }
  };

  return (
    <Fragment>
      <WrapSortable>
        <SortableListParent
          items={dataSources}
          onSortEnd={_onSortEnd}
          onSortEndChild={_onSortEndChild}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          onPublish={onPublish}
          onComingSoon={onComingSoon}
          onPremium={onPremium}
          onEditChild={onEditChild}
          onDuplicateChild={onDuplicateChild}
          onDeleteChild={onDeleteChild}
          childItemsKey={childItemsKey}
          onBrowseLibrary={onBrowseLibrary}
          onCreateLibrary={onCreateLibrary}
          pressDelay={500}
          hasScore={hasScore}
          hasRadioGroup={hasRadioGroup}
        />
      </WrapSortable>
    </Fragment>
  );
};
