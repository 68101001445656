import { axios, config } from "../../utils/axios.utils";
import { generateQuery } from "../../utils/request.utils";
import { RestoreType, SortData } from "../../types/global.types";
import { CompetencyFormType } from "./competency.types";

export const getCompetenciesAsync = (option: {}) => {
  const url = generateQuery("/competencies", option, ["levelId", "subjectId", "topicId", "status"]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getCompetencyAsync = (id: string) => {
  return axios.get(`/competencies/${id}`, config()).then(response => response.data.data);
};

export const getCompetenciesSelectAsync = (option: {}) => {
  const url = generateQuery("/competencies/for/select-option", option);
  return axios.get(url, config()).then(response => response.data.data);
};

export const getCompetencySelectCompetenciesAsync = (data: RestoreType) => {
  return axios
    .post("/competencies/list/selected-competencies", data, config())
    .then(response => response.data.data);
};

export const getCompetenciesTrashAsync = (option: {}) => {
  const url = generateQuery("/competencies/list/trash", option, [
    "levelId",
    "subjectId",
    "topicId",
    "status"
  ]);
  return axios.get(url, config()).then(response => response.data.data);
};

export const restoreCompetenciesAsync = (data: RestoreType) => {
  return axios.patch("/competencies/restore", data, config()).then(response => response.data);
};

export const createCompetencyAsync = (data: CompetencyFormType) => {
  return axios.post("/competencies", data, config()).then(response => response.data);
};

export const updateCompetencyAsync = (id: string, data: CompetencyFormType) => {
  return axios.put(`/competencies/${id}`, data, config()).then(response => response.data);
};

export const deleteCompetencyAsync = (id: string) => {
  return axios.delete(`/competencies/${id}`, config()).then(response => response.data);
};

export const deleteCompetencySourceAsync = (id: string) => {
  return axios.delete(`/competency-sources/${id}`, config()).then(response => response.data);
};

export const deleteCompetencySourcesAsync = (ids: string[]) => {
  return axios
    .post(`/competency-sources/deletes`, { ids }, config())
    .then(response => response.data);
};

export const deleteCompetencySourceLibraryAsync = (id: string) => {
  return axios
    .delete(`/competency-source-libraries/${id}`, config())
    .then(response => response.data);
};

export const sortCompetenciesAsync = (data: SortData) => {
  if (Math.random() > 0.5) return Promise.resolve(1);
  return Promise.reject("something went wrong");
  //   return axios.put(`/competencies/${id}/status`, data, config()).then(response => response.data);
};

export const getCompetencyReferenceUsingAsync = (id: string) => {
  return axios
    .get(`/competencies/reference-using/${id}`, config())
    .then(response => response.data.data);
};

export const competenciesSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/competencies/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};
