import { Layout } from "../../../components/layout/layout.component";
import { Row, Col, Input, Avatar, Popover } from "antd"
import { Container, FlexWrap, ElementFlex, FlexTitle } from "./usage.style"
import { FileSearchOutlined } from "@ant-design/icons"
import { FlexElement } from "../../elements/question/components/question.style";
import { Fragment } from "react";
import { Link } from "react-router-dom"
import { RoutePaths } from "../../../constants/route.const"
import { PieChart } from "../charts/pie-chart.component";
import { CalendarData } from "../charts/calendar.component";

 
export const UsagePage = () => {

  return (
    <Layout
      title="Data Usage"
    >
      
    {/* Data Part I */}

      <Row gutter={1} style={{marginTop: '5px'}}>

        {/* Calendar */}
          <Col span={8}>
            <Container>
              <FlexWrap>
                <ElementFlex style={{width: '100%'}}>
                  <ElementFlex style={{float: 'left'}}>
                    <FlexTitle>User Active</FlexTitle>
                  </ElementFlex>
                  <ElementFlex style={{float: 'right'}}>
                    <FlexWrap>
                      {/* <ElementFlex>
                        <Input placeholder="Search" type='text' size='small' />
                      </ElementFlex> */}
                      <ElementFlex style={{marginLeft: '8px'}}>
                        <Link to={RoutePaths.USAGE_BY_WEEK}>
                          <FileSearchOutlined/>
                        </Link>
                      </ElementFlex>
                    </FlexWrap>
                  </ElementFlex>
                </ElementFlex>
              </FlexWrap>
            </Container>
            <Container style={{height: '100%'}}>
              <CalendarData/>
            </Container>
          </Col>

        {/* Pie I */}
          <Col span={8}>
            <Container>
              <FlexWrap>
                <ElementFlex style={{width: '100%'}}>
                  <ElementFlex style={{float: 'left'}}>
                    <FlexTitle>Session by Level</FlexTitle>
                  </ElementFlex>
                  <ElementFlex style={{float: 'right'}}>
                    <FlexWrap>
                      <ElementFlex>
                        <Input placeholder="Search" type='text' size='small' />
                      </ElementFlex>
                      <ElementFlex style={{marginLeft: '8px'}}>
                      <Link to={RoutePaths.USAGE_BY_OVERALL}>
                        <FileSearchOutlined/>
                        </Link>
                      </ElementFlex>
                    </FlexWrap>
                  </ElementFlex>
                </ElementFlex>
              </FlexWrap>
            </Container>
            <Container className="cus-pie" style={{height: 'auto'}}>
              <PieChart/>
            </Container>
          </Col>

        {/* Pie II */}
          <Col span={8}>
            <Container>
              <FlexWrap>
                <ElementFlex style={{width: '100%'}}>
                  <ElementFlex style={{float: 'left'}}>
                    <FlexTitle>Session by Subject</FlexTitle>
                  </ElementFlex>
                  <ElementFlex style={{float: 'right'}}>
                    <FlexWrap>
                      <ElementFlex>
                        <Input placeholder="Search" type='text' size='small' />
                      </ElementFlex>
                      <ElementFlex style={{marginLeft: '8px'}}>
                      <Link to={RoutePaths.USAGE_BY_OVERALL}>
                        <FileSearchOutlined/>
                        </Link>
                      </ElementFlex>
                    </FlexWrap>
                  </ElementFlex>
                </ElementFlex>
              </FlexWrap>
            </Container>
            <Container className="cus-pie" style={{height: 'auto'}}>
              <PieChart/>
              
            </Container>
          </Col>
      </Row>
      
    {/* Data Part II */}
      
      <Row gutter={1} style={{marginTop: '5px'}}>
        <Col span={12}>
          <Container>
            <FlexWrap>
              <ElementFlex style={{width: '100%'}}>
                <ElementFlex style={{float: 'left'}}>
                  <FlexTitle>Most Learn Course</FlexTitle>
                </ElementFlex>
                <ElementFlex style={{float: 'right'}}>
                  <FlexWrap>
                    <ElementFlex>
                      <Input placeholder="Search" type='text' size='small' />
                    </ElementFlex>
                    <ElementFlex style={{marginLeft: '8px'}}>
                      <Link to={RoutePaths.USAGE_BY_SUBJECT}>
                        <FileSearchOutlined/>
                      </Link>
                    </ElementFlex>
                  </FlexWrap>
                </ElementFlex>
              </ElementFlex>
            </FlexWrap>
          </Container>
          <Container style={{height: '350px', overflow: 'scroll'}}>
            {plusData.map((item,index)=> {
              const rankIndex = index + 1;
              return (
                <FlexWrap key={index}>
                  <FlexElement style={{width: '10%'}}>
                    <FlexTitle>{rankIndex}.</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '30%'}}>
                    <FlexTitle>Moni Udom</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '25%'}}>
                    <FlexTitle>Grad 12</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '30%'}}>
                    <FlexTitle>2000 Coin</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '5%'}}>
                    <FileSearchOutlined style={{marginLeft: '3px'}}/>
                  </FlexElement>
                </FlexWrap>
              )
            })}
          </Container>
        </Col>
        <Col span={12}>
        <Container>
            <FlexWrap>
              <ElementFlex style={{width: '100%'}}>
                <ElementFlex style={{float: 'left'}}>
                  <FlexTitle>Most Practice Test</FlexTitle>
                </ElementFlex>
                <ElementFlex style={{float: 'right'}}>
                  <FlexWrap>
                    <ElementFlex>
                      <Input placeholder="Search" type='text' size='small' />
                    </ElementFlex>
                    <ElementFlex style={{marginLeft: '8px'}}>                      
                      <Link to={RoutePaths.USAGE_BY_USER}>
                        <FileSearchOutlined/>
                      </Link>
                    </ElementFlex>
                  </FlexWrap>
                </ElementFlex>
              </ElementFlex>
            </FlexWrap>
          </Container>
          <Container style={{height: '350px', overflow: 'scroll'}}>
            {plusData.map((item,index)=> {
              const rankIndex = index + 1;
              return (
                <FlexWrap key={index}>
                  <FlexElement style={{width: '10%'}}>
                    <FlexTitle>{rankIndex}.</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '30%'}}>
                    <FlexTitle>Moni Udom</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '25%'}}>
                    <FlexTitle>Grad 12</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '30%'}}>
                    <FlexTitle>2000 Coin</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '5%'}}>
                    <FileSearchOutlined style={{marginLeft: '3px'}}/>
                  </FlexElement>
                </FlexWrap>
              )
            })}
          </Container>
        </Col>
      </Row>

      
      {/* Data Part III */}

      <Row style={{marginTop: '5px'}}>
        <Col span={24}>
          <Container>
            <FlexWrap>
              <ElementFlex style={{width: '100%'}}>
                <ElementFlex style={{float: 'left'}}>
                  <FlexTitle>Total Usage By Grade</FlexTitle>
                </ElementFlex>
                <ElementFlex style={{float: 'right'}}>
                  <FlexWrap>
                    <ElementFlex>
                      <Input placeholder="Search" type='text' size='small' />
                    </ElementFlex>
                    <ElementFlex style={{marginLeft: '8px'}}>
                      <Link to={RoutePaths.USAGE_BY_DAILY}>
                        <FileSearchOutlined/>
                      </Link>
                    </ElementFlex>
                  </FlexWrap>
                </ElementFlex>
              </ElementFlex>
            </FlexWrap>
          </Container>
          <Container style={{ height: '250px'}}>
            <Row gutter={[8, 8]}>
              {data.map((item,index)=> {
                return(
                  <Fragment key={index}>
                    <Col span={2}>
                      <Avatar shape="square" style={{ backgroundColor: item.color, color: 'gray' }}>
                        {item.type}
                      </Avatar>
                    </Col>
                    <Col span={22}>
                      <Avatar shape="square" style={{ backgroundColor: item.color, width: '100%', color: 'gray' }}>
                        {item.value} Session
                      </Avatar>
                    </Col>
                </Fragment>)
              })}
            </Row>
          </Container>
        </Col>
        
      </Row>
    </Layout>
  )
}

const dataWidget = [{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8}]
const plusData = [...dataWidget, ...dataWidget, ...dataWidget, ...dataWidget, ...dataWidget];
const dataLabel = [{label:"Mon"},{label:"Tue"},{label:"Wed"},{label:"Thu"},{label:"Fri"},{label:"Sat"},{label:"Sun"},{label:"Sun"}];

const data = [
  {
    type: 'A',
    value: 27,
    color: '#a1dad073'
  },
  {
    type: 'B',
    value: 25,
    color: '#5b8ff982'
  },
  {
    type: 'C',
    value: 18,
    color: '#5d70928f'
  },
  {
    type: 'D',
    value: 15,
    color: '#f6bd1782'
  },
  {
    type: 'E',
    value: 10,
    color: '#6f5ef987'
  },
  {
    type: 'F',
    value: 5,
    color: '#6dc8ec'
  },
];

const config = {
  appendPadding: 10,
  data: data,
  angleField: 'value',
  colorField: 'type',
  radius: 1,
  innerRadius: 0.6,
  label: {
    type: 'inner',
    offset: '-50%',
    content: '{value}',
    style: {
      textAlign: 'center',
      fontSize: 14,
    },
  },
  interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
  statistic: {
    title: false,
    content: {
      style: {
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      content: '22000\nSession',
    },
  },
};