import { Form, Tooltip } from 'antd';
import { PropsType } from './input.type';
import { StyleInput } from './input.styles';

export const InputForm: React.FC<PropsType> = ({
  label,
  required,
  tooltip,
  placeholder,
  disabled,
  name,
  rules,
  fieldKey,
  value,
  className,
  addonBefore,
  addonAfter,
  hidden,
  onChange,
}) => {
  return (
    <>
      <Tooltip placement='topLeft' title={placeholder}>
        <Form.Item
          hidden={hidden}
          name={name}
          label={label}
          rules={rules}
          required={required}
          tooltip={tooltip}
          fieldKey={fieldKey}
          className={className}
        >
          <StyleInput
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            value={value}
            allowClear={true}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
          />
        </Form.Item>
      </Tooltip>
    </>
  );
};
