import { Input } from 'antd';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { SearchOutline } from 'react-ionicons';
import { useTranslation } from 'react-i18next';
import useRoute from '../../hooks/use-route.hook';
import { generateRequest } from '../../utils/request.utils';

interface SearchInputProps {
  handleSearch: (value: string) => void;
  isModal?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  handleSearch,
  isModal = false,
}) => {
  /*eslint-disable react-hooks/exhaustive-deps*/
  const { query, history, pathname } = useRoute();
  const { t } = useTranslation();

  const serachQuery = query.query;

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue(serachQuery);
  }, [serachQuery]);

  const debounceFunc = useCallback(
    _.debounce((value) => {
      handleSearch(value);
      if (!isModal) {
        if (!value) {
          history.push(
            generateRequest(pathname, {
              ...query,
              query: '',
            })
          );
        } else {
          history.push(
            generateRequest(pathname, {
              ...query,
              query: value,
            })
          );
        }
      }
    }, 1000),
    []
  );

  const handleChange = (e: any) => {
    debounceFunc(e.target.value);
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <Input
        placeholder={t('Search')}
        onChange={handleChange}
        value={searchValue}
        suffix={
          <SearchOutline
            width="17.2px"
            height="17.2px"
            style={{ color: 'rgba(0,0,0,.45)', lineHeight: 1 }}
          />
        }
      />
    </div>
  );
};
