import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import { examTestsSyncToFirestoreAsync } from "../../../atom/exam-test/exam-test.apis";
import useRoute from "../../../hooks/use-route.hook";
import {
  messageSuccess,
  showMessageErrors,
  showSyncConfirm,
} from "../../../utils/message.utils";
import { toSwiper } from "../../../utils/response.utils";
import { useExamTest } from "../../contents/exam-test/components/exam-test.hook";
import { SwiperComponent } from "../components/swiper/swiper.component";

export const FlowExamTestPage = () => {
  const {
    param: { id },
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { examTest } = useExamTest({ id, forceRender });
  const handleSyncTofirestore = (id: string) => {
    showSyncConfirm(() => {
      examTestsSyncToFirestoreAsync([id])
        .then(() => {
          messageSuccess();
        })
        .catch((err) => showMessageErrors(err.message));
    });
  };
  return (
    <SwiperComponent
      title={examTest.title}
      data={examTest}
      //onSync={() => handleSyncTofirestore(id)}
      dataSources={toSwiper(examTest.questions || [], [
        "questions",
        "questions",
      ])}
    />
  );
};
