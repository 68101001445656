import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface PropsType {
  title?: string;
  handleClick?: (e: any) => void;
  style?: Object;
  type?: "submit" | "button" | "reset" | undefined;
}

export const SaveButton: React.FC<PropsType> = ({
  handleClick,
  title = "Save",
  type = "submit",
  style
}) => {
  const { t } = useTranslation();
  return (
    <Button htmlType={type} type="primary" onClick={handleClick} style={style}>
      {t(title)}
    </Button>
  );
};
