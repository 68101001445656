import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import useRoute from "../../../hooks/use-route.hook";
import { useCourse } from "../../contents/course/components/course.hook";
import { FlowCourse } from "./flow-course.component";

export const FlowCoursePage = () => {
  const {
    param: { id }
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { course } = useCourse({ id, forceRender });

  return (
    <div>
      <FlowCourse dataSources={course.sections || []} data={course} />
    </div>
  );
};
