import { Col, Row } from "antd";
import { useRecoilValue } from "recoil";
import { appForceRenderState } from "../../../atom/app/app.atoms";
import { SECTION_TYPE } from "../../../constants/default-value.const";
import useRoute from "../../../hooks/use-route.hook";
import { generateRequest } from "../../../utils/request.utils";
import { toSwiper } from "../../../utils/response.utils";
import { useLearn } from "../../contents/learn/components/learn.hook";
import { SwiperComponent } from "../components/swiper/swiper.component";
import { TitlePreview } from "../components/swiper/swiper.styles";
import { Section, WrapLearn } from "./flow-learn.styles";

export const FlowLearnPage = () => {
  const {
    param: { id },
    query,
    history,
    pathname
  } = useRoute();
  const forceRender = useRecoilValue(appForceRenderState);
  const { learn } = useLearn({ id, forceRender });
  const { flowType } = query;

  const handleSection = (data: any) => {
    history.push(generateRequest(pathname, data));
  };

  if (flowType) {
    if (flowType === SECTION_TYPE.TEST)
      return (
        <SwiperComponent
          title={learn?.tests[0]?.title}
          data={learn?.tests[0]}
          dataSources={toSwiper(learn?.tests[0]?.libraries || [], ["libraries", "children"])}
        />
      );

    return (
      <SwiperComponent
        title={learn.title}
        // data={learn}
        dataSources={toSwiper(learn.activities || [], ["activities", "questions"])}
      />
    );
  }

  return (
    <WrapLearn>
      <TitlePreview>{learn.title}</TitlePreview>
      <div className="swiper">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Section
              onClick={() =>
                handleSection({
                  flowType: SECTION_TYPE.LEARN
                })
              }
            >
              Learn
            </Section>
          </Col>
          <Col span={12}>
            <Section
              onClick={() =>
                handleSection({
                  flowType: SECTION_TYPE.TEST
                })
              }
            >
              Test
            </Section>
          </Col>
        </Row>
      </div>
    </WrapLearn>
  );
};
