import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getExamTestAsync } from "../../../../atom/exam-test/exam-test.apis";
import { examTestLoadingState, examTestState } from "../../../../atom/exam-test/exam-test.atoms";
import { examTestSelector } from "../../../../atom/exam-test/exam-test.selectors";
import { UseHookProps } from "../../../../types/global.types";
import { showMessageErrors } from "../../../../utils/message.utils";

export const useExamTest = ({ id, forceRender }: UseHookProps) => {
  const setExamTest = useSetRecoilState(examTestState);
  const examTest = useRecoilValue(examTestSelector);
  const [loading, setLoading] = useRecoilState(examTestLoadingState);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getExamTestAsync(id)
        .then(res => {
          setExamTest(res);
          setLoading(false);
        })
        .catch(err => {
          showMessageErrors(err);
          setLoading(false);
        });
    }
    return () => {
      setExamTest({});
    };
  }, [id, setExamTest, setLoading, forceRender]);

  return {
    examTest,
    loading,
    setLoading
  };
};
