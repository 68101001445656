import { Form } from "antd";
import { PropsType } from "./input-password.type";
import { StyleInputPassword } from "./input-password.styles";
import { EyeOffSharp, EyeSharp } from "react-ionicons";

export const InputPasswordForm: React.FC<PropsType> = ({
  name,
  label,
  required,
  tooltip,
  placeholder,
  disabled,
  rules,
  onChange
}) => {
  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={rules}
        required={required}
        tooltip={tooltip}
        labelCol={{ span: 24 }}
      >
        <StyleInputPassword
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          iconRender={visible =>
            visible ? (
              <EyeSharp color={"#00000"} title={"Show password"} height="20px" width="20px" />
            ) : (
              <EyeOffSharp color={"#00000"} title={"Hide password"} height="20px" width="20px" />
            )
          }
        />
      </Form.Item>
    </>
  );
};
