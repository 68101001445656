// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Library } from "./library.types";

export const librariesState = atom<Library[]>({
  key: "librariesState",
  default: []
});

export const libraryState = atom<Library | {}>({
  key: "libraryState",
  default: {}
});

export const questionGroupState = atom<Library | {}>({
  key: "questionGroupState",
  default: {}
});

export const libraryMetaState = atom<Meta>({
  key: "libraryMetaState",
  default: {}
});

export const libraryLoadingState = atom<boolean>({
  key: "libraryLoadingState",
  default: false
});

export interface VideoOption {
  title: string;
  url: string;
}
export const videoOptionsState = atom<VideoOption[]>({
  key: "videosOptionState",
  default: []
});
