import _ from "lodash";
import { LooseObject } from "../types/global.types";

export const assignIndexKey = ({
  dataSources,
  childKey = ["children"],
  filterKey = ""
}: {
  dataSources: any[];
  childKey?: string[];
  filterKey?: string;
}) => {
  if (childKey.length === 1)
    return dataSources.map((dataSource: any, i: number) => {
      return {
        indexKey: [i],
        ...dataSource,
        filterKey: filterKey ? filterKey : dataSource.filterKey,
        [childKey[0]]: !dataSource[childKey[0]]
          ? []
          : dataSource[childKey[0]].map((child: any, j: number) => {
              return {
                ...child,
                // libraryId: child.libraryId ? child.libraryId : child.id,
                indexKey: [i, j],
                filterKey: filterKey ? filterKey : child.filterKey
              };
            })
      };
    });

  if (childKey.length === 2)
    return dataSources.map((dataSource: any, i: number) => {
      return {
        indexKey: [i],
        ...dataSource,
        filterKey: filterKey ? filterKey : dataSource.filterKey,
        [childKey[0]]: !dataSource[childKey[0]]
          ? []
          : dataSource[childKey[0]].map((child: any, j: number) => {
              return {
                ...child,
                indexKey: [i, j],
                filterKey: filterKey ? filterKey : child.filterKey,

                // libraryId: child.libraryId ? child.libraryId : child.id,
                [childKey[1]]: !child[childKey[1]]
                  ? []
                  : child[childKey[1]].map((child2: any, k: number) => {
                      return {
                        ...child2,
                        filterKey: filterKey ? filterKey : child2.filterKey,
                        // libraryId: child2.libraryId ? child2.libraryId : child2.id,
                        indexKey: [i, j, k]
                      };
                    })
              };
            })
      };
    });

  if (childKey.length === 3)
    return dataSources.map((dataSource: any, i: number) => {
      return {
        indexKey: [i],
        ...dataSource,
        filterKey,

        [childKey[0]]: !dataSource[childKey[1]]
          ? []
          : dataSource[childKey[0]].map((child: any, j: number) => {
              return {
                ...child,
                indexKey: [i, j],
                filterKey: filterKey ? filterKey : child.filterKey,
                // libraryId: child.libraryId ? child.libraryId : child.id,
                [childKey[1]]: !child[childKey[1]]
                  ? []
                  : child[childKey[1]].map((child2: any, k: number) => {
                      return {
                        ...child2,
                        // libraryId: child2.libraryId ? child2.libraryId : child2.id,
                        filterKey: filterKey ? filterKey : child2.filterKey,
                        indexKey: [i, j, k],
                        [childKey[2]]: !child2[childKey[2]]
                          ? []
                          : child2[childKey[2]].map((child3: any, l: number) => {
                              return {
                                ...child3,
                                filterKey: filterKey ? filterKey : child3.filterKey,
                                // libraryId: child2.libraryId ? child2.libraryId : child2.id,
                                indexKey: [i, j, k, l]
                              };
                            })
                      };
                    })
              };
            })
      };
    });

  if (childKey.length === 4)
    return dataSources.map((dataSource: any, i: number) => {
      return {
        indexKey: [i],
        ...dataSource,
        filterKey: filterKey ? filterKey : dataSource.filterKey,
        [childKey[0]]: dataSource[childKey[0]].map((child: any, j: number) => {
          return {
            ...child,
            indexKey: [i, j],
            filterKey: filterKey ? filterKey : child.filterKey,
            // libraryId: child.libraryId ? child.libraryId : child.id,
            [childKey[1]]: !child[childKey[1]]
              ? []
              : child[childKey[1]].map((child2: any, k: number) => {
                  return {
                    ...child2,
                    filterKey: filterKey ? filterKey : child2.filterKey,
                    // libraryId: child2.libraryId ? child2.libraryId : child2.id,
                    indexKey: [i, j, k],
                    [childKey[2]]: !child2[childKey[2]]
                      ? []
                      : child2[childKey[2]].map((child3: any, l: number) => {
                          return {
                            ...child3,
                            filterKey: filterKey ? filterKey : child3.filterKey,
                            // libraryId: child2.libraryId ? child2.libraryId : child2.id,
                            indexKey: [i, j, k, l],
                            [childKey[3]]: !child3[childKey[3]]
                              ? []
                              : child3[childKey[3]].map((child4: any, m: number) => {
                                  return {
                                    ...child4,
                                    filterKey: filterKey ? filterKey : child4.filterKey,
                                    // libraryId: child2.libraryId ? child2.libraryId : child2.id,
                                    indexKey: [i, j, k, l, m]
                                  };
                                })
                          };
                        })
                  };
                })
          };
        })
      };
    });

  return dataSources;
};

export const mapQuestions = ({
  dataSources,
  key = "",
  childKey = ["questions", "children"],
  filterKey = "",
  initIndexKey = []
}: {
  dataSources: any[];
  key?: string;
  childKey?: string[];
  filterKey?: string;
  initIndexKey?: number[];
}) => {
  if (!key) {
    return dataSources.map((question: any, i: number) => {
      const {
        id,
        rank,
        score,
        library: { id: libraryId, title, data, questionType, type, code, children }
      } = question;

      return {
        indexKey: [...initIndexKey, i],
        id,
        rank,
        title,
        libraryId,
        data,
        questionType,
        type,
        code,
        score,
        [childKey[0]]: children.map((child: any, j: number) => {
          return { ...child, libraryId: child.id, filterKey, indexKey: [...initIndexKey, i, j] };
        }),
        filterKey
      };
    });
  }

  return dataSources.map((question: any, i: number) => {
    const { id, rank /* title */ } = question;
    const mapSourceLibraries = question[key].map((library: any, j: number) => {
      const {
        library: { id: libraryId, code, title, type, questionType, data, children },
        id,
        rank
      } = library;

      return {
        indexKey: [...initIndexKey, i, j],
        id,
        rank,
        libraryId,
        code,
        title,
        type,
        questionType,
        data,
        [childKey[1]]: children.map((child: any, k: number) => {
          return { ...child, libraryId: child.id, filterKey, indexKey: [...initIndexKey, i, j, k] };
        }),
        filterKey
      };
    });

    return {
      indexKey: [...initIndexKey, i],
      id,
      rank,
      title: _.get(mapSourceLibraries, `[0].title`, ""),
      code: _.get(mapSourceLibraries, `[0].code`, ""),
      sourceId: _.get(mapSourceLibraries, `[0].id`, ""),
      libraryId: _.get(mapSourceLibraries, `[0].libraryId`, ""),
      data: _.get(mapSourceLibraries, `[0].data`, {}),
      questionType: _.get(mapSourceLibraries, `[0].questionType`, ""),
      type: _.get(mapSourceLibraries, `[0].type`, ""),
      children: _.get(mapSourceLibraries, `[0].children`, []),
      [childKey[0]]: mapSourceLibraries,
      filterKey
    };
  });
};

export const mapActivities = ({
  dataSources,
  key,
  childKey = ["activities", "children"],
  filterKey = ""
}: {
  dataSources: any[];
  key: string;
  childKey?: string[];
  filterKey?: string;
}) => {
  return dataSources.map((activity: any, i: number) => {
    const { id, rank, title } = activity;
    const mapCompetencySourceLibraries = activity[key].map((library: any, j: number) => {
      const {
        library: { id: libraryId, code, title, type, questionType, data, children },
        id,
        rank
      } = library;

      return {
        indexKey: [i, j],
        id,
        rank,
        libraryId,
        code,
        title,
        type,
        questionType,
        data,
        filterKey,
        [childKey[1]]: children.map((child: any, k: number) => {
          return { ...child, libraryId: child.id, filterKey, indexKey: [i, j, k] };
        })
      };
    });

    return {
      indexKey: [i],
      id,
      rank,
      title,
      filterKey,
      [childKey[0]]: mapCompetencySourceLibraries
    };
  });
};

export const referenceTitle = <T extends LooseObject>(data: T, keys: string[]) => {
  let tmp: LooseObject = {};
  keys.forEach((key: string) => {
    tmp[`${key}Title`] = data[key].title;
  });

  return tmp;
};

export const toSwiper = (dataSources: any[], key = ["questions", "questions"]) => {
  return dataSources.map(item => {
    return {
      ...item,
      libraries: _.get(item, `${key[0]}`, []).map((item2: any) => {
        return {
          ...item2,
          children: _.get(item2, `${key[1]}`, [])
        };
      })
    };
  });
};

export const toSwiperResource = (dataSources: any[]) => {
  return dataSources.map(item => {
    return {
      ...item,
      activities: item.questions.map((item2: any) => {
        return {
          ...item2,
          children: item2.questions
        };
      })
    };
  });
};
