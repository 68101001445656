import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  LIBRARY_TYPE,
  QUESTION_TYPE,
} from "../../constants/default-value.const";
import { MathJaxPreview } from "../mathjax-preview/mathjax-preview.component";
import { VideoPreview } from "../video-preview/video-preview.component";
import { useTranslation } from "react-i18next";
import { Divider, Tabs } from "antd";

export const LibraryViewDetail = ({ item }: { item?: any }) => {
  const { questionType, type } = item;
  const { t } = useTranslation();

  const { TabPane } = Tabs;
  const renderQuestion = (item: any, data: any = null) => {
    return (
      <div>
        <div>
          <Divider orientation="left">
            <b>{t("Question")} : </b>
          </Divider>
          <div
            style={{
              display: "flex",
              textAlign: "left",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            <MathJaxPreview math={_.get(item, "data.questionText")} />
          </div>
        </div>
        {data}
        <div>
          <Divider orientation="left">
            <b>{t("Solution")} : </b>
          </Divider>
          <div
            style={{
              display: "flex",
              backgroundColor: "#e6f4ff",
              border: "1px solid #91caff",
              textAlign: "left",
              borderRadius: "4px",
              marginTop: "5px",
              padding: "10px",
            }}
          >
            <MathJaxPreview math={_.get(item, "data.solution")} />
          </div>
        </div>
      </div>
    );
  };

  const renderView = () => {
    if (type === LIBRARY_TYPE.TEXT) {
      return (
        <div>
          <div>
            <Divider orientation="left">
              <b>{t("Description")}:</b>
            </Divider>
            <MathJaxPreview math={_.get(item, "data.description")} />
          </div>
        </div>
      );
    } else if (type === LIBRARY_TYPE.VIDEO) {
      return (
        <div>
          <div>
            <Tabs
              centered
              defaultActiveKey={(() => {
                if (_.get(item, "data.url", "")) return "1";
                if (_.get(item, "data.bunnyId", "")) return "2";
                if (_.get(item, "data.youtubeId", "")) return "3";
              })()}
            >
              <TabPane tab="Vimeo" key="1">
                <VideoPreview vimeoId={_.get(item, "data.url", "")} />
              </TabPane>
              <TabPane tab="Bunny" key="2">
                <VideoPreview bunnyId={_.get(item, "data.bunnyId", "")} />
              </TabPane>
              <TabPane tab="Youtube" key="3">
                <VideoPreview youtubeId={_.get(item, "data.youtubeId", "")} />
              </TabPane>
            </Tabs>
            <Divider orientation="left">
              <b>{t("Description")} : </b>
            </Divider>
            <MathJaxPreview math={_.get(item, "data.description")} />
          </div>
        </div>
      );
    } else if (questionType === QUESTION_TYPE.DRAG_DROP) {
      let answers = _.get(item, "data.answer", []).map((answer: any) => {
        return (
          <div key={answer.order} style={{ display: "flex" }}>
            <MathJaxPreview math={_.get(answer, "value1")} />
            {"<=>"}
            <MathJaxPreview math={_.get(answer, "value2")} />
          </div>
        );
      });
      return renderQuestion(item, answers);
    } else if (questionType === QUESTION_TYPE.FILL_GAP) {
      return renderQuestion(item);
      // } else if (questionType === QUESTION_TYPE.GROUP) {
      //   return <div>GROUP</div>;
    } else if (questionType === QUESTION_TYPE.INPUT) {
      const answer = (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            borderRadius: "4px",
            border: "1px solid #d4d4d4",
            marginTop: "15px",
            marginBottom: "15px",
            padding: "10px",
          }}
        >
          {/* {t('Answer')}: */}
          <MathJaxPreview math={_.get(item, "data.answer[0]")} />
        </div>
      );
      return renderQuestion(item, answer);
    } else if (questionType === QUESTION_TYPE.MCQ) {
      let answers = _.get(item, "data.answer", []).map((answer: any) => {
        return (
          <div
            key={answer.order}
            style={{
              display: "flex",
              textAlign: "center",
              borderRadius: "4px",
              backgroundColor: "#f6ffed",
              border: "1px solid #b7eb8f",
              marginTop: "12px",
              marginBottom: "12px",
              padding: "10px",
            }}
          >
            {answer.isRight ? (
              <CheckCircleOutlined
                style={{
                  color: "#52c41a",
                  margin: "auto 0",
                }}
              />
            ) : (
              <CloseCircleOutlined
                style={{
                  color: "#eb2f96",
                  margin: "auto 0",
                }}
              />
            )}{" "}
            &nbsp;&nbsp;
            {/* {answer.order + 1}*/}
            <MathJaxPreview math={_.get(answer, "text")} />
          </div>
        );
      });

      return renderQuestion(item, answers);
    } else if (questionType === QUESTION_TYPE.ORDER) {
      let answers = _.get(item, "data.answer", []).map((answer: any) => {
        return (
          <div style={{ paddingTop: "25px" }} key={answer.order}>
            <span>{answer.order + 1}</span>
            &nbsp;
            <MathJaxPreview math={_.get(answer, "value")} />
          </div>
        );
      });
      return renderQuestion(item, answers);
    }

    return null;
  };

  return renderView();
};
