import { Spin } from "antd";
import React from "react";
import { useApp } from "../../../../atom/app/app.hook";
import { Color } from "../../../../atom/color/color.types";
import { ModalWrapper } from "../../../../components/modal/modal.component";
import { ColorForm } from "./color-form.component";

interface ColorFormModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (value: Color) => void;
  dataSource?: Color;
}
export const ColorFormModal: React.FC<ColorFormModalProps> = ({
  visible,
  handleCancel,
  handleOk,
  dataSource
}) => {
  const { appLoading } = useApp();

  return (
    <ModalWrapper visible={visible}>
      <Spin spinning={appLoading}>
        <ColorForm dataSource={dataSource} handleCancel={handleCancel} handleOk={handleOk} />
      </Spin>
    </ModalWrapper>
  );
};
