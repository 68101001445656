// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { CompetitionTest } from "./competition-test.types";

export const competitionTestsState = atom<CompetitionTest[]>({
  key: "competitionTestsState",
  default: []
});

export const competitionTestMetaState = atom<Meta>({
  key: "competitionTestMetaState",
  default: {}
});

export const competitionTestState = atom<CompetitionTest | {}>({
  key: "competitionTestState",
  default: {}
});

export const competitionTestLoadingState = atom<boolean>({
  key: "competitionTestLoadingState",
  default: false
});
