import { Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect } from "react";
import { FormWrapper } from "../form/form.component";
import { InputForm } from "../form/input/input.component";
import { useTranslation } from "react-i18next";

export const FormActivityModal: React.FC<any> = ({
  visible,
  onOk,
  onCancel,
  title,
  dataSource
}) => {
  const [form] = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  });

  useEffect(() => {
    form.setFieldsValue({
      ...dataSource
    });
  }, [dataSource, form]);

  return (
    <Modal
      visible={visible}
      title={title}
      okText={t("Submit")}
      cancelText={t("Cancel")}
      onCancel={onCancel}
      maskClosable={false}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            onOk(values);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <FormWrapper form={form} name="formActivity">
        <InputForm name="id" hidden={true} />
        <InputForm
          name="title"
          label={t("Title")}
          rules={[{ required: true, message: "Please input the title!" }]}
        />
      </FormWrapper>
    </Modal>
  );
};
