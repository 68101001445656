// https://codesandbox.io/s/github/ozcanzaferayan/recoil-sample?file=/src/components/TodoListFilters.js

import { atom } from "recoil";
import { Meta } from "../../types/global.types";
import { Review } from "./review.types";

export const reviewsState = atom<Review[]>({
  key: "reviewsState",
  default: []
});

export const reviewMetaState = atom<Meta>({
  key: "reviewMetaState",
  default: {}
});

export const reviewState = atom<Review | {}>({
  key: "reviewState",
  default: {}
});

export const reviewLoadingState = atom<boolean>({
  key: "reviewLoadingState",
  default: false
});
