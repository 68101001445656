import styled from "styled-components";
import { Layout, Avatar } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";

export const MainHeader = styled(Layout.Header)`
  background: #fff;
  display: flex;
  color: #0a3d62;
  position: fixed;
  /* justify-content: space-between; */
  gap: 16px;
  z-index: 998;
  width: calc(100% - 238px);
  padding: 0 16px;
  box-shadow: 0 2px 4px 0 rgba(145, 158, 171, .24);
  border-bottom: 0px solid rgba(145, 158, 171, 0.24);
  @media screen and (min-width: 750px) {
    &.menu-mobile {
      display: none;
    }
    &.menu-desktop {
      display: visibility;
    }
  }
  @media screen and (max-width: 750px) {
    width: 100%;
    &.menu-mobile {
      display: visibility;
    }
    &.menu-desktop {
      display: none;
    }
  }
`;

export const HeaderTitle = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`;

export const HeaderMenu = styled.div`
  height: 100%;
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;

  .ant-tabs-nav {
    margin: 0;
  }
`;

export const HeaderAction = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;
export const ButtonDrawerMenu = styled(MenuUnfoldOutlined)`
  cursor: pointer;
  font-size: 20px;
  margin-top: 23px;
`;

export const ButtonMobile = styled(Avatar)`
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  position: relative;
  height: 60px;
`;
