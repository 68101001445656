import { Layout } from "../../../components/layout/layout.component";
import { Row, Col, Input, Avatar } from "antd"
import { Container, FlexWrap, ElementFlex, FlexTitle } from "./usage.style"
import { FileSearchOutlined } from "@ant-design/icons"
import { FlexElement } from "../../elements/question/components/question.style";
import { Fragment } from "react";

export const UsageByDailyReportPage = () => {
  return (
    <Layout>
      <Row gutter={1}>
        <Col span={8}>
          <Container>
            <ElementFlex>
              <FlexTitle>Usage summary</FlexTitle>
            </ElementFlex>
          </Container>
          <Container style={{height: '250px'}}>
            <Row gutter={1}>
              {dataWidget.map((item,index)=> {
                return (
                  <Col span={12} key={index}>
                    <ElementFlex style={{height: '125%px'}}>
                      <ElementFlex className='avatar'>
                        {562 + index}
                      </ElementFlex>
                    </ElementFlex>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </Col>
        <Col span={16}>
          <Container>
            <FlexWrap>
              <ElementFlex style={{width: '100%'}}>
                <ElementFlex style={{float: 'left'}}>
                  <FlexTitle>Usage session by subject</FlexTitle>
                </ElementFlex>
                <ElementFlex style={{float: 'right'}}>
                  <ElementFlex>
                    <Input placeholder="Search" type='text' size='small' />
                  </ElementFlex>
                </ElementFlex>
              </ElementFlex>
            </FlexWrap>
          </Container>
          <Container style={{ height: '250px'}}>
            <Row gutter={[8, 8]}>
              {data.map((item,index)=> {
                return(
                  <Fragment key={index}>
                    <Col span={2}>
                      <Avatar shape="square" style={{ backgroundColor: item.color, color: 'gray' }}>
                        {item.type}
                      </Avatar>
                    </Col>
                    <Col span={22}>
                      <Avatar shape="square" style={{ backgroundColor: item.color, width: '100%', color: 'gray' }}>
                        {item.value} Session
                      </Avatar>
                    </Col>
                </Fragment>)
              })}
            </Row>
          </Container>
        </Col>
      </Row>

      <Row gutter={1} style={{marginTop: '1px'}}>
        <Col span={8}>
          <Container>
            <ElementFlex>
              <FlexTitle>Daily usage online</FlexTitle>
            </ElementFlex>
          </Container>
          <Container style={{height: '250px', overflow: 'scroll'}}>
            {plusData.map((item,index)=> {
              const rankIndex = index + 1;
              return (
                <FlexWrap key={index}>
                  <FlexElement style={{width: '10%'}}>
                    <FlexTitle>{rankIndex}.</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '30%'}}>
                    <FlexTitle>Moni Udom</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '25%'}}>
                    <FlexTitle>Grad 12</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '30%'}}>
                    <FlexTitle>2000 Coin</FlexTitle>
                  </FlexElement>
                  <FlexElement style={{width: '5%'}}>
                    <FileSearchOutlined style={{marginLeft: '3px'}}/>
                  </FlexElement>
                </FlexWrap>
              )
            })}
          </Container>
        </Col>

        <Col span={16}>
          <Container>
            <FlexWrap>
              <ElementFlex style={{width: '100%'}}>
                <ElementFlex style={{float: 'left'}}>
                  <FlexTitle>Usage by user grade</FlexTitle>
                </ElementFlex>
                <ElementFlex style={{float: 'right'}}>
                  <ElementFlex>
                    <Input placeholder="Search" type='text' size='small' />
                  </ElementFlex>
                </ElementFlex>
              </ElementFlex>
            </FlexWrap>
          </Container>
          <Container style={{ height: '250px'}}>
            <Row gutter={[8, 8]}>
              {data.map((item,index)=> {
                return(
                  <Fragment key={index}>
                    <Col span={2}>
                      <Avatar shape="square" style={{ backgroundColor: '#6dc8ec', color: 'gray' }}>
                        {item.type}
                      </Avatar>
                    </Col>
                    <Col span={22}>
                      <Avatar shape="square" style={{ backgroundColor: '#6dc8ec82', width: '100%', color: 'gray' }}>
                        {item.value} Session
                      </Avatar>
                    </Col>
                </Fragment>)
              })}
            </Row>
          </Container>
        </Col>
      </Row>
    </Layout>
  )
}

const dataWidget = [{id:1},{id:2},{id:3},{id:4}]
const plusData = [...dataWidget, ...dataWidget, ...dataWidget, ...dataWidget, ...dataWidget];

const data = [
  {
    type: 'MATH',
    value: 27,
    color: '#a1dad073'
  },
  {
    type: 'B',
    value: 25,
    color: '#5b8ff982'
  },
  {
    type: 'C',
    value: 18,
    color: '#5d70928f'
  },
  {
    type: 'D',
    value: 15,
    color: '#f6bd1782'
  },
  {
    type: 'E',
    value: 10,
    color: '#6f5ef987'
  },
  {
    type: 'F',
    value: 5,
    color: '#6dc8ec'
  },
];
