import { axios, config } from '../../utils/axios.utils';
import { generateQuery } from '../../utils/request.utils';
import { RestoreType, SortData } from '../../types/global.types';
import { ReviewFormValue } from './review.types';

export const getReviewsAsync = (option: {}) => {
  const url = generateQuery('/reviews', option, [
    'levelId',
    'subjectId',
    'topicId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getReviewAsync = (id: string) => {
  return axios
    .get(`/reviews/${id}`, config())
    .then((response) => response.data.data);
};

export const getReviewsSelectAsync = (option: {}) => {
  const url = generateQuery('/reviews/for/select-option', option, [
    'levelId',
    'subjectId',
    'topicId',
    'status',
    'premium',
    'comingSoon',
  ]);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const getReviewsTrashAsync = (option: {}) => {
  const url = generateQuery('/reviews/list/trash', option);
  return axios.get(url, config()).then((response) => response.data.data);
};

export const restoreReviewsAsync = (data: RestoreType) => {
  return axios
    .patch('/reviews/restore', data, config())
    .then((response) => response.data);
};

export const createReviewAsync = (data: ReviewFormValue) => {
  return axios
    .post('/reviews', data, config())
    .then((response) => response.data);
};

export const updateReviewAsync = (id: string, data: ReviewFormValue) => {
  return axios
    .put(`/reviews/${id}`, data, config())
    .then((response) => response.data);
};

export const deleteReviewAsync = (id: string) => {
  return axios
    .delete(`/reviews/${id}`, config())
    .then((response) => response.data);
};

export const deleteReviewSourceAsync = (id: string) => {
  return axios
    .delete(`/review-activities/${id}`, config())
    .then((response) => response.data);
};

export const deleteReviewSourceLibraryAsync = (id: string) => {
  return axios
    .delete(`/review-activity-libraries/${id}`, config())
    .then((response) => response.data);
};

export const sortReviewsAsync = (data: SortData) => {
  return axios
    .patch(`/reviews/set-rank`, data, config())
    .then((response) => response.data);
};

export const reviewsSyncToFirestoreAsync = (id: string[] = []) => {
  return axios
    .patch('/reviews/sync/to-firestore', { id }, config())
    .then((response) => response.data);
};

export const reviewUnsyncFromFirestore = async (id: string) => {
  return axios
    .delete(`/reviews/unsync-from-firestore/${id}`, config())
    .then((response) => response.data);
};
